import {
  Button,
  DateField,
  Grid,
  Row,
  Space,
  Table,
  TextField,
  Typography,
} from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { EFormatDateTimeForPDF, EMedia } from "constants/constant";
import dayjs from "dayjs";
import { ICustomerInvoice } from "interfaces/customerInvoice";
import { useMemo } from "react";
import { convertToTableColumns, customColumn, formatNumber, getDataSummary, getTotalAmounts } from "utils";
import { exportToCSV, exportToExcel, exportToPDF } from "utils/excelExporter";

type ServiceActiveProps = {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  invoiceCustomer: ICustomerInvoice[];
  invoiceCustomerModal: ICustomerInvoice[];
  customerDetail: ICustomerInvoice | undefined;
  columnHeader: string[];
  titleInvoiceCus: string;
  period: string;
  nameInvoice: string;
  trueFields: string[];
};
const { Title } = Typography;
const CustomerInvoiceDetail = (props: ServiceActiveProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const { push } = useNavigation();
  const {
    invoiceCustomer,
    customerDetail,
    setIsModalVisible,
    columnHeader,
    titleInvoiceCus,
    period,
    nameInvoice,
    trueFields,
    invoiceCustomerModal,
  } = props;

  const handleRedirect = (customer: ICustomerInvoice) => {
    push(`/appointment-detail-interpreter/${customer?.callId}`);
  };

  const collumns = useMemo(
    () => [
      {
        key: "apptNbr",
        title: "Call Nbr.",
        render: (value: string, record: ICustomerInvoice) => (
          <TextField
            value={value ? value : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleRedirect(record)}
          />
        ),
      },

      {
        key: "dateService",
        title: "Date of Service",
        render: (value: string) =>
          value ? <DateField format="LLL" value={value ? value : ""} /> : "",
      },

      {
        key: "billTotalAmount",
        title: "Total",
        render: (value: number) => (
          <TextField value={value ? `$${formatNumber(value)}` : "$0"} />
        ),
      },
      {
        key: "invoiceCreateUser",
        title: "Created By",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "invoiceCreateDate",
        title: "Created Date",
        render: (value: string) =>
          value ? <DateField format="LLL" value={value ? value : ""} /> : "",
      },
    ],
    []
  );

  const recordCustomerDetail = invoiceCustomer
    ?.map((process: ICustomerInvoice) => {
      const newItem = {
        ...process,
      };

      if (trueFields.includes("invoicePeriodFromDate")) {
        newItem.invoicePeriodFromDate =
          dayjs(process?.invoicePeriodFromDate).format(
            EFormatDateTimeForPDF.DATE
          ) || "";
      }
      if (trueFields.includes("invoicePeriodToDate")) {
        newItem.invoicePeriodToDate =
          dayjs(process?.invoicePeriodToDate).format(
            EFormatDateTimeForPDF.DATE
          ) || "";
      }
      if (trueFields.includes("invoiceCreateDate")) {
        newItem.invoiceCreateDate =
          dayjs(process?.invoiceCreateDate).format(
            EFormatDateTimeForPDF.DATE
          ) || "";
      }
      if (trueFields.includes("startTime")) {
        newItem.startTime = process?.startTime
          ? dayjs(process?.startTime).format(EFormatDateTimeForPDF.TIME)
          : "";
        newItem.startDate = process?.startTime
          ? dayjs(process?.startTime).format(EFormatDateTimeForPDF.DATE)
          : "";
      }
      if (trueFields.includes("finishTime")) {
        newItem.finishTime = process?.finishTime
          ? dayjs(process?.finishTime).format(EFormatDateTimeForPDF.TIME)
          : "";
        newItem.finishDate = process?.finishTime
          ? dayjs(process?.finishTime).format(EFormatDateTimeForPDF.DATE)
          : "";
      }
      if (
        trueFields.some((field) =>
          [
            "billItem1",
            "billItem1Subtotal",
            "billItem2",
            "billItem2Subtotal",
            "billItem3",
            "billItem3Subtotal",
            "billItem4",
            "billItem4Subtotal",
          ].includes(field)
        )
      ) {
        newItem.billItem = formatNumber(process?.billItem) || ("" as any);
      }
      if (trueFields.includes("billTotalAmount")) {
        newItem.billTotalAmount =
          formatNumber(process?.billTotalAmount) || ("" as any);
      }
      if (trueFields.includes("tax")) {
        newItem.tax = formatNumber(process?.tax) || ("" as any);
      }
      if (trueFields.includes("preTaxAmount")) {
        newItem.preTaxAmount =
          formatNumber(process?.preTaxAmount) || ("" as any);
      }
      if (trueFields.includes("typeOfCall")) {
        newItem.typeOfCall =
          process?.typeOfCall === EMedia.VIDEO ? EMedia.VIDEO : EMedia.AUDIO;
      }
      if (trueFields.includes("bOtherRef1")) {
        newItem.otherRef1 = process?.bOtherRef1 || "";
      }
      if (trueFields.includes("bOtherRef2")) {
        newItem.otherRef2 = process?.bOtherRef2 || "";
      }
      if (trueFields.includes("bOtherRef3")) {
        newItem.otherRef3 = process?.bOtherRef3 || "";
      }
      return newItem;
    })
    ?.map(
      ({
        callId,
        id,
        createId,
        callTime,
        dateService,
        bOtherRef,
        bOtherRef1,
        bOtherRef2,
        bOtherRef3,
        interpreterIds,
        interpreterId, 
        customerId,
        busPhone,
        invoiceCreateUser,
        ...rest
      }: any) => rest
    )
    ?.map((item: { [x: string]: any }) => {
      const formattedItem: { [key: string]: any } = {};
      const {apptNbr, startDate, finishDate, startTime, finishTime, ...agrs} = item;
      for (const key in {apptNbr, startDate, finishDate, startTime, finishTime, ...agrs}) {
        const formattedKey = key
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, function (str) {
            return str?.toUpperCase();
          });
          if (key === 'intId') {
            formattedItem['Interpreter Id'] = item[key];
            continue;
          }
        formattedItem[formattedKey] = item[key];
      }

      return formattedItem;
    });

  const handleExportExcel = () => {
    setIsModalVisible(false);
    exportToExcel(
      `Customer Invoice - Process#${nameInvoice}`,
      recordCustomerDetail,
      recordCustomerDetail?.length > 0
        ? customColumn(recordCustomerDetail)
        : columnHeader, `Total Number of Appointments: ${recordCustomerDetail.length}`, `Total Amounts: $${getTotalAmounts(invoiceCustomer).toFixed(2)}`
    );
  };

  const handleExportCSV = () => {
    setIsModalVisible(false);
    exportToCSV(
      recordCustomerDetail,
      `Customer Invoice - Process#${nameInvoice}`,
      recordCustomerDetail?.length > 0
        ? customColumn(recordCustomerDetail)
        : columnHeader, `Total Number of Appointments: ${recordCustomerDetail.length}`, `Total Amounts: $${getTotalAmounts(invoiceCustomer).toFixed(2)}`
    );
  };

  const general = columnHeader.map((key) => ({ key }));

  const handleExportPDF = () => {
    setIsModalVisible(false);
    if (recordCustomerDetail.length) {
      exportToPDF({
        columns: convertToTableColumns(recordCustomerDetail),
        data: recordCustomerDetail,
        fileName: `Customer Invoice - Process#${nameInvoice}.pdf`,
        name: titleInvoiceCus,
        date: period,
        nameInvoice: `Invoice#: ${nameInvoice}`,
        isMultiCol: true,
        totalRow: `Total Number of Appointments : ${recordCustomerDetail.length}`,
        totalAmounts: Number(getTotalAmounts(invoiceCustomer).toFixed(2))
      });
    } else {
      exportToPDF({
        columns: general,
        data: recordCustomerDetail,
        fileName: `Customer Invoice - Process#${nameInvoice}.pdf`,
        name: titleInvoiceCus,
        date: period,
        nameInvoice: `Invoice#: ${nameInvoice}`,
        isMultiCol: true,
        totalAmounts: Number(getDataSummary(invoiceCustomer).totalAmounts)
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="title-process-detail">
        <Row>
          <Title level={5}>
            <span className="title-invoice">
              Invoice #{customerDetail?.id} for{" "}
            </span>{" "}
            {customerDetail?.customer?.name}
          </Title>
        </Row>
        <Row>
          <Title level={5}>
            <span className="title-invoice">Call: </span>{" "}
            {invoiceCustomer?.length} Records(s) Found
          </Title>
        </Row>
      </div>
      <Table
        dataSource={invoiceCustomerModal?.map(
          (item: ICustomerInvoice, index: number) => ({
            ...item,
            key: index,
          })
        )}
        pagination={false}
        scroll={{ x: 800 }}
      >
        {collumns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
      <Space style={{ marginTop: "10px" }} wrap={isMobile}>
        <Button className="btn-create" onClick={handleExportExcel}>
          Excel
        </Button>
        <Button className="btn-create" onClick={handleExportCSV}>
          CSV
        </Button>
        <Button className="btn-create" onClick={handleExportPDF}>
          PDF
        </Button>
      </Space>
    </div>
  );
};

export default CustomerInvoiceDetail;
