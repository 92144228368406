import { EMedia } from "constants/constant";
import { SocketContext } from "hooks/useContext/useSocketContext";
import { VideoContext } from "hooks/useContext/useVideoContext";
import { Page404 } from "pages/404Page";
import { useCallback, useContext, useEffect } from "react";
import Connecting from "./connecting";
import Room from "./room";
import { isCustomer, isInterpreter } from "utils";

function ensureMediaPermissions() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) =>
      devices.every((device) => !(device.deviceId && device.label))
    )
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((mediaStream) =>
            mediaStream.getTracks().forEach((track) => track.stop())
          );
      }
    });
}

function getAudioPermission() {
  return navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then((mediaStream) => mediaStream)
    .catch((error) => console.error("error : ", error));
}

export default function VideoCall() {
  const socket = useContext(SocketContext);
  const { connect, isConnecting, room, disconnect } = useContext(VideoContext);

  useEffect(() => {
    if ((localStorage.getItem("type-media") as string) === EMedia.AUDIO && isInterpreter()) {
      getAudioPermission().then(() => {
        connect(
          localStorage.getItem("token-twilio") as string,
          localStorage.getItem("token-room") as string
        );
      });
    } else {
      ensureMediaPermissions()
        .then(() => navigator.mediaDevices.enumerateDevices())
        .catch((error) => console.log("error : ", error))
        .finally(() => {
          const id = setTimeout(() => {
            connect(
              localStorage.getItem("token-twilio") as string,
              localStorage.getItem("token-room") as string
            );
          }, 2000);
          return () => {
            clearTimeout(id);
          };
        });
    }
  }, [socket, connect]);

  const handleLogout = useCallback(() => {
    if (isCustomer() || isInterpreter()) {
      socket.emit("end-call", {
        clientCallId: socket.id,
        callId: localStorage.getItem("callId") as string,
      });
    }
    disconnect();
  }, [disconnect, socket]);

  useEffect(() => {
    socket.emit("join-call-note", {
      roomSid: localStorage.getItem("token-room"),
    });
  }, [socket]);

  return room ? ( !isConnecting ?  <Room room={room} handleLogout={handleLogout} /> : <Connecting />) : <Page404 />;
};
