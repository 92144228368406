import {
  AutoComplete,
  Button,
  Checkbox,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Space,
  Table,
  TextField,
} from "@pankod/refine-antd";
import React, { useEffect, useMemo, useState } from "react";
import "../../../../styles/style.less";
import { ServiceCreate } from "./create";
import { CustomModal } from "components/Modal/CustomModal";
import { useCustom, useTranslate } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { RATE_TYPE_API } from "api/baseApi";
import ServiceActive from "./active";
import { IService } from "interfaces/service";
import { ServiceUpdate } from "./update";

const { PlusSquareOutlined, SearchOutlined, CloseOutlined } = Icons;
export const ServiceList: React.FC = () => {
  const translate = useTranslate();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const [isModalVisibleCreate, setIsModalVisibleCreate] = useState(false);
  const [isModalVisibleUpdate, setIsModalVisibleUpdate] = useState(false);
  const [isModalVisibleActive, setIsModalVisibleActive] = useState(false);
  const [detailService, setDetailService] = useState<IService>();

  const [searchNameService, setSearchNameService] = useState("");

  const [page, setPage] = useState(1);
  const [activeService, setActiveService] = useState([]);

  const { data: servicesData, refetch } = useCustom({
    url: RATE_TYPE_API.getAll,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
        search: searchNameService,
      },
    },
    queryOptions: {
      onSuccess: (servicesData) => {
        setActiveService(servicesData?.data?.results);
      },
    },
  });

  const pageTotal = servicesData?.data.total;

  const collumns = useMemo(
    () => [
      {
        title: "Nbr.",
        key: "key",
        render: (value: number) => (
          <TextField
            value={
              value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
            }
          />
        ),
      },
      {
        key: "name",
        title: "Name",
        render: (value: string, record: IService) => (
          <TextField
            value={value ? value : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => edit(record)}
          />
        ),
      },

      {
        title: `Is Active`,
        key: "isActive",
        render: (value: boolean, record: IService) => (
          <Checkbox checked={value} onChange={() => active(record)} />
        ),
      },
    ],
    [page]
  );

  const handleCreate = () => {
    setIsModalVisibleCreate(!isModalVisibleCreate);
  };

  const edit = (payRateData: IService) => {
    setDetailService(payRateData);
    setIsModalVisibleUpdate(true);
  };

  const active = (payRateData: IService) => {
    setDetailService(payRateData);
    setIsModalVisibleActive(true);
  };

  const handleChange = (page: number) => {
    setPage(page);
  };

  const onChange = (serviceName: string) => {
    setSearchNameService(serviceName);
  };

  const handleRemoveService = () => {
    setSearchNameService("");
  };

  useEffect(() => {
    setPage(1);
  }, [searchNameService]);

  useEffect(() => {
    refetch();
  }, [isModalVisibleCreate, isModalVisibleUpdate, isModalVisibleActive]);

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Listing</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.service")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      pageHeaderProps={{
        extra: (
          <Space>
            <Button className="btn-create" onClick={handleCreate}>
              <PlusSquareOutlined /> {translate("service.title.create")}
            </Button>
          </Space>
        ),
      }}
    >
      <Form layout="vertical">
        <Form.Item label="Search Services">
          <AutoComplete
            showSearch
            style={{
              width: isMobile ? "70%" : "50%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            value={searchNameService}
            onChange={onChange}
          >
            <Input
              suffix={
                <>
                  {searchNameService && (
                    <CloseOutlined onClick={handleRemoveService} />
                  )}
                  <SearchOutlined />
                </>
              }
              bordered={false}
            />
          </AutoComplete>
        </Form.Item>
      </Form>

      <Table
        dataSource={activeService?.map((item: IService, index: number) => ({
          ...item,
          key: index,
        }))}
        rowKey="id"
        pagination={
          pageTotal > 10
            ? {
                current: page,
                total: pageTotal || 1,
                onChange: handleChange,
                showTotal: () => `${pageTotal} total`,
              }
            : false
        }
        scroll={{ x: 800 }}
      >
        {collumns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
      <CustomModal
        title={translate("service.label.title.info")}
        setIsModalVisible={setIsModalVisibleCreate}
        isModalVisible={isModalVisibleCreate}
        destroyOnClose
      >
        <ServiceCreate
          setIsModalVisible={setIsModalVisibleCreate}
          isModalVisible={isModalVisibleCreate}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>

      <CustomModal
        title={translate("service.label.title.info")}
        setIsModalVisible={setIsModalVisibleUpdate}
        isModalVisible={isModalVisibleUpdate}
      >
        <ServiceUpdate
          setIsModalVisible={setIsModalVisibleUpdate}
          isModalVisible={isModalVisibleUpdate}
          service={detailService}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>

      <CustomModal
        title="Confirmation"
        setIsModalVisible={setIsModalVisibleActive}
        isModalVisible={isModalVisibleActive}
        width={isMobile ? "100%" : "50%"}
      >
        <ServiceActive
          setIsModalVisible={setIsModalVisibleActive}
          isModalVisible={isModalVisibleActive}
          service={detailService}
          refetch={refetch}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>
    </List>
  );
};
