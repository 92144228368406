import {
  AntdLayout,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography
} from "@pankod/refine-antd";
import { useCustomMutation, useNavigation } from "@pankod/refine-core";
import { RESET_PASSWORD } from "api/baseApi";
import { EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { useState } from "react";
import { openNotification } from "utils";

export interface IForgotPassword {
  username: string;
}

const { Title } = Typography;
const MSG_NOTICE = "An email has been sent to reset your password";


export const ForgotPassword: React.FC = () => {
  const { mutate, isLoading } = useCustomMutation();
  const { push } = useNavigation();
  const [isSend, setIsSend] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (event: IForgotPassword) => {
    mutate(
      {
        url: RESET_PASSWORD,
        method: "post",
        values: {
          username: event.username,
        },
      },
      {
        onSuccess: (createData) => {
          openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
          setIsSend(true);
          form.resetFields();
        },
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);
        },
      }
    );
  };

  const handleLoginPage = () => {
    push("/login");
  };

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img
                style={{width: '120px'}}
                className="logo"
                src="/images/global/logo_v2.png"
                alt="Refine Logo"
              />
            </div>
            <Card title={<Title level={3} className="title">
              Reset your password
            </Title>} headStyle={{ borderBottom: 0 }}>
              <Form
                form={form}
                layout="vertical"
                onFinish={(event) => {
                  onFinish(event);
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  label="User Name"
                  name="username"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter a valid username",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trim()}
                >
                  <Input size="large" maxLength={50} />
                </Form.Item>

                <Form.Item name="resetPassword" valuePropName="checked" noStyle>
                  <Typography.Link className="back-to-login-page" onClick={handleLoginPage}>
                    Back to Login Page
                  </Typography.Link>
                </Form.Item>

                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  loading={isLoading}
                  className="btn-login"
                >
                  Reset Password
                </Button>
                {/* )} */}
              </Form>
            </Card>
          </div>
          <Row justify="center">
            <Typography.Text
              style={{ marginTop: 25, fontSize: 15, color: "#67be23" }}
            >
              {isSend ? MSG_NOTICE : ""}
            </Typography.Text>
          </Row>
        </Col>
      </Row>
    </AntdLayout>
  );
};
