import { IChatNotification } from "interfaces/chat";
import { ReactNode, createContext, useState } from "react";


interface IChatNotificationContext {
  chatNotification: IChatNotification;
  setChatNotification: React.Dispatch<React.SetStateAction<IChatNotification>>;
}

const ChatNotificationContext = createContext<IChatNotificationContext>(null!);

type ChatNotificationProviderProps = {
  children : ReactNode;
  value : React.Context<IChatNotificationContext>;
}

function ChatNotificationProvider({ children }: ChatNotificationProviderProps): JSX.Element {
  const [chatNotification, setChatNotification] = useState<IChatNotification>(
    null!
  );
  return (
    <ChatNotificationContext.Provider
      value={{ chatNotification, setChatNotification }}
    >
      {children}
    </ChatNotificationContext.Provider>
  );
}

export { ChatNotificationProvider, ChatNotificationContext };