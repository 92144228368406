import { Button, Spin } from "@pankod/refine-antd";
import { EMedia, EStatusNotification, WAITING_TIME } from "constants/constant";
import { ChatNotificationContext } from "hooks/useContext/useChatNotificationContext";
import { ChatStateContext } from "hooks/useContext/useChatStateContext";
import { SocketContext } from "hooks/useContext/useSocketContext";
import { useSoundCallContext } from "hooks/useContext/useSoundCallContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { openNotification } from "utils";
import "../../../styles/style.less";

const DURATION_TIME = 10;

const capitalizeFirstLetter = (str: string) => {
  return str.replace(str.charAt(0), str.charAt(0).toUpperCase());
}

export const RequesterCallModal = () => {
  const { missedSound, requestSound } = useSoundCallContext();
  const [countDown, setCountDown] = useState(WAITING_TIME);
  const { chatNotification } = useContext(ChatNotificationContext);
  const {  setChatState } = useContext(ChatStateContext);
  const socket = useContext(SocketContext);

  const handleLostCall = useCallback((socketEvent: string) => {
    requestSound.pause();
    missedSound.play();
    socket.emit(socketEvent, {
      callId: chatNotification.callId,
    });
    setChatState({ isAccept: false, isPending: false, isCancel: true });
  }, [requestSound, missedSound, socket, chatNotification?.callId, setChatState]);

  useEffect(() => {
    if (countDown > 0) {
      const idCountDown = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);
      return () => {
        clearInterval(idCountDown);
      };
    } else {
      handleLostCall('miss-call');
      const mediaType = capitalizeFirstLetter(localStorage.getItem('type-media')?.toLowerCase() || '');
      const suggestMedia = localStorage.getItem('type-media') === EMedia.VIDEO ? capitalizeFirstLetter(EMedia.AUDIO.toLowerCase()) : capitalizeFirstLetter(EMedia.VIDEO.toLowerCase());
      const description = `No ${capitalizeFirstLetter(mediaType)} Interpreter is currently available. You can click on the phone icon to get an '${suggestMedia} only' interpreter or try a ${mediaType.toLowerCase()} interpreter again`;
      const msg = 'Notification';
      openNotification(msg, description, EStatusNotification.INFO, DURATION_TIME);
    }
  }, [countDown, handleLostCall]);


  return (
    <div className="info-inter">
      <div className="info-name info-icon">
        <Spin size="large" />
      </div>
      <h3 className="info-des">
        Matching you with interpreter, please wait...
      </h3>
      <h5 className="info-des">
        Your estimated wait time is less than {countDown} seconds
      </h5>
      <div className="accept-cancel-call cancel-call-my-4">
        <div className="cancle-call">
          <Button onClick={() => handleLostCall('close-call')}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};
