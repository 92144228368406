import { ErrorComponent } from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import { Refine } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { Footer, Header, Layout, Sider } from "components/layout";
import { LoginPage } from "pages/admin/login";
import {
  authProvider,
} from "providers/authProvider";
import dataProvider from "providers/dataProvider";
import {
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import Resources from "components/resources";
import Routes from "components/routes";
import AccessControlerProvider from "providers/accessControlProvider";
import useSocketChatEvent from "hooks/useSocketChatEvent";
import PendingCallModal from "components/Modal/PendingCallModal";

function App() {
  const { t, i18n } = useTranslation();
  useSocketChatEvent();
  const i18nProvider = useMemo(
    () => ({
      translate: (key: string, params: object) => t(key, params),
      changeLocale: (lang: string) => i18n.changeLanguage(lang),
      getLocale: () => i18n.language,
    }),
    [i18n, t]
  );
  
  return (
    <>
      <Refine
        disableTelemetry
        dataProvider={dataProvider}
        routerProvider={{
          ...routerProvider,
          routes: Routes(),
        }}
        accessControlProvider={{
          can: AccessControlerProvider(),
        }}
        authProvider={authProvider}
        LoginPage={LoginPage}
        resources={
          Resources()
        }
        Header={Header}
        Sider={Sider}
        Footer={Footer}
        Layout={Layout}
        i18nProvider={i18nProvider}
        catchAll={<ErrorComponent />}
      />
      <PendingCallModal/>
    </>
  );
}

export default App;
