import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  Row,
  Select,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import { BaseKey, useCustom, useUpdate } from "@pankod/refine-core";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import {
  DEPARTMENT_API,
  STATE_API,
  SUB_DEPARTMENT_API,
  UPDATE_SUB_DEPARTMENT_API,
} from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ICheckboxChangeActive } from "interfaces";
import { IDepartment } from "interfaces/department";
import { ISub_Department } from "interfaces/sub_department";
import { axiosInstance } from "providers/axios";
import { useEffect, useState } from "react";
import { openNotification} from "utils";
import { SubDepartmentsProps } from "./interface";

export const SubDepartmentsUpdate = (props: SubDepartmentsProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const { setIsModalVisible, subDepartment, isModalVisible, updateSuccess } =
    props;
  const [messageErr, setMessageErr] = useState<ISub_Department>();
  const [subDepartmentEdit, setSubDepartmentEdit] = useState<ISub_Department>();

  const { form, formProps } = useForm<ISub_Department>({
    action: EAction.EDIT,
  });

  useEffect(() => {
    (async () => {
      const subDepartmentEditData = await axiosInstance.get(
        SUB_DEPARTMENT_API.getOne + `/${subDepartment?.id}`
      );

      if (subDepartmentEditData) {
        setSubDepartmentEdit(subDepartmentEditData?.data?.data);
      }
    })();
  }, [subDepartment]);

  const [departmentData, setDepartmentData] = useState([]);

  useEffect(() => {
    (async () => {
      if (subDepartmentEdit?.customer?.id !== undefined) {
        const subDepartmentEditData = await axiosInstance.get(
          DEPARTMENT_API.getByCustomer +
            `?id=${subDepartmentEdit?.customer?.id}&page=&limit=`
        );
        setDepartmentData(subDepartmentEditData?.data?.results);
      }
    })();
  }, [subDepartmentEdit?.customer?.id]);

  const { data: statesData } = useCustom({
    url: STATE_API.getAll,
    method: "get",
  });

  const { setFields } = form;

  const { mutate, data: updateData, isLoading } = useUpdate();

  const onFinish = (event: ISub_Department) => {
    mutate(
      {
        resource: UPDATE_SUB_DEPARTMENT_API,
        id: subDepartment?.id as BaseKey,
        values: {
          name: event.name,
          departmentId: departmentId,
          contactName: event.contactName,
          accessCode: event.accessCode,
          email: event.email,
          street: event.street,
          suiteNumber: event.suiteNumber,
          stateId: event.stateId,
          city: event.city,
          zipCode: event.zipCode,
          phoneNumber: event.phoneNumber,
          ext: event.ext,
          isActive: event.isActive,
        },
      },
      {
        onError: (error) => {
          
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  const onCheck = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ isActive: true });
    } else {
      form.setFieldsValue({ isActive: false });
    }
  };

  useEffect(() => {
    form.resetFields();
    setFields([
      {
        name: "name",
        value: subDepartmentEdit?.name,
      },

      {
        name: "customerId",
        value: subDepartmentEdit?.customer?.id,
      },
      {
        name: "contactName",
        value: subDepartmentEdit?.contactName,
      },
      {
        name: "accessCode",
        value: subDepartmentEdit?.accessCode,
      },
      {
        name: "departmentCode",
        value: subDepartmentEdit?.department?.accessCode,
      },
      {
        name: "email",
        value: subDepartmentEdit?.email,
      },
      {
        name: "street",
        value: subDepartmentEdit?.street,
      },
      {
        name: "suiteNumber",
        value: subDepartmentEdit?.suiteNumber,
      },

      {
        name: "stateId",
        value: subDepartmentEdit?.stateId,
      },
      {
        name: "city",
        value: subDepartmentEdit?.city,
      },
      {
        name: "zipCode",
        value: subDepartmentEdit?.zipCode,
      },
      {
        name: "phoneNumber",
        value: subDepartmentEdit?.phoneNumber,
      },
      {
        name: "ext",
        value: subDepartmentEdit?.ext,
      },
      {
        name: "isActive",
        value: subDepartmentEdit?.isActive,
      },
      { name: "accessCodeCus", value: subDepartmentEdit?.customer?.accessCode },
    ]);
  }, [subDepartment, subDepartmentEdit, form, isModalVisible, setFields]);

  const [departmentCode, setDepartmentCode] = useState();
  const [departmentId, setDepartmentId] = useState<number | string>();
  useEffect(() => {
    if (subDepartmentEdit?.departmentId) {
      setDepartmentId(subDepartmentEdit?.departmentId);
    }
  }, [subDepartmentEdit?.departmentId]);

  const onSelect = (departmentId: number, option: BaseOptionType) => {
    (async () => {
      const department = await axiosInstance.get(
        DEPARTMENT_API.getOne + `/${departmentId}`
      );

      setDepartmentCode(department?.data?.accessCode);

      setDepartmentId(departmentId);

      form.setFieldsValue({
        departmentCode: department?.data?.accessCode,
        departmentId: department?.data?.id,
      });
    })();
  };

  useEffect(() => {
    if (updateData?.data.status === 200) {
      form.resetFields();
        openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
      updateSuccess();
      setIsModalVisible(false);
      setMessageErr(messageErr);
    }
  }, [updateData, form, setIsModalVisible, messageErr]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as ISub_Department)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          {" "}
          <Form.Item
            label="Customer"
            name="customer"
            rules={[
              {
                required: false,
                message: "Please enter name",
              },
            ]}
            initialValue={subDepartmentEdit?.customer?.name}
          >
            <Input disabled />
          </Form.Item>
          {messageErr?.customerId && (
            <Typography.Text type="danger">
              {messageErr.customerId[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="Access Code"
            name="accessCodeCus"
            rules={[
              {
                required: false,
                message: "Please enter Access Code",
              },
            ]}
            initialValue={subDepartmentEdit?.customer?.accessCode}
          >
            <Input disabled />
          </Form.Item>
          {messageErr?.accessCode && (
            <Typography.Text type="danger">
              {messageErr.accessCode[0]}
            </Typography.Text>
          )}
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="Department"
            name="departmentId"
            rules={[
              {
                required: true,
                message: "Please select department",
              },
            ]}
            initialValue={subDepartmentEdit?.department?.name}
          >
            <Select
              optionFilterProp="children"
              filterOption={(
                input,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={onSelect}
            >
              {departmentData?.map((item: IDepartment, index: number) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {messageErr?.departmentId && (
            <Typography.Text type="danger">
              {messageErr.departmentId}
            </Typography.Text>
          )}

          <Form.Item
            label="Department Code"
            name="departmentCode"
            rules={[
              {
                required: false,
                message: "Please select Department Code",
              },
            ]}
            initialValue={
              subDepartmentEdit
                ? subDepartmentEdit?.department?.accessCode
                : departmentCode
                ? departmentCode
                : ""
            }
          >
            <Input disabled />
          </Form.Item>
          {messageErr?.departmentCode && (
            <Typography.Text type="danger">
              {messageErr.departmentCode}
            </Typography.Text>
          )}
        </Col>
      </Row>
      <Form.Item
        label="Sub-department Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please enter Sub-department Name",
          },
        ]}
        normalize={(value, prevVal, prevVals) => value.trimStart()}
        initialValue={subDepartmentEdit?.name}
      >
        <Input maxLength={50} />
      </Form.Item>
      {messageErr?.name && (
        <Typography.Text type="danger">{messageErr.name[0]}</Typography.Text>
      )}
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="Contact Name"
            name="contactName"
            rules={[
              {
                required: true,
                message: "Please enter Contact Name",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
            initialValue={subDepartmentEdit?.contactName}
          >
            <Input maxLength={200} />
          </Form.Item>
          {messageErr?.contactName && (
            <Typography.Text type="danger">
              {messageErr.contactName[0]}
            </Typography.Text>
          )}

          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
            initialValue={subDepartmentEdit?.email}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.email && (
            <Typography.Text type="danger">
              {messageErr.email[0]}
            </Typography.Text>
          )}

          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              {
                required: true,
                pattern: /^(?:\d*)$/,
                message: "Must contain 10 numbers only",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
            initialValue={subDepartmentEdit?.phoneNumber}
          >
            <Input maxLength={10} />
          </Form.Item>
          {messageErr?.phoneNumber && (
            <Typography.Text type="danger">
              {messageErr.phoneNumber[0]}
            </Typography.Text>
          )}

          <Form.Item
            label="Ext"
            name="ext"
            rules={[
              {
                pattern: /^(?:\d*)$/,
                message: "Please enter a valid Ext number!",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
            initialValue={subDepartmentEdit?.ext}
          >
            <Input maxLength={5} />
          </Form.Item>
          {messageErr?.ext && (
            <Typography.Text type="danger">{messageErr.ext[0]}</Typography.Text>
          )}

          <Form.Item
            label="Zip Code/Postal Code"
            name="zipCode"
            rules={[
              {
                required: true,
                message: "Please enter Zip Code",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
            initialValue={subDepartmentEdit?.zipCode}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.zipCode && (
            <Typography.Text type="danger">
              {messageErr.zipCode[0]}
            </Typography.Text>
          )}
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="Street Address"
            name="street"
            rules={[
              {
                required: true,
                message: "Please enter Street Address",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
            initialValue={subDepartmentEdit?.street}
          >
            <Input maxLength={200} />
          </Form.Item>
          {messageErr?.street && (
            <Typography.Text type="danger">
              {messageErr.street[0]}
            </Typography.Text>
          )}

          <Form.Item
            label="Suite Number"
            name="suiteNumber"
            rules={[
              {
                required: false,
                message: "Please enter Suite Number",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
            initialValue={subDepartmentEdit?.suiteNumber}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.suiteNumber && (
            <Typography.Text type="danger">
              {messageErr.suiteNumber[0]}
            </Typography.Text>
          )}

          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
                message: "Please enter City",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
            initialValue={subDepartmentEdit?.city}
          >
            <Input maxLength={200} />
          </Form.Item>
          {messageErr?.city && (
            <Typography.Text type="danger">
              {messageErr.city[0]}
            </Typography.Text>
          )}

          <Form.Item
            label="State/Province"
            name="stateId"
            rules={[
              {
                required: true,
                message: "Please enter State/Province",
              },
            ]}
            initialValue={subDepartmentEdit?.stateId}
          >
            <Select
              allowClear
              showSearch
              placeholder="Select state"
              optionFilterProp="children"
              filterOption={(
                input,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {statesData?.data?.map((item: IDepartment, index: number) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {messageErr?.stateId && (
            <Typography.Text type="danger">
              {messageErr.stateId[0]}
            </Typography.Text>
          )}

          <Form.Item
            label="Sub-department Code"
            name="accessCode"
            rules={[
              {
                required: false,
                message: "Please select Sub-department Code",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
            initialValue={subDepartmentEdit?.accessCode}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.accessCode && (
            <Typography.Text type="danger">
              {messageErr.accessCode}
            </Typography.Text>
          )}
        </Col>
      </Row>
      <Form.Item
        name="isActive"
        valuePropName="checked"
        initialValue={subDepartmentEdit?.isActive}
      >
        <Checkbox
          onChange={(event) => {
            onCheck(event);
          }}
        >
          Is Active
        </Checkbox>
      </Form.Item>
      {messageErr?.isActive && (
        <Typography.Text type="danger">{messageErr.isActive}</Typography.Text>
      )}
      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
