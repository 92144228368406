import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  Row,
  useForm,
} from "@pankod/refine-antd";
import { BaseKey, useUpdate } from "@pankod/refine-core";
import { UPDATE_LANGUAGES_API } from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ICheckboxChangeActive } from "interfaces";
import { ILanguage } from "interfaces/languages";
import { useEffect } from "react";
import { openNotification } from "utils";
import { LanguageProps } from "./interface";

export const LanguageUpdate = (props: LanguageProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const { setIsModalVisible, language, isModalVisible, page, handleChange } =
    props;

  const { form, formProps } = useForm<ILanguage>({
    action: EAction.EDIT,
  });

  const { setFields } = form;

  const { mutate, data: updateData, isLoading } = useUpdate();

  const onFinish = (event: ILanguage) => {
    mutate(
      {
        resource: UPDATE_LANGUAGES_API,
        id: language?.id as BaseKey,
        values: {
          name: event.name,
          isActive: event.isActive,
          telephone: event.telephone,
          video: event.video,
        },
      },
      {
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);
        },
      }
    );
  };

  const onCheck = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ isActive: true });
    } else {
      form.setFieldsValue({ isActive: false });
    }
  };

  const onCheckTelephone = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ telephone: true });
    } else {
      form.setFieldsValue({ telephone: false });
    }
  };
  const onCheckVideo = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ video: true });
    } else {
      form.setFieldsValue({ video: false });
    }
  };

  useEffect(() => {
    form.resetFields();
    setFields([
      {
        name: "name",
        value: language?.name,
      },
      {
        name: "isActive",
        value: language?.isActive,
      },
    ]);
  }, [language, form, isModalVisible]);

  useEffect(() => {
    if (updateData?.data.status == 200) {
      form.resetFields();
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
      handleChange(page);
      setIsModalVisible(false);
    }
  }, [updateData, form, setIsModalVisible]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as ILanguage)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
            initialValue={language?.name}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input size="large" maxLength={50} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={12}>
          <Form.Item
            label="
            "
            name="isActive"
            initialValue={language?.isActive}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(event) => {
                onCheck(event);
              }}
              checked={language?.isActive}
            >
              Is Active
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 12 : 4}>
          <Form.Item
            name="telephone"
            valuePropName="checked"
            initialValue={language?.telephone}
          >
            <Checkbox
              onChange={(event) => {
                onCheckTelephone(event);
              }}
            >
              Audio
            </Checkbox>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={isMobile ? 12 : 4}>
          <Form.Item
            name="video"
            valuePropName="checked"
            initialValue={language?.video}
          >
            <Checkbox
              onChange={(event) => {
                onCheckVideo(event);
              }}
            >
              Video
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Update
        </Button>
      </div>
    </Form>
  );
};
