import {
  Button,
  Checkbox,
  DateField,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Space,
  Table,
  TextField,
} from "@pankod/refine-antd";
import React, { useEffect, useMemo, useState } from "react";
import "../../../../../styles/style.less";
import { StandardBillRatesCreate } from "./create";
import { IStandard } from "interfaces/standard";
import { CustomModal } from "components/Modal/CustomModal";
import { useCustom, useTranslate, useNavigation } from "@pankod/refine-core";
import { StandardBillRatesUpdate } from "./update";
import { Breadcrumb } from "antd";
import { BILL_RATE_API, CUSTOMER_API } from "api/baseApi";
import BillRateActive from "./active";
import RouterProvider from "@pankod/refine-react-router-v6";
import { ELanguageType } from "constants/constant";

const { PlusSquareOutlined, SearchOutlined } = Icons;

export const StandardBillRatesList: React.FC = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const { useParams } = RouterProvider;
  const { goBack } = useNavigation();
  const params = useParams();
  const [isModalVisibleCreate, setIsModalVisibleCreate] = useState(false);
  const [isModalVisibleUpdate, setIsModalVisibleUpdate] = useState(false);
  const [isModalVisibleActive, setIsModalVisibleActive] = useState(false);
  const [detailBillRate, setDetailBillRate] = useState<IStandard>();

  const [searchValueBillRate, setSearchValueBillRate] = useState("");
  const [activeBillRate, setActiveBillRate] = useState<IStandard[]>([]);

  const translate = useTranslate();

  const [page, setPage] = useState(1);

  const { data: billRatesData, refetch } = useCustom({
    url: BILL_RATE_API.getCustomer,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
        id: params.slug,
        search: searchValueBillRate,
      },
    },
    queryOptions: {
      onSuccess: (billRatesData) => {
        setActiveBillRate(billRatesData?.data?.results);
      },
    },
  });

  const { data: customerData } = useCustom({
    url: CUSTOMER_API.getOne + `/${params.slug}`,
    method: "get",
  });

  const pageTotal = billRatesData?.data.total;

  const collumns = useMemo(
    () => [
      {
        key: "rateType",
        title: "Rate Type",
        render: (value: IStandard, record: IStandard) => (
          <TextField
            value={value ? value.name : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => edit(record)}
          />
        ),
      },
      {
        key: "billRate",
        title: `Bill Rate`,
        render: (value: number) => <TextField value={value ? value : ""} />,
      },
      {
        key: "createAt",
        title: `Effective Date`,
        render: (value: string) =>
          value ? <DateField format="LL" value={value ? value : ""} /> : "",
      },
      {
        key: "languageType",
        title: `Language`,
        render: (value: number) => (
          <TextField
            value={
              value === 0
                ? ELanguageType.ALL_LANGUAGE
                : value === 1
                ? ELanguageType.AMERICAN
                : value === 2
                ? ELanguageType.SPANISH
                : ""
            }
          />
        ),
      },

      {
        title: `Is Active`,
        key: "isActive",
        render: (value: boolean, record: IStandard) => (
          <Checkbox checked={value} onChange={() => active(record)} />
        ),
      },
    ],
    []
  );

  const handleCreate = () => {
    setIsModalVisibleCreate(!isModalVisibleCreate);
  };

  const edit = (billRateData: IStandard) => {
    setDetailBillRate(billRateData);
    setIsModalVisibleUpdate(true);
  };

  const active = (billRateData: IStandard) => {
    setDetailBillRate(billRateData);
    setIsModalVisibleActive(true);
  };

  const handleChange = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    refetch();
  }, [isModalVisibleCreate, isModalVisibleUpdate, isModalVisibleActive]);

  useEffect(() => {
    setPage(1);
  }, [searchValueBillRate]);

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Listing</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.billRate")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      pageHeaderProps={{
        extra: (
          <Space>
            <Button className="btn-create" onClick={handleCreate}>
              <PlusSquareOutlined /> {translate("standard.title.create")}
            </Button>
          </Space>
        ),
      }}
    >
      <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
        <Form.Item
          label="Customer Name"
          className={!isMobile ? "search-item" : ""}
        >
          <Input
            style={{
              width: "100%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            value={customerData?.data?.name}
            bordered={false}
            size="large"
            disabled
          />
        </Form.Item>
        <Form.Item
          label="Search Rates"
          className={!isMobile ? "search-item" : ""}
        >
          <Input
            style={{
              width: "100%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            size="large"
            onChange={(event) => {
              setSearchValueBillRate(event.target.value);
            }}
            suffix={<SearchOutlined />}
          />
        </Form.Item>
      </Form>

      <Table
        dataSource={activeBillRate?.map((item: IStandard, index: number) => ({
          ...item,
          key: index,
        }))}
        rowKey="id"
        pagination={
          pageTotal > 10
            ? {
                current: page,
                total: pageTotal || 1,
                onChange: handleChange,
              }
            : false
        }
        scroll={{ x: 800 }}
      >
        {collumns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
      <Button style={{ marginTop: "10px" }} onClick={() => goBack()}>
        Back
      </Button>
      <CustomModal
        title="Bill Rate Information"
        setIsModalVisible={setIsModalVisibleCreate}
        isModalVisible={isModalVisibleCreate}
      >
        <StandardBillRatesCreate
          setIsModalVisible={setIsModalVisibleCreate}
          isModalVisible={isModalVisibleCreate}
          customerId={params.slug}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>

      <CustomModal
        title="Bill Rate Information"
        setIsModalVisible={setIsModalVisibleUpdate}
        isModalVisible={isModalVisibleUpdate}
      >
        <StandardBillRatesUpdate
          setIsModalVisible={setIsModalVisibleUpdate}
          isModalVisible={isModalVisibleUpdate}
          billRate={detailBillRate}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>

      <CustomModal
        title="Confirmation"
        setIsModalVisible={setIsModalVisibleActive}
        isModalVisible={isModalVisibleActive}
        width={isMobile ? "100%" : "50%"}
      >
        <BillRateActive
          setIsModalVisible={setIsModalVisibleActive}
          isModalVisible={isModalVisibleActive}
          billRate={detailBillRate}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>
    </List>
  );
};
