import {
  Col,
  Form,
  Input,
  Row,
  Typography,
  Select,
  Checkbox,
  Grid,
} from "@pankod/refine-antd";
import { useCustom } from "@pankod/refine-core";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { STATE_API } from "api/baseApi";
import { EAction } from "constants/constant";
import {
  addressLeftTabPane,
  addressRightTabPane,
  IFormFieldCustom,
} from "../DataFieldCustomer";
import "../styles.css";

const { Title } = Typography;
type AddressTabPaneProps = {
  option?: string;
  isSameAddress?: boolean;
  handleIsSameAddress?: (params: CheckboxChangeEvent) => void;
};
interface IOptionObject {
  id: number;
  isActive?: boolean;
  name: string;
  createAt?: string;
}
export const AddressTabPane = (props: AddressTabPaneProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const { option, isSameAddress, handleIsSameAddress } = props;
  const { data: stateData, refetch } = useCustom({
    url: STATE_API.getAll,
    method: "get",
  });
  return (
    <Form.List name="addressTabPane">
      {(field, { add, remove }) => {
        return (
          <Row gutter={16}>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              <Title level={5} className="title-address">
                Physical Address
              </Title>
              {addressLeftTabPane.map((item: IFormFieldCustom) => (
                <Form.Item
                  label={item.label}
                  name={item.nameProp}
                  key={item.key}
                  rules={[
                    {
                      required: item.required,
                      message: item.message,
                    },
                  ]}
                  className="form-item-py"
                  normalize={(value, prevVal, prevVals) => value.trimStart()}
                >
                  <Input
                    readOnly={option === EAction.DETAIL}
                    size="large"
                    maxLength={50}
                  />
                </Form.Item>
              ))}

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please select state / province",
                  },
                ]}
                label="State / Province"
                name="stateId"
              >
                <Select
                  allowClear
                  showSearch
                  size="large"
                  disabled={option === EAction.DETAIL}
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {stateData?.data?.map(
                    (item: IOptionObject, index: number) => (
                      <Select.Option key={index} value={item.id}>
                        {item.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              <Row>
                <Col span={isMobile ? 24 : 12}>
                  <Title level={5} className="title-address">
                    Mailing Address
                  </Title>
                </Col>

                <Col span={12}>
                  {option !== EAction.DETAIL && (
                    <Checkbox
                      disabled={option === EAction.DETAIL}
                      checked={isSameAddress}
                      onChange={handleIsSameAddress}
                    >
                      Same as Address
                    </Checkbox>
                  )}
                </Col>
              </Row>

              <Form.Item
                label="M.Address Line 1"
                name={isSameAddress ? "address1" : "mAddress1"}
                className="form-item-py"
                normalize={(value, prevVal, prevVals) => value.trimStart()}
              >
                <Input
                  readOnly={option === EAction.DETAIL}
                  size="large"
                  maxLength={50}
                />
              </Form.Item>

              <Form.Item
                label="M.Address Line 2"
                name={isSameAddress ? "address2" : "mAddress2"}
                className="form-item-py"
                normalize={(value, prevVal, prevVals) => value.trimStart()}
              >
                <Input
                  readOnly={option === EAction.DETAIL}
                  size="large"
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item
                label="M.Address Line 3"
                name={isSameAddress ? "address3" : "mAddress3"}
                className="form-item-py"
                normalize={(value, prevVal, prevVals) => value.trimStart()}
              >
                <Input
                  readOnly={option === EAction.DETAIL}
                  size="large"
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item
                className="form-item-py"
                label="M.City"
                name={isSameAddress ? "city" : "mCity"}
                normalize={(value, prevVal, prevVals) => value.trimStart()}
              >
                <Input
                  readOnly={option === EAction.DETAIL}
                  size="large"
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item
                className="form-item-py"
                label="M. Zip Code / M. Postal Code"
                name={isSameAddress ? "zipCode" : "mZipCode"}
                normalize={(value, prevVal, prevVals) => value.trimStart()}
              >
                <Input
                  readOnly={option === EAction.DETAIL}
                  size="large"
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item
                label="M. State / M. Province"
                name={isSameAddress ? "stateId" : "mStateId"}
              >
                <Select
                  disabled={option === EAction.DETAIL}
                  allowClear
                  showSearch
                  size="large"
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {stateData?.data?.map(
                    (item: IOptionObject, index: number) => (
                      <Select.Option key={index} value={item.id}>
                        {item.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        );
      }}
    </Form.List>
  );
};
