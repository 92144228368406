import * as FileSaver from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";

const FILE_EXTENSION = ".xlsx";
interface IExportData {
  [key: string]: any;
}

export interface TableColumn {
  key: string;
}

type ExportToPDFProps = {
  data?: any;
  fileName: string;
  columns: TableColumn[];
  name?: string;
  date?: any;
  nameInvoice?: string;
  nameCustomer?: string;
  didParseCell?: any;
  totalRow?: string;
  totalAmounts?: number;
  colSummary?: TableColumn[];
  dataSummary?: any;
  totalSummary?: number;
  isMultiCol?: boolean;
};

type ExportDataArray = IExportData[];

const createWorkSheet = (totalNumber: string | undefined, totalAmounts: string | undefined, headerColumn: any, data: ExportDataArray) => {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  if (totalAmounts && totalNumber) {
    XLSX.utils.sheet_add_aoa(ws, [[totalNumber, totalAmounts]], { origin: 'A1' });
  }
  XLSX.utils.sheet_add_aoa(ws, [headerColumn], { origin: totalAmounts ? 'A3' : 'A1' });
  XLSX.utils.sheet_add_json(ws, data, { origin: totalAmounts ? 'A4' : 'A2', skipHeader: true });
  return ws;
}

export const exportToExcel = (
  fileName: string,
  data: ExportDataArray,
  columns: any,
  totalNumber?: string,
  totalAmounts?: string
) => {
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, createWorkSheet(totalNumber, totalAmounts, columns, data), 'data');
  XLSX.writeFile(wb, fileName + FILE_EXTENSION);
};

export const exportToCSV = (csvData: any, fileName: string, columns: any, totalNumber?: string, totalAmounts?: string) => {
  const csvString = XLSX.utils.sheet_to_csv(createWorkSheet(totalNumber, totalAmounts, columns, csvData));
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });
  FileSaver.saveAs(blob, `${fileName}.csv`);
};
interface IExportInvoiceToExcel {
  fileName: string;
  dataInvoice: any,
  headerColInvoice: any;
  dataSummary: any;
  headerColSummary: any;
  totalPay?: number;
  totalRow?: string;
}
const creatWorkSheetInvoice = ({
  fileName,
  dataInvoice,
  headerColInvoice,
  headerColSummary,
  dataSummary,
  totalPay,
}: IExportInvoiceToExcel) => {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_aoa(ws, [headerColSummary], { origin: 'A1' });
  XLSX.utils.sheet_add_json(ws, dataSummary, { origin: 'A2', skipHeader: true });

  XLSX.utils.sheet_add_aoa(ws, [[`Total : $${totalPay}`]], { origin: 'A4' });

  XLSX.utils.sheet_add_aoa(ws, [headerColInvoice], { origin: 'A6' });
  XLSX.utils.sheet_add_json(ws, dataInvoice, { origin: 'A7', skipHeader: true });
  return ws;
}
export const exportInvoiceToExcel = ({
  fileName,
  dataInvoice,
  headerColInvoice,
  headerColSummary,
  dataSummary,
  totalPay,
}: IExportInvoiceToExcel) => {
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, creatWorkSheetInvoice({
    fileName,
    dataInvoice,
    headerColInvoice,
    headerColSummary,
    dataSummary,
    totalPay
  }), 'data');
  XLSX.writeFile(wb, fileName + FILE_EXTENSION);
}
export const exportInvoiceToCSV = ({
  fileName,
  dataInvoice,
  headerColInvoice,
  headerColSummary,
  dataSummary,
  totalPay,
}: IExportInvoiceToExcel) => {
  const csvString = XLSX.utils.sheet_to_csv(creatWorkSheetInvoice({
    fileName,
    dataInvoice,
    headerColInvoice,
    headerColSummary,
    dataSummary,
    totalPay
  }));
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });
  FileSaver.saveAs(blob, `${fileName}.csv`);
};
const UNIT_PDF = 'pt';
const SIZE_PDF = 'A3';
const ORIENTTATION_PDF = 'landscape';
const FONT_FAMILY_PDF = 'Helvetica';
const TITLE_PDF = '911 Interpreters';
const FONT_SIZE_PDF = {
  XS: 11,
  SM: 13,
  MD: 16
}
const FONT_WEIGHT_PDF = {
  BOLD: 'bold',
  NORMAL: 'normal'
}
const TEXT_ALIGN_LEFT = 40;

export const exportToPDF = ({
  columns,
  data,
  fileName,
  name = '',
  date,
  nameInvoice,
  nameCustomer,
  didParseCell,
  totalRow,
  totalAmounts,
  colSummary,
  dataSummary,
  totalSummary,
  isMultiCol
}: ExportToPDFProps) => {
  const doc = new jsPDF(ORIENTTATION_PDF, UNIT_PDF, SIZE_PDF);
  const halfPastWidth = doc.internal.pageSize.getWidth() / 2;
  doc.setFont(FONT_FAMILY_PDF);

  //i init Date
  const offsetXDate =
    doc.internal.pageSize.getWidth() -
    doc.getStringUnitWidth(new Date().toLocaleString()) * doc.getFontSize() +
    15;
  doc.setFontSize(FONT_SIZE_PDF.XS);
  doc.text(new Date().toLocaleString(), offsetXDate, 30);

  //i Title
  doc.setFont(FONT_FAMILY_PDF, FONT_WEIGHT_PDF.BOLD);
  doc.setFontSize(FONT_SIZE_PDF.MD);
  doc.text(TITLE_PDF, halfPastWidth, 40, {
    align: "center",
  });

  //i Sub title
  doc.setFont(FONT_FAMILY_PDF, FONT_WEIGHT_PDF.NORMAL);
  doc.setFontSize(FONT_SIZE_PDF.SM);
  doc.text(name, halfPastWidth, 60, {
    align: "center",
  });
  doc.text(date || "", halfPastWidth, 80, {
    align: "center",
  });

  doc.setFont(FONT_FAMILY_PDF, FONT_WEIGHT_PDF.BOLD);
  doc.setFontSize(FONT_SIZE_PDF.SM);
  doc.text(nameInvoice || "", TEXT_ALIGN_LEFT, 110);

  doc.setFont(FONT_FAMILY_PDF, FONT_WEIGHT_PDF.BOLD);
  doc.setFontSize(FONT_SIZE_PDF.SM);
  doc.text(nameCustomer || "", TEXT_ALIGN_LEFT, date ? 130 : 110);

  //i Total Row && total amount money
  totalRow && doc.text(totalRow, TEXT_ALIGN_LEFT, 130);
  totalAmounts && doc.text(`Total Amounts : $${totalAmounts}`, TEXT_ALIGN_LEFT, 150);

  let offsetYSummaryTab: number;

  const getOffsetYCursor = () => {
    if (name && date && nameInvoice && nameCustomer) return 140;
    if (name && date && nameInvoice) return 170;
    if ((name && date) || (name && nameInvoice)) {
      if (totalAmounts) return 170;
      if (colSummary && offsetYSummaryTab) return offsetYSummaryTab + 40;
      return 130;
    }
    if (name) return 80;
    if(nameInvoice) return 120;
    return 55;
  }

  if (colSummary) {
    autoTable(doc, {
      startY: getOffsetYCursor(),
      head: [colSummary!.map(col => col.key)],
      body: dataSummary?.map((row: any) => colSummary?.map(col => row[col.key])),
      styles: {
        overflow: "linebreak",
        fontSize: 8,
        cellPadding: 5,
      },
      tableWidth: halfPastWidth / 2,
      didParseCell: didParseCell,
      didDrawPage: d => { offsetYSummaryTab = d?.cursor?.y || 0 },
    });
    doc.text(`Total : $${totalSummary}`, TEXT_ALIGN_LEFT, offsetYSummaryTab! + 20);
  }


  autoTable(doc, {
    startY: getOffsetYCursor(),
    head: [columns.map(col => col.key)],
    body: data.map((row: any) => columns.map(col => row[col.key])),
    styles: {
      overflow: "linebreak",
      fontSize: isMultiCol ? 5 : 8,
      cellPadding: isMultiCol ? 2 : 5,
    },
    didParseCell: didParseCell,
  });

  doc.save(fileName);
};
