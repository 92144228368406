import { useCallback, useContext, useState } from "react";
import { LocalVideoTrack } from "twilio-video";
import { VideoContext } from "./useContext/useVideoContext";
import useIsLocalTrackStopped from "./useIsLocalTrackStopped";

export const isMobile = () => {
  if (
    typeof navigator === "undefined" ||
    typeof navigator.userAgent !== "string"
  ) {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
};

export default function useLocalVideoToggle() {
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
  } = useContext(VideoContext);

  const videoTrack = localTracks.find(
    (track: any) => track.kind === "video"
  ) as LocalVideoTrack;
  const [isPublishing, setIspublishing] = useState(false);
  const isStopped = useIsLocalTrackStopped(videoTrack);

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if ((videoTrack && !isStopped) || (videoTrack?.dimensions.width === null)) {
        const localTrackPublication =
        localParticipant?.unpublishTrack(videoTrack);
        localParticipant?.emit("trackUnpublished", localTrackPublication);
        videoTrack.stop();
      } else {
        setIspublishing(true);
        getLocalVideoTrack()
          .then((track: LocalVideoTrack) => {
            return localParticipant?.publishTrack(track, { priority: "low" });
          })
          .catch((errorString) => {
            window.alert(errorString);
          })
          .finally(() => setIspublishing(false));
      }
    }
  }, [isPublishing, isStopped, localParticipant, videoTrack, getLocalVideoTrack]);

  return [!isStopped, toggleVideoEnabled] as const;
}
