import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Select,
  Space,
  Spin,
} from "@pankod/refine-antd";
import { useCustom } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import {
  CUSTOMER_API,
  DEPARTMENT_API,
  INTERPRETER_API,
  PAY_BILL_REPORT_API,
  SUB_DEPARTMENT_API,
} from "api/baseApi";
import { EFormatDateTime, ERateCall } from "constants/constant";
import dayjs, { Dayjs } from "dayjs";
import { IAptPayBillReport } from "interfaces/aptPayBill";
import { ICustomerReq } from "interfaces/customer";
import { IDepartment } from "interfaces/department";
import { IInterpreter } from "interfaces/interpreter";
import { ISub_Department } from "interfaces/sub_department";
import jsPDF from "jspdf";
import { axiosInstance } from "providers/axios";
import dataProvider from "providers/dataProvider";
import React, { useState } from "react";
import { exportToCSV, exportToExcel } from "utils/excelExporter";
import autoTable from "jspdf-autotable";
import useNotification from "hooks/useNotification";
import { formatDateTime } from "utils";

const { SearchOutlined, CloseOutlined, ExclamationCircleOutlined } = Icons;
const { Option } = Select;

const COL_EXCEL = [
  "Apt ID",
  "Customer",
  "Department",
  "Sub-department",
  "Requester Name",
  "Requester Email",
  "Type of Call",
  "Language",
  "Interpreter",
  "Interpreter ID",
  "Date Of Call",
  "Time Start",
  "Time Finish",
  "Duration(s)",
  "Connection Time (s)",
  "Interpreter Pay Rate",
  "Minutes Paid",
  "Total Paid ($)",
  "Customer Bill Rate",
  "Minutes Billed",
  "Total billed ($)",
  "Profit Margin",
  "Other Reference 1",
  "Other Reference 2",
  "Other Reference 3",
  "Customer Rating"
];

const collumnsPDF = [
  {
    key: "Apt ID",
  },
  {
    key: "Customer",
  },
  {
    key: "Department",
  },
  {
    key: "Sub-department",
  },
  {
    key: "Requester Name",
  },
  {
    key: "Type of Call",
  },
  {
    key: "Language",
  },
  {
    key: "Interpreter",
  },
  {
    key: "Date Of Call",
  },
  {
    key: "Time Start",
  },
  {
    key: "Time Finish",
  },
  {
    key: "Duration(s)",
  },
  {
    key: "Connection Time (s)",
  },
  {
    key: "Total Paid ($)",
  },
  {
    key: "Total billed ($)",
  },
  {
    key: "Customer Rating"
  }
];

export const ApptPayOrBillList: React.FC = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const [isLoading, setIsLoading] = useState(false);

  const [interpreterId, setInterpreterId] = useState<string>();
  const [searchInterpreter, setSearchInterpreter] = useState<string>("");
  const [customerId, setCustomerId] = useState<string>();
  const [valueCustomer, setValueCustomer] = useState<string>("");
  const [valueDepartment, setValueDepartment] = useState<string>("");
  const [valueSubDepartment, setValueSubDepartment] = useState<string>("");
  const [department, setDepartment] = useState<string>("");
  const [subDepartment, setSubDepartment] = useState<string>("");
  const [groupId, setGroupId] = useState<number>();

  const [activeDepartment, setActiveDepartment] = useState([]);
  const [activeSubdepartment, setActiveSubdepartment] = useState<
    ISub_Department[]
  >([]);

  const { openNotificationErrors, contextHolder } = useNotification();

  const { data: interpretersData } = useCustom({
    url: INTERPRETER_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
        search: null,
      },
    },
  });

  const { data: customersData } = useCustom({
    url: CUSTOMER_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
        search: null,
      },
    },
  });
  const handleStartDate = (startDate: Dayjs | null) => {
    setStartDate(startDate ?? "");
  };

  const handleEndDate = (endDate: Dayjs | null) => {
    setEndDate(endDate ?? "");
  };

  const onChange = (interpreter: string, option: BaseOptionType) => {
    setSearchInterpreter(option.label);
  };

  const onSelect = (interpreterId: string, option: BaseOptionType) => {
    setInterpreterId(interpreterId);
    setSearchInterpreter(option.label);
  };

  const handleRemoveInterpreter = () => {
    setInterpreterId("");
    setSearchInterpreter("");
  };
  const onChangeCustomer = (customerId: string) => {
    setValueCustomer(customerId);
  };

  const handleChangeGroup = (groupId: number) => {
    setGroupId(groupId);
  };

  const onSelectCustomer = async (
    customerId: string,
    option: BaseOptionType
  ) => {
    const departmentByCusId = await axiosInstance.get(
      DEPARTMENT_API.getByCustomer + `?id=${customerId}&page=&limit=`
    );
    setActiveDepartment(departmentByCusId?.data?.results);

    setCustomerId(customerId);
    setValueCustomer(option.label);
  };

  const onSelectDepartment = async (
    departmentId: string,
    option: BaseOptionType
  ) => {
    const subDepartments = await axiosInstance.get(
      SUB_DEPARTMENT_API.getByDepartment + `?id=${departmentId}&page=&limit=`
    );
    setActiveSubdepartment(subDepartments.data.results);

    setDepartment(departmentId);
    setValueDepartment(option.label);
  };

  const onChangeDepartment = (departmentId: string) => {
    setValueDepartment(departmentId);
  };

  const onSelectSubDepartment = async (
    subDepartmentId: string,
    option: BaseOptionType
  ) => {
    setSubDepartment(subDepartmentId);
    setValueSubDepartment(option.label);
  };

  const onChangeSubDepartment = (subDepartmentId: string) => {
    setValueSubDepartment(subDepartmentId);
  };

  const handleRemoveCustomer = () => {
    setCustomerId("");
    setValueCustomer("");
    setValueDepartment("");
    setActiveDepartment([]);
  };

  const handleRemoveDepartment = () => {
    setValueDepartment("");
    setDepartment("");
    setActiveSubdepartment([]);
  };

  const handleRemoveSubDepartment = () => {
    setValueDepartment("");
    setValueSubDepartment("");
    setDepartment("");
    setSubDepartment("");
    setActiveSubdepartment([]);
    setActiveDepartment([]);
  };
  const { post } = dataProvider;

  

  const viewData = (arrInterAct: IAptPayBillReport[]) => {
    return arrInterAct?.map((apt: IAptPayBillReport) => ({
      "Apt ID": apt?.callerId,
      Customer: apt?.customerName,
      Department: apt?.departmentName,
      "Sub-department": apt?.subdepartmentName,
      "Requester Name": apt?.requesterName,
      "Requester Email": apt?.requesterEmail,
      "Type of Call": apt?.media,
      Language: apt?.language,
      Interpreter: apt?.interpreterName,
      "Interpreter ID": apt?.intId,
      "Date Of Call": formatDateTime(apt?.createdAt),
      "Time Start": formatDateTime(apt?.startTime),
      "Time Finish": formatDateTime(apt?.endTime),
      "Duration(s)": apt?.duration,
      "Connection Time (s)": apt?.connectTime,
      "Interpreter Pay Rate": apt?.ratePay,
      "Minutes Paid": apt?.minutesPaid,
      "Total Paid ($)": apt?.totalPay?.toFixed(2),
      "Customer Bill Rate": apt?.rateBill,
      "Minutes Billed":apt?.minutesBilled, 
      "Total billed ($)": apt?.totalBill?.toFixed(2),
      "Profit Margin": apt?.income?.toFixed(2),
      "Other Reference 1": apt?.note?.otherRef1,
      "Other Reference 2": apt?.note?.otherRef2,
      "Other Reference 3": apt?.note?.otherRef3,
      "Customer Rating": ERateCall[apt?.rating]
    }));
  };

  const handleViewAptPayBillReport = async () => {
    if (!groupId || !startDate || !endDate) {
      return Promise.reject("Missing required parameter(s)");
    }
    const dateStart = dayjs(startDate).format(EFormatDateTime.DATE);
    const finishDate = dayjs(endDate).format(EFormatDateTime.DATE);

    const payload = {
      start: dateStart,
      end: finishDate,
      interpreterId: interpreterId,
      customerId: customerId,
      departmentId: department,
      subdepartmentId: subDepartment,
      groupId: groupId,
    };

    const data = await post({
      url: PAY_BILL_REPORT_API,
      payload: payload,
    });

    return data;
  };

  const dateRange = `Period: ${dayjs(startDate).format("DD/MM/YYYY")} - ${dayjs(
    endDate
  ).format("DD/MM/YYYY")}`;


  const name = "Appointment Pay_Bill Report.pdf";
  const handleExportPDF = async () => {
    try {
      setIsLoading(true);
      const dataBillPay = await handleViewAptPayBillReport();
      const data = viewData(dataBillPay?.data);

      // First, create an object to store the grouped data
      const resultLanguage = {} as any;
      const resultCustomer = {} as any;
      const resultInterpreter = {} as any;

      data.forEach((obj) => {
        if (!resultLanguage[obj.Language]) {
          resultLanguage[obj.Language] = [];
        }
        resultLanguage[obj.Language].push(obj);

        if (!resultCustomer[obj.Customer]) {
          resultCustomer[obj.Customer] = [];
        }
        resultCustomer[obj.Customer].push(obj);

        if (!resultInterpreter[obj.Interpreter]) {
          resultInterpreter[obj.Interpreter] = [];
        }
        resultInterpreter[obj.Interpreter].push(obj);
      });

      // Next, create an array of objects in the desired format
      const dataLanguage = [];
      const dataCustomer = [];
      const dataInterpreter = [];
      for (const [language, objects] of Object.entries(resultLanguage)) {
        dataLanguage.push([{ content: language, colSpan: 15 }]);
        (objects as any).forEach((obj: any) => dataLanguage.push(obj));
      }
      for (const [language, objects] of Object.entries(resultCustomer)) {
        dataCustomer.push([{ content: language, colSpan: 15 }]);
        (objects as any).forEach((obj: any) => dataCustomer.push(obj));
      }
      for (const [language, objects] of Object.entries(resultInterpreter)) {
        dataInterpreter.push([{ content: language, colSpan: 15 }]);
        (objects as any).forEach((obj: any) => dataInterpreter.push(obj));
      }

      const unit = "pt";
      const size = "A3";
      const orientation = "landscape";

      const doc = new jsPDF(orientation, unit, size);

      const titleStyle = {
        font: "helvetica",
        fontStyle: "bold",
        fontSize: 16,
      };

      doc.setFont(titleStyle.font, titleStyle.fontStyle);
      doc.setFontSize(titleStyle.fontSize);
      doc.text("911 Interpreters", doc.internal.pageSize.width / 2, 30, {
        align: "center",
      });

      const titleStyleChild = {
        font: "helvetica",
        fontStyle: "normal",
        fontSize: 13,
      };
      doc.setFont(titleStyleChild.font, titleStyleChild.fontStyle);
      doc.setFontSize(titleStyleChild.fontSize);
      doc.text(
        "Appointment Pay/Bill Report",
        doc.internal.pageSize.getWidth() / 2,
        55,
        {
          align: "center",
        }
      );
      doc.text(dateRange, doc.internal.pageSize.getWidth() / 2, 80, {
        align: "center",
      });

      autoTable(doc, {
        startY: 100,
        head: [collumnsPDF.map((c) => c.key)] as any,
        body:
          groupId === 1
            ? dataLanguage.map((row: any) => collumnsPDF.map((c) => row[c.key]))
            : groupId === 2
            ? dataCustomer.map((row: any) => collumnsPDF.map((c) => row[c.key]))
            : dataInterpreter.map((row: any) =>
                collumnsPDF.map((c) => row[c.key])
              ),
        styles: {
          overflow: "linebreak",
          font: "serif",
          fontSize: 8,
          cellPadding: 5,
        },
        didParseCell(data) {
          if (data.column.dataKey === 6 && groupId === 1) {
            data.cell.styles.textColor = "red";
          }

          if (data.column.dataKey === 1 && groupId === 2) {
            data.cell.styles.textColor = "red";
          }

          if (data.column.dataKey === 7 && groupId === 3) {
            data.cell.styles.textColor = "red";
          }
        },
      });
      doc.save(name);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotificationErrors(
        "Error",
        "Please fill in all the required fields",
        "topRight",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  };

  const handleExportExcel = async () => {
    try {
      setIsLoading(true);
      const { data } = await handleViewAptPayBillReport();
      exportToExcel(
        "Appointment Pay_Bill Report",
        viewData(data),
        COL_EXCEL
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotificationErrors(
        "Error",
        "Please fill in all the required fields",
        "topRight",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  };

  const handleExportCSV = async () => {
    try {
      setIsLoading(true);
      const { data } = await handleViewAptPayBillReport();
      exportToCSV(
        viewData(data),
        "Appointment Pay_Bill Report",
        COL_EXCEL
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotificationErrors(
        "Error",
        "Please fill in all the required fields",
        "topRight",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  };

  return (
    <Spin spinning={isLoading}>
      <List
        title={
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item>Report</Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item">
              Appointment Pay/Bill Report
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {contextHolder}
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Invoice Period From Date"
            name="fromDate"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter start date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={startDate}
              onChange={(e) => handleStartDate(e)}
              disabledDate={(current) => {
                return endDate ? current && current > endDate : false;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Customer Name"
            className={!isMobile ? "search-item" : ""}
          >
            <AutoComplete
              showSearch
              style={{
                width: "100%",
                borderBottom: "1px solid #d9d9d9",
                borderRadius: "0px",
              }}
              options={customersData?.data?.results?.map(
                (item: ICustomerReq, index: number) => ({
                  value: item.id,
                  label: item.name,
                })
              )}
              filterOption={(
                valueCustomer,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.label
                  ?.toUpperCase()
                  .indexOf(valueCustomer.toUpperCase()) !== -1
              }
              bordered={false}
              value={valueCustomer}
              onChange={onChangeCustomer}
              onSelect={onSelectCustomer}
            >
              <Input
                suffix={
                  <>
                    {valueCustomer && (
                      <CloseOutlined onClick={handleRemoveCustomer} />
                    )}
                    <SearchOutlined />
                  </>
                }
                bordered={false}
              />
            </AutoComplete>
          </Form.Item>
        </Form>
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Invoice Period To Date"
            name="toDate"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter end date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={endDate}
              disabledDate={(current) => {
                return current && current < startDate;
              }}
              onChange={(e) => handleEndDate(e)}
            />
          </Form.Item>

          <Form.Item
            label="Department"
            className={!isMobile ? "search-item" : ""}
          >
            <AutoComplete
              showSearch
              style={{
                width: "100%",
                borderBottom: "1px solid #d9d9d9",
                borderRadius: "0px",
              }}
              bordered={false}
              options={activeDepartment?.map(
                (item: IDepartment, index: number) => ({
                  value: item.id,
                  label: item.name,
                })
              )}
              value={valueDepartment}
              filterOption={(
                valueDepartment,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.label
                  ?.toUpperCase()
                  .indexOf(valueDepartment.toUpperCase()) !== -1
              }
              onSelect={onSelectDepartment}
              onChange={onChangeDepartment}
            >
              <Input
                suffix={
                  <>
                    {valueDepartment && (
                      <CloseOutlined onClick={handleRemoveDepartment} />
                    )}
                    <SearchOutlined />
                  </>
                }
                bordered={false}
              />
            </AutoComplete>
          </Form.Item>
        </Form>
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Group By"
            name="groupBy"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter group by",
              },
            ]}
          >
            <Select onChange={(e) => handleChangeGroup(e)} value={groupId}>
              <Option value={1}>Languages</Option>
              <Option value={2}>Customers</Option>
              <Option value={3}>Interpreters</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Sub-department"
            className={!isMobile ? "search-item" : ""}
          >
            <AutoComplete
              showSearch
              style={{
                width: "100%",
                borderBottom: "1px solid #d9d9d9",
                borderRadius: "0px",
              }}
              bordered={false}
              options={activeSubdepartment?.map(
                (item: ISub_Department, index: number) => ({
                  value: item.id,
                  label: item.name,
                })
              )}
              value={valueSubDepartment}
              filterOption={(
                valueSubDepartment,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.label
                  ?.toUpperCase()
                  .indexOf(valueSubDepartment.toUpperCase()) !== -1
              }
              onSelect={onSelectSubDepartment}
              onChange={onChangeSubDepartment}
            >
              <Input
                suffix={
                  <>
                    {valueSubDepartment && (
                      <CloseOutlined onClick={handleRemoveSubDepartment} />
                    )}
                    <SearchOutlined />
                  </>
                }
                bordered={false}
              />
            </AutoComplete>
          </Form.Item>
        </Form>
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Select Interpreter"
            className={!isMobile ? "search-item" : ""}
          >
            <AutoComplete
              showSearch
              size="large"
              style={{
                width: "100%",
                borderBottom: "1px solid #d9d9d9",
                borderRadius: "0px",
              }}
              options={interpretersData?.data?.results?.map(
                (item: IInterpreter, index: number) => ({
                  value: item.id,
                  label: item.name,
                })
              )}
              filterOption={(
                searchInterpreter,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.label
                  ?.toUpperCase()
                  .indexOf(searchInterpreter.toUpperCase()) !== -1
              }
              bordered={false}
              value={searchInterpreter}
              onChange={onChange}
              onSelect={onSelect}
            >
              <Input
                suffix={
                  <>
                    {searchInterpreter && (
                      <CloseOutlined onClick={handleRemoveInterpreter} />
                    )}
                    <SearchOutlined />
                  </>
                }
                bordered={false}
              />
            </AutoComplete>
          </Form.Item>
        </Form>

        <Space wrap={isMobile}>
          <Button
            className={
              isMobile ? "search-report-call-mobile" : "search-report-call"
            }
            onClick={handleExportPDF}
          >
            Get PDF
          </Button>
          <Button
            className={
              isMobile ? "search-report-call-mobile" : "search-report-call"
            }
            onClick={handleExportExcel}
          >
            Get Excel
          </Button>
          <Button
            className={
              isMobile ? "search-report-call-mobile" : "search-report-call"
            }
            onClick={handleExportCSV}
          >
            Get CSV
          </Button>
        </Space>
      </List>
    </Spin>
  );
};
