import {
  Button,
  Col,
  Form,
  Grid,
  Input,
  List,
  Row,
  Select,
  Space,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import { useCustom, useNavigation, useUpdate } from "@pankod/refine-core";
import RouterProvider from "@pankod/refine-react-router-v6";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import {
  INTERPRETER_API,
  STATE_API,
  UPDATE_PROFILE_INTERPRETER_API,
} from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { IInterpreter } from "interfaces/interpreter";
import { IState } from "interfaces/state";
import { useEffect } from "react";
import { openNotification} from "utils";
const { Title } = Typography;
const { Option } = Select;
export const UpdateProfile: React.FC = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.sm;

  const { useParams } = RouterProvider;
  const { goBack } = useNavigation();
  const params = useParams();
  const { push } = useNavigation();
  const { formProps, form } = useForm<IInterpreter>({
    action: EAction.EDIT,
  });

  const user = JSON.parse(localStorage.getItem("user") || "");

  const { data: interpreterEdit } = useCustom({
    url: INTERPRETER_API.getOne + `/${params.slug}`,
    method: "get",
  });

  const { data: statesData } = useCustom({
    url: STATE_API.getAll,
    method: "get",
  });

  const { mutate, data: updateData, isLoading } = useUpdate();

  const onFinish = (event: IInterpreter) => {
    mutate(
      {
        resource: UPDATE_PROFILE_INTERPRETER_API,
        id: params?.slug,
        values: {
          firstname: event.firstname,
          lastname: event.lastname,
          phone: event.phone,
          phoneType: event.phoneType,
          ext: Number(event.ext) ?? "",
          email: event.email,
          gender: event.gender,
          streetAddress: event.streetAddress ?? "",
          aptAddress: event.aptAddress ?? "",
          cityAddress: event.cityAddress ?? "",
          zipCodeAddress: event.zipCodeAddress ?? "",
          stateAddress: event.stateAddress ?? null,
          streetMail: event.streetMail ?? "",
          aptMail: event.aptMail ?? "",
          cityMail: event.cityMail ?? "",
          zipCodeMail: event.zipCodeMail,
          stateMail: event.stateMail ?? null,
          isActive: event.isActive,
          lastModifiedBy: user?.username,
        },
      },
      {
        onError: (error) => {
          
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  useEffect(() => {
    if (updateData?.data.status === 200) {
      form.resetFields();
        openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
      push(`/profile`);
    }
  }, [updateData, form, push]);

  const { setFields } = form;
  useEffect(() => {
    form.resetFields();
    setFields([
      {
        name: "firstname",
        value: interpreterEdit?.data.user.firstname,
      },
      {
        name: "lastname",
        value: interpreterEdit?.data.user.lastname,
      },

      {
        name: "gender",
        value: interpreterEdit?.data.user.gender,
      },
      {
        name: "email",
        value: interpreterEdit?.data.user.email,
      },
      {
        name: "phone",
        value: interpreterEdit?.data.user.phone,
      },
      {
        name: "avatarPath",
        value: interpreterEdit?.data.user.avatarPath,
      },
      {
        name: "avatarThumbnailPath",
        value: interpreterEdit?.data.user.avatarThumbnailPath,
      },
    ]);
  }, [interpreterEdit, form, setFields]);

  return (
    <List title="My Profile">
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(event) => {
          onFinish(event as IInterpreter);
        }}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={isMobile ? 24 : 12}>
            <Title level={5} className="title-address">
              Home Address
            </Title>
            <Form.Item
              label="Street Address"
              name="streetAddress"
              rules={[
                {
                  required: true,
                  message: "Please enter Street Address",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trimStart()}
              initialValue={interpreterEdit?.data?.addressHome?.streetAddress}
            >
              <Input maxLength={200} />
            </Form.Item>

            <Form.Item
              label="Apt. #"
              name="aptAddress"
              rules={[
                {
                  required: false,
                  message: "Please enter Apt. #",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trimStart()}
              initialValue={interpreterEdit?.data?.addressHome?.aptNumber}
            >
              <Input maxLength={200} />
            </Form.Item>

            <Form.Item
              label="City"
              name="cityAddress"
              rules={[
                {
                  required: true,
                  message: "Please enter city",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trimStart()}
              initialValue={interpreterEdit?.data?.addressHome?.city}
            >
              <Input maxLength={200} />
            </Form.Item>

            <Form.Item
              label="State / Province"
              name="stateAddress"
              rules={[
                {
                  required: true,
                  message: "Please enter State / Province",
                },
              ]}
              initialValue={interpreterEdit?.data?.addressHome?.stateId}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(
                  input,
                  option: BaseOptionType | DefaultOptionType | undefined
                ) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {statesData?.data?.map((item: IState, index: number) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Zip Code / Postal Code"
              name="zipCodeAddress"
              rules={[
                {
                  required: true,
                  message: "Please enter Zip Code / Postal Code",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trim()}
              initialValue={interpreterEdit?.data?.addressHome?.zipCode}
            >
              <Input maxLength={50} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={isMobile ? 24 : 12}>
            <Title level={5} className="title-address">
              Mailing Address
            </Title>
            <Form.Item
              label="Mailing Street Address"
              name="streetMail"
              rules={[
                {
                  required: false,
                  message: "Please enter Mailing Street Address",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trimStart()}
              initialValue={interpreterEdit?.data?.addressMail?.mailAddress}
            >
              <Input maxLength={200} />
            </Form.Item>

            <Form.Item
              label="Mailing Apt. #"
              name="aptMail"
              rules={[
                {
                  required: false,
                  message: "Please enter Mailing Apt",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trimStart()}
              initialValue={interpreterEdit?.data?.addressMail?.aptNumber}
            >
              <Input maxLength={200} />
            </Form.Item>

            <Form.Item
              label="Mailing City"
              name="cityMail"
              rules={[
                {
                  required: false,
                  message: "Please enter Mailing City",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trimStart()}
              initialValue={interpreterEdit?.data?.addressMail?.city}
            >
              <Input maxLength={200} />
            </Form.Item>

            <Form.Item
              label="Mailing State / Province"
              name="stateMail"
              rules={[
                {
                  required: false,
                  message: "Please enter Mailing State / Province",
                },
              ]}
              initialValue={interpreterEdit?.data?.addressMail?.stateId}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(
                  input,
                  option: BaseOptionType | DefaultOptionType | undefined
                ) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {statesData?.data?.map((item: IState, index: number) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Mailing Zip Code / Postal Code"
              name="zipCodeMail"
              rules={[
                {
                  required: false,
                  message: "Please enter Mailing Zip Code / Postal Code",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trim()}
              initialValue={interpreterEdit?.data?.addressMail?.zipCode}
            >
              <Input maxLength={50} />
            </Form.Item>
          </Col>
        </Row>
        <Title level={5} className="title-address">
          General Information
        </Title>
        <Row gutter={16}>
          <Col className="gutter-row" span={isMobile ? 24 : 12}>
            <Form.Item
              label="First Name"
              name="firstname"
              rules={[
                {
                  required: false,
                  message: "Please enter first name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: false,
                  message: "Please enter email",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={isMobile ? 24 : 12}>
            <Form.Item
              label="Last Name"
              name="lastname"
              rules={[
                {
                  required: false,
                  message: "Please enter last name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[
                {
                  required: false,
                  message: "Please enter gender",
                },
              ]}
            >
              <Select placeholder="Gender">
                <Option value="MALE">MALE</Option>
                <Option value="FEMALE">FEMALE</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Title level={5} className="title-address">
          Phones
        </Title>
        <Row gutter={16}>
          <Col className="gutter-row" span={isMobile ? 24 : 8}>
            {" "}
            <Form.Item
              label="Phone Type "
              name="phoneType"
              rules={[
                {
                  required: true,
                  message: "Please enter Phone Type",
                },
              ]}
              initialValue={interpreterEdit?.data.phoneType}
            >
              <Select>
                <Option value={1}>Home</Option>
                <Option value={2}>Cell</Option>
                <Option value={3}>Other</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={isMobile ? 24 : 8}>
            {" "}
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  pattern: /^(?:\d*)$/,
                  message: "Must contain 10 numbers only",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trim()}
              initialValue={interpreterEdit?.data?.user?.phone}
            >
              <Input maxLength={10} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={isMobile ? 24 : 8}>
            <Form.Item
              label="Ext"
              name="ext"
              rules={[
                {
                  pattern: /^(?:\d*)$/,
                  message: "Must contain 5 numbers only",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trim()}
              initialValue={interpreterEdit?.data?.user?.faxNumber}
            >
              <Input maxLength={5} />
            </Form.Item>
          </Col>
        </Row>

        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0px 24px",
            height: "64px",
            backgroundColor: "#FFF",
          }}
        >
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
          <Button type="default" onClick={() => goBack()}>
            Cancel
          </Button>
        </Space>
      </Form>
    </List>
  );
};
