import { Table, TextField, Typography } from "@pankod/refine-antd";
import { BaseRecord } from "@pankod/refine-core";
import { Grid } from "antd";
import { useMemo } from "react";

interface ILanguageDistribution {
  languageName : string;
  periodTotal? : string;
  yearTotal? : string;
}

type LanguageDistributionTableProps = {
  listOfLanguageDistribution : Array<ILanguageDistribution> | BaseRecord | undefined;
}

export const LanguageDistributionTable = ({ listOfLanguageDistribution }: LanguageDistributionTableProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isLaptop = !breakpoint.xl;

  const languageDistributionCol = useMemo(
    () => [
      {
        title: "Language",
        dataIndex: "languageName",
        render: (value: string) => <TextField value={value || ""} />,
      },
      {
        title: "Total period",
        dataIndex: "periodTotal",
        render: (value: string) => <TextField value={value || ""} />,
      },
      {
        title: "Total year",
        dataIndex: "yearTotal",
        render: (value: string) => <TextField value={value || ""} />,
      },
    ],
    []
  );

  return (
      <Table
      style={{
        height : '480px'
      }}
        dataSource={listOfLanguageDistribution as ILanguageDistribution[]}
         
        bordered
        scroll={{
          y: isLaptop ? 300 :  330
        }}
        pagination={false}
        title={() => (
          <Typography.Title level={5}>
            Language Distribution (for the period selected above)
          </Typography.Title>
        )}
        columns={languageDistributionCol}
      />
  );
};
