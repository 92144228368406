import {
  Button,
  Checkbox,
  Form,
  Grid,
  Input,
  Typography,
  useForm
} from "@pankod/refine-antd";
import { useCreate, useCustom, useUpdate } from "@pankod/refine-core";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  CREATE_GROUP_PERMISSION_API,
  PERMISSION_API,
  UPDATE_GROUP_PERMISSION_API,
} from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { IGroupDetail, IPermission } from "interfaces/group";
import { useEffect, useState } from "react";
import { openNotification } from "utils";
import "../../../../styles/style.less";

type InterpreterCreateProps = {
  setIsModalVisible: (data: boolean) => void;
  refetch: () => void;
  groupData?: IGroupDetail;
  option: string;
};

interface IGroupName {
  name?: string;
}
const { Title } = Typography;

export const GroupAction = (props: InterpreterCreateProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const { option, setIsModalVisible, refetch, groupData } = props;

  const [dataPermissions, setDataPermissions] = useState<IPermission[]>([]);
  const [checkedPermissionList, setCheckedPermissionList] = useState<number[]>(
    []
  );
  const { formProps, form } = useForm({
    action: option === EAction.CREATE ? "create" : "edit",
  });
  const { setFields } = form;
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate();
  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useCreate();
  const { data: permissionData, isSuccess } = useCustom({
    url: PERMISSION_API.getAll,
    method: "get",
  });

  useEffect(() => {
    if (permissionData) {
      setDataPermissions(
        permissionData?.data.map(
          (item: {
            id: number;
            level: number;
            name: string;
            parent: number;
            path: string;
          }) => ({
            ...item,
            checked: false,
          })
        )
      );
    }
  }, [permissionData]);

  useEffect(() => {
    if (groupData && permissionData && isSuccess) {
      form.resetFields();
      setFields([
        {
          name: "name",
          value: groupData?.groupInfo?.name,
        },
      ]);
      setDataPermissions((prevData: Array<IPermission>) => {
        const newState = [...prevData];
        newState.forEach((dataPermission) => {
          dataPermission.checked = false;
          groupData.listPermissions.forEach((item: IPermission) => {
            if (item.id === dataPermission.id) {
              dataPermission.checked = true;
            }
          });
        });
        return newState;
      });
    }
  }, [groupData, form, setFields, permissionData, isSuccess]);

  const handleResetField = () => {
    refetch();
    setDataPermissions((prevData: Array<IPermission>) => {
      const newState = [...prevData];
      newState.forEach((item) => {
        item.checked = false;
      });
      return newState;
    });
    form.resetFields();
    setIsModalVisible(false);
  };

  const onFinishCreate = (event: IGroupName) => {
    mutateCreate(
      {
        resource: CREATE_GROUP_PERMISSION_API,
        values: {
          name: event.name?.trim(),
          listPermissions: checkedPermissionList,
        },
      },

      {
        onSuccess: (createData) => {
          openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
          handleResetField();
        },
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);
        },
      }
    );
  };
  const onFinishUpdate = (event: IGroupName) => {
    mutateUpdate(
      {
        resource: UPDATE_GROUP_PERMISSION_API,
        id: groupData?.groupInfo?.id as number,
        values: {
          name: event.name?.trim(),
          listPermissions: checkedPermissionList,
        },
      },
      {
        onSuccess: (updateData) => {
          openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
          handleResetField();
        },
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);
        },
      }
    );
  };

  const onIsCheckPermission = (
    e: CheckboxChangeEvent,
    id: number,
    parent: number
  ) => {
    if (parent === 0)
      setDataPermissions((prevData: Array<IPermission>) => {
        const newState = [...prevData];
        newState.forEach((item) => {
          if (item.id === id || item.parent === id)
            item.checked = e.target.checked;
        });
        return newState;
      });
    else
      setDataPermissions((prevData: Array<IPermission>) => {
        const newState = [...prevData];
        let indexParent: number = -1;
        for (let index = 0; index < newState.length; ++index) {
          if (newState[index].id === parent) indexParent = index;
          if (newState[index].id === id)
            newState[index].checked = e.target.checked;
        }
        newState[indexParent].checked = true;
        for (let index = 0; index < newState.length; ++index) {
          if (
            newState[index].parent === parent &&
            newState[index].checked === false
          ) {
            newState[indexParent].checked = false;
            break;
          }
        }
        return newState;
      });
  };

  useEffect(() => {
    if (dataPermissions) {
      const dataPermissionActive = dataPermissions.filter(
        (item) => item.checked
      );
      setCheckedPermissionList(dataPermissionActive.map((item) => item.id));
    }
  }, [dataPermissions]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => {
        option === EAction.CREATE
          ? onFinishCreate(event)
          : onFinishUpdate(event);
      }}
    >
      <Form.Item
        label="Group Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please enter group name",
          },
        ]}
        normalize={(value, prevVal, preVals) => value.trimStart()}
      >
        <Input size="large" />
      </Form.Item>
      <Title level={5} className="title-address">
        Permissions
      </Title>
      {dataPermissions.map((key: IPermission) => (
        <div
          className={key.parent !== 0 ? "checkbox-child" : "checkbox-parent"}
          key={key.id}
        >
          <Checkbox
            onChange={(e: CheckboxChangeEvent) =>
              onIsCheckPermission(e, key.id, key.parent)
            }
            value={key.id}
            checked={key.checked}
          >
            {key.name}
          </Checkbox>
          <br />
        </div>
      ))}{" "}
      <div className={!isMobile ? "submit" : ""}>
        <Button
          type="primary"
          htmlType="submit"
          loading={
            EAction.CREATE === option ? isLoadingCreate : isLoadingUpdate
          }
        >
          Save
        </Button>
      </div>
    </Form>
  );
};
