import {
  Button,
  DatePicker,
  Empty,
  Form,
  Grid,
  Icons,
  List,
  Result,
  Space,
  Spin,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { useCustom } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { REPORT_CALL_CUSTOMER_API } from "api/baseApi";
import { EFormatDateTime } from "constants/constant";
import dayjs, { Dayjs } from "dayjs";
import useNotification from "hooks/useNotification";
import { IFillCustomer } from "interfaces/fillCustomerreport";
import { axiosInstance } from "providers/axios";
import React, { useMemo, useState } from "react";
import { exportToCSV, exportToExcel, exportToPDF } from "utils/excelExporter";

const { ExclamationCircleOutlined } = Icons;

const COL_EXCEL = [
  "Customer",
  "Total Requests",
  "Requests Filled",
  "Requests not Filled",
  "Requests not connected",
  "Percentage Filled",
];

const COL_PDF = [
  { key: "Customer" },
  { key: "Total Requests" },
  { key: "Requests Filled" },
  { key: "Requests not Filled" },
  { key: "Requests not connected" },
  { key: "Percentage Filled" },
];

let localeNoData = {
  emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
};

let locale = {
  emptyText: (
    <Result subTitle="Please select appoiment fill by customer report" />
  ),
};

export const ApptFillRateByCus: React.FC = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const { openNotificationErrors, openNotificationInfo, contextHolder } =
    useNotification();

  const { data: aptFillByCustomersData } = useCustom({
    url: REPORT_CALL_CUSTOMER_API,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
        start: startDate ? dayjs(startDate).format(EFormatDateTime.DATE) : "",
        end: endDate ? dayjs(endDate).format(EFormatDateTime.DATE) : "",
      },
    },
    queryOptions: {
      enabled: isSearch,
      onSuccess: (aptFillByCustomer) => {
        if(!aptFillByCustomer?.data?.results?.length){
          openNotificationInfo(
            "No data found",
            "There are no results matching the search Appointment Fill Rate by Customer Reports",
            <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
          );
        }
      },
    },
  });

  const pageTotal = aptFillByCustomersData?.data?.total;

  const handleChange = (page: number) => {
    setPage(page);
  };

  const handleStartDate = (startDate: Dayjs | null) => {
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(null);
    }
  };

  const handleEndDate = (endDate: Dayjs | null) => {
    if (endDate) {
      setIsSearch(true);
      setEndDate(endDate);
    } else {
      setIsSearch(false);
      setEndDate(null);
    }
  };

  const dateRange = `Date Range: ${dayjs(startDate)?.format(
    "DD/MM/YYYY"
  )} - ${dayjs(endDate)?.format("DD/MM/YYYY")}`;

  localStorage.setItem("date", dateRange);

  const handleShowAllPDF = (record: IFillCustomer) => {
    setIsLoading(true);
    exportToPDF({
      columns: COL_PDF,
      data: viewData([record]),
      fileName: "Appoinment Fill Rate by Customer Reports.pdf",
      name: "Fill Rate by Customer Report",
      date: localStorage.getItem("date"),
      nameInvoice: "",
    });
    localStorage.removeItem("date");
    setIsLoading(false);
  };
  const handleExportAllExcel = (record: IFillCustomer) => {
    setIsLoading(true);
    exportToExcel(
      "Appoinment Fill Rate by Customer Reports",
      viewData([record]),
      COL_EXCEL
    );
    setIsLoading(false);
  };
  const handleExportAllCSV = (record: IFillCustomer) => {
    setIsLoading(true);
    exportToCSV(
      viewData([record]),
      "Appoinment Fill Rate by Customer Reports",
      COL_EXCEL
    );
    setIsLoading(false);
  };

  const collumns = useMemo(
    () => [
      {
        key: "key",
        title: "Nbr.",
        render: (value: string) => (
          <TextField
            value={
              value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
            }
          />
        ),
      },
      {
        key: "total",
        title: "Print",
        render: (value: string, record: IFillCustomer) => (
          <TextField
            value={value ? "Print" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleShowAllPDF(record)}
          />
        ),
      },

      {
        key: "total",
        title: "Excel",
        render: (value: string, record: IFillCustomer) => (
          <TextField
            value={value ? "Excel" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleExportAllExcel(record)}
          />
        ),
      },

      {
        key: "total",
        title: "CSV",
        render: (value: string, record: IFillCustomer) => (
          <TextField
            value={value ? "CSV" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleExportAllCSV(record)}
          />
        ),
      },

      {
        key: "customer",
        title: "Customer",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "complete",
        title: "Complete",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "missed",
        title: "Missed",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "rejected",
        title: "Not Connected",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "percent",
        title: "Percent",
        render: (value: string) => (
          <TextField value={value ? `${value}%` : ""} />
        ),
      },
      {
        key: "total",
        title: "Total",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
    ],
    [page]
  );



  const viewData = (arrAptFillCus: IFillCustomer[]) => {
    return arrAptFillCus?.map((customer: IFillCustomer) => ({
        Customer: customer?.customer,
        "Total Requests": customer?.total,
        "Requests Filled": customer?.complete,
        "Requests not Filled": customer?.missed,
        "Requests not connected": customer?.rejected,
        "Percentage Filled": `${customer?.percent}%`,
      }));
  };

  const handleViewAptFillCustomer = () => {
    const formattedStartDate = dayjs(startDate).format(EFormatDateTime.DATE);
    const formattedEndDate = dayjs(endDate).format(EFormatDateTime.DATE);
    if (!startDate || !endDate) {
      return Promise.reject(new Error("Missing start or end date"));
    }

    return axiosInstance.get(
      `${REPORT_CALL_CUSTOMER_API}?start=${formattedStartDate}&end=${formattedEndDate}`
    );
  };

  const handleExportPDF = async () => {
    try {
      setIsLoading(true);
      const { data } = await handleViewAptFillCustomer();
      exportToPDF({
        columns: COL_PDF,
        data: viewData(data?.results),
        fileName: "Appoinment Fill Rate by Customer Reports.pdf",
        name: "Fill Rate by Customer Report",
        date: dateRange,
        nameInvoice: "",
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotificationErrors(
        "Error",
        "Please fill in all the required fields",
        "topRight",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  };

  const handleExportExcel = async () => {
    try {
      setIsLoading(true);
      const { data } = await handleViewAptFillCustomer();
      exportToExcel(
        "Appoinment Fill Rate by Customer Reports",
        viewData(data?.results),
        COL_EXCEL
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotificationErrors(
        "Error",
        "Please fill in all the required fields",
        "topRight",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  };

  const handleExportCSV = async () => {
    try {
      setIsLoading(true);
      const { data } = await handleViewAptFillCustomer();
      exportToCSV(
        viewData(data?.results),
        "Appoinment Fill Rate by Customer Reports",
        COL_EXCEL
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotificationErrors(
        "Error",
        "Please fill in all the required fields",
        "topRight",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  };

  return (
    <Spin spinning={isLoading} size="large">
      <List
        title={
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item>Report</Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item">
              Appointment Fill Rate by Customer Reports
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {contextHolder}
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Invoice Period From Date"
            name="fromDate"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter start date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={startDate}
              onChange={(e) => handleStartDate(e)}
              disabledDate={(current) => {
                return endDate ? current && current > endDate : false;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Invoice Period To Date"
            name="toDate"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter end date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={endDate}
              disabledDate={(current) => {
                return current && current < startDate;
              }}
              onChange={(e) => handleEndDate(e)}
            />
          </Form.Item>
        </Form>
        <Space wrap={isMobile}>
          <Button
            className={
              isMobile ? "search-report-call-mobile" : "search-report-call"
            }
            onClick={handleExportPDF}
          >
            PDF report
          </Button>
          <Button
            className={
              isMobile ? "search-report-call-mobile" : "search-report-call"
            }
            onClick={handleExportExcel}
          >
            Excel report
          </Button>
          <Button
            className={
              isMobile ? "search-report-call-mobile" : "search-report-call"
            }
            onClick={handleExportCSV}
          >
            CSV report
          </Button>
        </Space>
        <Table
          locale={startDate && endDate ? localeNoData : locale}
          dataSource={aptFillByCustomersData?.data?.results?.map(
            (item: IFillCustomer, index: number) => ({
              ...item,
              key: index,
            })
          )}
          rowKey="id"
          pagination={
            pageTotal > 10
              ? {
                  total: pageTotal ? pageTotal : 1,
                  current: page,
                  onChange: handleChange,
                  showTotal: () => `${pageTotal} total`,
                }
              : false
          }
          scroll={{ x: 800 }}
        >
          {collumns.map((col) => (
            <Table.Column dataIndex={col.key} {...col} />
          ))}
        </Table>
      </List>
    </Spin>
  );
};
