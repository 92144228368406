import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import { BaseKey, useCustom, useUpdate } from "@pankod/refine-core";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import {
  LANGUAGES_API,
  PROFILE_API,
  RATE_TYPE_API,
  STATE_API,
  UPDATE_USER_API,
} from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ISelectItemPermisson } from "interfaces";
import { IAppoinment } from "interfaces/appointment";
import { ICustomer } from "interfaces/customer";
import { IInterpreterType } from "interfaces/interpreter";
import { ILanguage } from "interfaces/languages";
import { IState } from "interfaces/state";
import { useState } from "react";
import { openNotification } from "utils";

type RequesterInformationEditProps = {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  requester: ICustomer | undefined;
};

const { TextArea } = Input;
const { Title } = Typography;
export const AppointmentInformationEdit = (
  props: RequesterInformationEditProps
) => {
  const { requester } = props;

  const [selectedItems, setSelectedItem] = useState<ISelectItemPermisson>();

  const { Option } = Select;
  const { form, formProps } = useForm<IAppoinment>({
    action: EAction.EDIT,
  });

  const { data: languagesData } = useCustom({
    url: LANGUAGES_API.getAll,
    method: "get",
    config: {
      query: {
        page: "",
        limit: "",
        search: "",
      },
    },
  });

  const { data: servicesData, refetch } = useCustom({
    url: RATE_TYPE_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
        search: null,
      },
    },
  });

  const { data: profile } = useCustom({
    url: PROFILE_API,
    method: "get",
  });

  const { data: stateData } = useCustom({
    url: STATE_API.getAll,
    method: "get",
  });

  const { setFields } = form;
  const { mutate, data: updateData, isLoading } = useUpdate();

  const onFinish = (event: IAppoinment) => {
    mutate(
      {
        resource: UPDATE_USER_API,
        id: requester?.id as BaseKey,
        values: {
          startDate: event.startDate,
          finishDate: event.finishDate,
          startTime: event.startTime,
          endTime: event.endTime,
          languageId: event.languageId,
          gender: event.gender,
          servicetype: event.servicetype,
          street: event.street,
          suite: event.suite,
          city: event.city,
          stateId: event.stateId,
          zipCode: event.zipCode,
          notes: event.notes,
          additional: event.additional,
        },
      },
      {
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  const onChangeTime = (time: any, timeString: string) => {};

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as IAppoinment)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={8}>
          <Form.Item
            label="Date"
            name="date"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="date" maxLength={50} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={8}>
          <Form.Item
            label="Time"
            name="timeStart"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TimePicker
              use12Hours
              format="h:mm:ss A"
              style={{ width: "100%" }}
              onChange={onChangeTime}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={8}>
          {" "}
          <Form.Item
            label="Estimated Duration (s) "
            name="estimated"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={8}>
          <Form.Item
            label="Finish Date"
            name="date"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input type="date" maxLength={50} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={8}>
          <Form.Item
            label="Time"
            name="timeFinsish"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TimePicker
              use12Hours
              format="h:mm:ss A"
              style={{ width: "100%" }}
              onChange={onChangeTime}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={8}>
          <Form.Item
            label="Language"
            name="languages"
            rules={[
              {
                required: true,
                message: "Please select language",
              },
            ]}
          >
            <Select
              value={selectedItems}
              showSearch
              placeholder="Select a language"
              style={{ width: "100%" }}
              optionFilterProp="children"
              filterOption={(
                input,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {languagesData?.data?.results.map(
                (item: ILanguage, index: number) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={8}>
          <Form.Item
            label="Gender Request"
            name="gender"
            rules={[
              {
                required: false,
                message: "Please enter gender",
              },
            ]}
          >
            <Select placeholder="Gender">
              <Option value="MALE">MALE</Option>
              <Option value="FEMALE">FEMALE</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={8}>
          <Form.Item
            label="Service Type"
            name="serviceType"
            rules={[
              {
                required: false,
                message: "Please enter gender",
              },
            ]}
          >
            <Select>
              {servicesData?.data?.results.map((item: IInterpreterType) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Street Address"
        name="street"
        rules={[
          {
            required: true,
          },
        ]}
        normalize={(value, prevVal, prevVals) => value.trimStart()}
      >
        <Input />
      </Form.Item>
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <Form.Item
            label="Apt# / Suite"
            name="suite"
            rules={[
              {
                required: false,
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="State/Province"
            name="stateId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="children"
              filterOption={(
                input,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {stateData?.data?.map((item: IState, index: number) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={12}>
          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Zip Code/Postal Code"
            name="zipCode"
            rules={[
              {
                required: true,
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Additional Information "
        name="additional"
        rules={[
          {
            required: false,
          },
        ]}
        normalize={(value, prevVal, prevVals) => value.trimStart()}
      >
        <TextArea rows={5} maxLength={200} />
      </Form.Item>
      <Title level={5}>Appointment Notes (Use for Internal Remarks)</Title>
      <Form.Item
        label="Notes"
        name="note"
        rules={[
          {
            required: false,
          },
        ]}
        normalize={(value, prevVal, prevVals) => value.trimStart()}
      >
        <TextArea rows={5} maxLength={200} />
      </Form.Item>

      <div className="submit">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
