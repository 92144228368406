import {
  AntdLayout,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography
} from "@pankod/refine-antd";
import {
  useCustomMutation,
  useLogout,
  useNavigation,
} from "@pankod/refine-core";
import { CREATE_PASSWORD } from "api/baseApi";
import { EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { openNotification } from "utils";

export interface IGeneratePassword {
  username: string;
  token: string;
  password: string;
}

export const GeneratePassword: React.FC = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const username = query.get("username");
  const token = query.get("token");
  const { push } = useNavigation();

  const { Title } = Typography;
  const { mutate, isLoading } = useCustomMutation();

  const [form] = Form.useForm();
  const { mutate: logout } = useLogout();
  const onFinish = (event: IGeneratePassword) => {
    mutate(
      {
        url: CREATE_PASSWORD,
        method: "put",
        values: {
          username: username,
          token: token,
          password: event.password,
        },
      },
      {
        onSuccess: (createData) => {
          openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
          logout();
          push("/login");
          form.resetFields();
        },
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 5);;
        },
      }
    );
  };

  const tokenExpired = useCallback((token: string) => {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }, []);

  useEffect(() => {
    if (tokenExpired(token as string)) {
      // openNotificationError("Token is expired");
      push("/404");
    }
  }, [token, tokenExpired]);

  const CardTitle = (
    <>
      <Title level={3} className="title">
        Create your password
      </Title>
      <div className="note-title">
        Please create a password to access 911 Interpreters VRI Portal
      </div>
    </>
  );

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img
                className="logo"
                src="/images/global/logo_v2.png"
                alt="Refine Logo"
              />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                form={form}
                layout="vertical"
                onFinish={(event) => {
                  onFinish(event);
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password size="large" />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>

                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  loading={isLoading}
                  className="btn-login"
                >
                  Save
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
