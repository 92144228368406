/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useCreate } from "@pankod/refine-core";
import { Form, Input, useForm, Button, Row, Col } from "@pankod/refine-antd";
import { CREATE_RATE_TYPE_API } from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { openNotification} from "utils";
import { IPayBillItem } from "interfaces/invoiceInformation";

type BillItemAutoProps = {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  option: string;
};

export const BillItemAuto = (props: BillItemAutoProps) => {
  const { setIsModalVisible } = props;

  const { formProps, form } = useForm<IPayBillItem>({
    action: EAction.CREATE,
  });

  const { mutate, data: createData, isLoading } = useCreate();

  const onFinish = (event: IPayBillItem) => {
    mutate(
      {
        resource: CREATE_RATE_TYPE_API,
        values: {
          name: event.name,
        },
      },
      {
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  useEffect(() => {
    if (createData?.data.status === 200) {
      form.resetFields();
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
      setIsModalVisible(false);
    }
  }, [createData, form, setIsModalVisible]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as IPayBillItem)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <Form.Item
            label="Item Name"
            name="name"
            rules={[
              {
                required: true,
                message:
                  "Name must start with a letter and can not contain spaces or special characters",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input maxLength={50} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={4}>
          <Form.Item label="Rate" name="rate">
            <Input maxLength={50} disabled />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={4}>
          <Form.Item
            label="Quantity"
            name="quantity"
            rules={[
              {
                required: true,
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
            initialValue={321}
          >
            <Input maxLength={50} type="number" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={4}>
          <Form.Item
            label="Ratio"
            name="ratio"
            rules={[
              {
                required: false,
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
            initialValue={1}
          >
            <Input maxLength={50} />
          </Form.Item>
        </Col>
      </Row>

      <div className="submit">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Add
        </Button>
      </div>
    </Form>
  );
};
