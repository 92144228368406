import { ConfigProvider } from "antd";
import {
  ChatNotificationContext,
  ChatNotificationProvider
} from "hooks/useContext/useChatNotificationContext";
import {
  ChatStateContext,
  ChatStateProvider,
} from "hooks/useContext/useChatStateContext";
import {
  CollageContext,
  CollageProvider,
} from "hooks/useContext/useCollagedContext";
import { SocketProvider, socket } from "hooks/useContext/useSocketContext";
import { UserContext, UserProvider } from "hooks/useContext/useUserContext";
import {
  UserInfoContext,
  UserInfoProvider,
} from "hooks/useContext/useUserInfoContext";
import { createRoot } from "react-dom/client";
import reportWebVitals from "reportWebVitals";
import App from "./App";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import SoundCallProvider from "hooks/useContext/useSoundCallContext";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <ConfigProvider  theme={{ hashed: false }}>
    <SocketProvider value={socket}>
      <ChatStateProvider value={ChatStateContext}>
        <SoundCallProvider>
          <UserInfoProvider value={UserInfoContext}>
            <ChatNotificationProvider value={ChatNotificationContext}>
              <UserProvider value={UserContext}>
                <CollageProvider value={CollageContext}>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </CollageProvider>
              </UserProvider>
            </ChatNotificationProvider>
          </UserInfoProvider>
        </SoundCallProvider>
      </ChatStateProvider>
    </SocketProvider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
