import { Button, Space } from "@pankod/refine-antd";
import { BaseKey, useCustom, useUpdate } from "@pankod/refine-core";
import { useEffect } from "react";

import {
  ACTIVE_USER_API,
  DEACTIVE_USER_API,
  INTERPRETER_API,
} from "api/baseApi";
import { EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { IInterpreter } from "interfaces/interpreter";
import { openNotification } from "utils";

type InterpreterActiveProps = {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  interpreter: IInterpreter | undefined;
  refetch: () => void;
  page: number;
  handleChange: (page: number) => void;
};

const InterpreterActive = (props: InterpreterActiveProps) => {
  const { setIsModalVisible, interpreter, refetch, page, handleChange } = props;
  const {
    mutate,
    data: activeInterpreter,
    isLoading: isLoadingActive,
  } = useUpdate();

  const { data: interpreterActive } = useCustom({
    url: INTERPRETER_API.getOne + `/${interpreter?.id}`,
    method: "get",
  });

  const handleChangeActive = () => {
    if (interpreter?.isActive === true) {
      mutate({
        resource: DEACTIVE_USER_API,
        id: interpreterActive?.data?.userId,
        values: {
          userId: interpreterActive?.data?.userId,
        },
      });
    } else {
      mutate({
        resource: ACTIVE_USER_API,
        id: interpreterActive?.data?.userId as BaseKey,
        values: {
          userId: interpreterActive?.data?.userId,
        },
      });
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (activeInterpreter?.data.status === 200) {
      handleChange(page);
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, activeInterpreter?.data?.message, EStatusNotification.SUCCESS);
      setIsModalVisible(false);
    }
  }, [activeInterpreter, setIsModalVisible]);

  useEffect(() => {
    refetch();
  }, [isLoadingActive]);

  return (
    <Space
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
      }}
    >
      <div>
        You are about to change the Active Status of this{" "}
        <strong>Interpreter</strong>. Please select <strong>Change</strong> to
        continue or <strong>Cancel</strong> to go back
      </div>
      <Space style={{ marginTop: 10 }}>
        <Button
          onClick={handleChangeActive}
          className="btn-create"
          style={{ marginRight: "10px", backgroundColor: "#" }}
          loading={isLoadingActive}
        >
          Change
        </Button>
        <Button onClick={handleCancel}>Cancel </Button>
      </Space>
    </Space>
  );
};

export default InterpreterActive;
