import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  Row,
  Select,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import { BaseKey, useCustom, useUpdate } from "@pankod/refine-core";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import {
  CUSTOMER_API,
  DEPARTMENT_API,
  GROUP_PERMISSION_API,
  PROFILE_API,
  UPDATE_USER_API,
  USER_API,
} from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ICheckboxChangeActive, ISelectItemPermisson } from "interfaces";
import { ICustomerReq } from "interfaces/customer";
import { IDepartment } from "interfaces/department";
import { IGroup } from "interfaces/group";
import { IUser } from "interfaces/users";
import { axiosInstance } from "providers/axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { openNotification} from "utils";

type UserUpdateProps = {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  user: IUser | undefined;
  isRequester: number | undefined;
  setIsRequester: Dispatch<SetStateAction<number | undefined>>;
  page: number;
  handleChange: (page: number) => void;
};

export const UserUpdate = (props: UserUpdateProps) => {
  const {
    setIsModalVisible,
    user,
    isModalVisible,
    setIsRequester,
    isRequester,
    page,
    handleChange,
  } = props;

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const [messageErr, setMessageErr] = useState<IUser>();
  const [selectedItems, setSelectedItem] = useState<ISelectItemPermisson>();
  const [selectPermissions, setSelectedPermission] = useState([]);

  const [inputValueDepartment, setInputValueDepartment] = useState<number>();

  const [messageRole, setMessageRole] = useState("");
  const [department, setDepartment] = useState([]);

  const { Option } = Select;
  const { form, formProps } = useForm<IUser>({
    action: EAction.EDIT,
  });

  const { data: userEditData, refetch: refetchEditData } = useCustom({
    url: USER_API.getOne + `/${user?.id}`,
    method: "get",
  });

  const { data: groupsData } = useCustom({
    url: GROUP_PERMISSION_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
      },
    },
  });

  const { data: customerData } = useCustom({
    url: CUSTOMER_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
        search: "",
      },
    },
  });

  const { data: profile } = useCustom({
    url: PROFILE_API,
    method: "get",
  });

  useEffect(() => {
    form.setFieldsValue({
      departmentId: userEditData?.data?.departmentId,
    });
  }, [userEditData?.data?.departmentId]);

  useEffect(() => {
    (async () => {
      if (userEditData?.data?.customerId) {
        const department = await axiosInstance.get(
          DEPARTMENT_API.getByCustomer +
            `?id=${userEditData?.data?.customerId}&page=&limit=`
        );

        setDepartment(department?.data?.results);
      }
    })();
  }, [userEditData?.data?.customerId]);

  const onSelect = (customerId: number, option: BaseOptionType) => {
    (async () => {
      const departmentByCusId = await axiosInstance.get(
        DEPARTMENT_API.getByCustomer + `?id=${customerId}&page=&limit=`
      );
      setDepartment(departmentByCusId?.data?.results);
    })();

    form.setFieldsValue({
      departmentId: "",
    });
  };

  const onChange = (customerId: number, option: BaseOptionType) => {
    setInputValueDepartment(customerId);
  };

  const { setFields } = form;
  const { mutate, data: updateData, isLoading } = useUpdate();

  const onFinish = (event: IUser) => {
    mutate(
      {
        resource: UPDATE_USER_API,
        id: user?.id as BaseKey,
        values: {
          username: event.username,
          firstname: event.firstname,
          lastname: event.lastname,
          phone: event.phone,
          email: event.email,
          roleId: event.roleId,
          faxNumber: Number(event.faxNumber),
          isActive: event.isActive,
          groups: event.groups,
          customerId: event.customerId,
          departmentId: event.departmentId,
          lastModifiedBy: profile?.data?.username,
        },
      },
      {
        onError: (error) => {
          
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  const onCheckIsActive = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ isActive: true });
    } else {
      form.setFieldsValue({ isActive: false });
    }
  };

  const handleChangeGroup = (selectedItems: ISelectItemPermisson) => {
    const assets_choose = selectedItems.map((item: number, index: number) => {
      let arrayItem: ISelectItemPermisson = {
        key: 0,
        permissions: [],
        map: undefined,
      };
      arrayItem.permissions = groupsData?.data?.results?.filter(
        (x: { id: number }) => x.id === item
      )[0];
      arrayItem.key = index;

      return arrayItem;
    });

    setSelectedPermission(assets_choose);
    setSelectedItem(selectedItems);
  };

  useEffect(() => {
    form.resetFields();
    setFields([
      {
        name: "firstname",
        value: userEditData?.data.firstname,
      },
      {
        name: "username",
        value: userEditData?.data?.username,
      },
      {
        name: "lastname",
        value: userEditData?.data.lastname,
      },
      {
        name: "phone",
        value: userEditData?.data?.phone,
      },
      {
        name: "email",
        value: userEditData?.data?.email,
      },
      {
        name: "faxNumber",
        value: userEditData?.data?.fax,
      },
      {
        name: "roleId",
        value: user?.roleId,
      },
      {
        name: "isActive",
        value: user?.isActive,
      },
      {
        name: "customerId",
        value: userEditData?.data?.customerId,
      },
      {
        name: "departmentId",
        value: userEditData?.data?.departmentId,
      },
      {
        name: "groups",
        value: userEditData?.data.groups?.map((item: IGroup) => item.id),
      },
    ]);
  }, [user, userEditData, form, isModalVisible]);

  useEffect(() => {
    if (updateData?.data.status === 201) {
      form.resetFields();
      handleChange(page);
        openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
      setIsModalVisible(false);
      setMessageErr(messageErr);
      refetchEditData();
    }
  }, [updateData, form, setIsModalVisible, refetchEditData]);

  const handleOnchangeRole = (value: number) => {
    if (user?.roleId !== null && value !== user?.roleId) {
      setMessageRole("Can not chose this option!");
    } else {
      setMessageRole("");
    }
    setIsRequester(value);
  };

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as IUser)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="User Name"
            name="username"
            rules={[
              {
                required: true,
                message:
                  "User Name must start with a letter and can not contain spaces or special characters",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
            initialValue={userEditData?.data?.username}
          >
            <Input size="large" maxLength={50} />
          </Form.Item>
          {messageErr?.username && (
            <Typography.Text type="danger">
              {messageErr.username[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
            initialValue={userEditData?.data?.email}
          >
            <Input size="large" maxLength={50} />
          </Form.Item>
          {messageErr?.email && (
            <Typography.Text type="danger">
              {messageErr.email[0]}
            </Typography.Text>
          )}
          <Row gutter={16}>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Must contain 10 numbers only",
                  },
                ]}
                normalize={(value, prevVal, prevVals) => value.trim()}
                initialValue={userEditData?.data?.phone}
              >
                <Input size="large" maxLength={10} />
              </Form.Item>
              {messageErr?.phone && (
                <Typography.Text type="danger">
                  {messageErr.phone[0]}
                </Typography.Text>
              )}
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              <Form.Item
                label="Ext"
                name="faxNumber"
                rules={[
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Must contain maximum 5 numbers",
                  },
                ]}
                normalize={(value, prevVal, prevVals) => value.trim()}
                initialValue={userEditData?.data?.fax}
              >
                <Input size="large" maxLength={5} />
              </Form.Item>
              {messageErr?.faxNumber && (
                <Typography.Text type="danger">
                  {messageErr.faxNumber}
                </Typography.Text>
              )}
            </Col>
          </Row>
          <Form.Item
            label="User Role"
            name="roleId"
            rules={[
              {
                required: true,
                message: "Please enter role",
              },
            ]}
            initialValue={user?.roleId}
          >
            <Select
              size="large"
              placeholder="Select role"
              onChange={(value) => {
                handleOnchangeRole(value);
              }}
              disabled={Boolean(user?.roleId)}
            >
              <Option value={1}>Admin</Option>
              <Option value={2}>Interpreter</Option>
              <Option value={3}>Requester</Option>
            </Select>
          </Form.Item>
          <span className="mess-role">{messageRole}</span>
          {messageErr?.roleId && (
            <Typography.Text type="danger">{messageErr.roleId}</Typography.Text>
          )}
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="Add an Group to User Group List"
            name="groups"
            rules={[
              {
                required: true,
                message: "Please enter group",
              },
            ]}
            initialValue={userEditData?.data.groups?.map(
              (item: IGroup) => item.id
            )}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              size="large"
              placeholder="Pick a Group"
              value={selectedItems}
              onChange={handleChangeGroup}
              style={{ width: "100%" }}
              optionFilterProp="children"
              filterOption={(
                input,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {groupsData?.data?.results?.map((item: IGroup, index: number) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="First Name"
            name="firstname"
            rules={[
              {
                required: true,
                message: "Please enter first name",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
            initialValue={userEditData?.data.firstname}
          >
            <Input size="large" maxLength={50} />
          </Form.Item>
          {messageErr?.firstname && (
            <Typography.Text type="danger">
              {messageErr.firstname[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="Last Name"
            name="lastname"
            rules={[
              {
                required: true,
                message: "Please enter last name",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
            initialValue={userEditData?.data.lastname}
          >
            <Input size="large" maxLength={50} />
          </Form.Item>
          {messageErr?.lastname && (
            <Typography.Text type="danger">
              {messageErr.lastname[0]}
            </Typography.Text>
          )}

          {isRequester === 3 && (
            <>
              <Form.Item
                label="Customer Name"
                name="customerId"
                rules={[
                  {
                    required: true,
                    message: "Please enter Customer Name",
                  },
                ]}
                initialValue={userEditData?.data?.customerId}
              >
                <Select
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(
                    input,
                    option: BaseOptionType | DefaultOptionType | undefined
                  ) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={onSelect}
                  onChange={onChange}
                >
                  {customerData?.data?.results?.map((item: ICustomerReq) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
              {messageErr?.customerId && (
                <Typography.Text type="danger">
                  {messageErr.customerId}
                </Typography.Text>
              )}
              <Form.Item
                label="Department"
                name="departmentId"
                rules={[
                  {
                    required: true,
                    message: "Please enter department",
                  },
                ]}
                initialValue={userEditData?.data?.departmentId}
              >
                <Select
                  size="large"
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  defaultValue={userEditData?.data?.departmentId}
                >
                  {department?.map((item: IDepartment) => (
                    <>
                      <Option value={item.id}>{item.name}</Option>
                    </>
                  ))}
                </Select>
              </Form.Item>
              {messageErr?.departmentId && (
                <Typography.Text type="danger">
                  {messageErr.departmentId}
                </Typography.Text>
              )}
            </>
          )}

          <Form.Item
            label="
          "
            name="isActive"
            valuePropName="checked"
            initialValue={user?.isActive}
          >
            <Checkbox
              onChange={(event) => {
                onCheckIsActive(event);
              }}
            >
              Is Active
            </Checkbox>
          </Form.Item>
          {messageErr?.isActive && (
            <Typography.Text type="danger">
              {messageErr.isActive}
            </Typography.Text>
          )}
        </Col>
      </Row>

      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
