import { Col, Row, Typography } from "@pankod/refine-antd";
import { Image } from "antd";
import { IMAGE_API } from "api/baseApi";
import { DotIcon } from "components/icons";
import { ILanguageOnline } from "interfaces/users";
import "../style.css";

type ProfileDetailProps = {
  listProfile: ILanguageOnline | undefined;
  handleOpenModalProfile: (data: any) => void;
};

const { Text } = Typography;

export const ProfileDashboard = (props: ProfileDetailProps) => {
  const { handleOpenModalProfile, listProfile } = props;
  const baseUrl = process.env.REACT_APP_API_PROXY;
  return (
      <>
        {listProfile?.users?.map((interpreter: any) => (
          <Row
            justify="start"
            className="interpreter-online"
            onClick={() => handleOpenModalProfile(interpreter.id)}
            key={interpreter?.id}
          >
            <Col>
              {interpreter?.avatar ? (
                <>
                  {" "}
                  <Image
                    preview={false}
                    src={baseUrl + IMAGE_API + `/${interpreter?.avatar}`}
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: "50%",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                  <DotIcon className="icon-activity" />
                </>
              ) : (
                <>
                  <Image
                    preview={false}
                    src="/images/global/image.png"
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: "50%",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                  <DotIcon className="icon-activity" />
                </>
              )}
            </Col>
            <Col>
              <Text className="name-profile" key={interpreter.id}>
                {interpreter?.firstname + " " + interpreter?.lastname}
              </Text>
            </Col>
          </Row>
        ))}
      </>
  );
};
