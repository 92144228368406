import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  Row,
  Select,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import { BaseKey, useCustom, useUpdate } from "@pankod/refine-core";
import {
  DEPARTMENT_API,
  REQUESTER_API,
  SUB_DEPARTMENT_API,
  UPDATE_REQUESTER_API,
} from "api/baseApi";
import { UserContext } from "hooks/useContext/useUserContext";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ICheckboxChangeActive } from "interfaces";
import { IRequester } from "interfaces/requesters";
import { axiosInstance } from "providers/axios";
import React, { useContext, useEffect, useState } from "react";
import { openNotification} from "utils";
import { ISub_Department } from "interfaces/sub_department";
import { ICustomer } from "interfaces/users";
import { IDepartment } from "interfaces/department";
import { BaseOptionType } from "antd/es/select";

export interface RequestersProps {
  requester?: IRequester | undefined;
  customer?: ICustomer | undefined;
  department?: IDepartment | undefined;
  subdepartment?: ISub_Department | undefined;
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  updateSuccess: () => void;
}

export const UpdateRequestersModal = (props: RequestersProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const { setIsModalVisible, requester, isModalVisible, updateSuccess } = props;
  const [messageErr, setMessageErr] = useState<IRequester>();
  const [requesterEdit, setRequesterEdit] = useState<IRequester>();
  const [changeDepartmentId, setChangeDepartmentId] = useState<number>();
  const { form, formProps } = useForm<IRequester>({
    action: EAction.EDIT,
  });
  const [subDepartmentValue, setSubDepartmentValue] = useState<
    string | undefined
  >("");
  const [subDepartmentId, setSubDepartmentId] = useState<number>();
  const [subDepartmentData, setSubdepartmentData] =
    useState<ISub_Department[]>();

  useEffect(() => {
    (async () => {
      const RequesterEditData = await axiosInstance.get(
        REQUESTER_API.getOne + `/${requester?.id}`
      );
      if (RequesterEditData) {
        setRequesterEdit(RequesterEditData?.data);
      }
    })();
    (async () => {
      const data = await axiosInstance.get(
        SUB_DEPARTMENT_API.getByDepartment +
          `?id=${requester?.department.id}&page=&limit=`
      );
      setSubdepartmentData(data?.data.results);
    })();
  }, [requester]);

  useEffect(() => {
    if (requesterEdit?.departmentId) {
      setChangeDepartmentId(requesterEdit?.departmentId);
    }
    if (requesterEdit?.subdepartmentId) {
      setSubDepartmentId(requesterEdit?.subdepartmentId);
    }
  }, [requesterEdit]);

  const { data: departmentData } = useCustom({
    url: DEPARTMENT_API.getByCustomer,
    method: "get",
    config: {
      query: {
        id: requesterEdit?.customerId,
        page: null,
        limit: null,
      },
    },
  });

  const handleChangeDepartment = (
    departmentId: number,
    option: BaseOptionType
  ) => {
    (async () => {
      if (departmentId === undefined) {
        setSubdepartmentData([]);
      } else {
        const data = await axiosInstance.get(
          SUB_DEPARTMENT_API.getByDepartment +
            `?id=${departmentId}&page=&limit=`
        );
        setSubdepartmentData(data?.data.results);
        setChangeDepartmentId(departmentId);
      }
    })();
  };

  const handleClearDepartment = () => {
    setSubdepartmentData([]);
    setSubDepartmentId(0);
    setSubDepartmentValue("");
  };

  const handleChangeSubDepartment = (
    subDepartmentId: number,
    option: BaseOptionType
  ) => {
    setSubDepartmentId(subDepartmentId);
  };

  const handleClearSubDepartment = () => {
    setSubDepartmentId(0);
  };

  const { setFields } = form;

  const { mutate, data: updateData, isLoading } = useUpdate();

  const onFinish = (event: IRequester) => {
    mutate(
      {
        resource: UPDATE_REQUESTER_API,
        id: requester?.id as BaseKey,
        values: {
          username: event.username,
          firstname: event.firstname,
          lastname: event.lastname,
          phone: event.phone,
          customerId: requesterEdit?.customerId,
          fax: event.ext,
          departmentId: changeDepartmentId,
          subdepartmentId: subDepartmentId,
          supervisorName: event.supervisorName,
          suiteNumber: parseInt(event.suiteNumber),
          isActive: event.isActive,
          email: event.email,
          medicalType: event.medicalType,
        },
      },
      {
        onError: (error) => {
            
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  const onCheck = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ isActive: true });
    } else {
      form.setFieldsValue({ isActive: false });
    }
  };

  useEffect(() => {
    form.resetFields();
    setFields([
      {
        name: "username",
        value: requesterEdit?.user.username,
      },
      {
        name: "firstname",
        value: requesterEdit?.user.firstname,
      },
      {
        name: "lastname",
        value: requesterEdit?.user.lastname,
      },
      {
        name: "phone",
        value: requesterEdit?.user.phone,
      },
      {
        name: "customerId",
        value: requesterEdit?.customer.id,
      },
      {
        name: "departmentId",
        value: requesterEdit?.departmentId,
      },
      {
        name: "subdepartmentId",
        value: requesterEdit?.subdepartmentId,
      },
      {
        name: "supervisorName",
        value: requesterEdit?.supervisorName,
      },
      {
        name: "email",
        value: requesterEdit?.user.email,
      },
      {
        name: "suiteNumber",
        value: requesterEdit?.suiteNumber,
      },
      {
        name: "isActive",
        value: requesterEdit?.user.isActive,
      },
      {
        name: "medicalType",
        value: requesterEdit?.medicalType,
      },
      {
        name: "ext",
        value: requesterEdit?.user.faxNumber,
      },
    ]);
  }, [requesterEdit, form, isModalVisible, setFields]);

  useEffect(() => {
    if (updateData?.data.status === 200) {
      form.resetFields();
        openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
      updateSuccess();
      setIsModalVisible(false);
      setMessageErr(messageErr);
    }
  }, [updateData, form, setIsModalVisible, messageErr]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => {
        onFinish(event as IRequester);
      }}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="User Name"
            name="username"
            rules={[
              {
                required: true,
                pattern: /^[a-zA-Z]\w*$/,
                message:
                  "User Name must start with a letter and can not contain spaces or special characters",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input size="large" />
          </Form.Item>
          {messageErr?.username && (
            <Typography.Text type="danger">
              {messageErr.username[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="Customer"
            name="customerName"
            rules={[
              {
                required: false,
                message: "Please enter customer",
              },
            ]}
            initialValue={requester?.customer.name}
          >
            <Input disabled size="large" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="
            "
            name="isActive"
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox
              defaultChecked={false}
              onChange={(event) => {
                onCheck(event);
              }}
            >
              Is Active
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="Department"
            name="departmentId"
            rules={[
              {
                required: false,
                message: "Please select department",
              },
            ]}
            // initialValue={requester?.department?.name}
          >
            <Select
              placeholder="Please select department"
              style={{ width: "100%" }}
              onClear={handleClearDepartment}
              onChange={handleChangeDepartment}
            >
              {departmentData?.data?.results.map(
                (item: IRequester, index: number) => (
                  <>
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  </>
                )
              )}
            </Select>
          </Form.Item>

          <Row gutter={16}>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name",
                  },
                ]}
                initialValue={requester?.user.firstname}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    required: false,
                    message: "Please enter Phone Number",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              {messageErr?.phone && (
                <Typography.Text type="danger">
                  {messageErr.phone[0]}
                </Typography.Text>
              )}
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name",
                  },
                ]}
                initialValue={requester?.user.firstname}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item
                label="Ext"
                name="ext"
                rules={[
                  {
                    required: false,
                    message: "Please enter Ext",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
            initialValue={requester?.user.email}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="Sub-department"
            name="subdepartmentId"
            rules={[
              {
                required: false,
                message: "Please enter Sub-department",
              },
            ]}
            // initialValue={subDepartmentValue}
          >
            <Select
              placeholder="Please select Subdepartment"
              onClear={handleClearSubDepartment}
              onChange={handleChangeSubDepartment}
              style={{ width: "100%" }}
            >
              {subDepartmentData?.map(
                (item: ISub_Department, index: number) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="Supervisor Name"
            name="supervisorName"
            rules={[
              {
                required: false,
                message: "Please enter Supervisor Name",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Suite Number"
            name="suiteNumber"
            rules={[
              {
                required: false,
                message: "Please enter Suite Number",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Medical Type"
            name="medicalType"
            rules={[
              {
                required: false,
                message: "Please enter Medical Type",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
      </Row>
      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
