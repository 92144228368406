import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Row,
  Typography,
} from "@pankod/refine-antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { EAction } from "constants/constant";
import { ICustomerReq } from "interfaces/customer";
import { useEffect, useState } from "react";
import {
  defaultBillItemData,
  defaultTimeListData,
  ICheckboxType,
} from "../DataFieldCustomer";
import "../styles.css";
const { Title } = Typography;

type InvoiceCustomizingTabPaneProps = {
  customerRequest: ICustomerReq;
  option: string;
  onBooleanInputChange: (e: CheckboxChangeEvent) => void;
  setCustomerRequest: React.Dispatch<React.SetStateAction<ICustomerReq>>;
};

export const InvoiceCustomizingTabPane = ({
  customerRequest,
  option,
  onBooleanInputChange,
  setCustomerRequest,
}: InvoiceCustomizingTabPaneProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const [billItem, setBillItem] = useState<ICheckboxType>(defaultBillItemData);

  const [timeList, setTimeList] = useState<ICheckboxType>(defaultTimeListData);

  const handleUpdateCheckedParent = (prevData: ICheckboxType) => {
    const newState = { ...prevData };

    for (const item in newState.childrens) {
      newState.childrens[item].checked = customerRequest[item];
    }
    newState.checked = true;
    for (const item in newState.childrens) {
      if (!newState.childrens[item].checked) {
        newState.checked = false;
      }
    }

    return newState;
  };

  useEffect(() => {
    if (customerRequest) {
      setBillItem((prevData: ICheckboxType) => {
        return handleUpdateCheckedParent(prevData);
      });
      setTimeList((prevData: ICheckboxType) => {
        return handleUpdateCheckedParent(prevData);
      });
    }
  }, [customerRequest]);

  const handleIsCheckedTimeForCustomer = (e: CheckboxChangeEvent) => {
    setTimeList((prevData: ICheckboxType) => {
      const newState = { ...prevData };
      newState.checked = e.target.checked;

      for (const item in newState.childrens) {
        newState.childrens[item].checked = e.target.checked;
      }
      setCustomerRequest((prevData: ICustomerReq) => {
        const newStateCustomer = { ...prevData };
        for (const item in newState.childrens) {
          newStateCustomer[item] = newState.childrens[item].checked;
        }

        return newStateCustomer;
      });

      return newState;
    });
  };

  const handleIsCheckedBillItemForCustomer = (e: CheckboxChangeEvent) => {
    setBillItem((prevData: ICheckboxType) => {
      const newState = { ...prevData };
      newState.checked = e.target.checked;

      for (const item in newState.childrens) {
        newState.childrens[item].checked = e.target.checked;
      }
      setCustomerRequest((prevData: ICustomerReq) => {
        const newStateCustomer = { ...prevData };
        for (const item in newState.childrens) {
          newStateCustomer[item] = newState.childrens[item].checked;
        }

        return newStateCustomer;
      });

      return newState;
    });
  };

  const handleSetStateItem = (
    prevData: ICheckboxType,
    e: CheckboxChangeEvent
  ) => {
    const newState = { ...prevData };

    if (newState.childrens) {
      newState.childrens[e.target.name as string].checked = e.target.checked;
    }
    newState.checked = true;
    for (const item in newState.childrens) {
      if (!newState.childrens[item].checked) {
        newState.checked = false;
      }
    }
    return newState;
  };

  const handleIsCheckedTimeChildren = (e: CheckboxChangeEvent) => {
    setTimeList((prevData: ICheckboxType) => {
      return handleSetStateItem(prevData, e);
    });
    setCustomerRequest({
      ...customerRequest,
      [e.target.name as string]: e.target.checked,
    });
  };

  const handleIsCheckedBillItemChildren = (e: CheckboxChangeEvent) => {
    setBillItem((prevData: ICheckboxType) => {
      return handleSetStateItem(prevData, e);
    });
    setCustomerRequest({
      ...customerRequest,
      [e.target.name as string]: e.target.checked,
    });
  };

  return (
    <>
      <Title level={5} className="title-address">
        Add column
      </Title>
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="appointmentNumber"
              checked={customerRequest.appointmentNumber}
              onChange={(e: CheckboxChangeEvent) => {
                onBooleanInputChange(e);
              }}
            >
              Appointment Number
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="processNumber"
              checked={customerRequest.processNumber}
              onChange={(e) => onBooleanInputChange(e)}
            >
              Process Number
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              checked={
                customerRequest.invoicePeriodFromDate &&
                customerRequest.invoicePeriodToDate
              }
              onChange={(e) =>
                setCustomerRequest({
                  ...customerRequest,
                  invoicePeriodFromDate: e.target.checked,
                  invoicePeriodToDate: e.target.checked,
                })
              }
            >
              Invoice Period
            </Checkbox>
            <div className="checkbox-group">
              <Checkbox
                disabled={option === EAction.DETAIL}
                name="invoicePeriodFromDate"
                checked={customerRequest.invoicePeriodFromDate}
                onChange={(e) => onBooleanInputChange(e)}
              >
                Invoice Period From Date
              </Checkbox>
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.invoicePeriodToDate}
                name="invoicePeriodToDate"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Invoice Period To Date
              </Checkbox>
            </div>
          </Form.Item>

          <Form.Item>
            <Checkbox
            name="invoiceCreateDate"
           disabled={option === EAction.DETAIL}
           checked={customerRequest.invoiceCreateDate}
           onChange={(e) => onBooleanInputChange(e)}
            >      
              Invoice Create Date
            </Checkbox>
          </Form.Item>
          <Form.Item name="customerAll">
            <Checkbox
              disabled={option === EAction.DETAIL}
              onChange={(e) => {
                setCustomerRequest({
                  ...customerRequest,
                  customerName: e.target.checked,
                  customerEmail: e.target.checked,
                });
              }}
              checked={
                customerRequest.customerEmail &&
                customerRequest.customerId
              }
            >
              Customer
            </Checkbox>
            <div className="checkbox-group">
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.customerName}
                name="customerName"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Customer Name
              </Checkbox>
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.customerEmail}
                name="customerEmail"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Customer Email
              </Checkbox>
            </div>
          </Form.Item>

          <Form.Item name="phoneAll">
            <Checkbox
              disabled={option === EAction.DETAIL}
              onChange={(e) => {
                setCustomerRequest({
                  ...customerRequest,
                  bcellPhone: e.target.checked,
                  bfaxNumber: e.target.checked,
                });
              }}
              checked={
                customerRequest.bcellPhone &&
                customerRequest.bfaxNumber
              }
            >
              Phone
            </Checkbox>
            <div className="checkbox-group">
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.bcellPhone}
                name="bcellPhone"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Cell Phone
              </Checkbox>
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.bfaxNumber}
                name="bfaxNumber"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Fax Number
              </Checkbox>
            </div>
          </Form.Item>

          <Form.Item name="department">
            <Checkbox
              disabled={option === EAction.DETAIL}
              onChange={(e) => {
                setCustomerRequest({
                  ...customerRequest,
                  departmentId: e.target.checked,
                  departmentName: e.target.checked,
                  subDepartmentName: e.target.checked,
                });
              }}
              checked={
                customerRequest.departmentId &&
                customerRequest.departmentName &&
                customerRequest.subDepartmentName
              }
            >
              Department
            </Checkbox>
            <div className="checkbox-group">
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.departmentId}
                name="departmentId"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Department Id
              </Checkbox>
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.departmentName}
                name="departmentName"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Department Name
              </Checkbox>
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.subDepartmentName}
                name="subDepartmentName"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Sub-department Name
              </Checkbox>
            </div>
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="invoiceNumber"
              checked={customerRequest.invoiceNumber}
              onChange={(e: CheckboxChangeEvent) => {
                onBooleanInputChange(e);
              }}
            >
              Invoice Number
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="sourceLanguage"
              checked={customerRequest.sourceLanguage}
              onChange={(e: CheckboxChangeEvent) => {
                onBooleanInputChange(e);
              }}
            >
              Source Language
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="languageName"
              checked={customerRequest.languageName}
              onChange={(e: CheckboxChangeEvent) => {
                onBooleanInputChange(e);
              }}
            >
              Language Name
            </Checkbox>
          </Form.Item>

          <Form.Item
            name="
interpreter"
          >
            <Checkbox
              disabled={option === EAction.DETAIL}
              onChange={(e) => {
                setCustomerRequest({
                  ...customerRequest,
                  interpreterId: e.target.checked,
                  interpreterName: e.target.checked,
                });
              }}
              checked={
                customerRequest.interpreterId && customerRequest.interpreterName
              }
            >
              Interpreter
            </Checkbox>
            <div className="checkbox-group">
              <Checkbox
                name="interpreterId"
                disabled={option === EAction.DETAIL}
                checked={customerRequest.interpreterId}
                onChange={(e) => onBooleanInputChange(e)}
              >
                Interpreter Id
              </Checkbox>
              <Checkbox
                name="interpreterName"
                disabled={option === EAction.DETAIL}
                checked={customerRequest.interpreterName}
                onChange={(e) => onBooleanInputChange(e)}
              >
                Interpreter Name
              </Checkbox>
            </div>
          </Form.Item>

          <Form.Item name="timeAll">
            <Checkbox
              disabled={option === EAction.DETAIL}
              onChange={(e) => {
                handleIsCheckedTimeForCustomer(e);
              }}
              checked={timeList.checked}
            >
              Time
            </Checkbox>
            <div className="checkbox-group">
              {Object.values(timeList.childrens as {})?.map(
                (timeItem: any, index: number) => (
                  <Checkbox
                    disabled={option === EAction.DETAIL}
                    key={index}
                    name={timeItem.name as string}
                    checked={timeItem.checked}
                    onChange={(e) => {
                      handleIsCheckedTimeChildren(e);
                    }}
                  >
                    {timeItem.label}
                  </Checkbox>
                )
              )}
            </div>
          </Form.Item>

          <Form.Item name="requesterAll">
            <Checkbox
              disabled={option === EAction.DETAIL}
              onChange={(e) => {
                setCustomerRequest({
                  ...customerRequest,
                  requesterName: e.target.checked,
                  requesterPhone: e.target.checked,
                  requesterEmail: e.target.checked,
                });
              }}
              checked={
                customerRequest.requesterPhone &&
                customerRequest.requesterEmail &&
                customerRequest.requesterName
              }
            >
              Requester
            </Checkbox>
            <div className="checkbox-group">
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.requesterName}
                name="requesterName"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Requester Name
              </Checkbox>
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.requesterPhone}
                name="requesterPhone"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Requester Phone
              </Checkbox>
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.requesterEmail}
                name="requesterEmail"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Requester Email
              </Checkbox>
            </div>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="appointmentCreateUser"
              onChange={(e: CheckboxChangeEvent) => {
                onBooleanInputChange(e);
              }}
              checked={customerRequest.appointmentCreateUser}
            >
              Appointment Create User
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              onChange={(e) => {
                handleIsCheckedBillItemForCustomer(e);
              }}
              checked={billItem.checked}
              name="BillItemAll"
            >
              Bill Item
            </Checkbox>
            <div className="checkbox-group">
              {Object.values(billItem.childrens as {}).map((item: any) => (
                <Checkbox
                  disabled={option === EAction.DETAIL}
                  key={item.name}
                  name={item.name}
                  checked={item.checked}
                  onChange={(e) => {
                    handleIsCheckedBillItemChildren(e);
                  }}
                >
                  {item.label}
                </Checkbox>
              ))}
            </div>
          </Form.Item>
          <Form.Item name="lep">
            <Checkbox
              disabled={option === EAction.DETAIL}
              onChange={(e) => {
                setCustomerRequest({
                  ...customerRequest,
                  serviceRecipient1FullName: e.target.checked,
                  serviceRecipient2FullName: e.target.checked,
                  bOtherRef1: e.target.checked,
                  bOtherRef2: e.target.checked,
                  bOtherRef3: e.target.checked,
                });
              }}
              checked={
                customerRequest.bOtherRef1 &&
                customerRequest.bOtherRef2 &&
                customerRequest.bOtherRef3 &&
                customerRequest.serviceRecipient1FullName &&
                customerRequest.serviceRecipient2FullName
              }
            >
              LEP
            </Checkbox>
            <div className="checkbox-group">
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.serviceRecipient1FullName}
                name="serviceRecipient1FullName"
                onChange={(e) => onBooleanInputChange(e)}
              >
                LEP 1 Full Name
              </Checkbox>
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.serviceRecipient2FullName}
                name="serviceRecipient2FullName"
                onChange={(e) => onBooleanInputChange(e)}
              >
                LEP 2 Full Name
              </Checkbox>
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.bOtherRef1}
                name="bOtherRef1"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Other Ref.#1
              </Checkbox>
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.bOtherRef2}
                name="bOtherRef2"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Other Ref.#2
              </Checkbox>
              <Checkbox
                disabled={option === EAction.DETAIL}
                checked={customerRequest.bOtherRef3}
                name="bOtherRef3"
                onChange={(e) => onBooleanInputChange(e)}
              >
                Other Ref.#3
              </Checkbox>
            </div>
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="additionalInformation"
              onChange={(e: CheckboxChangeEvent) => {
                onBooleanInputChange(e);
              }}
              checked={customerRequest.additionalInformation}
            >
              Additional Information
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="streetAddress"
              checked={customerRequest.streetAddress}
              onChange={(e: CheckboxChangeEvent) => {
                onBooleanInputChange(e);
              }}
            >
              Street Address
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="aptSuite"
              checked={customerRequest.aptSuite}
              onChange={(e: CheckboxChangeEvent) => {
                onBooleanInputChange(e);
              }}
            >
              Apt # / Suite
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="bcity"
              checked={customerRequest.bcity}
              onChange={(e: CheckboxChangeEvent) => {
                onBooleanInputChange(e);
              }}
            >
              City
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="zipCodePostalCode"
              checked={customerRequest.zipCodePostalCode}
              onChange={(e: CheckboxChangeEvent) => {
                onBooleanInputChange(e);
              }}
            >
              Zip Code / Postal Code
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="stateProvince"
              checked={customerRequest.stateProvince}
              onChange={(e: CheckboxChangeEvent) => {
                onBooleanInputChange(e);
              }}
            >
              State / Province
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={option === EAction.DETAIL}
              name="typeOfCall"
              checked={customerRequest.typeOfCall}
              onChange={(e: CheckboxChangeEvent) => {
                onBooleanInputChange(e);
              }}
            >
              Type of Call
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
