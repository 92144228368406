import FinishCall from "components/FinishCall";
import InviteCall from "components/InviteCall";
import RateCall from "components/RateCall";
import { GeneratePassword } from "components/generate-password/GeneratePassWord";
import { Page404 } from "pages/404Page";
import  VideoChat  from "components/VideoProvider";
import { ForgotPassword } from "components/reset-password/ForgotPassword";
import { ResetPassword } from "components/reset-password/ResetPassword";
import Connecting from "pages/VideoCall/connecting";

export default function Routes() {
    return ([
        {
          element: <VideoChat />,
          path: "/video-call",
          Layout: false,
        },
        {
          element: <FinishCall />,
          path: "/finish-call",
          Layout: false,
        },
        {
          element: <InviteCall />,
          path: "/invite",
          Layout: false,
        },
        {
          element: <RateCall />,
          path: "/rate-call",
          Layout: false,
        },
        {
          element: <GeneratePassword />,
          path: "/generate-password",
          Layout: false,
        },
        {
          element: <Page404 />,
          path: "/404",
          Layout: false,
        },
        {
          element: <ForgotPassword />,
          path: "/forgot-password",
          Layout: false,
        },
        {
          element: <ResetPassword />,
          path: "/reset-password",
          Layout: false,
        },
        {
          element: <Connecting />,
          path: "/connecting-test",
          Layout: false,
        },
      ])
}