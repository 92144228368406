import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { Icon } from "@pankod/refine-antd";

const USDSVG = () => (
  <svg
    width="1em"
    height="1em"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
  >
    <path d="M146 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3C4.9 411.4-2.4 392.5 4.8 376.3s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7 0 0C105.4 279.3 70.4 270 44.4 253c-14.2-9.3-27.5-22-35.8-39.6C.3 195.4-1.4 175.4 2.5 154.1C9.7 116 38.3 91.2 70.8 78.3c13.3-5.3 27.9-8.9 43.2-11V32c0-17.7 14.3-32 32-32z" />
  </svg>
);

const USDIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={USDSVG} {...props} />
);

const VideoSlashSVG = () => (
  <svg focusable="false" width="24" height="24" viewBox="0 0 24 24">
    <path d="M18 10.48V6c0-1.1-.9-2-2-2H6.83l2 2H16v7.17l2 2v-1.65l4 3.98v-11l-4 3.98zM16 16L6 6 4 4 2.81 2.81 1.39 4.22l.85.85C2.09 5.35 2 5.66 2 6v12c0 1.1.9 2 2 2h12c.34 0 .65-.09.93-.24l2.85 2.85 1.41-1.41L18 18l-2-2zM4 18V6.83L15.17 18H4z"></path>
  </svg>
);

const VideoSlashIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={VideoSlashSVG} {...props} />
);

const VideoEndSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    color="#fff"
    height="1em"
    viewBox="0 96 960 960"
  >
    <path d="M480 416q126 0 241 50.5T918 613q8 10 8.5 22t-8.5 21l-94 94q-8 8-23 9t-24-6l-114-85q-6-5-9-11t-3-13V505q-42-16-85.5-22.5T480 476q-42 0-85.5 6.5T309 505v139q0 7-3 13t-9 11l-114 85q-12 9-24.5 8T136 750l-94-94q-9-9-8.5-21t8.5-22q82-96 197-146.5T480 416ZM244 530q-39 19-73.5 45.5T104 632l57 59 83-61V530Zm467-4v98l87 66 58-58q-32-33-68.5-58.5T711 526Zm-467 4Zm467-4Z" />
  </svg>
);

const VideoEndIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={VideoEndSVG} {...props} />
);

const DotSVG = () => (
  <svg
    id="SvgjsSvg1091"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
  >
    <defs id="SvgjsDefs1092"></defs>
    <g id="SvgjsG1093">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="1em"
        height="1em"
      >
        <circle cx="12" cy="12" r="10" fill="#3a9a6f"></circle>
      </svg>
    </g>
  </svg>
);

const DotIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DotSVG} {...props} />
);

const AvatarSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 29 29"
    id="user"
    width="1.5em"
    height="1.5em"
  >
    <path d="M14.5 2A12.514 12.514 0 0 0 2 14.5 12.521 12.521 0 0 0 14.5 27a12.5 12.5 0 0 0 0-25Zm7.603 19.713a8.48 8.48 0 0 0-15.199.008A10.367 10.367 0 0 1 4 14.5a10.5 10.5 0 0 1 21 0 10.368 10.368 0 0 1-2.897 7.213ZM14.5 7a4.5 4.5 0 1 0 4.5 4.5A4.5 4.5 0 0 0 14.5 7Z"></path>
  </svg>
);

const AvatarIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AvatarSVG} {...props} />
);

const CloseSVG = () => (
  <svg
    style={{ color: "white" }}
    xmlns="http://www.w3.org/2000/svg"
    width="2em"
    height="2em"
    fill="currentColor"
    className="bi bi-x"
    viewBox="0 0 16 16"
  >
    <path
      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
      fill="white"
    ></path>
  </svg>
);

const CloseIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CloseSVG} {...props} />
);

const VideoSVG = () => (
  <svg
    style={{ color: "white" }}
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1.5em"
    fill="currentColor"
    className="bi bi-camera-video-fill"
    viewBox="0 0 16 16"
  >
    <path
      fill-rule="evenodd"
      d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"
      fill="white"
    ></path>{" "}
  </svg>
);

const VideoIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={VideoSVG} {...props} />
);

const DotRedSVG = () => (
  <svg
    id="SvgjsSvg1091"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
  >
    <defs id="SvgjsDefs1092"></defs>
    <g id="SvgjsG1093">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="0.8em"
        height="0.8em"
      >
        <circle cx="12" cy="12" r="10" fill="red"></circle>
      </svg>
    </g>
  </svg>
);

const DotRedIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DotRedSVG} {...props} />
);

const EndCallSVG = () => (
  <svg
    viewBox="0 0 24 11"
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1.5em"
  >
    <path
      d="M12 2.5c-1.6 0-3.1.3-4.6.7v3.1c0 .4-.2.7-.6.9-1 .5-1.9 1.1-2.7 1.9-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3L.2 6.6c-.1-.2-.2-.4-.2-.7 0-.3.1-.5.3-.7C3.3 2.3 7.5.5 12 .5c4.5 0 8.7 1.8 11.7 4.7.2.2.3.4.3.7 0 .3-.1.5-.3.7l-2.5 2.5c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.8-.7-1.7-1.4-2.7-1.9-.3-.2-.6-.5-.6-.9V3.2c-1.4-.4-2.9-.7-4.5-.7Z"
      fill="#ffffff"
      fillRule="evenodd"
      className="fill-000000"
    ></path>
  </svg>
);
const EndCallIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EndCallSVG} {...props} />
);
const VideoEnable = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="200.000000pt"
    height="192.000000pt"
    viewBox="0 0 200.000000 192.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    ></g>
  </svg>
);
const VideoEnableIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={VideoEnable} {...props} />
);
export {
  USDIcon,
  DotIcon,
  AvatarIcon,
  VideoSlashIcon,
  VideoEndIcon,
  CloseIcon,
  VideoIcon,
  DotRedIcon,
  EndCallIcon,
  VideoEnableIcon,
};
