import { Line } from "@ant-design/plots";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Grid,
  List,
  Row,
  Space,
  Spin,
  Typography,
} from "@pankod/refine-antd";
import { useCustom, usePermissions, useTranslate } from "@pankod/refine-core";
import { TOTAL_STATISTICS } from "api/baseApi";
import { EFormatDateTime } from "constants/constant";
import dayjs, { Dayjs } from "dayjs";
import { DashboardCustomer } from "pages/customer/dashboard";
import { useState } from "react";

type totalCall = {
  data?: {
    totalCalls?: number;
    totalMinutes?: number;
  };
};

type CustomersDashboardProps = {
  totalCall: totalCall | undefined;
};

export const CustomersDashboard = (props: CustomersDashboardProps) => {
  const { totalCall } = props;
  const translate = useTranslate();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const [isSearching, setIsSearching] = useState(false);

  const [totalStasticsData, setTotalStasticsData] = useState<any>([]);

  const { data: totalStasticData, isLoading: isLoadingData } = useCustom({
    url: TOTAL_STATISTICS,
    method: "get",
    config: {
      query: {
        start: isSearching
          ? startDate
            ? dayjs(startDate).format(EFormatDateTime.DATE)
            : ""
          : "",
        end: isSearching
          ? endDate
            ? dayjs(endDate).format(EFormatDateTime.DATE)
            : ""
          : "",
      },
    },

    queryOptions: {
      onSuccess: (totalStatics) => {
        setTotalStasticsData(totalStatics?.data?.data);
      },
    },
  });

  const handleStartDate = (startDate: Dayjs | null) => {
    if (startDate) {
      setStartDate(startDate);
    } else {
      setIsSearching(false);
      setStartDate("");
    }
  };

  const handleEndDate = (endDate: Dayjs | null) => {
    if (endDate) {
      setEndDate(endDate);
    } else {
      setIsSearching(false);
      setEndDate("");
    }
  };

  const handleSearchCall = async () => {
    if (!startDate || !endDate) {
      setIsSearching(false);
    }
    setIsSearching(true);
  };

  const configCustomer = {
    data: totalStasticsData,
    xField: "date",
    yField: "value",
    seriesField: "category",
    xAxis: {
      tickCount: 8,
    },
    legend: {
      position: "top-right",
    },
    style: { height: 350 },
  };

  const totalCalls = totalStasticData && totalStasticData?.data?.totalCalls;
  const totalMinutes = totalStasticData && totalStasticData?.data?.totalMinutes;

  return (
    <>
      <Row>
        <Col span={24}>
          <List title={translate("resource.dashboard")}>
            <Form
              layout="vertical"
              className={!isMobile ? "search-form" : ""}
              style={{ paddingBottom: 0 }}
            >
              <Form.Item
                label="Start Date"
                name="fromDate"
                className={!isMobile ? "search-item" : ""}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  value={startDate}
                  onChange={(e) => handleStartDate(e)}
                  disabledDate={(current) => {
                    return endDate ? current && current > endDate : false;
                  }}
                />
              </Form.Item>
              <Form.Item
                label="End Date"
                name="toDate"
                className={!isMobile ? "search-item" : ""}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  value={endDate}
                  disabledDate={(current) => {
                    return current && current < startDate;
                  }}
                  onChange={(e) => handleEndDate(e)}
                />
              </Form.Item>

              <Button
                className={
                  isMobile ? "search-report-call-mobile" : "search-report-call"
                }
                style={{
                  marginTop: !isMobile ? 30 : 0,
                  marginBottom: isMobile ? 20 : 0,
                }}
                onClick={() => handleSearchCall()}
                disabled={startDate && endDate ? false : true}
              >
                Submit
              </Button>
            </Form>
            <Spin spinning={isLoadingData}>
              <List
                title={`Usage Report-Calls ${isSearching
                    ? startDate &&
                    endDate &&
                    `(${dayjs(startDate).format("DD/MM/YYYY") +
                    " - " +
                    dayjs(endDate).format("DD/MM/YYYY")
                    })`
                    : ""
                  }`}
              >
                <Row gutter={16}>
                  <Col span={isMobile ? 24 : 9}>
                    <Card
                      title="Total Calls"
                      style={{
                        height: "120px",
                        borderRadius: "15px",
                      }}
                      headStyle={{
                        background: "rgb(229 247 254)",
                      }}
                    >
                      <Space>
                        <Typography.Title level={4}>
                          {(startDate && endDate
                            ? totalCalls
                            : totalCall?.data?.totalCalls) || 0}
                        </Typography.Title>
                      </Space>
                    </Card>
                  </Col>
                  <Col span={isMobile ? 24 : 9}>
                    <Card
                      title="Total Minutes"
                      style={{
                        height: "120px",
                        borderRadius: "15px",
                        marginTop: isMobile ? 30 : 0,
                      }}
                      headStyle={{
                        background: "rgb(229 247 254)",
                      }}
                    >
                      <Space>
                        <Typography.Title level={4}>
                          {(startDate && endDate
                            ? totalMinutes
                            : totalCall?.data?.totalMinutes) || 0}
                        </Typography.Title>
                      </Space>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col span={24}>
                    <Row gutter={16}>
                      <Col
                        className="gutter-row assets-summary-pie-chart"
                        span={24}
                      >
                        <Line {...(configCustomer as any)} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </List>
            </Spin>
            <DashboardCustomer
              startDate={startDate}
              endDate={endDate}
              isSearching={isSearching}
            />
          </List>
        </Col>
      </Row>
    </>
  );
};
