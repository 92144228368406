import { Modal } from "@pankod/refine-antd";
import { ReactNode, useCallback } from "react";

const DEFAULT_WIDTH = 1000;
const BODY_STYLE: React.CSSProperties = { overflowY: "auto", maxHeight: "calc(100vh - 200px)" };

type CustomModalProps = {
  setIsModalVisible: (data: boolean) => void;
  isModalVisible: boolean;
  children: ReactNode;
  title?: string;
  width?: string | number;
  [key: string]: any;
};

export const CustomModal = (props: CustomModalProps) => {

  const {
    setIsModalVisible,
    isModalVisible,
    children,
    title,
    width = DEFAULT_WIDTH,
    ...passProps
  } = props;

  const handleHiddenModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  return (
    <Modal
      centered
      title={title}
      visible={isModalVisible}
      onOk={handleHiddenModal}
      onCancel={handleHiddenModal}
      footer={null}
      width={width}
      bodyStyle={BODY_STYLE}
      {...passProps}
    >
      {children}
    </Modal>
  );
};
