import {
  Button,
  Card,
  Rate,
  Row,
  Space,
  Typography
} from "@pankod/refine-antd";
import { RATE_CALL } from "api/baseApi";
import { ERateCall } from "constants/constant";
import { axiosInstance } from "providers/axios";
import React from "react";
import "./style.css";


export default function RateCall() {
  const [start, setStart] = React.useState(0);
  const isLoading = React.useRef(false);
  const searchParams = new URLSearchParams(window.location.search);
  const handleRateCall = () => {
    isLoading.current = true;
    axiosInstance
      .post(`${RATE_CALL}/${searchParams.get("call_id")}`, {
        rate: Object.values(ERateCall)[start - 1],
      }).finally(() => window.close())
  };
  return (
    <div className="w-100 bg-light">
      <Row justify="center" className="rate-contain">
        <Space direction="vertical">
            <div className="wrapper-logo">
              <img
                className="img-logo"
                src="/images/global/logo_v2.png"
                alt="911-logo"
              />
            </div>
          <Card className="card">
            <Typography.Title className="rate-title" level={2}>
              How did it go?
            </Typography.Title>
            <Space className="rate-space">
              <Typography.Paragraph>
                Please rate your interpreter
              </Typography.Paragraph>
              <Rate onChange={setStart} value={start} className="rate" />
            </Space>
            <Button
              type="primary"
              className="rate-btn-submit"
              disabled={start === 0 || isLoading.current}
              onClick={handleRateCall}
              block
            >
              Submit
            </Button>
          </Card>
        </Space>
      </Row>
    </div>
  );
}
