export enum EMedia {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
}

export enum ERoomStatus {
  CONNECTED = "connected",
  COMPLETE = "complete",
}

export enum EAction {
  CREATE = "create",
  CLONE = "clone",
  DETAIL = "detail",
  EDIT = "edit",
}

export enum EStatusCall {
  COMPLETE = "Completed",
  CANCELLED = "Cancelled",
  MISSED = "Missed",
}

export enum IStatusResetPassword {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  EXPIRED = "EXPIRED",
}

export enum ELanguageType {
  ALL_LANGUAGE = "All other languages",
  AMERICAN = "American Sign Language",
  SPANISH = "Spanish"
}

export enum EFormatDateTime {
  DATE = "YYYY-MM-DD",
  DATETIME = "YYYY-MM-DD hh:mm A",
}

export enum ERateCall {
  Poor = 1,
  Fair = 2,
  Satisfactory = 3,
  Good = 4,
  Excellent = 5,
}

export enum EAuth {
  AUTHORIZED = "Unauthorized",
}

export enum ESTATUS {
  ON = "ON",
  OFF = "OFF",
}

export enum EStatusHistory {
  PENDING = "PENDING",
  MISSED = "MISSED",
  COMPLETE = "COMPLETE",
  CALLING = "CALLING",
}

export enum EFormatDateTimeForPDF {
  DATE = "M/D/YYYY",
  TIME = "h:mm:ss A",
}

export enum EStatusReport {
  ALL = "All",
  PENDING = "Connecting",
  CALLING = "Live Calls",
  MISSED = "Missed",
  REJECTED = "Not connected",
  COMPLETE = "Complete",
}

export enum ETitleStatus  {
  ONLINE = 'ready', 
  AVAILABLE = 'Available', 
  ON_CALL = 'incall'
}

export enum EStatusNotification  {
  SUCCESS = 'success',
  INFO = 'info', 
  WARNING = 'warning',
  ERROR = 'error'
}

export const MSG_NOTIFICATION = {
  SUCCESSFUL : 'Successful',
  NOTIFICATION : 'Notification', 
  WARNING : 'Warning',
  ERROR : 'Error'
}

export enum EMediaType {
  Audio = 1,
  Video = 2
}

export enum ELanguage {
  all, 
  american,
  spanish
}

export enum Role {
  STAFF = "Staff",
  INDEPENDENT_CONTRACTOR = "Independent Contractor",
  AGENCY = "Agency",
  VOLUNTEER = "Volunteer",
}

export enum EMediaDeviceInfo {
  AUDIO_INPUT = 'audioinput',
  AUDIO_OUTPUT = 'audiooutput'
}

export const WAITING_TIME = 30;
export const MIN_DURATION = 1; 
export const TIME_AUTO_REFRESH = 10000;