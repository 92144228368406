import {
  Button,
  Col,
  Descriptions,
  Form,
  Icons,
  Row,
  Space,
  Table,
  TextField,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import { BaseKey, useCustom, useUpdate } from "@pankod/refine-core";
import { PROFILE_API, UPDATE_USER_API } from "api/baseApi";
import { CustomModal } from "components/Modal/CustomModal";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ICustomer } from "interfaces/customer";
import { IUser } from "interfaces/users";
import { useState } from "react";
import { openNotification } from "utils";
import { BillItemAuto } from "./bill-item-auto/create";
import { PayItemAuto } from "./pay-item-auto/create";
import { PayItemManual } from "./pay-item-manual/create";

type InvoiceInformationEditProps = {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  requester: ICustomer | undefined;
};

const { Title, Text } = Typography;
const { PlusSquareOutlined, CloseOutlined } = Icons;
export const InvoiceInformationEdit = (props: InvoiceInformationEditProps) => {
  const { requester } = props;

  const [isModalCreatePayItemAuto, setIsModalCreatePayItemAuto] =
    useState(false);
  const [isModalCreatePayItemManual, setIsModalCreatePayItemManual] =
    useState(false);
  const [isModalCreateBillItemAuto, setIsModalCreateBillItemAuto] =
    useState(false);
  const [isModalCreateBillItemManual, setIsModalCreateBillItemManual] =
    useState(false);

  const { form, formProps } = useForm<IUser>({
    action: EAction.EDIT,
  });

  const { data: profile } = useCustom({
    url: PROFILE_API,
    method: "get",
  });

  const { setFields } = form;
  const { mutate, data: updateData, isLoading } = useUpdate();

  const onFinish = (event: IUser) => {
    mutate(
      {
        resource: UPDATE_USER_API,
        id: requester?.id as BaseKey,
        values: {},
      },
      {
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  const dataSource = [
    {
      name: "On-site interpretation",
      rate: "$20.00",
      quantity: "321.00",
      ratio: "1.00",
      subtotal: "$6,420.00",
      delete: <CloseOutlined style={{ cursor: "pointer" }} />,
    },
  ];

  const columns = [
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
      render: (value: string) => <TextField value={value ? value : ""} />,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Ratio",
      dataIndex: "ratio",
      key: "ratio",
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
    },
  ];

  const handleCreatePayItemAuto = () => {
    setIsModalCreatePayItemAuto(true);
  };

  const handleCreatePayItemManual = () => {
    setIsModalCreatePayItemManual(true);
  };

  const handleCreateBillItemAuto = () => {
    setIsModalCreateBillItemAuto(true);
  };

  const handleCreateBillItemManual = () => {
    setIsModalCreateBillItemManual(true);
  };

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as IUser)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <Title level={5}>Interpreter Payment Config</Title>

          <Descriptions bordered>
            <Descriptions.Item
              label="Minimum Minute(s) Paid - On-site"
              span={3}
            >
              60
            </Descriptions.Item>
            <Descriptions.Item
              label="Next Paying Increment In Minutes - On-site"
              span={3}
            >
              15
            </Descriptions.Item>
            <Descriptions.Item label="Minimum Minute(s) Paid - Audio" span={3}>
              1
            </Descriptions.Item>
            <Descriptions.Item
              label="Next Paying Increment In Minutes - Audio"
              span={3}
            >
              1
            </Descriptions.Item>
            <Descriptions.Item
              label="Minimum Words(s) Paid - Translation"
              span={3}
            >
              100
            </Descriptions.Item>
            <Descriptions.Item
              label="Next Paying Increment In Words - Translation"
              span={3}
            >
              1
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col className="gutter-row" span={12}>
          <Title level={5}>Customer Billing Config</Title>

          <Descriptions bordered>
            <Descriptions.Item
              label="Minimum Minute(s) Billed - On-site"
              span={3}
            >
              60
            </Descriptions.Item>
            <Descriptions.Item
              label="Next Billing Increment In Minutes - On-site"
              span={3}
            >
              15
            </Descriptions.Item>
            <Descriptions.Item
              label="Minimum Minute(s) Billed - Audio"
              span={3}
            >
              1
            </Descriptions.Item>
            <Descriptions.Item
              label="Next Billed Increment In Minutes - Audio"
              span={3}
            >
              1
            </Descriptions.Item>
            <Descriptions.Item
              label="Minimum Words(s) Billed - Translation"
              span={3}
            >
              100
            </Descriptions.Item>
            <Descriptions.Item
              label="Next Billing Increment In Words - Translation"
              span={3}
            >
              1
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <Title level={5} className="requester-information">
        Duration
      </Title>
      <Row gutter={16}>
        <Col className="gutter-row" span={8}>
          <Text>
            Estimated duration: <b>6</b> (hrs)
          </Text>
        </Col>
        <Col className="gutter-row" span={8}>
          Actual duration: <b>321</b> (hrs)
        </Col>
      </Row>

      <Row gutter={16} className="requester-information">
        <Col className="gutter-row" span={17}>
          <Title level={5}>Pay Items</Title>
        </Col>

        <Col className="gutter-row" span={7}>
          <Space>
            <Button className="btn-create" onClick={handleCreatePayItemAuto}>
              <PlusSquareOutlined /> Add Auto
            </Button>
            {""}
            <Button className="btn-create" onClick={handleCreatePayItemManual}>
              <PlusSquareOutlined /> Add Manual
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        summary={() => (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4}>
                <b>Total</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <b>$6,420.00</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />

      <Row gutter={16} className="requester-information">
        <Col className="gutter-row" span={17}>
          <Title level={5}>Bill Items</Title>
        </Col>
        <Col className="gutter-row" span={7}>
          <Space>
            <Button className="btn-create" onClick={handleCreateBillItemAuto}>
              <PlusSquareOutlined /> Add Auto
            </Button>

            <Button className="btn-create" onClick={handleCreateBillItemManual}>
              <PlusSquareOutlined /> Add Manual
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        summary={() => (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4}>
                <b>Pre-tax Amount</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <b>$12,840.00</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4}>
                <b>Tax</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <b>$1,669.20</b>{" "}
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4}>
                <b>Total</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {" "}
                <b>$14,509.20</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />

      <div className="submit">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>

      <CustomModal
        title="Pay Item Auto"
        setIsModalVisible={setIsModalCreatePayItemAuto}
        isModalVisible={isModalCreatePayItemAuto}
        width="50%"
      >
        <PayItemAuto
          option={EAction.CREATE}
          setIsModalVisible={setIsModalCreatePayItemAuto}
          isModalVisible={isModalCreatePayItemAuto}
        />
      </CustomModal>

      <CustomModal
        title="Pay Item Manual"
        setIsModalVisible={setIsModalCreatePayItemManual}
        isModalVisible={isModalCreatePayItemManual}
        width="50%"
      >
        <PayItemManual
          option={EAction.CREATE}
          setIsModalVisible={setIsModalCreatePayItemManual}
          isModalVisible={isModalCreatePayItemManual}
        />
      </CustomModal>

      <CustomModal
        title="Bill Item Auto"
        setIsModalVisible={setIsModalCreateBillItemAuto}
        isModalVisible={isModalCreateBillItemAuto}
        width="50%"
      >
        <BillItemAuto
          option={EAction.CREATE}
          setIsModalVisible={setIsModalCreateBillItemAuto}
          isModalVisible={isModalCreateBillItemAuto}
        />
      </CustomModal>
      <CustomModal
        title="Bill Item Manual"
        setIsModalVisible={setIsModalCreateBillItemManual}
        isModalVisible={isModalCreateBillItemManual}
        width="50%"
      >
        <BillItemAuto
          option={EAction.CREATE}
          setIsModalVisible={setIsModalCreateBillItemManual}
          isModalVisible={isModalCreateBillItemManual}
        />
      </CustomModal>
    </Form>
  );
};
