import React, { useEffect, useState } from "react";
import { useCreate, useCustom } from "@pankod/refine-core";
import {
  Form,
  Input,
  useForm,
  Button,
  Typography,
  Col,
  Row,
  Checkbox,
  Select,
  Grid,
} from "@pankod/refine-antd";

import "../../../../../styles/style.less";
import {
  CREATE_REQUESTER_API,
  DEPARTMENT_API,
  SUB_DEPARTMENT_API,
} from "api/baseApi";
import { ICheckboxChangeActive } from "interfaces";
import { IRequester } from "interfaces/requesters";
import { IDepartment } from "interfaces/department";
import { UserContext } from "hooks/useContext/useUserContext";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { axiosInstance } from "providers/axios";
import { ISub_Department } from "interfaces/sub_department";
import { openNotification} from "utils";
import { ICustomer } from "interfaces/users";

export interface RequestersProps {
  requester?: IRequester | undefined;
  customer?: ICustomer | undefined;
  department?: IDepartment | undefined;
  subdepartment?: ISub_Department | undefined;
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  createSuccess: () => void;
}

export const CreateRequesterModal = (props: RequestersProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const { setIsModalVisible, department, customer, createSuccess } = props;
  const [messageErr, setMessageErr] = useState<IRequester>();
  const [subDepartmentData, setSubdepartmentData] =
    useState<ISub_Department[]>();
  const { customerId, departmentId } = React.useContext(UserContext);
  const [changeDepartmentId, setChangeDepartmentId] = useState<string>("0");
  const [subDepartmentId, setSubDepartmentId] = useState<number>(0);
  const { formProps, form } = useForm<IRequester>({
    action: EAction.CREATE,
  });

  const { mutate, data: createData, isLoading } = useCreate();

  useEffect(() => {
    if (departmentId !== "0") {
      (async () => {
        const data = await axiosInstance.get(
          SUB_DEPARTMENT_API.getByDepartment +
            `?id=${departmentId}&page=&limit=`
        );
        setSubdepartmentData(data?.data.results);
        setChangeDepartmentId(departmentId);
      })();
    } else {
      setSubdepartmentData([]);
      setChangeDepartmentId("0");
    }
  }, [departmentId]);

  const onFinish = (event: IRequester) => {
    mutate(
      {
        resource: CREATE_REQUESTER_API,
        values: {
          username: event.username,
          firstname: event.firstname,
          lastname: event.lastname,
          phone: event.phone,
          customerId: customerId,
          fax: event.ext,
          departmentId: Number(changeDepartmentId),
          subdepartmentId: subDepartmentId,
          supervisorName: event.supervisorName,
          suit: event.suiteNumber,
          isActive: event.isActive,
          email: event.email,
          medicalType: event.medicalType,
          notification: event.notification,
        },
      },
      {
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  const onCheckIsActive = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ isActive: true });
    } else {
      form.setFieldsValue({ isActive: false });
    }
  };

  const { data: departmentData } = useCustom({
    url: DEPARTMENT_API.getByCustomer,
    method: "get",
    config: {
      query: {
        id: customerId,
        page: null,
        limit: null,
      },
    },
  });

  useEffect(() => {
    if (createData?.data.status === 201) {
      createSuccess();
      form.resetFields();
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
      setMessageErr(messageErr);
      setIsModalVisible(false);
    }
  }, [createData, form, messageErr, setIsModalVisible]);

  const { setFields } = form;

  const handleChangeDepartment = (departmentId: string) => {
    (async () => {
      if (departmentId === undefined) {
        setSubdepartmentData([]);
      } else {
        const data = await axiosInstance.get(
          SUB_DEPARTMENT_API.getByDepartment +
            `?id=${departmentId}&page=&limit=`
        );
        setSubdepartmentData(data?.data.results);
        setChangeDepartmentId(departmentId);
      }
    })();
  };

  const handleChangeSubDepartment = (subDepartmentId: number) => {
    setSubDepartmentId(subDepartmentId);
  };

  const handleClearDepartment = React.useCallback(() => {
    setSubDepartmentId(0);
    setChangeDepartmentId("0");
  }, []);

  const handleClearSubDepartment = () => {
    setSubDepartmentId(0);
  };

  useEffect(() => {
    form.resetFields();
    setFields([
      {
        name: "customerId",
        value: customer?.id,
      },
      {
        name: "departmentId",
        value: department?.id ?? "",
      },
    ]);
  }, [customer, department, form, setFields]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => {
        onFinish(event as IRequester);
      }}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="User Name"
            name="username"
            rules={[
              {
                required: true,
                pattern: /^[a-zA-Z]\w*$/,
                message:
                  "User Name must start with a letter and can not contain spaces or special characters",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.username && (
            <Typography.Text type="danger">
              {messageErr.username[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="Customer"
            name="customerName"
            rules={[
              {
                required: false,
                message: "Please enter customer",
              },
            ]}
            initialValue={customer?.label}
          >
            <Input disabled size="large" />
          </Form.Item>
          {messageErr?.customerId && (
            <Typography.Text type="danger">
              {messageErr.customerId}
            </Typography.Text>
          )}
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="
          "
            name="isActive"
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox
              defaultChecked={false}
              onChange={(event) => {
                onCheckIsActive(event);
              }}
            >
              Is Active
            </Checkbox>
          </Form.Item>
          {messageErr?.isActive && (
            <Typography.Text type="danger">
              {messageErr.isActive}
            </Typography.Text>
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="Department"
            name="departmentName"
            rules={[
              {
                required: false,
                message: "Please enter department",
              },
            ]}
            initialValue={department?.label}
          >
            <Select
              onChange={handleChangeDepartment}
              onClear={handleClearDepartment}
              showSearch
            >
              {departmentData?.data?.results.map(
                (item: IDepartment, index: number) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
          {messageErr?.department && (
            <Typography.Text type="danger">
              {messageErr.departmentId}
            </Typography.Text>
          )}
          <Row gutter={16}>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name",
                  },
                ]}
              >
                <Input maxLength={50} />
              </Form.Item>
              {messageErr?.firstname && (
                <Typography.Text type="danger">
                  {messageErr.firstname[0]}
                </Typography.Text>
              )}
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    pattern: /^(?:\d*)$/,
                    message: "Must contain 10 numbers only",
                  },
                ]}
              >
                <Input maxLength={10} />
              </Form.Item>
              {messageErr?.phone && (
                <Typography.Text type="danger">
                  {messageErr.phone}
                </Typography.Text>
              )}
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name",
                  },
                ]}
              >
                <Input maxLength={50} />
              </Form.Item>
              {messageErr?.lastname && (
                <Typography.Text type="danger">
                  {messageErr.lastname[0]}
                </Typography.Text>
              )}

              <Form.Item
                label="Ext"
                name="ext"
                rules={[
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Please enter a valid Ext number!",
                  },
                ]}
                normalize={(value, prevVal, prevVals) => value.trim()}
              >
                <Input maxLength={5} />
              </Form.Item>
              {messageErr?.ext && (
                <Typography.Text type="danger">
                  {messageErr.ext[0]}
                </Typography.Text>
              )}
            </Col>
          </Row>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.email && (
            <Typography.Text type="danger">
              {messageErr.email[0]}
            </Typography.Text>
          )}
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="Sub-department"
            name="subDepartment"
            rules={[
              {
                required: false,
                message: "Please enter Sub-department",
              },
            ]}
            initialValue={""}
          >
            <Select
              showSearch
              onClear={handleClearSubDepartment}
              onChange={handleChangeSubDepartment}
              value={subDepartmentId}
            >
              {subDepartmentData?.length !== 0 &&
                subDepartmentData?.map(
                  (item: ISub_Department, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  )
                )}
            </Select>
          </Form.Item>
          {messageErr?.subdepartment && (
            <Typography.Text type="danger">
              {messageErr.subdepartmentId}
            </Typography.Text>
          )}

          <Form.Item
            label="Supervisor Name"
            name="supervisorName"
            rules={[
              {
                required: false,
                message: "Please enter Supervisor Name",
              },
            ]}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.supervisorName && (
            <Typography.Text type="danger">
              {messageErr.supervisorName[0]}
            </Typography.Text>
          )}

          <Form.Item
            label="Suite Number"
            name="suiteNumber"
            rules={[
              {
                pattern: /^(?:\d*)$/,
                message: "Please enter a valid Suite number!",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input type="number" maxLength={5} />
          </Form.Item>
          {messageErr?.suiteNumber && (
            <Typography.Text type="danger">
              {messageErr.suiteNumber[0]}
            </Typography.Text>
          )}

          <Form.Item
            label="Medical Type"
            name="medicalType"
            rules={[
              {
                required: false,
                message: "Please enter Medical Type",
              },
            ]}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.medicalType && (
            <Typography.Text type="danger">
              {messageErr.medicalType[0]}
            </Typography.Text>
          )}
        </Col>
      </Row>
      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
