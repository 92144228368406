import { AudioMutedOutlined } from "@ant-design/icons";
import  DefaultAvatar  from "components/avatar";
import usePublications from "hooks/usePublications";
import usePublicationsToggle from "hooks/usePublicationsToggle";
import Video, { RemoteParticipant } from "twilio-video";
import Publication from "../publication";
import "../styles.css";

interface IParticipantProps {
  key?: React.Key | null | undefined;
  participant: Video.LocalParticipant | RemoteParticipant;
  participantLength: number;
  room: Video.Room;
  identity: string;
  handleLogout: (events: any) => void;
}

const Participant = ({
  participant,
  room,
  participantLength,
  handleLogout,
  identity,
}: IParticipantProps) => {
  let publications = usePublications(participant);
  const publicationsToogle = usePublicationsToggle(participant);

  const isVideo = publications.some((track) => track.kind === "video");

  let isVideoMobile = false;
  let isAudioEnable = publications.find(
    (track) => track.kind === "audio"
  )?.isTrackEnabled;

  if (
    publicationsToogle.length >= 1 &&
    !publications.find((track) => track.kind === "video")?.isTrackEnabled
  ) {
    publications = publications.filter((track) => track.kind !== "video");
    isVideoMobile = true;
  }
  return (
    <>
      {(!isVideo || isVideoMobile) && (
        <DefaultAvatar
          sizeAvatar={150}
          fontSizeProps={80}
          lastname={identity}
        />
      )}
      {!isAudioEnable && <AudioMutedOutlined className="icon-call mic-off" />}
      {publications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          participant={participant}
          // isLocal={isLocal}
          // disableAudio={disableAudio}
          // videoPriority={videoPriority}
        />
      ))}
    </>
  );
};

export default Participant;
