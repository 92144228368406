/* eslint-disable react-hooks/exhaustive-deps */
import { Button, useForm } from "@pankod/refine-antd";
import React, { useState, useEffect } from "react";
import { useUpdate } from "@pankod/refine-core";
import { ACTIVE_REQUESTER_API, DEACTIVE_REQUESTER_API } from "api/baseApi";
import { Space } from "antd";
import { IRequester } from "interfaces/requesters";
import { BaseKey } from "@pankod/refine-core/dist/interfaces";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { openNotification } from "utils";
import { ICustomer } from "interfaces/customer";
import { IDepartment } from "interfaces/department";
import { ISub_Department } from "interfaces/sub_department";

export interface RequestersProps {
  requester?: IRequester | undefined;
  customer?: ICustomer | undefined;
  department?: IDepartment | undefined;
  subdepartment?: ISub_Department | undefined;
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  activeSuccess: () => void;
}

const RequesterActive = (props: RequestersProps) => {
  const { setIsModalVisible, requester, activeSuccess } = props;
  const [messageErr, setMessageErr] = useState<IRequester>();
  const { form } = useForm<IRequester>({
    action: EAction.EDIT,
  });

  const { mutate, data: updateData } = useUpdate();

  const handleActive = () => {
    if (requester?.user.isActive === true) {
      mutate({
        resource: DEACTIVE_REQUESTER_API,
        id: requester?.user.id,
        values: {
          name: requester,
        },
      });
    } else {
      mutate({
        resource: ACTIVE_REQUESTER_API,
        id: requester?.user.id as BaseKey,
        values: {
          name: requester,
        },
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (updateData?.data.status == 200) {
      form.resetFields();
        openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
      activeSuccess();
      setIsModalVisible(false);
      setMessageErr(messageErr);
    }
  }, [updateData, form, setIsModalVisible, messageErr]);

  return (
    <Space
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
      }}
    >
      <div>
        You are about to change the Active Status of this{" "}
        <strong>Requester</strong>. Please select <strong>Change</strong> to
        continue or <strong>Cancel</strong> to go back
      </div>
      <Space style={{ marginTop: 10 }}>
        <Button
          onClick={handleActive}
          className="btn-create"
          style={{ marginRight: "10px", backgroundColor: "#" }}
        >
          Change
        </Button>
        <Button onClick={handleCancel}>Cancel </Button>
      </Space>
    </Space>
  );
};

export default RequesterActive;
