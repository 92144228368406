import {
  AutoComplete,
  Button,
  DatePicker,
  Empty,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Result,
  Space,
  Spin,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { useCustom } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { INTERPRETER_ACTIVITY_API, INTERPRETER_API } from "api/baseApi";
import { EFormatDateTime, ERateCall } from "constants/constant";
import dayjs, { Dayjs } from "dayjs";
import useNotification from "hooks/useNotification";
import { IInterpreter } from "interfaces/interpreter";
import { IInterpreterAct } from "interfaces/interpreterAct";
import { axiosInstance } from "providers/axios";
import React, { useEffect, useMemo, useState } from "react";
import { formatDateTime } from "utils";
import { exportToCSV, exportToExcel, exportToPDF } from "utils/excelExporter";

const { SearchOutlined, CloseOutlined, ExclamationCircleOutlined } = Icons;

const columnInterAct = [
  "Appt ID",
  "Customer",
  "Department",
  "Sub-department",
  "Requester",
  "Interpreter",
  "Language",
  "Call Time",
  "Start Time",
  "Finish Time",
  "Status",
  "Customer Rating"
];

const collumnsPDF = [
  { key: "Appt ID" },
  { key: "Customer" },
  { key: "Department" },
  { key: "Sub-department" },
  { key: "Requester" },
  { key: "Interpreter" },
  { key: "Language" },
  { key: "Call Time" },
  { key: "Start Time" },
  { key: "Finish Time" },
  { key: "Status" }, { key: "Customer Rating" },
];

export const InterpreterActivityReport: React.FC = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const [isShowNotification, setIsShowNotification] = useState(false);
  const [searchInterpreter, setSearchInterpreter] = useState<string>("");
  const [interpreterId, setInterpreterId] = useState<string>();
  const [page, setPage] = useState<number>(1);

  const [activeInterpreterActReport, setActiveInterpreterActReport] = useState<
    IInterpreterAct[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const { openNotificationErrors, openNotificationInfo, contextHolder } =
    useNotification();

  const { data: interpretersData } = useCustom({
    url: INTERPRETER_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
        search: null,
      },
    },
  });

  const { data: interpreterActReportsData } = useCustom({
    url: INTERPRETER_ACTIVITY_API,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
        id: interpreterId,
        start: startDate ? dayjs(startDate).format(EFormatDateTime.DATE) : "",
        end: endDate ? dayjs(endDate).format(EFormatDateTime.DATE) : "",
      },
    },
    queryOptions: {
      enabled: isSearch,
      onSuccess: (interpreterActReport) => {
        setActiveInterpreterActReport(interpreterActReport?.data?.results);
      },
    },
  });

  const pageTotal = interpreterActReportsData?.data?.total;
  const handleChange = (page: number) => {
    setPage(page);
  };

  const dateRange = `Date Range: ${dayjs(startDate)?.format(
    "DD/MM/YYYY"
  )} - ${dayjs(endDate)?.format("DD/MM/YYYY")}`;

  localStorage.setItem("date", dateRange);

  const handleShowAllPDF = (record: IInterpreterAct) => {
    const data = [] as IInterpreterAct[];
    data.push(record);

    exportToPDF({
      columns: collumnsPDF,
      data: viewData(data),
      fileName: "Interpreter Activity Report.pdf",
      name: "Interpreter Activity Summary Report",
      date: localStorage.getItem("date"),
      nameInvoice: searchInterpreter
        ? `Interpreter Name: ${searchInterpreter}`
        : "",
    });
    localStorage.removeItem("date");
  };
  const handleExportAllExcel = (record: IInterpreterAct) => {
    const data = [] as IInterpreterAct[];
    data.push(record);

    exportToExcel(
      "Interpreter Activity Report",
      viewData(data),
      columnInterAct
    );
  };
  const handleExportAllCSV = (record: IInterpreterAct) => {
    const data = [] as IInterpreterAct[];
    data.push(record);

    exportToCSV(viewData(data), "Interpreter Activity Report", columnInterAct);
  };

  const collumns = useMemo(
    () => [
      {
        key: "key",
        title: "Nbr.",
        render: (value: string) => (
          <TextField
            value={
              value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
            }
          />
        ),
      },
      {
        key: "id",
        title: "Print",
        render: (value: string, record: IInterpreterAct) => (
          <TextField
            value={value ? "Print" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleShowAllPDF(record)}
          />
        ),
      },

      {
        key: "id",
        title: "Excel",
        render: (value: string, record: IInterpreterAct) => (
          <TextField
            value={value ? "Excel" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleExportAllExcel(record)}
          />
        ),
      },

      {
        key: "id",
        title: "CSV",
        render: (value: string, record: IInterpreterAct) => (
          <TextField
            value={value ? "CSV" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleExportAllCSV(record)}
          />
        ),
      },
      {
        key: "interpreterName",
        title: "Interpreter",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "createdAt",
        title: "Call of Date",
        render: (value: Dayjs) => <TextField value={formatDateTime(value)} />,
      }
    ],
    [page]
  );

  const handleStartDate = (startDate: Dayjs | null) => {
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate("");
    }
  };

  const handleEndDate = (endDate: Dayjs | null) => {
    if (endDate) {
      setIsSearch(true);
      setEndDate(endDate);
    } else {
      setIsSearch(false);
      setEndDate("");
    }
    setIsShowNotification(true);
  };

  const onChange = (interpreter: string, option: BaseOptionType) => {
    setSearchInterpreter(option.label);
  };

  const onSelect = (interpreterId: string, option: BaseOptionType) => {
    setInterpreterId(interpreterId);
    setSearchInterpreter(option.label);
    setIsSearch(true);
    setIsShowNotification(true);
  };

  const handleRemoveInterpreter = () => {
    setInterpreterId("");
    setSearchInterpreter("");
    // setIsSearch(false);
  };



  const viewData = (arrInterAct: IInterpreterAct[]) => {
    return arrInterAct?.map((interpreter: IInterpreterAct) => ({
      "Appt ID": interpreter?.callerId,
      Customer: interpreter?.customerName,
      Department: interpreter?.departmentName,
      "Sub-department": interpreter?.subdepartmentName,
      Requester: interpreter?.requesterName,
      Interpreter: interpreter?.interpreterName,
      Language: interpreter?.language,
      "Call Time":  dayjs(interpreter?.createdAt).format(EFormatDateTime.DATETIME),
      "Start Time": dayjs(interpreter?.startTime).format(EFormatDateTime.DATETIME),
      "Finish Time": dayjs(interpreter?.endTime).format(EFormatDateTime.DATETIME),
      Status: interpreter?.status,
      "Customer Rating": ERateCall[interpreter?.rating]
    }));
  };

  const handleViewInterpreterAct = () => {
    const timeOfServiceFrom = dayjs(startDate).format(EFormatDateTime.DATE);
    const timeOfServiceTo = dayjs(endDate).format(EFormatDateTime.DATE);

    if (!startDate || !endDate) {
      return Promise.reject(new Error("Missing start or end date"));
    }
    if (interpreterId) {
      return axiosInstance.get(
        `${INTERPRETER_ACTIVITY_API}?id=${interpreterId}&start=${timeOfServiceFrom}&end=${timeOfServiceTo}&page=&limit=`
      );
    } else {
      return axiosInstance.get(
        `${INTERPRETER_ACTIVITY_API}?id=&start=${timeOfServiceFrom}&end=${timeOfServiceTo}&page=&limit=`
      );
    }
  };



  const handleExportPDF = async () => {
    try {
      setIsLoading(true);
      const { data } = await handleViewInterpreterAct();
      exportToPDF({
        columns: collumnsPDF,
        data: viewData(data?.results),
        fileName: "Interpreter Activity Report.pdf",
        name: "Interpreter Activity Summary Report",
        date: dateRange,
        nameInvoice: searchInterpreter
          ? `Interpreter Name: ${searchInterpreter}`
          : "",
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotificationErrors(
        "Error",
        "Please fill in all the required fields",
        "topRight",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  };

  const handleExportExcel = async () => {
    try {
      setIsLoading(true);
      const { data } = await handleViewInterpreterAct();
      exportToExcel(
        "Interpreter Activity Report",
        viewData(data?.results),
        columnInterAct
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotificationErrors(
        "Error",
        "Please fill in all the required fields",
        "topRight",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  };

  const handleExportCSV = async () => {
    try {
      setIsLoading(true);
      const { data } = await handleViewInterpreterAct();
      exportToCSV(
        viewData(data?.results),
        "Interpreter Activity Report",
        columnInterAct
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotificationErrors(
        "Error",
        "Please fill in all the required fields",
        "topRight",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  };

  let localeNoData = {
    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
  };
  let locale = {
    emptyText: <Result subTitle="Please select interpreter activity report" />,
  };

  useEffect(() => {
    if (isShowNotification && activeInterpreterActReport.length === 0) {
      openNotificationInfo(
        "No data found",
        "There are no results matching Interpreter Activity Report",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  }, [activeInterpreterActReport]);

  return (
    <Spin spinning={isLoading}>
      <List
        title={
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item>Report</Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item">
              Interpreter Activity Report
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {contextHolder}
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Date of Service From"
            name="fromDate"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter start date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={startDate}
              onChange={(e) => handleStartDate(e)}
              disabledDate={(current) => {
                return endDate ? current && current > endDate : false;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Date of Service To"
            name="toDate"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter end date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={endDate}
              disabledDate={(current) => {
                return current && current < startDate;
              }}
              onChange={(e) => handleEndDate(e)}
            />
          </Form.Item>
        </Form>
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Search Interpreters"
            className={!isMobile ? "search-item" : ""}
          >
            <AutoComplete
              showSearch
              size="large"
              style={{
                width: "100%",
                borderBottom: "1px solid #d9d9d9",
                borderRadius: "0px",
              }}
              options={interpretersData?.data?.results?.map(
                (item: IInterpreter, index: number) => ({
                  value: item.id,
                  label: item.name,
                })
              )}
              filterOption={(
                searchInterpreter,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.label
                  ?.toUpperCase()
                  .indexOf(searchInterpreter.toUpperCase()) !== -1
              }
              bordered={false}
              value={searchInterpreter}
              onChange={onChange}
              onSelect={onSelect}
            >
              <Input
                suffix={
                  <>
                    {searchInterpreter && (
                      <CloseOutlined onClick={handleRemoveInterpreter} />
                    )}
                    <SearchOutlined />
                  </>
                }
                bordered={false}
              />
            </AutoComplete>
          </Form.Item>
        </Form>
        <Space wrap={isMobile} style={{ marginBottom: 20 }}>
          <Button className="btn-create" onClick={handleExportPDF}>
            PDF
          </Button>
          <Button className="btn-create" onClick={handleExportExcel}>
            Excel
          </Button>
          <Button className="btn-create" onClick={handleExportCSV}>
            CSV
          </Button>
        </Space>
        <Table
          locale={
            (startDate && endDate) || searchInterpreter ? localeNoData : locale
          }
          dataSource={activeInterpreterActReport?.map(
            (item: IInterpreterAct, index: number) => ({
              ...item,
              key: index,
            })
          )}
          rowKey="id"
          pagination={
            pageTotal > 10
              ? {
                total: pageTotal ? pageTotal : 1,
                current: page,
                onChange: handleChange,
                showTotal: () => `${pageTotal} total`,
              }
              : false
          }
          scroll={{ x: 800 }}
        >
          {collumns.map((col) => (
            <Table.Column dataIndex={col.key} {...col} />
          ))}
        </Table>
      </List>
    </Spin>
  );
};
