import { Button, Form, Grid, Input, useForm } from "@pankod/refine-antd";
import { useUpdate } from "@pankod/refine-core";
import { UPDATE_PASSWORD_API } from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { openNotification } from "utils";
import "../../../styles/style.less";

interface IPassword {
  password: string;
  newpassword: string;
}
type ChangePasswordProps = {
  userId: string;
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
};
export default function ChangePassword({
  userId,
  setIsModalVisible,
}: ChangePasswordProps) {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const { formProps, form } = useForm<IPassword>({
    action: EAction.EDIT,
  });
  const { mutate, data: updateData, isLoading } = useUpdate();
  const onFinish = (event: IPassword) => {
    mutate(
      {
        resource: UPDATE_PASSWORD_API,
        id: userId,
        values: { password: event.password, newpassword: event.newpassword },
      },
      
      {
        onSuccess : () => {
          form.resetFields();
          openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
          setIsModalVisible(false);
        },
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);
        },
      }
    )
  };

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => {
        onFinish(event as IPassword);
      }}
    >
      <Form.Item
        name="password"
        label="Old password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          }
        ]}
      >
        <Input.Password size="large" />
      </Form.Item>

      <Form.Item
        name="newpassword"
        label="New password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
        hasFeedback
      >
        <Input.Password size="large" />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="New password confirmation"
        dependencies={["newpassword"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("newpassword") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                "The two passwords that you entered do not match!"
              );
            },
          }),
        ]}
      >
        <Input.Password size="large" />
      </Form.Item>

      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
}
