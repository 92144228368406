import { useNavigation } from "@pankod/refine-core";
import { ERoomStatus, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { EPermissions } from "constants/permission";
import EventEmitter from "events";
import { STORE_PERMISSION } from "providers/authProvider";
import { useCallback, useEffect, useRef, useState } from "react";
import Video, { ConnectOptions, LocalTrack, Room } from "twilio-video";
import { openNotification } from "utils";

// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(
  localTracks: LocalTrack[],
  options?: ConnectOptions
) {
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const [isConnecting, setIsConnecting] = useState(true);
  const { push, replace } = useNavigation();
  const localTracksRef = useRef<LocalTrack[]>([]);

  useEffect(() => {
    localTracksRef.current = localTracks;
  }, [localTracks]);

  const disconnect = useCallback(() => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub: any) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
        localStorage.removeItem("token-room");
        localStorage.removeItem("token-twilio");
        localStorage.setItem("room-state", ERoomStatus.COMPLETE);
        localStorage.removeItem("clientId");
        if (
          localStorage.getItem(STORE_PERMISSION) ===
          EPermissions.CUSTOMER.toString()
        ) {
          replace(`/rate-call/?call_id=${localStorage.getItem("callId")}`);
        } else if (
          localStorage.getItem(STORE_PERMISSION) ===
          EPermissions.INTERPRETER.toString()
        ) {
          window.close();
        } else {
          replace("/finish-call");
        }
      }
      return new EventEmitter() as Room;
    });
  }, []);

  const connect = useCallback(
    (token: string, nameRoom: string) => {
      return Video.connect(token, { name: nameRoom, preferredVideoCodecs: ['VP8'], tracks: [] }).then(
        (newRoom) => {
          setRoom(newRoom);
          newRoom.once("disconnected", () => {
            disconnect();
            setTimeout(() => setRoom(new EventEmitter() as Room));
            window.removeEventListener("beforeunload", disconnect);
            window.removeEventListener("pagehide", disconnect);
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          localTracksRef.current.forEach((track) =>
            newRoom.localParticipant.publishTrack(track, {
              priority: track.kind === "video" ? "low" : "standard",
            })
          );

          setIsConnecting(false);

          window.addEventListener("beforeunload", disconnect);
          window.addEventListener("pagehide", disconnect);
        },
        (error) => {
          push("/404");
          openNotification(MSG_NOTIFICATION.ERROR, error, EStatusNotification.ERROR, 2);;
          setIsConnecting(false);
        }
      );
    },
    [options]
  );
  return { room, isConnecting, connect, disconnect };
}
