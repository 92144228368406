import { Button, Col, Form, Input, Row, useForm } from "@pankod/refine-antd";
import { BaseKey, useUpdate } from "@pankod/refine-core";
import { CREATE_NOTE } from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { IQuestionCall } from "interfaces/questionCall";
import { openNotification } from "utils";

interface IInformation {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  notes: IQuestionCall;
  callID: string | null;
}

const InfomationCallModal = (props: IInformation) => {
  const { isModalVisible, setIsModalVisible, notes, callID } = props;
  function handleCloseModal() {
    setIsModalVisible(!isModalVisible);
  }

  const { form, formProps } = useForm<IQuestionCall>({
    action: EAction.EDIT,
  });

  const { mutate, isLoading } = useUpdate();

  const onFinish = (event: IQuestionCall) => {
    mutate(
      {
        resource: CREATE_NOTE,
        id: callID as BaseKey,
        values: {
          notes: {
            note: `Notes: ${notes.notes},  ${notes.otherRef1} , ${notes.otherRef2}, ${notes.otherRef3}`,
            otherRef1: event.otherRef1,
            otherRef2: event.otherRef2,
            otherRef3: event.otherRef3,
          },
        },
      },

      {
        onSuccess: (createData) => {
          openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
          form.resetFields();
          setIsModalVisible(false);
        },
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);

        },
      }
    );
  };
  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as IQuestionCall)}
    >
      <Row gutter={2}>
        {notes?.otherRef1 && (
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={notes?.otherRef1}
              name="otherRef1"
              normalize={(value, _prevVal, _prevVals) => value.trimStart()}
            >
              <Input maxLength={50} />
            </Form.Item>
          </Col>
        )}
        {notes?.otherRef2 && (
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={notes?.otherRef2}
              name="otherRef2"
              rules={[
                {
                  required: false,
                },
              ]}
              normalize={(value, _prevVal, _prevVals) => value.trimStart()}
            >
              <Input maxLength={50} />
            </Form.Item>
          </Col>
        )}
        {notes?.otherRef3 && (
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={notes?.otherRef3}
              name="otherRef3"
              rules={[
                {
                  required: false,
                },
              ]}
              normalize={(value, _prevVal, _prevVals) => value.trimStart()}
            >
              <Input maxLength={50} />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Col
        className="gutter-row"
        span={24}
        style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
      >
        <Button style={{
          background: "#ff5252"
        }} type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
        <Button className="btn-cancer" onClick={handleCloseModal}>Cancel</Button>
      </Col>
    </Form>
  );
};

export default InfomationCallModal;
