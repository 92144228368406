import {
  Badge,
  Col,
  Grid,
  Icons,
  List,
  RcFile,
  Row,
  Typography,
} from "@pankod/refine-antd";
import { usePermissions } from "@pankod/refine-core";
import { Image, Upload } from "antd";
import {
  IMAGE_API,
  UPLOAD_AVATAR
} from "api/baseApi";
import { EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { EPermissions } from "constants/permission";
import { UserInfoContext } from "hooks/useContext/useUserInfoContext";
import dataProvider from "providers/dataProvider";
import React, { CSSProperties, useCallback, useContext, useState } from "react";
import { openNotification } from "utils";
import "../../../styles/style.less";

const { UserOutlined, PhoneOutlined, CalendarOutlined } = Icons;

type BeforeUploadFileType = File | Blob | boolean | string;

type ImageURL = {
  data: {
    content: {
      avatarPath: string;
    };
  };
};

const profileImg: CSSProperties = {
  width: 200,
  height: 200,
  borderRadius: "50%",
  objectFit: "cover",
  cursor: "pointer"
}

const languageTag: CSSProperties = {
  borderColor: "hwb(205 6% 9%)",
  background: "white",
  color: "#333",
  margin: "0px 5px",
  fontSize: 13,
  padding: "0px 10px",
}

const DEFAULT_IMG = '/images/global/image.png';

export const Profile: React.FC = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const baseUrl = process.env.REACT_APP_API_PROXY;
  const { userInfo } = useContext(UserInfoContext);
  const { data: permissionsData } = usePermissions();
  const [imageUrl, setImageUrl] = useState<ImageURL>();
  const { post } = dataProvider;

  const handleUploadImg = useCallback(
    (imgFile: Exclude<BeforeUploadFileType, File | boolean> | RcFile) => {
      const formData = new FormData();
      formData.append("image", imgFile);
      post({
        url: UPLOAD_AVATAR,
        payload: formData,
      }).then((data) => {
        setImageUrl(data?.data?.content.avatarPath);
      }).catch(error => {
        openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 3)
      });
    },
    [post],
  )
  
  const getImgSrc = ()=>{
    if(!imageUrl && !userInfo?.avatarpath) return DEFAULT_IMG;
    if(imageUrl) return baseUrl + IMAGE_API + `/${imageUrl}`;
    return baseUrl + IMAGE_API + `/${userInfo?.avatarpath}`;
  }

  return (
    <List title="My Profile">
      <div
        className="wrapper-profile"
        style={{
          padding: 24,
          textAlign: isMobile ? "center" : "start",
        }}
      >
        <div>
          <Upload
            name="image"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={({ file }) => {
              handleUploadImg(file);
            }}
          >
              <Image
                preview={false}
                src={getImgSrc()}
                style={profileImg}
              />
          </Upload>
        </div>
        <Typography.Title
          className="name-profile-user"
          level={2}
          style={{ textAlign: isMobile ? "center" : "start" }}
        >
          {userInfo?.firstname + " " + userInfo?.lastname}
        </Typography.Title>
        <Row justify="center">
          {permissionsData?.roleId !== EPermissions.CUSTOMER && (
            <Col span={24} className="profile-user" >
              <UserOutlined /> <Typography.Text className="text-profile">{userInfo?.gender}</Typography.Text>
            </Col>)
          }
          <Col span={24} className="profile-user">
            <PhoneOutlined /> <Typography.Text className="text-profile">{userInfo?.phone}</Typography.Text>
          </Col>
          <Col span={24} className="profile-user">
            <CalendarOutlined /> <Typography.Text className="text-profile">{userInfo?.email}</Typography.Text>
          </Col>
          <Col span={24} className="profile-user">
            <Typography.Text className="text-profile">
              {userInfo?.language && userInfo?.language?.map((language: {
                  id: number;
                  language: string;
                }) => (
                  <Badge
                    count={language.language || ""}
                    style={languageTag}
                    size="default"
                  />
                ))}
            </Typography.Text>
          </Col>
        </Row>
      </div>
    </List>
  );
};
