import { CSSProperties } from "react";

export const antLayoutSider: CSSProperties = {
  position: "fixed",
  overflowY: "scroll",
  scrollbarWidth: "auto",
  overflowX: "hidden",
  zIndex: 999,
  height: "100%",

  // position: "relative",
};
export const antLayoutSiderMobile: CSSProperties = {
  position: "fixed",
  height: "100vh",
  zIndex: 999,
};
