import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import common_en from "../public/locales/en/common.json"

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        supportedLngs: ["en", "vi"],
        fallbackLng: ["en", "vi"],
        interpolation: {
            escapeValue: false,
        },
        resources: {
            vi: {
                translation: common_en,
            }
        },
    });

export default i18n;
