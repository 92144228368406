export const UserType = {
  customer: "CUSTOMER",
  interpreter: "INTERPRETER",
};
export const RoleType = {
  customer: 1,
  interpreter: 2,
};

export enum InterpreterType {
  STAFF = "Staff",
  INDEPENDENT_CONTRACTOR = "Independent Contractor",
  AGENCY = "Agency",
  VOLUNTEER = "Volunteer",
}
