import {
  AudioFilled,
  AudioMutedOutlined,
  PhoneFilled,
  UnorderedListOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Icons, Modal } from "@pankod/refine-antd";
import { Button } from "antd";
import {
  CALL_API,
  CREATE_NEW_INVITE_LINK,
  GET_NOTE_PAD,
  INVITE_PARTICIPANT,
  SEND_SMS,
} from "api/baseApi";
import { DotRedIcon, EndCallIcon } from "components/icons";
import AddPersonModal from "components/Modal/AddPersonModal";
import { EPermissions } from "constants/permission";
import useLocalAudioToggle from "hooks/useLocalAudioToggle";
import useLocalVideoToggle from "hooks/useLocalVideoToggle";
import { axiosInstance } from "providers/axios";
import { SocketContext } from "hooks/useContext/useSocketContext";

import { useContext, useEffect, useRef, useState } from "react";
import "../styles.css";
import { Notepad } from "../notepad";
import InfomationCallModal from "../InfomationCall";
import { EMedia, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { isInterpreter, openNotification } from "utils";
import { CustomModal } from "components/Modal/CustomModal";

type ControlProps = {
  participantLength: number;
  handleLogout: () => void;
};
export default function Control({
  handleLogout,
  participantLength,
}: ControlProps) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const user = JSON.parse(localStorage.getItem("user") as string);
  const [isNote, setIsNote] = useState(true);
  const [notes, setNotes] = useState<any>();
  const socket = useContext(SocketContext);
  const [showNotePadModal, setShowNotePadModal] = useState(false);
  const [isModalVisible, setisModalVisible] = useState(false);
  const callId = useRef(localStorage.getItem("callId"));
  const tokenRoom = localStorage.getItem("token-room");
  const typeMedia = useRef(localStorage.getItem("type-media"));
  const [message, setMessage] = useState<string>("");
  const [isRead, setIsRead] = useState(false);
  const [isCreateNote, setIsCreateContent] = useState(true);

  useEffect(() => {
    (async () => {
      if (callId.current) {
        const userDataSearch = await axiosInstance.get(
          CALL_API.getQuestions + `/${callId.current}`
        );
        setNotes(userDataSearch?.data);
      }
    })();
  }, []);



  const handleOpenNote = () => {
    setIsNote(!isNote);
  };
  const handleAddToRoom = () => {
    setisModalVisible(true);
  };
  const handleCopyUrlToClipboard = async () => {
    const res = await axiosInstance.post(CREATE_NEW_INVITE_LINK, {
      roomSid: tokenRoom
    });
    openNotification(MSG_NOTIFICATION.SUCCESSFUL, 'Link copied successfully', EStatusNotification.SUCCESS);
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_CALL_INVITE_URL}/?roomSid=${searchParams.get(
        "roomSid"
      )}&pass=${res?.data?.pass}`
    );
  };

  const searchParams = new URLSearchParams(window.location.search);

  const handleInviteWithEmail = (email: string) => {
    setisModalVisible(false);
    axiosInstance.post(INVITE_PARTICIPANT, {
      emailReceive: email,
      roomSid: searchParams.get("roomSid"),
      userSend: user.username,
    });
    openNotification(MSG_NOTIFICATION.SUCCESSFUL, 'Send link to email successful', EStatusNotification.SUCCESS);
  };
  const handleInviteWithPhone = (phone: string) => {
    setisModalVisible(false);
    axiosInstance.post(SEND_SMS, {
      to: "+" + phone,
      roomSid: searchParams.get("roomSid"),
      userSend: user.username,
    }).then(() => {
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, 'Send link to phone successful', EStatusNotification.SUCCESS);
    }).catch(err => {
      openNotification(MSG_NOTIFICATION.ERROR, err.response.data.error, EStatusNotification.ERROR, 2);
    });
  };
  useEffect(() => {
    (async () => {
      if (tokenRoom) {
        const notePad = await axiosInstance.get(GET_NOTE_PAD + `/${tokenRoom}`);
        setMessage(notePad?.data);
      }
    })();
  }, [tokenRoom]);

  const handleOpenNotePad = async () => {
    setShowNotePadModal(true);
    if (tokenRoom) {
      const notePadsData = await axiosInstance.get(
        GET_NOTE_PAD + `/${tokenRoom}`
      );
      setMessage(notePadsData?.data);
    }
    setIsRead(false);
  };

  const handleSendMsg = () => {
    if (message) {
      setIsCreateContent(false);
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, 'Message sent!', EStatusNotification.SUCCESS);
      socket.emit("on-note-call", {
        roomSid: tokenRoom,
        message: message,
      });
    }
  };

  useEffect(() => {
    socket.on("receive-note", (data) => {
      setIsRead(true);
      setMessage(data);
      setIsCreateContent(false);
      openNotification(MSG_NOTIFICATION.NOTIFICATION, 'You have received a message!', EStatusNotification.INFO, 2);
    });

    return () => {
      socket.off("receive-note", () => { });
    };
  }, []);

  const handleCloseNotePad = () => {
    setShowNotePadModal(false);
    setIsRead(false);
  };

  return (
    <>
      <div className="icon-items">
        <Button
          shape="circle"
          icon={<Icons.UsergroupAddOutlined className="icon-call" />}
          onClick={handleAddToRoom}
          className="btn-icon"
          disabled={
            participantLength === 3 || localStorage.getItem("role") === null
          }
        />
        {isInterpreter() && (
            <Button
              shape="circle"
              icon={
                isNote ? (
                  <UnorderedListOutlined color="rgb(60, 64, 67) " />
                ) : (
                  <UnorderedListOutlined className="icon-call" />
                )
              }
              onClick={handleOpenNote}
              style={{
                background: `${isNote ? "#fff" : "rgb(60, 64, 67)"}`,
                border: "none",
                outline: "none",
                width: "40px ",
                height: "40px",
                textAlign: "center",
              }}
            />
          )
        }
        <Button
          shape="circle"
          icon={
            isAudioEnabled ? (
              <AudioFilled className="icon-call" />
            ) : (
              <AudioMutedOutlined className="icon-call" />
            )
          }
          onClick={toggleAudioEnabled}
          className="btn-icon"
        />
        <Button
          shape="circle"
          hidden={typeMedia.current === EMedia.AUDIO && isInterpreter()}
          icon={
            isVideoEnabled ? (
              <VideoCameraOutlined className="icon-call" />
            ) : (
              <VideoCameraAddOutlined className="icon-call" />
            )
          }
          onClick={toggleVideoEnabled}
          className="btn-icon"
        />
        <div className="container-note-pad">
          <Button
            shape="circle"
            icon={<Icons.CommentOutlined className="icon-call content-note-pad" />}
            onClick={handleOpenNotePad}
            className="btn-icon"
          />
          {isRead && !showNotePadModal && (
            <DotRedIcon
              style={{
                color: "red",
                fontSize: 15,
                position: "absolute",
                right: -3,
              }}
            />
          )}
        </div>
        <Button
          danger
          shape="circle"
          className="btn-icon-cancel"
          icon={<EndCallIcon className="icon-call" />}
          onClick={handleLogout}
        />
      </div>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setisModalVisible(false)}
        width={450}
        centered
        closable={false}
        destroyOnClose
      >
        <AddPersonModal
          params={searchParams.get("roomSid") || ""}
          handleCopyUrlToClipboard={handleCopyUrlToClipboard}
          handleInviteWithEmail={handleInviteWithEmail}
          handleInviteWithPhone={handleInviteWithPhone}
        />
      </Modal>

      {isInterpreter() && (
          <Modal
            visible={isNote}
            footer={null}
            onCancel={() => handleOpenNote}
            width={450}
            closable={false}
            centered
            destroyOnClose
            title={notes?.notes}
          >
            <InfomationCallModal
              isModalVisible={isNote}
              setIsModalVisible={setIsNote}
              notes={notes}
              callID={callId.current}
            />
          </Modal>
        )}
      <Modal
        visible={showNotePadModal}
        footer={null}
        onCancel={handleCloseNotePad}
        width={600}
        centered
        destroyOnClose
      >
        <Notepad
          message={message}
          setMessage={setMessage}
          onCreateNotePad={handleSendMsg}
        />
      </Modal>
    </>
  );
}
