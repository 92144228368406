import { Button, Popconfirm, Table, TextField, Typography } from "@pankod/refine-antd";
import { BaseRecord } from "@pankod/refine-core";
import { EFormatDateTime } from "constants/constant";
import dayjs from "dayjs";
import { socket } from "hooks/useContext/useSocketContext";
import { useEffect, useMemo, useRef, useState } from "react";
import { formatTimer } from "utils";

interface ICallProcess {
  roomSid: string;
  language: string;
  customer: string;
  interpreter: string;
  startTime: string;
  currentLength: number;
  connectionTime: number;
}

type CallInProgressProps = {
  listOfCallInProgress: Array<ICallProcess> | BaseRecord | undefined;
}

export const CallInProgressTable = ({ listOfCallInProgress }: CallInProgressProps) => {
  const [callInProgessData, setCallInProgessData] = useState<any>([]);
  const idInternalRef = useRef(0);
  const listOfCallInProgressRef = useRef<Array<ICallProcess>>();

  const collumnsCallInProgress = useMemo(
    () => [
      {
        title: "Language",
        dataIndex: "languageName",
        render: (value: string) => <TextField value={value || ""} />,
      },
      {
        title: "Customer",
        dataIndex: "customerName",
        render: (value: string) =>
          value ? <TextField value={value || ""} /> : "",
      },
      {
        title: "Interpreter",
        dataIndex: "name",
        render: (value: string) => <TextField value={value || ""} />,
      },
      {
        title: "Time start",
        dataIndex: "startTime",
        render: (value: any) => <TextField value={value ? dayjs(value).format(EFormatDateTime.DATETIME) : ""} />,
      },
      {
        title: "Current Length",
        dataIndex: "currentLength",
        render: (value: string) => <TextField value={value || ""} />,
      },
      {
        title: "Connection Time",
        dataIndex: "connectionTime",
        render: (value: number) => <TextField value={value || ""} />,
      },
      {
        title: 'Action',
        render: (_: string, record: any) => {
          return (<Popconfirm
            title="Are you sure you want to end this call?"
            onConfirm={() => {
              socket.emit('admin-end-call', {
                roomSid: record.roomSid
              })
            }}
          >
            <Button type="primary">End call</Button>
          </Popconfirm>)
        }
      }
    ],
    []
  );

  const handleUpdateDurationCall = () => {
    idInternalRef.current = window.setInterval(() => {
      setCallInProgessData(listOfCallInProgressRef.current?.map((item: ICallProcess, index: number) => {
        return ({
          key: index,
          ...item,
          currentLength: formatTimer(dayjs().diff(dayjs(item?.startTime)) / 1000)
        })
      }))
    }, 1000);
  }

  useEffect(() => {
    listOfCallInProgressRef.current = listOfCallInProgress as Array<ICallProcess>;
    handleUpdateDurationCall();
    return () => {
      clearInterval(idInternalRef.current);
    }
  }, [listOfCallInProgress])

  useEffect(() => {
    socket.on('call-progress', (data) => {
      const { call } = data;
      if (call) {
        listOfCallInProgressRef.current?.push(call);
        clearInterval(idInternalRef.current)
        handleUpdateDurationCall();
      }
    })

    socket.on('call-end', data => {
      clearInterval(idInternalRef.current);
      listOfCallInProgressRef.current = listOfCallInProgressRef.current?.filter((item: any) => item?.id !== data.callId);
      handleUpdateDurationCall();
    })

    return () => {
      socket.off('call-progress');
      socket.off('call-end');
    }
  }, [socket]);

  return (
    <Table
      bordered
      scroll={{ x: 900, y: 330 }}
      dataSource={callInProgessData}
      style={{ marginTop: "10px" }}
      pagination={false}
      title={() => (
        <Typography.Title level={5}>Calls In Progress</Typography.Title>
      )}
      columns={collumnsCallInProgress}
    />
  );
};
