export enum EPermissions {
  CUSTOMER = 3,
  INTERPRETER = 2,
  ADMIN = 1,
}

export const Permission = {
  dashboard: {
    level: 0,
    name: "Dashboard",
    default: 0,
    children: [],
  },
  listings: {
    name: "Listings",
    level: 1,
    children: [
      {
        name: "Standard Pay/Bill Rates",
        level: 1,
        default: 0,
      },
      {
        name: "Customers",
        level: 1,
        default: 0,
      },
      {
        name: "Department",
        level: 1,
        default: 0,
      },
      {
        name: "Sub-Department",
        level: 1,
        default: 0,
      },
      {
        name: "Requester",
        level: 1,
        default: 0,
      },
      {
        name: "Interpreter",
        level: 1,
        default: 0,
      },
    ],
  },
  report: {
    name: "Report",
    level: 2,
    children: [
      {
        name: "Interpreter Report",
        level: 2,
        default: 0,
      },
      {
        name: "Payment Report",
        level: 2,
        default: 0,
      },
      {
        name: "Income Report",
        level: 2,
        default: 0,
      },
      {
        name: "Process Interpreter Invoice",
        level: 2,
        default: 0,
      },
      {
        name: "View Interpreter Invoice",
        level: 2,
        default: 0,
      },
      {
        name: "Process Customer Invoice",
        level: 2,
        default: 0,
      },
      {
        name: "View Customer Invoice",
        level: 2,
        default: 0,
      },
      {
        name: "Billing Report",
        level: 2,
        default: 0,
      },
      {
        name: "Appointment Cancelation Report",
        level: 2,
        default: 0,
      },
      {
        name: "Appointment Fill Rate By Language Report",
        level: 2,
        default: 0,
      },
      {
        name: "Appointment Fill Rate By Customer Report",
        level: 2,
        default: 0,
      },
      {
        name: "Appointment Pay Or Bill Report",
        level: 2,
        default: 0,
      },
      {
        name: "Independent Contractor Appointment Report",
        level: 2,
        default: 0,
      },
      {
        name: "Interpreter Evaluation Form",
        level: 2,
        default: 0,
      },
      {
        name: "Interpreter Activity Report",
        level: 2,
        default: 0,
      },
    ],
  },
  systemAdmin: {
    name: "System Admin",
    level: 3,
    children: [
      {
        name: "Group",
        level: 3,
        default: 0,
      },
      {
        name: "User",
        level: 3,
        default: 0,
      },
      {
        name: "Language",
        level: 3,
        default: 0,
      },
    ],
  },
};

export enum ERole {
  ADMIN = "ADMIN",
  INTERPRETER = "INTERPRETER",
  REQUESTER = "REQUESTER",
  AGENCY = "AGENCY",
}
