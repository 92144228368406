import {
  Button,
  Card,
  Grid,
  Input,
  Row,
  Space,
  Typography,
} from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { EMediaDeviceInfo, ERoomStatus, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { socket } from "hooks/useContext/useSocketContext";
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { openNotification } from "utils";

const contain: CSSProperties = {
  width: "100%",
  minHeight: "inherit",
  height: "100%",
  alignItems: "center",
};

const containSm: CSSProperties = {
  width: "100%",
  minHeight: "inherit",
  height: "100%",
  alignItems: "center",
  padding: '0 15px'
};
const btnSubmit: CSSProperties = {
  marginTop: "30px",
};
const imgLogo: CSSProperties = {
  width: "120px",
  marginBottom: "20px",
};
const title: CSSProperties = {
  marginBottom: "45px",
  textAlign: "center",
};
const bgLight: CSSProperties = {
  backgroundColor: "#fff",
  width: "100%",
  height: "100vh",
};
const wrapperLogo: CSSProperties = {
  display: "flex",
  justifyContent: 'center'
}
const cardSM: CSSProperties = {
  width: '300px'
}
const cardXL: CSSProperties = {
  width: '450px'
}
export default function InviteCall() {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.sm;
  const [username, setUsername] = useState("");
  const isLoading = useRef(false);
  const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const { replace, push } = useNavigation();

  const handleAvailableMic = () => {
    isLoading.current = true;
    socket.emit("add-participant", {
      roomSid: searchParams.get("roomSid"),
      identity: username,
      pass: searchParams.get('pass')
    });
  }
  const handleJoinRoom = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const isAvailableMic = devices.find((device) => device.kind === EMediaDeviceInfo.AUDIO_INPUT && device.deviceId);
    if (isAvailableMic) {
      handleAvailableMic();
    } else {
      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(_ => {
        handleAvailableMic();
      }).catch(_ => {
        openNotification(MSG_NOTIFICATION.ERROR, 'Microphone has been blocked, please allow microphone.', EStatusNotification.ERROR, 3);
      })
    }

  };
  useEffect(() => {
    socket.emit("fetch-room", {
      roomId: searchParams.get("roomSid"),
    });
  }, [searchParams]);

  useEffect(() => {
    socket.on("msg-fetch-room", (data) => {
      if (data.status === "completed") {
        push("/404");
      }
    });

    socket.on('invite-expired', () => {
      openNotification(MSG_NOTIFICATION.ERROR, 'The invitation has expired', EStatusNotification.ERROR, 5);
      push('/404');
    })
    return () => {
      socket.off("msg-fetch-room");
      socket.off('invite-expired')
    };
  }, [socket]);

  useEffect(() => {
    socket.on("accept-participant", (data) => {
      localStorage.setItem("token-twilio", data.tokenGenerator.token);
      localStorage.setItem("token-room", data.tokenGenerator.indentity.room);
      localStorage.setItem("room-state", ERoomStatus.CONNECTED);
      localStorage.setItem('type-media', data.type);
      replace(`/video-call/?roomSid=${data.tokenGenerator.indentity.room}&pass=${searchParams.get('pass')}`);
      isLoading.current = false;
    });

    return () => {
      socket.off("accept-participant");
    };
  }, []);

  return (
    <div style={bgLight}>
      <Row justify="center" style={isMobile ? containSm : contain}>
        <Space direction="vertical" wrap={isMobile}>
          <div style={wrapperLogo} className="wrapper-logo">
            <img
              style={imgLogo}
              src="/images/global/logo_v2.png"
              alt="911-logo"
            />
          </div>
          <Card style={isMobile ? cardSM : cardXL}>
            <Typography.Title style={title} level={4}>
              Join the room with your name.
            </Typography.Title>
            <Input
              size="large"
              value={username}
              onPressEnter={() => {
                if (username) handleJoinRoom();
              }}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Button
              type="primary"
              style={btnSubmit}
              disabled={!username}
              onClick={handleJoinRoom}
              loading={isLoading.current}
              block
            >
              Submit
            </Button>
          </Card>
        </Space>
      </Row>
    </div>
  );
}
