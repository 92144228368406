import {
  AutoComplete,
  Button,
  Checkbox,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Select,
  Space,
  Table,
  TextField,
  useForm,
} from "@pankod/refine-antd";
import { useCreate, useCustom, useTranslate } from "@pankod/refine-core";
import {
  CREATE_LANGUAGE_ORDER_API,
  LANGUAGES_API,
  LANGUAGE_ORDER_ADMIN_API,
} from "api/baseApi";
import { CustomModal } from "components/Modal/CustomModal";
import { ILanguage } from "interfaces/languages";
import React, { useEffect, useState } from "react";
import { LanguagesCreate } from "./create";
import { Breadcrumb } from "antd";
import { LanguageUpdate } from "./update";
import LanguageActive from "./active";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { openNotification  } from "utils";

const { PlusSquareOutlined, SearchOutlined, CloseOutlined, CheckOutlined } =
  Icons;
export const LanguagesList: React.FC = () => {
  const t = useTranslate();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeLanguages, setActiveLanguages] = useState([]);

  const [isModalVisibleUpdate, setIsModalVisibleUpdate] = useState(false);
  const [detailLanguage, setDetailLanguage] = useState<ILanguage>();
  const [isModalVisibleActive, setIsModalVisibleActive] = useState(false);

  const [searchValueLanguage, setValueLanguage] = useState("");

  const [page, setPage] = useState(1);

  const { data: languagesData, refetch } = useCustom({
    url: LANGUAGES_API.getAll,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
        search: searchValueLanguage,
      },
    },
    queryOptions: {
      onSuccess: (languagesData) => {
        setActiveLanguages(languagesData?.data?.results);
      },
    },
  });

  const { data: languagesDataActive } = useCustom({
    url: LANGUAGES_API.getActive,
    method: "get",
    config: {
      query: {
        page: page,
        limit: null,
        search: searchValueLanguage,
      },
    },
  });

  const { data: languageOrderData, refetch: refetchLanguage } = useCustom({
    url: LANGUAGE_ORDER_ADMIN_API,
    method: "get",
  });

  const pageTotal = languagesData?.data.total;

  const update = (languageData: ILanguage) => {
    setDetailLanguage(languageData);
    setIsModalVisibleUpdate(true);
  };

  const active = (languageData: ILanguage) => {
    setDetailLanguage(languageData);
    setIsModalVisibleActive(true);
  };

  const columns = [
    {
      title: "Nbr.",
      key: "key",
      render: (value: number) => (
        <TextField
          value={
            value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
          }
        />
      ),
    },
    {
      title: `Language`,
      key: "name",
      render: (value: string, record: ILanguage) => (
        <TextField
          value={value ? value : ""}
          style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
          onClick={() => update(record)}
        />
      ),
    },
    {
      title: `Audio`,
      key: "telephone",
      render: (value: boolean) => (
        <TextField value={value ? <CheckOutlined /> : <CloseOutlined />} />
      ),
    },
    {
      title: `Video`,
      key: "video",
      render: (value: boolean) => (
        <TextField value={value ? <CheckOutlined /> : <CloseOutlined />} />
      ),
    },
    {
      title: `Is Active`,
      key: "isActive",
      render: (value: boolean, record: ILanguage) => (
        <Checkbox checked={value} onChange={() => active(record)} />
      ),
    },
  ];

  const handleCreate = () => {
    handleOpenModel();
  };

  const handleOpenModel = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleChange = (page: number) => {
    setPage(page);
  };

  const onChange = (languageName: string) => {
    setValueLanguage(languageName);
  };

  const handleRemoveInterpreter = () => {
    setValueLanguage("");
  };

  useEffect(() => {
    refetch();
  }, [isModalVisible, isModalVisibleUpdate, isModalVisibleActive, refetch]);

  useEffect(() => {
    setPage(1);
  }, [searchValueLanguage]);

  const { formProps, form } = useForm<ILanguage>({
    action: EAction.CREATE,
  });

  const { mutate, isLoading } = useCreate();

  const onFinish = (event: ILanguage) => {
    if (event.languages) {
      mutate(
        {
          resource: CREATE_LANGUAGE_ORDER_API,
          values: {
            languages: event.languages,
          },
        },
        {
          onSuccess: (createData) => {
            form.resetFields();
            openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
            refetchLanguage();
          },
          onError: (error) => {
            openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);
          },
        }
      );
    } else {
      openNotification(MSG_NOTIFICATION.ERROR, "Please select language!", EStatusNotification.ERROR, 2);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      languages: languageOrderData?.data?.languages?.map(
        (item: ILanguage) => item?.id
      ),
    });
  }, [languageOrderData, form]);

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>System Administration</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {t("resource.languages")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      pageHeaderProps={{
        extra: (
          <Space>
            <Button className="btn-create" onClick={handleCreate}>
              <PlusSquareOutlined /> {t("language.title.create")}
            </Button>
          </Space>
        ),
      }}
    >
      <CustomModal
        title={t("language.label.title.info")}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      >
        <LanguagesCreate
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>

      <CustomModal
        title={t("language.label.title.info")}
        setIsModalVisible={setIsModalVisibleUpdate}
        isModalVisible={isModalVisibleUpdate}
      >
        <LanguageUpdate
          setIsModalVisible={setIsModalVisibleUpdate}
          isModalVisible={isModalVisibleUpdate}
          language={detailLanguage}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>

      <CustomModal
        title="Confirmation"
        setIsModalVisible={setIsModalVisibleActive}
        isModalVisible={isModalVisibleActive}
        width={isMobile ? "100%" : "50%"}
      >
        <LanguageActive
          setIsModalVisible={setIsModalVisibleActive}
          isModalVisible={isModalVisibleActive}
          language={detailLanguage}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>

      <Form layout="vertical">
        <Form.Item label="Search language">
          <AutoComplete
            showSearch
            size="large"
            style={{
              width: isMobile ? "100%" : "50%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            value={searchValueLanguage}
            onChange={onChange}
          >
            <Input
              suffix={
                <>
                  {searchValueLanguage && (
                    <CloseOutlined onClick={handleRemoveInterpreter} />
                  )}
                  <SearchOutlined />
                </>
              }
              bordered={false}
            />
          </AutoComplete>
        </Form.Item>
      </Form>
      <Form
        layout="vertical"
        className={!isMobile ? "search-form" : ""}
        {...formProps}
        onFinish={(event) => onFinish(event as ILanguage)}
      >
        <Form.Item
          label="Most Requested Languages"
          name="languages"
          rules={[
            {
              required: false,
              message: "Please enter language",
            },
          ]}
          className={!isMobile ? "search-item" : ""}
          initialValue={languageOrderData?.data?.languages?.map(
            (item: ILanguage) => item?.id
          )}
        >
          <Select
            mode="multiple"
            allowClear
            showSearch
            size="large"
            optionFilterProp="children"
            filterOption={(
              input,
              option: BaseOptionType | DefaultOptionType | undefined
            ) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            style={{
              width: "100%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
          >
            {languagesDataActive?.data?.results?.map(
              (item: ILanguage, index: number) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              )
            )}
          </Select>
        </Form.Item>
        <Form.Item
          label="
          "
          className={!isMobile ? "search-item" : ""}
        >
          {" "}
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Add
          </Button>
        </Form.Item>
        {/* <Space wrap={isMobile}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Add
          </Button>
        </Space> */}
      </Form>

      <Table
        dataSource={activeLanguages?.map((item: ILanguage, index: number) => ({
          ...item,
          key: index,
        }))}
        pagination={
          pageTotal > 10
            ? {
                total: pageTotal ? pageTotal : 1,
                current: page,
                onChange: handleChange,
                showTotal: () => `${pageTotal} total`,
              }
            : false
        }
        scroll={{ x: 800 }}
      >
        {columns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
    </List>
  );
};
