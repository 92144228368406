//USER
export const PROFILE_API = "api/user/profile";
export const USERS_API = "api/user/get-all-customers-interpreter";
export const USER_API = {
  getAll: "api/user/get-all",
  getOne: "api/user/get-one",
};
export const REMOVE_TOKEN = "api/devicetoken/delete";
export const CREATE_USER_API = "api/auth/user/register";
export const UPDATE_USER_API = "api/user/update";
export const ACTIVE_USER_API = "api/auth/user/active";
export const DEACTIVE_USER_API = "api/auth/user/de-active";
export const DELETE_USER_API = "api/user/delete";
export const UPDATE_CUSTOMER_API = "api/user/update/customer";
export const UPDATE_PASSWORD_API = "api/user/update-password";
export const FIND_INTERPRETER_STATUS_ONLINE =
  "api/user/find-interpreter-status-online";

//UPDATE AVATAR
export const UPLOAD_AVATAR = "api/user/upload-avatar";
export const IMAGE_API = "api/user/get-image";

//AUTH
export const LOGIN_API = "api/auth/login";
export const CREATE_API_ADMIN = "api/auth/admin/register";
export const CREATE_CUSTOMER_API = "api/auth/register-customer";
export const SEND_MAIL = "api/auth/send-mail-generate-password";
export const CREATE_PASSWORD = "api/auth/create-password";
export const RE_SEND_PASSWORD = "api/auth/update-status-reset-password";
export const RESET_PASSWORD = "api/auth/reset-password";
export const CHANGE_PASSWORD_VERIFY_TOKEN =
  "api/auth/change-password-verify-token";

//CUSTOMER
export const CUSTOMER_API = {
  getAll: "api/customer/get-all",
  getOne: "api/customer/get-one",
  getDepartmentByCus: "api/departments/get-by-customer",
  getSubDepartmentByCus: "api/customer/get-subdepartment",
};
export const CREATE_CUSTOMERS_API = "api/customer/create";
export const UPDATE_CUSTOMERS_API = "api/customer/update";
export const DELETE_CUSTOMERS_API = "api/customer/delete";
export const ACTIVE_CUSTOMERS_API = "api/customer/active";
export const DEACTIVE_CUSTOMERS_API = "api/customer/de-active";
export const GET_QUESTIONS_API = "api/customer";

//REQUESTER
export const REQUESTER_API = {
  getAll: "api/requester/get-all",
  getOne: "api/requester/get-one",
  getByCustomer: "api/requester/get-all/customer",
  getByDepartment: "api/requester/get-all/department",
};
export const CREATE_REQUESTER_API = "api/requester/create";
export const UPDATE_REQUESTER_API = "api/requester/update";
export const ACTIVE_REQUESTER_API = "api/auth/user/active";
export const DEACTIVE_REQUESTER_API = "api/auth/user/de-active";

//INTERPRETER
export const INTERPRETER_API = {
  getAll: "api/interpreters/get-all",
  getOne: "api/interpreters/get-one",
  getActive: "api/interpreters/get-active",
};
export const CREATE_INTERPRETER_API = "api/interpreters/create";
export const UPDATE_INTERPRETER_API = "api/interpreters/update";
export const UPDATE_PROFILE_INTERPRETER_API = "api/interpreters/update-profile";
export const ACTIVE_INTERPRETER_API = "api/interpreters/active";
export const DEACTIVE_INTERPRETER_API = "api/interpreters/deactive";

// INTERPRETER TYPE
export const INTERPRETER_TYPE_API = "api/interpreter-type/find-all";
export const CREATE_INTERPRETER_TYPE_API = "api/interpreter-type/create";

//INTERPRETER_LANGUAGES
export const INTERPRETER_LANGUAGE_API = {
  getAll: "api/interlang/get-all",
  getLanguage: "api/interlang/get-language",
  getInterpreter: "api/interlang/get-interpreter",
};
export const INTERPRETER_LANGUAGE_DELETE_API =
  "api/interlang/delete-language/{id}/language/{languages}";

//LANGUAGES
export const LANGUAGES_API = {
  getAll: "api/languages/get-all",
  getAllClientSide: "api/languages/get-all/client-side",
  getOne: "api/languages/get-one",
  getActive: "api/languages/get-all/language-active",
};
export const CREATE_LANGUAGES_API = "api/languages/create";
export const UPDATE_LANGUAGES_API = "api/languages/update";
export const DELETE_LANGUAGES_API = "api/languages/delete";
export const ACTIVE_LANGUAGES_API = "api/languages/active";
export const DEACTIVE_LANGUAGES_API = "api/languages/de-active";

//DEPARTMENTS
export const DEPARTMENT_API = {
  getAll: "api/departments/get-all",
  getOne: "api/departments/get-one",
  getAllByEnterprise: "api/departments/get-all-department-by-enterprise",
  getByCustomer: "api/departments/get-by-customer",
  getAllActive: "api/departments/get-active",
  getBySubdepartment: "api/departments/get-sub-department",
};
export const CREATE_DEPARTMENT_API = "api/departments/create";
export const UPDATE_DEPARTMENT_API = "api/departments/update";
export const ACTIVE_DEPARTMENT_API = "api/departments/active";
export const DEACTIVE_DEPARTMENT_API = "api/departments/deactive";

//SUB_DEPARTMENTS
export const SUB_DEPARTMENT_API = {
  getAll: "api/subdepartments/get-all",
  getOne: "api/subdepartments/get-one",
  getByDepartments: "api/subdepartments/get-sub-department",
  getByDepartment: "api/subdepartments/get-by-department",
  getByCustomer: "api/subdepartments/get-by-customer",
};
export const CREATE_SUB_DEPARTMENT_API = "api/subdepartments/create";
export const UPDATE_SUB_DEPARTMENT_API = "api/subdepartments/update";
export const ACTIVE_SUB_DEPARTMENT_API = "api/subdepartments/active";
export const DEACTIVE_SUB_DEPARTMENT_API = "api/subdepartments/de-active";

//ROOM
export const ROOM_API = {
  getAll: "api/rooms/get-all",
  getToken: "api/rooms/getToken",
  findRoom: "api/rooms/find-room",
};
export const CREATE_ROOM_API = "api/rooms/create-room";
export const JOIN_ROOM_API = "api/rooms/join-room/roomId";
export const FINISH_ROOM_API = "api/rooms/finish-room/roomId";
export const DELETE_ROOM_API = "api/rooms/delete-room";
export const SEND_NOTIFY_API = "api/rooms/send-notify";
export const DEVICE_CREATE_TOKEN = "/api/devicetoken/regis-token";

//PERMISSION
export const PERMISSION_API = {
  getAll: "api/permissions/get-all",
};

//GROUP_PERMISSION
export const GROUP_PERMISSION_API = {
  getAll: "api/group/getAll",
  getOne: "api/group/findOne",
};
export const CREATE_GROUP_PERMISSION_API = "api/group/create";
export const UPDATE_GROUP_PERMISSION_API = "api/group/update";
export const DELETE_GROUP_PERMISSION_API = "api/grouppermission";

//STATE
export const STATE_API = {
  getAll: "api/state/getAll",
};

//ADDRESS_MAIL
export const ADDRESS_MAIL_API = {
  getAll: "api/address-mail",
  getOne: "api/address-mail",
};
export const CREATE_ADDRESS_MAIL_API = "api/address-mail";
export const UPDATE_ADDRESS_MAIL_API = "api/address-mail";
export const DELETE_ADDRESS_MAIL_API = "api/address-mail";

//ADDRESS_PHONE
export const ADDRESS_PHONE_API = {
  getAll: "api/address-phone",
  getOne: "api/address-phone",
};
export const CREATE_ADDRESS_PHONE_API = "api/address-phone";
export const UPDATE_ADDRESS_PHONE_API = "api/address-phone";
export const DELETE_ADDRESS_PHONE_API = "api/address-phone";

// BILL_RATE
export const BILL_RATE_API = {
  getAll: "api/bill-rate/get-bill-rate",
  getCustomer: "api/bill-rate/get-bill-customer",
};
export const CREATE_BILL_RATE_API = "api/bill-rate/create-bill-rate";
export const UPDATE_BILL_RATE_API = "api/bill-rate/update/bill-rate";
export const ACTIVE_BILL_RATE_API = "api/bill-rate/active-bill-rate";
export const DEACTIVE_BILL_RATE_API = "api/bill-rate/de-active-bill-rate";

// PAY_RATE
export const PAY_RATE_API = {
  getAll: "api/bill-rate/get-pay-rate",
  getInterpreter: "api/bill-rate/get-pay-interpreter",
};
export const CREATE_PAY_RATE_API = "api/bill-rate/create-pay-rate";
export const UPDATE_PAY_RATE_API = "api/bill-rate/update/pay-rate";
export const ACTIVE_PAY_RATE_API = "api/bill-rate/active-pay-rate";
export const DEACTIVE_PAY_RATE_API = "api/bill-rate/de-active-pay-rate";

// SERVICE
export const RATE_TYPE_API = {
  getAll: "api/rate-type/get-all",
  getAllActive: "api/rate-type/get-all-active",
};
export const CREATE_RATE_TYPE_API = "api/rate-type/create";
export const UPDATE_RATE_TYPE_API = "api/rate-type/update";
export const ACTIVE_RATE_TYPE_API = "api/rate-type/active";
export const DEACTIVE_RATE_TYPE_API = "api/rate-type/de-active";

//INVOICE-PROCESS-CUSTOMER
export const INVOICE_PROCESS_CUSTOMER_API = {
  getAll: "api/invoice-process/get-all",
  getOne: "api/invoice-process/get-one",
  getDetail: "api/invoice-customer-details/get-one",
  getDetailShowOne: "api/invoice-customer-details/show-one",
};
export const CREATE_INVOICE_PROCESS_CUSTOMER_API = "api/invoice-process/invoice-customer/create";

//INVOICE-PROCESS-INTERPRETER
export const INVOICE_PROCESS_INTERPRETER_API = {
  getAll: "api/invoice-interpreter/get-all",
  getOne: "api/invoice-interpreter/get-one",
  getDetail: "api/invoice-interpreter-details",
  findManyByProcess: "api/invoice-process-interpreter/find-many/by-process",
};
export const CREATE_INVOICE_PROCESS_INTERPRETER_API = "api/invoice-interpreter/create";

//LANGUAGE-ORDER
export const LANGUAGE_ORDER_ADMIN_API = "api/language-order/get-all";
export const LANGUAGE_ORDER_API = "api/language-order/get-all/by-interpreter";
export const CREATE_LANGUAGE_ORDER_API = "api/language-order/create";

//CALL
export const CALL_API = {
  getOne: "api/call/get-one",
  getAll: "api/call/get-all",
  getQuestions: "/api/call/get-questions",
  getCallUser: "api/call/get-call-user",
};
export const CREATE_NOTE = "api/call/create-note";
export const GET_NOTE_PAD = "api/call/get-note-pad";

//REPORT
export const INTERPRETER_BY_LANGUAGE_API = "api/interpreters/get-interpreter";
export const BILLING_REPORT_API = "api/invoice-process-customer/find-report";
export const PAYMENT_REPORT_API = "api/invoice-process-interpreter/find-report";
export const INTERPRETER_ACTIVITY_API = "api/call/reports/interpreter-activity";
export const REPORT_CALL_LANGUAGE_API = "api/call/fill-language-report";
export const REPORT_CALL_CUSTOMER_API = "api/call/fill-customer-report";
export const PAY_BILL_REPORT_API = "api/call/get-report/bill-pay-report";

//CHANGE-STATUS
export const CHANGE_STATUS_API = "api/user/change-status";

//INVITE
export const INVITE_PARTICIPANT = "api/call/invite-participant";
export const SEND_SMS = "/api/call/send-sms";
export const CREATE_NEW_INVITE_LINK = 'api/call-invite/create';

//DASHBOARD
export const GET_TOTAL_BY_MONTH = "api/call/get-total/by-month";
export const GET_CALL_STATISTICS = "api/call/call-activity";
export const GET_TOTAL = "api/call/get-total";
export const GET_TOTAL_USER = "api/call/get-total-user";
export const GET_CALL_PROGRESS = "api/call/call-progress";
export const GET_LANGUAGE_DISTRIBUTION = "api/call/get-call/language-distribution";
export const RATE_CALL = "/api/call/add-rate";
export const TOTAL_STATISTICS = "api/call/total-statistics/by-user";

//INTERPRETER-ONLINE
export const LANGUAGE_ONLINE = {
  getAll: "api/languages/get-all/interpreter-online",
  getOne: "api/interpreters/get-one/interpreter-online",
};

//CALL AUDIO
export const GET_TOKEN_AUDIO = "api/call/token-generatorVoiceCall";
export const CREATE_VOICE_CALL = "api/call/create-voice";
export const END_VOICE_CALL = "api/call/endvoice-call";
export const FORCE_KILL_ROOM = "api/call/force-kill-room";
export const CALL_PERCENT_TOTAL_API = "api/call/percent-total/by-user";