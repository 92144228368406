import {
  AutoComplete,
  DateField,
  Empty,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Result,
  Spin,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { useCustom } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { BILLING_REPORT_API, CUSTOMER_API } from "api/baseApi";
import { EFormatDateTime } from "constants/constant";
import dayjs from "dayjs";
import useNotification from "hooks/useNotification";
import { IBillingReport } from "interfaces/billingReport";
import { ICustomer, ICustomerReq } from "interfaces/customer";
import { localeInvalid, localeNoData } from "pages/components/EmptyTable";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { formatDateTime, formatNumber } from "utils";
import { exportToCSV, exportToExcel, exportToPDF } from "utils/excelExporter";

const { SearchOutlined, CloseOutlined, ExclamationCircleOutlined } = Icons;

const SELECT_STYLE : React.CSSProperties = {
    width: "100%",
    borderBottom: "1px solid #d9d9d9",
    borderRadius: "0px",
}

const COL_EXCEL = [
  "Appointment Number",
  "Date of Call",
  "Start Time",
  "Finsish Time",
  "Customer Name",
  "Customer Email",
  "Bus Phone",
  "Cell Phone",
  "Fax Number",
  "Department Name",
  "Sub-department Name",
  "Language",
  "Interpreter Name",
  "Requester Name",
  "Requester Email",
  "Duration (s)",
  "Rate",
  "Ratio",
  "Bill Items",
  "Pre-Tax Amount",
  "Total (+tax)",
  "Status",
  "Type of Call",
];

const COL_PDF = [
  { key: "Appt Nbr" },
  { key: "Date of Call" },
  { key: "Call Start" },
  { key: "Call End" },
  { key: "Language" },
  { key: "Department Name" },
  { key: "Requester Name" },
  { key: "Interpreter Name" },
  { key: "Duration (s)" },
  { key: "Total ($)" },
  { key: "Tax ($)" },
  { key: "Total(+tax) ($)" },
  { key: "Status" },
];

const LIMIT_PAGE = 10;

const START_PAGE = 1;

export const BillingReportsList: React.FC = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const [isShowTitleNoData, setIsShowTitleNoData] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [searchCustomer, setSearchCustomer] = useState("");
  const [page, setPage] = useState(START_PAGE);
  const [invoiceNbr, setInvoiceNbr] = useState("");
  const { openNotificationInfo, contextHolder } = useNotification();
  const [pageTotal, setPageTotal] = useState(0);

  const { data: customersData } = useCustom({
    url: CUSTOMER_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
        search: null,
      },
    },
  });

  const { data: billingReportsData } = useCustom({
    url: BILLING_REPORT_API,
    method: "get",
    config: {
      query: {
        page: page,
        limit: LIMIT_PAGE,
        id: customerId,
        processId: invoiceNbr,
      },
    },
    queryOptions: {
      enabled: isSearch,
      onSuccess: (billingReport) => {
        setPageTotal(billingReport?.data?.total);
        if(!billingReport?.data?.results?.length){
          openNotificationInfo(
            "No data found",
            "There are no results matching Billing report",
            <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
          );
        } 
      },
    },
  });

  const viewData = (arrBillingReport: IBillingReport[]) => {
    return arrBillingReport?.map((billing: IBillingReport) => ({
        "Appointment Number": billing?.callerId,
        "Date of Call":  formatDateTime(billing?.createdAt),
        "Start Time": dayjs(billing?.startTime).format(EFormatDateTime.DATETIME),
        "Finsish Time": dayjs(billing?.endTime).format(EFormatDateTime.DATETIME),
        "Customer Name": billing?.customerName,
        "Customer Email": billing?.emailCustomer,
        "Bus Phone": billing?.busPhone,
        "Cell Phone": billing?.cellPhone,
        "Fax Number": billing?.faxNumber,
        "Department Name": billing?.departmentName,
        "Sub-department Name": billing?.subdepartmentName,
        Language: billing?.language,
        "Interpreter Name": billing?.interpreterName,
        "Requester Name": billing?.requesterName,
        "Requester Email": billing?.requesterEmail,
        "Duration (s)": billing?.duration,
        Rate: billing?.rateBill,
        Ratio: billing?.ratioBill,
        "Bill Items": formatNumber(billing?.totalBill),
        "Pre-Tax Amount": formatNumber(billing?.totalBillPreTaxAmount) || 0,
        "Total (+tax)": formatNumber(billing?.totalBillTaxed) || 0,
        Status: billing?.status,
        "Type of Call": billing?.media,
      }))
  };

  const handleExportExcel = (billing: IBillingReport) => {
    return exportToExcel("Billing Report", viewData(billing?.call), COL_EXCEL);
  };

  const handleExportCSV = (billing: IBillingReport) => {
    return exportToCSV(viewData(billing?.call), "Billing Report", COL_EXCEL);
  };

  const viewDataPDF = (arrBillingReport: IBillingReport[]) => {
    return arrBillingReport?.map((billing: IBillingReport) => ({
        "Appt Nbr": billing?.callerId,
        "Date of Call": dayjs(billing?.createdAt).format(EFormatDateTime.DATETIME),
        "Call Start": dayjs(billing?.startTime).format(EFormatDateTime.DATETIME),
        "Call End": dayjs(billing?.endTime).format(EFormatDateTime.DATETIME),
        Language: billing?.language,
        "Department Name": billing?.departmentName,
        "Requester Name": billing?.requesterName,
        "Interpreter Name": billing?.interpreterName,
        "Duration (s)": billing?.duration,
        "Total ($)": formatNumber(billing?.totalBillPreTaxAmount) || 0,
        "Tax ($)": formatNumber(billing?.totalBillTaxed) || 0,
        "Total(+tax) ($)": formatNumber(billing?.totalBill) || 0,
        Status: billing?.status,
      }));
  };

  const handleShowPDF = (billing: IBillingReport) => {
    const fileName = "Billing Report.pdf";
    const title = billing?.call[0]?.customerName ? `Customer Name: ${billing?.call[0]?.customerName}` : "";
    const date = billing?.createdDate ? `Invoice Date: ${dayjs(billing?.createdDate).format("DD/MM/YYYY")}` : "";
    const nameInvoice = billing?.invoiceInterpreterId ? `Invoice#: ${billing?.invoiceInterpreterId}` : "";
    exportToPDF({
      columns: COL_PDF,
      data: viewDataPDF(billing?.call),
      fileName: fileName,
      name: title,
      date: date,
      nameInvoice: nameInvoice,
    });
  };

  const collumns = useMemo(
    () => [
      {
        key: "key",
        title: "Nbr.",
        render: (value: string) => (
          <TextField
            value={(page - 1) * LIMIT_PAGE + value + 1}
          />
        ),
      },
      {
        key: "id",
        title: "Detail",
        render: (value: string, record: IBillingReport) => (
          <TextField
            value={value ? "Get Excel" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleExportExcel(record)}
          />
        ),
      },

      {
        key: "id",
        title: "Get CSV",
        render: (value: string, record: IBillingReport) => (
          <TextField
            value={value ? "Get CSV" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleExportCSV(record)}
          />
        ),
      },

      {
        key: "id",
        title: "Get PDF",
        render: (value: string, record: IBillingReport) => (
          <TextField
            value={value ? "Get PDF" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleShowPDF(record)}
          />
        ),
      },
      {
        key: "invoiceInterpreterId",
        title: "Process Number",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "createdDate",
        title: `Process Date`,
        render: (value: string) =>
          value ? <DateField format="LLL" value={value ? value : ""} /> : "",
      },
      {
        key: "totalPay",
        title: `Total`,
        render: (value: number) => (
          <TextField value={value ? `$${formatNumber(value)}` : `$0`} />
        ),
      },
    ],
    [page]
  );


  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const onChange = (customer: string) => {
    setSearchCustomer(customer);
  };

  const onSelect = async (customerId: string, option: BaseOptionType) => {
    setCustomerId(customerId);
    setSearchCustomer(option.label);
    setIsSearch(true);
    setIsShowTitleNoData(true);
  };

  const handleRemoveCustomer = () => {
    setCustomerId('');
    setPageTotal(0);
    setIsSearch(false);
    setSearchCustomer('');
    setIsShowTitleNoData(false);
  };

  const onChangeProcessInvoice = async (event: string) => {
    setInvoiceNbr(event);
    setIsShowTitleNoData(true);
    setIsSearch(true);
  };

  return (
      <List
        title={
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item>Report</Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item">
              Billing Reports
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {contextHolder}
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Search Customer by Name"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutoComplete
              showSearch
              size="large"
              style={SELECT_STYLE}
              options={customersData?.data?.results?.map(
                (item: ICustomerReq, index: number) => ({
                  value: item.id,
                  label: item.name,
                })
              )}
              filterOption={(
                searchCustomer,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.label
                  ?.toUpperCase()
                  .indexOf(searchCustomer.toUpperCase()) !== -1
              }
              bordered={false}
              value={searchCustomer}
              onChange={onChange}
              onSelect={onSelect}
            >
              <Input
                suffix={
                  <>
                    {searchCustomer && (
                      <CloseOutlined onClick={handleRemoveCustomer} />
                    )}
                    <SearchOutlined />
                  </>
                }
                bordered={false}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item
            label="Process Number (optional) "
            className={!isMobile ? "search-item" : ""}
          >
            <AutoComplete
              showSearch
              size="large"
              style={SELECT_STYLE}
              bordered={false}
              value={invoiceNbr}
              onChange={(event) => onChangeProcessInvoice(event)}
            >
              <Input bordered={false} />
            </AutoComplete>
          </Form.Item>
        </Form>
        {/* <Space>
          <Button className="search-report-call" onClick={handleShowBilling}>
            Search
          </Button>
        </Space> */}

        <Table
          locale={isShowTitleNoData ? localeNoData : localeInvalid('Please select billing report')}
          dataSource={isSearch ? billingReportsData?.data?.results?.map(
            (item: ICustomer, index: number) => ({
              ...item,
              key: index,
            })
          ) : []}
          rowKey="id"
          pagination={
            pageTotal > LIMIT_PAGE
              ? {
                  current: page,
                  total: pageTotal,
                  onChange: handlePageChange,
                  showTotal: () => `${pageTotal} total`,
                }
              : false
          }
          scroll={{ x: 900 }}
        >
          {collumns.map((col) => (
            <Table.Column dataIndex={col.key} {...col} />
          ))}
        </Table>
      </List>
  );
};
