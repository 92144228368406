import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  useForm,
} from "@pankod/refine-antd";
import { BaseKey, useCustom, useUpdate } from "@pankod/refine-core";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import {
  GROUP_PERMISSION_API,
  PROFILE_API,
  UPDATE_USER_API,
} from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ISelectItemPermisson } from "interfaces";
import { ICustomer } from "interfaces/customer";
import { IGroup } from "interfaces/group";
import { IRequester } from "interfaces/requesters";
import { useState } from "react";
import { openNotification } from "utils";

type RequesterInformationEditProps = {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  requester: ICustomer | undefined;
};

export const RequesterInformationEdit = (
  props: RequesterInformationEditProps
) => {
  const { requester } = props;
  const [selectedItems, setSelectedItem] = useState<ISelectItemPermisson>();
  const [selectPermissions, setSelectedPermission] = useState([]);

  const { form, formProps } = useForm<IRequester>({
    action: EAction.EDIT,
  });

  const { data: groupsData } = useCustom({
    url: GROUP_PERMISSION_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
      },
    },
  });

  const { data: profile } = useCustom({
    url: PROFILE_API,
    method: "get",
  });

  const { mutate, data: updateData, isLoading } = useUpdate();

  const onFinish = (event: IRequester) => {
    mutate(
      {
        resource: UPDATE_USER_API,
        id: requester?.id as BaseKey,
        values: {
          customerId: event.customerId,
          departmentId: event.departmentId,
          subdepartmentId: event.subdepartmentId,
          requesterId: event.requestName,
          phone: event.phone,
          email: event.email,
        },
      },
      {
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);
        },
      }
    );
  };

  const handleChangeGroup = (selectedItems: ISelectItemPermisson) => {
    const assets_choose = selectedItems.map((item: number, index: number) => {
      let arrayItem: ISelectItemPermisson = {
        key: 0,
        permissions: [],
        map: undefined,
      };
      arrayItem.permissions = groupsData?.data?.results?.filter(
        (x: { id: number }) => x.id === item
      )[0];
      arrayItem.key = index;

      return arrayItem;
    });

    setSelectedPermission(assets_choose);
    setSelectedItem(selectedItems);
  };

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as IRequester)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <Form.Item label="Customer Name" name="name">
            <Input size="large" disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <Form.Item
            label="Department"
            name="departmentId"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input size="large" maxLength={50} />
          </Form.Item>

          <Form.Item
            label="Requester"
            name="requesterId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size="large" maxLength={50} />
          </Form.Item>

          <Form.Item
            label="Requester Phone"
            name="phone"
            rules={[
              {
                pattern: /^(?:\d*)$/,
                message: "Must contain 10 numbers only",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input size="large" maxLength={10} disabled />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={12}>
          <Form.Item
            label="Sub-department"
            name="subDepartmentId"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              size="large"
              value={selectedItems}
              onChange={handleChangeGroup}
              style={{ width: "100%" }}
              optionFilterProp="children"
              filterOption={(
                input,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {groupsData?.data?.results?.map((item: IGroup, index: number) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Requester Email" name="email">
            <Input size="large" disabled />
          </Form.Item>
        </Col>
      </Row>

      <div className="submit">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
