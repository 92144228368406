import {
  Grid,
  Row,
  Space,
  Typography
} from "@pankod/refine-antd";
import './style.css';

export default function FinishCall() {
  const breakpoint = Grid.useBreakpoint();
  const isTablet = !breakpoint.md;
  return (
    <div className="w-100 bg-light">
      <Row justify="center" className="container" >
        <Space direction="vertical" wrap={isTablet}>
          <Space align="center" wrap={isTablet}>
            <img
              className="img-logo"
              src="/images/global/logo_v2.png"
              alt="911-logo"
            />
          </Space>
          <Typography.Title className="title"  level={2}>
            Thank you.
          </Typography.Title>
        </Space>
      </Row>
    </div>
  );
}
