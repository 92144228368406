/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Empty,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Result,
  Row,
  Space,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { useCustom, useTranslate } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { CUSTOMER_API, DEPARTMENT_API, REQUESTER_API } from "api/baseApi";
import { CustomModal } from "components/Modal/CustomModal";
import { UserContext } from "hooks/useContext/useUserContext";
import { IDepartment } from "interfaces/department";
import { IRequester } from "interfaces/requesters";
import { ISub_Department } from "interfaces/sub_department";
import { ICustomer } from "interfaces/users";
import { axiosInstance } from "providers/axios";
import React, { useEffect, useMemo, useState } from "react";
import "../../../../styles/style.less";
import RequesterActive from "./Modal/ActiveModal";
import { CreateRequesterModal } from "./Modal/CreateModal";
import { UpdateRequestersModal } from "./Modal/UpdateModal";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
  TableColumn,
} from "utils/excelExporter";
import useNotification from "hooks/useNotification";

const columns: TableColumn[] = [
  { key: "Nbr" },
  { key: "Requester Name" },
  { key: "Phone" },
  { key: "Email Address" },
  { key: "User Name" },
  { key: "Customer Name" },
  { key: "Department" },
  { key: "Sub-department" },
  { key: "Is Active" },
];

const columnRequester = [
  "Nbr",
  "Requester Name",
  "Phone",
  "Email Address",
  "User Name",
  "Customer Name",
  "Department",
  "Sub-department",
  "Is Active",
];


const {
  PlusSquareOutlined,
  SearchOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} = Icons;

export const RequestersList: React.FC = () => {
  const translate = useTranslate();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.xl;


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isModalVisibleUpdate, setIsModalVisibleUpdate] = useState(false);
  const [isModalVisibleActive, setIsModalVisibleActive] = useState(false);
  const [isShowNotification, setIsShowNotification] = useState(false);
  const [isShowNotificationByDepartment, setIsShowNotificationByDepartment] =
    useState(false);

  const [requester, setRequester] = useState<IRequester>();
  const [customer, setCustomer] = useState<ICustomer>();
  const [department, setDepartment] = useState<IDepartment>();

  const [activeDepartment, setActiveDepartment] = useState([]);
  const [requestersData, setRequestersData] = useState<any>([]);

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState<number>(1);

  const { customerId, departmentId, setCustomerId, setDepartmentId } =
    React.useContext(UserContext);

  const { data: allCustomerData } = useCustom({
    url: CUSTOMER_API.getAll,
    method: "get",
    config: {
      query: {
        limit: "",
        page: "",
        search: "",
      },
    },
  });

  const { data: allRequestersData } = useCustom({
    url: REQUESTER_API.getAll,
    method: "get",
    config: {
      query: {
        limit: "",
        page: page,
        search: searchValue,
        customerId: isSearch ? customerId : "",
        departmentId: isSearch ? departmentId : "",
      },
    },
    queryOptions: {
      enabled: isSearch,
      onSuccess: (requester) => {
        setRequestersData(requester?.data?.results);
      },
    },
  });

  const handleViewRequester = () => {
    return axiosInstance.get(
      `${REQUESTER_API.getAll}?limit=&page=&customerId=&departmentId=&search=`
    );
  };

  const handleChangeCustomer = async (customerId: string, option: any) => {
    setPage(1);
    setDepartmentId("");
    setDepartment(undefined);
    setCustomer(option);
    setCustomerId(option?.value);
    const departmentByCusId = await axiosInstance.get(
      DEPARTMENT_API.getByCustomer + `?id=${customerId}&page=10&limit=`
    );

    setActiveDepartment(departmentByCusId?.data.results);
    setIsShowNotification(true);
  };

  const handleSelectCustomer = (customerId: string) => {
    setIsSearch(true);
  };

  const handleChangeDepartment = (departmentId: string, option: any) => {
    setPage(1);
    setDepartment(option);
    setDepartmentId(option?.value);
    setIsShowNotificationByDepartment(true);
  };

  const handleRemoveCustomer = () => {
    setPage(1);
    setSearchValue("");
    setCustomerId("");
    setDepartmentId("");
    setCustomer(undefined);
    setDepartment(undefined);
    setActiveDepartment([]);
    setIsShowNotification(false);
  };

  const handleRemoveDepartment = () => {
    setSearchValue("");
    setDepartment(undefined);
    setDepartmentId("");
    setIsShowNotificationByDepartment(false);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRequester = (requesterName: string, option: any) => {
    setIsSearch(true);
    setPage(1);
    setSearchValue(requesterName);
  };

  const handleRemoveRequester = () => {
    setPage(1);
    setSearchValue("");
  };

  const handleEdit = async (requester: IRequester) => {
    const res = await requesterData(requester);
    setRequester(res);
    setIsModalVisibleUpdate(true);
  };

  const handleCreate = () => {
    handleOpenModel();
  };

  const handleOpenModel = () => {
    setIsModalVisible(!isModalVisible);
  };

  const pageTotal =
    allRequestersData?.data?.results && allRequestersData?.data?.total;

  const requesterData = React.useCallback(async (requester: IRequester) => {
    const userDataSearch = await axiosInstance.get(
      REQUESTER_API.getOne + `/${requester.id}`
    );
    return userDataSearch.data;
  }, []);

  const handleActive = async (requester: IRequester) => {
    const res = await requesterData(requester);
    setRequester(res);
    setIsModalVisibleActive(true);
  };

  const updateSuccess = async () => {
    if (customerId) {
      const userDataSearch = await axiosInstance.get(
        REQUESTER_API.getAll +
        `?page=${page}&limit=10&customerId=${customerId}&departmentId=`
      );
      setRequestersData(userDataSearch?.data?.results);
      const departmentByCusId = await axiosInstance.get(
        DEPARTMENT_API.getByCustomer + `?id=${customerId}&page=10&limit=`
      );
      setActiveDepartment(departmentByCusId?.data.results);
    }
    if (customerId !== "" && departmentId !== "0") {
      const userDataSearch = await axiosInstance.get(
        REQUESTER_API.getAll +
        `?page=${page}&limit=10&customerId=${customerId}&departmentId=${departmentId}`
      );
      setRequestersData(userDataSearch?.data?.results);
    }
    if (searchValue && customerId !== "") {
      const userDataSearch = await axiosInstance.get(
        REQUESTER_API.getAll +
        `?search=${searchValue}&page=${page}&limit=10&customerId=${customerId}&departmentId=`
      );
      setRequestersData(userDataSearch?.data?.results);
    }
    if (searchValue && departmentId !== "") {
      const userDataSearch = await axiosInstance.get(
        REQUESTER_API.getAll +
        `?search=${searchValue}&page=${page}&limit=10&customerId=${customerId}&departmentId=${departmentId}`
      );
      setRequestersData(userDataSearch?.data?.results);
    }
  };



  const formatRequester = (arr: any) => {
    return arr?.map((requester: any) => ({
      Nbr: requester.id,
      "Requester Name": requester?.requestName,
      Phone: requester?.phone,
      "Email Address": requester?.email,
      "User Name": requester?.username,
      "Customer Name": requester?.customerName,
      Department: requester?.department?.name,
      "Sub-department": requester?.subdepartment?.name,
      "Is Active": requester?.isActive,
    }));
  };

  const handleExportExcel = async () => {
    const { data } = await handleViewRequester();

    if (customerId) {
      exportToExcel(
        "Requester-Listing",
        formatRequester(requestersData),
        columnRequester
      );
    } else {
      exportToExcel(
        "Requester-Listing",
        formatRequester(data?.results),
        columnRequester
      );
    }
  };

  const handleExportCSV = async () => {
    const { data } = await handleViewRequester();

    if (customerId) {
      exportToCSV(
        formatRequester(requestersData),
        "Requester-Listing",
        columnRequester
      );
    } else {
      exportToCSV(
        formatRequester(data?.results),
        "Requester-Listing",
        columnRequester
      );
    }
  };

  const handleExportPDF = async () => {
    const { data } = await handleViewRequester();
    if (customerId) {
      exportToPDF({
        columns,
        data: formatRequester(requestersData),
        fileName: "requester.pdf",
        name: customer?.label
          ? `Customer Name: ${customer?.label ? customer?.label : ""}`
          : "",
        date: department?.label
          ? `Department Name: ${department?.label ? department?.label : ""}`
          : "",
        nameInvoice: searchValue
          ? `Requester Name: ${searchValue ? searchValue : ""}`
          : "",
      });
    } else {
      exportToPDF({
        columns,
        data: formatRequester(data?.results),
        fileName: "Requester-Listing.pdf",
        name: customer?.label
          ? `Customer Name: ${customer?.label ? customer?.label : ""}`
          : "",
        date: department?.label
          ? `Department Name: ${department?.label ? department?.label : ""}`
          : "",
        nameInvoice: searchValue
          ? `Requester Name: ${searchValue ? searchValue : ""}`
          : "",
      });
    }
  };

  const column = useMemo(
    () => [
      {
        key: "key",
        dataIndex: "key",
        title: "Nbr",
        render: (value: number) => (
          <TextField
            value={
              value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
            }
          />
        ),
      },
      {
        key: "requestName",
        dataIndex: "requestName",
        title: "Requester Name",
        render: (value: any, record: IRequester) => (
          <TextField
            value={value ? value : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleEdit(record)}
          />
        ),
      },
      {
        key: "phone",
        dataIndex: "phone",
        title: "Phone Number",
        render: (value: any) => <TextField value={value ? value : ""} />,
      },
      {
        key: "email",
        dataIndex: "email",
        title: `Email Address`,
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "username",
        dataIndex: "username",
        title: `User Name`,
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "department",
        dataIndex: "department",
        title: `Department`,
        render: (value: any) => <TextField value={value ? value.name : ""} />,
      },
      {
        key: "subdepartment",
        dataIndex: "subdepartment",
        title: `Sub-department`,
        render: (value: ISub_Department) => (
          <TextField value={value ? value.name : ""} />
        ),
      },
      {
        key: "isActive",
        title: `Is Active`,
        dataIndex: "isActive",
        render: (value: boolean, record: IRequester) => (
          <Checkbox checked={value} onClick={() => handleActive(record)} />
        ),
      },
    ],
    [handleActive, handleEdit, page]
  );

  let localeNoData = {
    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
  };

  let locale = {
    emptyText: <Result subTitle="Please select requester search" />,
  };

  useEffect(() => {
    setPage(1);
  }, [customerId, departmentId, searchValue]);

  const { openNotificationInfo, contextHolder } = useNotification();

  useEffect(() => {
    if (isShowNotification && requestersData?.total === 0) {
      openNotificationInfo(
        "No data found",
        "There are no results matching Requesters",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  }, [requestersData, isShowNotification]);

  useEffect(() => {
    if (isShowNotificationByDepartment && requestersData?.total === 0) {
      openNotificationInfo(
        "No data found",
        "There are no results matching Requesters",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  }, [requestersData, isShowNotificationByDepartment]);

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Listing</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.requesters")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      {contextHolder}
      <Form layout="vertical" className="filter_requesters">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={isMobile ? 24 : 12}>
            <Form.Item label="Search Customer by Name">
              <AutoComplete
                showSearch
                size="large"
                style={{
                  width: "100%",
                  borderBottom: "1px solid #d9d9d9",
                  borderRadius: "0px",
                }}
                bordered={false}
                options={allCustomerData?.data?.results?.map(
                  (item: IRequester, index: number) => ({
                    value: item.id,
                    label: item.name,
                  })
                )}
                value={customer?.label}
                filterOption={(inputValueCustomers, option: any) =>
                  option?.label
                    ?.toUpperCase()
                    .indexOf(inputValueCustomers.toUpperCase()) !== -1
                }
                onChange={handleChangeCustomer}
                onSelect={handleSelectCustomer}
              >
                <Input
                  suffix={
                    <>
                      {customer?.label && (
                        <CloseOutlined onClick={handleRemoveCustomer} />
                      )}
                      <SearchOutlined />
                    </>
                  }
                  bordered={false}
                />
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={isMobile ? 24 : 12}>
            <Form.Item label="Search Department by Name">
              <AutoComplete
                showSearch
                size="large"
                style={{
                  width: "100%",
                  borderBottom: "1px solid #d9d9d9",
                  borderRadius: "0px",
                }}
                bordered={false}
                options={activeDepartment?.map(
                  (item: ISub_Department, index: number) => ({
                    value: item.id,
                    label: item.name,
                  })
                )}
                value={department?.label}
                filterOption={(inputValueDepartment, option: any) =>
                  option?.label
                    ?.toUpperCase()
                    .indexOf(inputValueDepartment.toUpperCase()) !== -1
                }
                onChange={handleChangeDepartment}
              >
                <Input
                  suffix={
                    <>
                      {department?.label && (
                        <CloseOutlined onClick={handleRemoveDepartment} />
                      )}
                      <SearchOutlined />
                    </>
                  }
                  bordered={false}
                />
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={isMobile ? 24 : 12}>
            <Form.Item label="Search Name">
              <AutoComplete
                dropdownMatchSelectWidth={0}
                size="large"
                style={{
                  width: "100%",
                  borderBottom: "1px solid #d9d9d9",
                  borderRadius: "0px",
                }}
                bordered={false}
                filterOption={(inputValueDepartment, option: any) =>
                  option?.label
                    ?.toUpperCase()
                    .indexOf(inputValueDepartment.toUpperCase()) !== -1
                }
                value={searchValue}
                onChange={handleChangeRequester}
              >
                <Input
                  suffix={
                    <>
                      {searchValue && (
                        <CloseOutlined onClick={handleRemoveRequester} />
                      )}
                      <SearchOutlined />
                    </>
                  }
                  bordered={false}
                />
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={isMobile ? 24 : 12}>
            <Space wrap={isMobile}>
              {customer?.label && (
                <Button onClick={() => handleCreate()} className="btn-create">
                  <PlusSquareOutlined /> Add Requester
                </Button>
              )}
              <Button onClick={handleExportExcel} className="btn-create">
                Excel
              </Button>
              <Button onClick={handleExportCSV} className="btn-create">
                CSV
              </Button>

              <Button onClick={handleExportPDF} className="btn-create">
                PDF
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <CustomModal
        title="Confirmation"
        setIsModalVisible={setIsModalVisibleActive}
        isModalVisible={isModalVisibleActive}
        width={isMobile ? "100%" : "50%"}
      >
        <RequesterActive
          activeSuccess={updateSuccess}
          setIsModalVisible={setIsModalVisibleActive}
          isModalVisible={isModalVisibleActive}
          requester={requester}
        />
      </CustomModal>
      <CustomModal
        title="Requesters Information"
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      >
        <CreateRequesterModal
          createSuccess={updateSuccess}
          customer={customer}
          department={department}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
      </CustomModal>

      <CustomModal
        title="Requesters Information"
        setIsModalVisible={setIsModalVisibleUpdate}
        isModalVisible={isModalVisibleUpdate}
      >
        <UpdateRequestersModal
          updateSuccess={updateSuccess}
          setIsModalVisible={setIsModalVisibleUpdate}
          isModalVisible={isModalVisibleUpdate}
          requester={requester}
        />
      </CustomModal>

      <Table
        locale={customer ? localeNoData : locale}
        scroll={{ x: 800 }}
        dataSource={requestersData?.map((item: IRequester, index: number) => ({
          ...item,
          key: index,
        }))}
        rowKey="id"
        pagination={
          (pageTotal as number) > 10
            ? {
              current: page,
              position: ["bottomRight"],
              total: pageTotal ? pageTotal : 0,
              showSizeChanger: true,
              onChange: handleChangePage,
              showTotal: () => `${pageTotal} total`,
            }
            : false
        }
      >
        {column.map((col) => (
          <Table.Column {...col} />
        ))}
      </Table>
    </List>
  );
};
