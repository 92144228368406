import {
  AutoComplete,
  DateField,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Table,
  TextField
} from "@pankod/refine-antd";
import { useCustom } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { INTERPRETER_API, PAYMENT_REPORT_API } from "api/baseApi";
import { EFormatDateTime, ERateCall } from "constants/constant";
import dayjs from "dayjs";
import useNotification from "hooks/useNotification";
import { IInterpreter } from "interfaces/interpreter";
import { IPaymentReport } from "interfaces/paymentReport";
import { localeInvalid, localeNoData } from "pages/components/EmptyTable";
import React, { useMemo, useRef, useState } from "react";
import { formatNumber, } from "utils";
import { exportToCSV, exportToExcel, exportToPDF } from "utils/excelExporter";

const COL_EXCEL = [
  "Appt Nbr",
  "Call of Date",
  "Duration (s)",
  "Customer Name",
  "Department Name",
  "Sub-department Name",
  "Language",
  "Call Start",
  "Call End",
  "Rate",
  "Ratio",
  "Total ($)",
  "Status",
  "Type of Call",
  "Customer Rating"
];

const COL_PDF = [
  { key: "Appt Nbr" },
  { key: "Date of Call" },
  { key: "Start Time" },
  { key: "End Time" },
  { key: "Status" },
  { key: "Department" },
  { key: "Sub-department" },
  { key: "Interpreter Name" },
  { key: "Language" },
  { key: "Duration (s)" },
  { key: "Total ($)" },
  { key: "Customer Rating" }
];

const { SearchOutlined, CloseOutlined, ExclamationCircleOutlined } = Icons;

export const PaymentReportsList: React.FC = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const [searchInterpreter, setSearchInterpreter] = useState('');
  const [interpreterId, setInterpreterId] = useState('');
  const [page, setPage] = useState(1);
  const { openNotificationInfo, contextHolder } = useNotification();
  const [pageTotal, setPageTotal] = useState(0);

  const { data: interpretersData } = useCustom({
    url: INTERPRETER_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
        search: null,
      },
    },
  });

  const { data: activePaymentReportData } = useCustom({
    url: PAYMENT_REPORT_API,
    method: "get",
    config: {
      query: {
        id: interpreterId,
        page: page,
        limit: 10
      },
    },
    queryOptions: {
      enabled: !!interpreterId,
      onSuccess: (activePaymentReport) => {
        setPageTotal(activePaymentReport?.data?.total);
        if (!activePaymentReport?.data?.results?.length) {
          openNotificationInfo(
            "No data found",
            "No matching results for Payment Report",
            <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
          );
        }
      },
    },
  });

  const viewDataReport = (arrPaymentReport: IPaymentReport[]) => {
    return arrPaymentReport?.map((payment: IPaymentReport) => ({
      "Appt Nbr": payment?.callerId,
      "Call of Date": dayjs(payment?.createdAt).format(EFormatDateTime.DATETIME),
      "Duration (s)": payment?.duration,
      "Customer Name": payment?.customerName,
      "Department Name": payment?.departmentName,
      "Sub-department Name": payment?.subdepartmentName,
      Language: payment?.language,
      "Call Start": dayjs(payment?.startTime).format(EFormatDateTime.DATETIME),
      "Call End": dayjs(payment?.endTime).format(EFormatDateTime.DATETIME),
      Rate: payment?.ratePay,
      Ratio: payment?.ratioPay,
      "Total ($)": payment?.totalPay ? `${formatNumber(payment?.totalPay)}` : 0,
      Status: payment?.status,
      "Type of Call": payment?.media,
    }));
  };

  const handleExportExcel = (payment: IPaymentReport) => {
    return exportToExcel(
      "Payment Report",
      viewDataReport(payment.call),
      COL_EXCEL
    );
  };

  const handleExportCSV = (payment: IPaymentReport) => {
    return exportToCSV(
      viewDataReport(payment.call),
      "Payment Report",
      COL_EXCEL
    );
  };

  const viewDataPDF = (arrPaymentReport: IPaymentReport[]) => {
    return arrPaymentReport?.map((payment: IPaymentReport) => ({
      "Appt Nbr": payment?.callerId,
      "Date of Call": dayjs(payment?.createdAt).format(
        EFormatDateTime.DATETIME
      ),
      "Start Time":
        dayjs(payment?.startTime).format(EFormatDateTime.DATETIME)
      ,
      "End Time":
        dayjs(payment?.endTime).format(EFormatDateTime.DATETIME)
      ,
      Status: payment?.status,
      Department: payment?.departmentName,
      "Sub-department": payment?.subdepartmentName,
      "Requester Name": payment?.requesterName,
      "Interpreter Name": payment?.interpreterName,
      Language: payment?.language,
      "Duration (s)": payment?.duration,
      "Total ($)": payment?.totalPay ? `${formatNumber(payment?.totalPay)}` : 0,
      "Customer Rating": ERateCall[payment?.rating]
    }));
  };

  const handleShowPDF = (payment: IPaymentReport) => {
    const fileName = "Payment Report.pdf";
    const title = payment?.call?.length
      ? `Interpreter Name: ${payment?.call[0]?.interpreterName}`
      : "";
    const date = payment?.createdDate
      ? `Invoice Date: ${dayjs(payment?.createdDate).format("DD/MM/YYYY")}`
      : "";
    const invoiceName = payment?.invoiceInterpreterId
      ? `Invoice#: ${payment?.invoiceInterpreterId}`
      : "";
    exportToPDF({
      columns: COL_PDF,
      data: viewDataPDF(payment.call),
      fileName: fileName,
      name: title,
      date: date,
      nameInvoice: invoiceName,
    });
  };

  const collumns = useMemo(
    () => [
      {
        key: "key",
        title: "Nbr.",
        render: (value: string) => (
          <TextField
            value={
              value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
            }
          />
        ),
      },
      {
        key: "id",
        title: "Print",
        render: (value: string, record: IPaymentReport) => (
          <TextField
            value={value ? "Print" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleShowPDF(record)}
          />
        ),
      },

      {
        key: "id",
        title: "Excel",
        render: (value: string, record: IPaymentReport) => (
          <TextField
            value={value ? "Excel" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleExportExcel(record)}
          />
        ),
      },

      {
        key: "id",
        title: "CSV",
        render: (value: string, record: IPaymentReport) => (
          <TextField
            value={value ? "CSV" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleExportCSV(record)}
          />
        ),
      },

      {
        key: "invoiceInterpreterId",
        title: "Process Number",
        render: (value: number) => <TextField value={value ? value : ""} />,
      },
      {
        key: "createdDate",
        title: `Process Date`,
        render: (value: string) =>
          value ? <DateField format="LLL" value={value ? value : ""} /> : "",
      },
      {
        key: "totalPay",
        title: `Total`,
        render: (value: number) => (
          <TextField value={value ? `$${formatNumber(value)}` : `$0`} />
        ),
      },
    ],
    [page]
  );

  const onChange = (interpreter: string, option: BaseOptionType) => {
    setSearchInterpreter(option.label);
  };

  const onSelect = async (interpreterId: string, option: BaseOptionType) => {
    setPage(1);
    setInterpreterId(interpreterId)
    setSearchInterpreter(option.label);
  };

  const handleRemoveInterpreter = () => {
    setPageTotal(0);
    setSearchInterpreter('');
    setInterpreterId('');
  };


  const handleChange = (page: number) => {
    setPage(page);
  };

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Report</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            Payment Reports
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      {contextHolder}
      <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
        <Form.Item
          label="Search Interpreter by Name"
          className={!isMobile ? "search-item" : ""}
        >
          <AutoComplete
            showSearch
            size="large"
            style={{
              width: "100%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            options={interpretersData?.data?.results?.map(
              (item: IInterpreter, index: number) => ({
                value: item.id,
                label: item.name,
              })
            )}
            filterOption={(
              searchInterpreter,
              option: BaseOptionType | DefaultOptionType | undefined
            ) =>
              option?.label
                ?.toUpperCase()
                .indexOf(searchInterpreter.toUpperCase()) !== -1
            }
            bordered={false}
            value={searchInterpreter}
            onChange={onChange}
            onSelect={onSelect}
          >
            <Input
              suffix={
                <>
                  {searchInterpreter && (
                    <CloseOutlined onClick={handleRemoveInterpreter} />
                  )}
                  <SearchOutlined />
                </>
              }
              bordered={false}
            />
          </AutoComplete>
        </Form.Item>
      </Form>

      <Table
        locale={searchInterpreter ? localeNoData : localeInvalid('Please select payment report')}
        dataSource={interpreterId ? activePaymentReportData?.data?.results?.map(
          (item: IPaymentReport, index: number) => ({
            ...item,
            key: index,
          })
        ) : []}
        rowKey="id"
        pagination={
          pageTotal > 10
            ? {
              total: pageTotal,
              current: page,
              onChange: handleChange,
              showTotal: () => `${pageTotal} total`,
            }
            : false
        }
        scroll={{ x: 800 }}
      >
        {collumns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
    </List>
  );
};
