import { createContext, useState } from "react";

interface IUserContext {
  interpreterId: number;
  customerId: string;
  departmentId: string;
  subDepartmentId: number;
  setInterpreterId: React.Dispatch<React.SetStateAction<number>>;
  setCustomerId: React.Dispatch<React.SetStateAction<string>>;
  setDepartmentId: React.Dispatch<React.SetStateAction<string>>;
  setSubDepartmentId: React.Dispatch<React.SetStateAction<number>>;
}
const UserContextDefault = {
  customerId: "",
  departmentId: "",
  interpreterId: 0,
  subDepartmentId: 0,
  setInterpreterId: () => {},
  setCustomerId: () => {},
  setDepartmentId: () => {},
  setSubDepartmentId: () => {},
};
const UserContext = createContext<IUserContext>(UserContextDefault);

function UserProvider({ children }: any): JSX.Element {
  const [interpreterId, setInterpreterId] = useState(0);
  const [customerId, setCustomerId] = useState<string>("");
  const [departmentId, setDepartmentId] = useState<string>("");
  const [subDepartmentId, setSubDepartmentId] = useState(0);

  const valueProvider = {
    interpreterId,
    setInterpreterId,
    customerId,
    setCustomerId,
    departmentId,
    setDepartmentId,
    subDepartmentId,
    setSubDepartmentId,
  };
  return (
    <UserContext.Provider value={valueProvider}>{children}</UserContext.Provider>
  );
}

export { UserContext, UserProvider };
