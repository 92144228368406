/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";

import {
  useTranslate,
  useNavigation,
  usePermissions,
} from "@pankod/refine-core";
import { Menu, Grid, Icons, useMenu } from "@pankod/refine-antd";
import { EPermissions } from "constants/permission";
import { CollageContext } from "hooks/useContext/useCollagedContext";
import { IResource } from "interfaces";
import { MenuWithItems } from "../MenuItem";

const {
  RightOutlined,
  DashboardOutlined,
  BarcodeOutlined,
  BarChartOutlined,
  ProfileOutlined,
  UnorderedListOutlined,
  StrikethroughOutlined,
  FileZipOutlined,
  UserOutlined,
  HistoryOutlined,
} = Icons;
export const SideMenu = () => {
  const { collapsed, setCollapsed, setIsShowMenu, isShowMenu } =
    useContext(CollageContext);
  const translate = useTranslate();
  const { selectedKey } = useMenu();
  const { push } = useNavigation();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const SubMenu = Menu.SubMenu;
  const { data: permissionsData } = usePermissions();

  const isAdmin = permissionsData?.roleId === EPermissions.ADMIN;
  const isRequester = permissionsData?.roleId === EPermissions.CUSTOMER;
  const isInterpreter = permissionsData?.roleId === EPermissions.INTERPRETER;

  const isSelected = (item: IResource) => item.path === selectedKey;
  const hasParent = (item: IResource, parent: number) => item.parent === parent;
  const hasLevel = (item: IResource, level: number) => item.level === level;
  const getResourceName = (item: IResource) =>
    item.name === `${translate("resource.dashboard")}`
      ? "DASHBOARD"
      : `${translate("resource.history")}`
        ? "HISTORY"
        : item.name;
        
  let parentsMenu = null;

  if (isAdmin) {
    parentsMenu = permissionsData?.permission?.filter((element: IResource) => hasLevel(element, 0)).reduce((obj: {
      [key: string]: boolean;
    }, element: IResource) => {
      let newObj = { ...obj };
      newObj[element.path] = true;
      return newObj;
    }, {})
  }

  return (
    <>
      {isRequester ? (
        <MenuWithItems
          selectedKey={selectedKey}
          items={permissionsData.permission.filter(
            (item: IResource) => item.level === 0
          )}
          iconMappings={{
            [translate("resource.profile")]: <ProfileOutlined />,
            [translate("resource.reportCallRequester")]: <BarChartOutlined />,
            [translate("resource.listCall")]: <BarcodeOutlined />,
            [translate("resource.dashboard")]: <DashboardOutlined />,
          }}
          onMenuClick={push}
          collapsed={collapsed}
        />
      ) : isInterpreter ? (
        <MenuWithItems
          selectedKey={selectedKey}
          items={permissionsData.permission.filter(
            (item: IResource) => item.level === 0
          )}
          iconMappings={{
            [translate("resource.profile")]: <ProfileOutlined />,
            [translate("resource.reportCallInterpreter")]: <BarChartOutlined />,
            [translate("resource.dashboard")]: <DashboardOutlined />,
          }}
          onMenuClick={push}
          collapsed={collapsed}
        />
      ) : null}

      {isAdmin && (
        <Menu
          selectedKeys={[selectedKey]}
          mode="inline"
          onClick={({ key }) => {
            if (!breakpoint.lg) {
              setCollapsed(true);
            }
            push(key as string);
          }}
        >
          {
            permissionsData?.permission
              ?.filter(
                (item: IResource) =>
                  hasLevel(item, 0) && item.path === "dashboard"
              )
              .map((item: IResource) => {
                return (
                  <Menu.Item
                    style={{
                      fontWeight: isSelected(item) ? "bold" : "normal",
                    }}
                    key={item.path}
                    icon={
                      getResourceName(item) === "DASHBOARD" ? (
                        <DashboardOutlined />
                      ) : null
                    }
                    onClick={isMobile ? () => setIsShowMenu(false) : () => { }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {getResourceName(item)}
                      {!collapsed && <RightOutlined />}
                    </div>
                  </Menu.Item>
                )
              })
          }

          {parentsMenu['listings'] && <SubMenu
            title={
              <span>
                <UnorderedListOutlined />
                <span>{translate("resource.configuration")}</span>
              </span>
            }
            key={"listings"}
          >
            {permissionsData?.permission
              ?.filter(
                (item: IResource) => hasLevel(item, 1) && hasParent(item, 2)
              )
              .map((item: IResource) => (
                <Menu.Item
                  style={{
                    fontWeight: isSelected(item) ? "bold" : "normal",
                    ...(collapsed &&
                      !isShowMenu && {
                      background: "white",
                      margin: 0,
                      color: "black",
                    }),
                  }}
                  key={item.path}
                  onClick={isMobile ? () => setIsShowMenu(false) : () => { }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {item.name}
                    {!collapsed && isSelected(item) && <RightOutlined />}
                  </div>
                </Menu.Item>
              ))}
          </SubMenu>
          }
          {permissionsData?.permission
            ?.filter(
              (item: IResource) => hasLevel(item, 0) && item.path === "history"
            )
            .map((item: IResource) => (
              <Menu.Item
                style={{
                  fontWeight: isSelected(item) ? "bold" : "normal",
                }}
                key={item.path}
                icon={
                  getResourceName(item) === "HISTORY" ? (
                    <HistoryOutlined />
                  ) : null
                }
                onClick={isMobile ? () => setIsShowMenu(false) : () => { }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {getResourceName(item)}
                  {!collapsed && <RightOutlined />}
                </div>
              </Menu.Item>
            ))}
          {parentsMenu['invoice'] && <SubMenu
            title={
              <span>
                <StrikethroughOutlined />
                <span>{translate("resource.invoices")}</span>
              </span>
            }
            key={"invoices"}
          >
            {permissionsData?.permission
              ?.filter(
                (item: IResource) => hasLevel(item, 1) && hasParent(item, 10)
              )
              .map((item: IResource) => (
                <Menu.Item
                  style={{
                    fontWeight: isSelected(item) ? "bold" : "normal",
                    ...(collapsed &&
                      !isShowMenu && {
                      background: "white",
                      margin: 0,
                      color: "black",
                    }),
                  }}
                  key={item.path}
                  onClick={isMobile ? () => setIsShowMenu(false) : () => { }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {item.name}
                    {!collapsed && isSelected(item) && <RightOutlined />}
                  </div>
                </Menu.Item>
              ))}
          </SubMenu>}
          {parentsMenu["report"] && <SubMenu
            title={
              <span>
                <FileZipOutlined />
                <span>{translate("resource.reports")}</span>
              </span>
            }
            key={"reports"}
          >
            {permissionsData?.permission
              ?.filter(
                (item: IResource) => hasLevel(item, 1) && hasParent(item, 15)
              )
              .map((item: IResource) => (
                <Menu.Item
                  style={{
                    fontWeight: isSelected(item) ? "bold" : "normal",
                    ...(collapsed &&
                      !isShowMenu && {
                      background: "white",
                      margin: 0,
                      color: "black",
                    }),
                  }}
                  key={item.path}
                  onClick={isMobile ? () => setIsShowMenu(false) : () => { }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {item.name}
                    {!collapsed && isSelected(item) && <RightOutlined />}
                  </div>
                </Menu.Item>
              ))}
          </SubMenu>}
          {parentsMenu["system-administration"] && <SubMenu
            title={
              <span>
                <UserOutlined />
                <span>{translate("resource.systems")}</span>
              </span>
            }
            key={"systems"}
          >
            {permissionsData?.permission
              ?.filter(
                (item: IResource) => hasLevel(item, 1) && hasParent(item, 22)
              )
              .map((item: IResource) => (
                <Menu.Item
                  style={{
                    fontWeight: isSelected(item) ? "bold" : "normal",
                    ...(collapsed &&
                      !isShowMenu && {
                      background: "white",
                      margin: 0,
                      color: "black",
                    }),
                  }}
                  key={item.path}
                  onClick={isMobile ? () => setIsShowMenu(false) : () => { }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {item?.name}
                    {!collapsed && isSelected(item) && <RightOutlined />}
                  </div>
                </Menu.Item>
              ))}
          </SubMenu>}
        </Menu>
      )}
    </>
  );
};
