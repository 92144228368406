import {
  AutoComplete,
  Button,
  Checkbox,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Space,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { useCustom, useNavigation, useTranslate } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { INTERPRETER_API } from "api/baseApi";
import { CustomModal } from "components/Modal/CustomModal";
import { ELanguage, EMediaType } from "constants/constant";
import { InterpreterType } from "constants/users";
import { ISelectItem } from "interfaces";
import { IBillRateReport } from "interfaces/billingReport";
import {
  IInterpreter,
  IInterpreterReq,
  ILanguageInterpreter,
} from "interfaces/interpreter";
import { ILanguage } from "interfaces/languages";
import { axiosInstance } from "providers/axios";
import React, { useEffect, useState } from "react";
import {
  TableColumn,
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "utils/excelExporter";
import "../../../../styles/style.less";
import InterpreterActive from "./active";

const { PlusSquareOutlined, SearchOutlined, CloseOutlined } = Icons;
interface IPayRate {
  spanishAudio?: number;
  spanishVideo?: number;
  allAudio?: number;
  allVideo?: number;
}

const columns: TableColumn[] = [
  { key: "Interpreter Id" },
  { key: "Interpreter Name" },
  { key: "Interpreter Type" },
  { key: "Gender" },
  { key: "Username" },
  { key: "Email" },
  { key: "Phone" },  { key: "Home Address" },
  { key: "Languages" },
  { key: "Pay rate for Spanish Audio" },
  { key: "Pay rate for Spanish Video" },
  { key: "Pay rate for All Audio" },
  { key: "Pay rate for All Video" },
];

const COL_INTERPRETERS_EXCEL = [
  "Interpreter Id",
  "Interpreter Name",
  "Interpreter Type",
  "Gender",
  "Username",
  "Email",
  "Phone",
  "Home Address",
  "Languages",
  "Pay rate for Spanish Audio",
  "Pay rate for Spanish Video",
  "Pay rate for All Audio",
  "Pay rate for All Video"
];


export const ManagerInterpreterList: React.FC = () => {
  const { push } = useNavigation();
  const translate = useTranslate();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const [isModalVisibleActive, setIsModalVisibleActive] = useState(false);
  const [activeInterpreter, setActiveInterpreter] = useState<IInterpreterReq[]>(
    []
  );
  const [searchInterpreter, setSearchInterpreter] = useState<string>("");

  const [detail, setDetail] = useState<IInterpreter>();
  const [page, setPage] = useState<number>(1);

  const { data: interpretersData, refetch } = useCustom({
    url: INTERPRETER_API.getAll,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
        search: searchInterpreter,
      },
    },
    queryOptions: {
      onSuccess: (interpretersData) => {
        setActiveInterpreter(interpretersData?.data?.results);
      },
    },
  });

  const pageTotal = interpretersData?.data.total;

  const column = [
    {
      title: "Nbr.",
      key: "key",
      render: (value: number) => (
        <TextField
          value={
            value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
          }
        />
      ),
    },
    {
      title: `Interpreter`,
      key: `name`,
      render: (value: string, record: IInterpreter) => (
        <TextField
          value={value ? value : ""}
          style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
          onClick={() => edit(record)}
        />
      ),
    },
    {
      title: `Languages`,
      key: "languages",
      render: (value: ISelectItem) => (
        <TextField
          value={
            value
              ? value.map((item: ILanguage) => (
                <div key={item?.id}>{item?.name}</div>
              ))
              : ""
          }
        />
      ),
    },
    {
      title: `Email Address`,
      key: "email",
      render: (value: string) => <TextField value={value ? value : ""} />,
    },
    {
      title: `Is Active`,
      key: "isActive",
      render: (value: boolean, record: IInterpreter) => (
        <Checkbox checked={value} onChange={() => active(record)} />
      ),
    },
  ];

  const edit = (interpreter: IInterpreter) => {
    push(`/interpreter-edit/${interpreter.id}`);
  };

  const active = (interpreter: IInterpreter) => {
    setDetail(interpreter);
    setIsModalVisibleActive(true);
  };

  const handleCreate = () => {
    push(`/interpreter-add`);
  };

  const handleChange = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    refetch();
  }, [isModalVisibleActive, refetch]);

  const onChange = (interpreterName: string, option: {}) => {
    setSearchInterpreter(interpreterName);
  };

  useEffect(() => {
    setPage(1);
  }, [searchInterpreter]);

  const handleRemoveInterpreter = () => {
    setSearchInterpreter("");
  };

  const fetchInterpreterData = async () => {
    const response = await axiosInstance.get(
      INTERPRETER_API.getAll +
      `?page=${page}&limit=&search=${searchInterpreter}`
    );
    return response?.data?.results;
  };


  const customInterpreterData = (interpreterData: IInterpreterReq[]) => {
    return interpreterData?.map((interpreter: IInterpreterReq) => {
      const interpreterType = Object.values(InterpreterType)[interpreter?.intType] || InterpreterType.AGENCY;

      const getLanguages = (languagesList : ILanguageInterpreter[]) => {
        let languages = '';
        const length = languagesList?.length;
        for(let index = 0; index < length; index++){
          if(index === length - 1){
            languages += `${languagesList[index].name}`;
            return languages;
          }
          languages += `${languagesList[index].name}, `;
        }
        return languages;
      }
      
      const payRate: IPayRate | undefined = interpreter?.payRate?.reduce((obj: IPayRate, currentValue: IBillRateReport) => {
        return {
          ...obj,
          [`${ELanguage[currentValue.languageType]}${EMediaType[currentValue.rateTypeId]}`]: currentValue.payRate
        }
      }, {});


      return {
        "Interpreter Id": interpreter?.intId,
        "Interpreter Name": interpreter?.name,
        "Interpreter Type": interpreterType,
        Gender: interpreter?.gender,
        Username: interpreter?.username,
        Email: interpreter?.email,
        Phone: interpreter?.phone,
        "Home Address": `${interpreter?.homeAddress?.streetAddress || ''} ${interpreter?.homeAddress.city || ''} ${interpreter?.homeAddress.zipCode || ''}`,
        Languages: getLanguages(interpreter?.languages),
        "Pay rate for Spanish Audio": payRate?.spanishAudio,
        "Pay rate for Spanish Video": payRate?.spanishVideo,
        "Pay rate for All Audio": payRate?.allAudio,
        "Pay rate for All Video": payRate?.allVideo
      };
    });
  };

  const handleExportExcel = async () => {
    const interpreterData = await fetchInterpreterData();
    exportToExcel(
      "Interpreter-Listing",
      customInterpreterData(interpreterData),
      COL_INTERPRETERS_EXCEL
    );
    customInterpreterData(interpreterData)
  };

  const handleExportCSV = async () => {
    const interpreterData = await fetchInterpreterData();
    exportToCSV(
      customInterpreterData(interpreterData),
      "Interpreter-Listing",
      COL_INTERPRETERS_EXCEL
    );
  };



  const handleExportPDF = async () => {
    const interpreterData = await fetchInterpreterData();
    exportToPDF({
      columns,
      data: customInterpreterData(interpreterData),
      fileName: "Interpreter-Listing.pdf",
      name: "Listing Interpreter",
      nameInvoice: searchInterpreter
        ? `Interpreter Name: ${searchInterpreter ? searchInterpreter : ""}`
        : "",
    });
  };

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Listing</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.interpreter")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      pageHeaderProps={{
        extra: (
          <Space wrap={isMobile}>
            <Button className="btn-create" onClick={handleCreate}>
              <PlusSquareOutlined />{" "}
              {translate("interpreter.label.title.create")}
            </Button>
            <Button className="btn-create" onClick={handleExportExcel}>
              Excel
            </Button>
            <Button className="btn-create" onClick={handleExportCSV}>
              CSV
            </Button>
            <Button className="btn-create" onClick={() => handleExportPDF()}>
              PDF
            </Button>
          </Space>
        ),
      }}
    >
      <Form layout="vertical">
        <Form.Item label="Search Interpreter by Name">
          <AutoComplete
            showSearch
            size="large"
            style={{
              width: isMobile ? "100%" : "50%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            value={searchInterpreter}
            onChange={onChange}
          >
            <Input
              suffix={
                <>
                  {searchInterpreter && (
                    <CloseOutlined onClick={handleRemoveInterpreter} />
                  )}
                  <SearchOutlined />
                </>
              }
              bordered={false}
            />
          </AutoComplete>
        </Form.Item>
      </Form>
      <Table
        scroll={{ x: 800 }}
        dataSource={activeInterpreter?.map(
          (item: IInterpreterReq, index: number) => ({
            ...item,
            key: index,
          })
        )}
        pagination={
          pageTotal > 10
            ? {
              current: page,
              total: pageTotal || 1,
              onChange: handleChange,
              showTotal: () => `${pageTotal} total`,
            }
            : false
        }
      >
        {column.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>

      <CustomModal
        title="Confirmation"
        setIsModalVisible={setIsModalVisibleActive}
        isModalVisible={isModalVisibleActive}
        width={isMobile ? "100%" : "50%"}
      >
        <InterpreterActive
          setIsModalVisible={setIsModalVisibleActive}
          isModalVisible={isModalVisibleActive}
          interpreter={detail}
          refetch={refetch}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>
    </List>
  );
};
