import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { formatTimer } from "utils";

export default function TimeCall() {
  const [timeCall, setTimeCall] = useState('');

  useEffect(() => {
    const startAt = new Date();
    const idInternal = setInterval(() => {
      setTimeCall(formatTimer(dayjs().diff(dayjs(startAt.toISOString())) / 1000))
    }, 1000);
    return () => {
      clearInterval(idInternal);
    }
  }, [])

  return (
    <span>
      {timeCall}
    </span>
  );
}
