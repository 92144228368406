import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  Row,
  useForm,
} from "@pankod/refine-antd";
import { BaseKey, useUpdate } from "@pankod/refine-core";
import { UPDATE_RATE_TYPE_API } from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ICheckboxChangeActive } from "interfaces";
import { IService } from "interfaces/service";
import { useEffect } from "react";
import { openNotification  } from "utils";
import { ServiceProps } from "./interface";

export const ServiceUpdate = (props: ServiceProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const { setIsModalVisible, service, isModalVisible, page, handleChange } =
    props;

  const { form, formProps } = useForm<IService>({
    action: EAction.EDIT,
  });

  const { setFields } = form;

  const { mutate, data: updateData, isLoading } = useUpdate();

  const onFinish = (event: IService) => {
    mutate(
      {
        resource: UPDATE_RATE_TYPE_API,
        id: service?.id as BaseKey,
        values: {
          name: event.name,
          isActive: event.isActive,
        },
      },
      {
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);
        },
      }
    );
  };

  useEffect(() => {
    form.resetFields();
    setFields([
      {
        name: "name",
        value: service?.name,
      },
      {
        name: "isActive",
        value: service?.isActive,
      },
    ]);
  }, [service, form, isModalVisible]);

  useEffect(() => {
    if (updateData?.data.status == 200) {
      handleChange(page);
      form.resetFields();
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
      setIsModalVisible(false);
    }
  }, [updateData, form, setIsModalVisible]);

  const onCheck = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ isActive: true });
    } else {
      form.setFieldsValue({ isActive: false });
    }
  };

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as IService)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message:
                  "Name must start with a letter and can not contain spaces or special characters",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
            initialValue={service?.name}
          >
            <Input maxLength={50} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="
          "
            name="isActive"
            valuePropName="checked"
            initialValue={service?.isActive}
          >
            <Checkbox
              onChange={(event) => {
                onCheck(event);
              }}
            >
              Is Active
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
