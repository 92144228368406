import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Typography,
  Icons,
  Grid,
  useForm,
} from "@pankod/refine-antd";
import { EAction } from "constants/constant";
import { billingLeftTabPane, billingRightTabPane } from "../DataFieldCustomer";
import "../styles.css";
const { Title } = Typography;

const { AudioTwoTone, PercentageOutlined } = Icons;

type BillingTabPaneProps = {
  handleBillRate?: () => void;
  option?: string;
};

export const BillingTabPane = (props: BillingTabPaneProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const { handleBillRate, option } = props;
  return (
    <Form.List name="billingTabPane">
      {(field, { add, remove }) => {
        return (
          <>
            {option === EAction.DETAIL && (
              <Button className="add-bill-rate" onClick={handleBillRate}>
                Bill Rates
              </Button>
            )}
            <Row gutter={16}>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Form.Item
                  label="Tax Rate"
                  name="taxRate"
                  normalize={(value, prevVal, prevVals) => value.trimStart()}
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]*)?$/,
                      message: "Please enter a valid number",
                    },
                  ]}
                >
                  <Input
                    className="input-number"
                    readOnly={option === EAction.DETAIL}
                    prefix={<PercentageOutlined style={{ fontSize: "16px" }} />}
                    max={50}
                    maxLength={10}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Form.Item label="Cancellation Within ">
                  <Input
                    readOnly={option === EAction.DETAIL}
                    size="large"
                    name="cancellationPeriod"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Title level={5} className="title-address">
              Audio
            </Title>
            <Row gutter={16}>
              {billingLeftTabPane.map((item) => (
                <Col
                  span={isMobile ? 24 : 12}
                  key={item.key}
                  className="gutter-row"
                >
                  <Form.Item
                    label={item.label}
                    name={item.nameProp}
                    style={{ marginBottom: "10px" }}
                    initialValue={0}
                    rules={[
                      {
                        pattern: /^-?\d+$/,
                        message: "Please enter a valid number",
                      },
                    ]}
                  >
                    <Input
                      className="input-number"
                      readOnly={option === EAction.DETAIL}
                      max={50}
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>
              ))}
            </Row>

            <Title level={5} className="title-address">
              Video
            </Title>
            <Row gutter={16}>
              {billingRightTabPane.map((item) => (
                <Col
                  span={isMobile ? 24 : 12}
                  key={item.key}
                  className="gutter-row"
                >
                  <Form.Item
                    label={item.label}
                    style={{ marginBottom: "10px" }}
                    name={item.nameProp}
                    rules={[
                      {
                        pattern: /^-?\d+$/,
                        message: "Please enter a valid number",
                      },
                    ]}
                    initialValue={0}
                  >
                    <Input
                      readOnly={option === EAction.DETAIL}
                      max={50}
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </>
        );
      }}
    </Form.List>
  );
};
