import React, { createContext, useContext, useEffect, useRef } from 'react'

interface ISoundCallContext {
    recieveSound: HTMLAudioElement;
    missedSound: HTMLAudioElement;
    requestSound: HTMLAudioElement;
}
const SoundCallContext = createContext<ISoundCallContext>(null!);

export default function SoundCallProvider({ children }: any) {
    const recieveSoundRef = useRef(new Audio("/audio/recieve_call.mp3"));
    const missedSoundRef = useRef(new Audio("/audio/missed_call.mp3"));
    const requestSoundRef = useRef(new Audio("/audio/send_call.mp3"));

    useEffect(()=>{
        recieveSoundRef.current.loop = true;
        requestSoundRef.current.loop = true;
    }, []);

    return (
        <SoundCallContext.Provider value={{ recieveSound: recieveSoundRef.current, missedSound: missedSoundRef.current, requestSound: requestSoundRef.current }}>
            {children}
        </SoundCallContext.Provider>
    )
}

export const useSoundCallContext = () => {
    const context = useContext(SoundCallContext);
    if (!context) {
        throw new Error('useSoundCallContext must be used within a SoundCallProvider');
    }
    return context;
}
