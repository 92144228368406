import TimeCall from "components/TimeCall";
import { EPermissions } from "constants/permission";
import { SocketContext } from "hooks/useContext/useSocketContext";
import { VideoContext } from "hooks/useContext/useVideoContext";
import { useContext, useEffect, useRef, useState } from "react";
import Video, { RemoteParticipant } from "twilio-video";
import Control from "../control";
import Participant from "../participant";
import "../styles.css";

interface IRoom {
  room: Video.Room;
  handleLogout: () => void;
}
const Room = ({ handleLogout }: IRoom) => {
  const { room } = useContext(VideoContext);
  const [participants, setParticipants] = useState<RemoteParticipant[]>(
    Array.from(room.participants.values())
  );
  const user = JSON.parse(localStorage.getItem("user") as string);
  const languageName = useRef(localStorage.getItem("language-name"));
  const interpreterId = useRef(localStorage.getItem("interpreter-id"));

  const socket = useContext(SocketContext);
  const callId = useRef(localStorage.getItem("callId"));
  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };
    const participantDisconnected = (participant: RemoteParticipant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };
    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    if (participants.length < 1) {
      setParticipants(() => {
        const newState: RemoteParticipant[] = [];
        room.participants.forEach((participant) => {
          newState.push(participant);
        });
        return newState;
      });
    }
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  useEffect(() => {
    if (localStorage.getItem("role") === EPermissions.INTERPRETER.toString()) {
      socket.emit("interpreter-join", {
        timeJoin: new Date(),
        userId: user.id,
        callId: callId.current,
      });
    }
  }, []);
  const layoutRoomParticipant = () => {
    switch (participants.length) {
      case 0:
        return "col-12";
      case 1:
        return "col-6";
      case 2:
        return "col-4";
      case 3:
        return "col-3";
      default:
        return "";
    }
  };
  let remoteParticipants;

  if (participants.length > 0) {
    remoteParticipants = participants.map((participant) => {
      return (
        <div
          className={layoutRoomParticipant().toString() + " col-md-12"}
          key={participant.sid}
        >
          <div
            className={
              participants.length === 3
                ? "participant-avatar participant-avatar-max"
                : "participant-avatar"
            }
          >
            <Participant
              key={participant.sid}
              participantLength={participants.length}
              participant={participant}
              room={room}
              handleLogout={handleLogout}
              identity={participant.identity}
            />
            <p
              className={`participant-name participant-name-${layoutRoomParticipant().toString()}`}
            >
              {participant.identity}
            </p>
          </div>
        </div>
      );
    });
  }

  const [isClock, setIsClock] = useState(false);

  useEffect(() => {
    if (participants.length > 0 && !isClock) setIsClock(true);
    else if(participants.length === 0 && !isClock){
      const idTimer = setTimeout(()=>{
         if(participants.length === 0) {
           socket.emit('call-connect', {
             callId : callId.current, 
             roomSid : room.sid
           })
         }
         clearTimeout(idTimer);
      }, 10000);
    }
  }, [participants]);

  return (
    <>
      <div className="room">
        <div
          className={
            participants.length === 3
              ? "remote-participants remote-participants-max"
              : "remote-participants"
          }
        >
          {remoteParticipants}
          <div className={layoutRoomParticipant().toString() + " col-md-12"}>
            <div
              className={
                participants.length === 3
                  ? "participant-avatar participant-avatar-max"
                  : "participant-avatar"
              }
            >
              <Participant
                participantLength={participants.length}
                participant={room.localParticipant}
                room={room}
                handleLogout={handleLogout}
                identity={room.localParticipant.identity}
              />
              <p
                className={`participant-name participant-name-${layoutRoomParticipant().toString()}`}
              >
                {room.localParticipant.identity}
              </p>
            </div>
          </div>
        </div>
        <Control
          handleLogout={handleLogout}
          participantLength={participants.length}
        />
      </div>
      <div className="room-info">
        <p>
          {`${
            interpreterId.current !== null ? interpreterId.current + " | " : ""
          }`}
          {`${
            languageName.current !== null ? languageName.current + " | " : ""
          }`}
          {isClock && <TimeCall />}
        </p>
      </div>
    </>
  );
};

export default Room;
