import {
  Button,
  Icons,
  List,
  Space,
  Table,
  TextField,
} from "@pankod/refine-antd";

import { CustomModal } from "components/Modal/CustomModal";
import React, { useMemo, useState } from "react";

import { GROUP_PERMISSION_API } from "api/baseApi";

import { useCustom, useTranslate } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { EAction } from "constants/constant";
import { IGroupDetail } from "interfaces/group";
import { axiosInstance } from "providers/axios";
import "../../../../styles/style.less";
import { GroupAction } from "./action";

interface IGroup {
  id: number;
  name: string;
}

const { PlusSquareOutlined } = Icons;
export const GroupList: React.FC = () => {
  const [isModalVisibleCreate, setIsModalVisibleCreate] = useState(false);
  const [isModalVisibleUpdate, setIsModalVisibleUpdate] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [detailGroup, setDetailGroup] = useState<IGroupDetail>({
    listPermissions: [],
  });
  const t = useTranslate();

  const { data: groupsData, refetch } = useCustom({
    url: GROUP_PERMISSION_API.getAll,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
      },
    },
  });
  const pageTotal = groupsData?.data.total;
  const handleCreate = () => {
    handleOpenModel();
  };

  const handleOpenModel = () => {
    setIsModalVisibleCreate(!isModalVisibleCreate);
  };
  const update = async (groupData: IGroup) => {
    const dataGroup = await axiosInstance.get(
      GROUP_PERMISSION_API.getOne + "/" + groupData.id
    );
    setDetailGroup(dataGroup.data);
    setIsModalVisibleUpdate(true);
  };
  const collumns = useMemo(
    () => [
      {
        title: "Nbr.",
        key: "key",
        render: (value: number) => (
          <TextField
            value={
              value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
            }
          />
        ),
      },
      {
        title: "Group",
        key: "name",
        render: (value: string, record: IGroup) => (
          <TextField
            value={value ? value : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => update(record)}
          />
        ),
      },
    ],
    [page]
  );

  const handlePageChange = (page: number) => {
    setPage(page);
  };
  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>System Administration</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {t("resource.groups")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      pageHeaderProps={{
        extra: (
          <Space>
            <Button className="btn-create" onClick={handleCreate}>
              <PlusSquareOutlined /> Add New Group
            </Button>
          </Space>
        ),
      }}
    >
      <Table
        style={{ border: "1px solid rgb(195 195 195 / 50%)", borderRadius: 5 }}
        dataSource={groupsData?.data.results.map(
          (value: IGroup, index: number) => ({
            ...value,
            key: index,
          })
        )}
        pagination={
          (pageTotal as number) > 10
            ? {
                position: ["bottomRight"],
                total: pageTotal ? pageTotal : 0,
                onChange: handlePageChange,
                showTotal: () => `${pageTotal} total`,
              }
            : false
        }
        scroll={{ x: 800 }}
      >
        {collumns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>

      <CustomModal
        title="Create Group"
        setIsModalVisible={setIsModalVisibleCreate}
        isModalVisible={isModalVisibleCreate}
      >
        <GroupAction
          setIsModalVisible={setIsModalVisibleCreate}
          refetch={refetch}
          option={EAction.CREATE}
        />
      </CustomModal>
      <CustomModal
        title="Update Group"
        setIsModalVisible={setIsModalVisibleUpdate}
        isModalVisible={isModalVisibleUpdate}
      >
        <GroupAction
          setIsModalVisible={setIsModalVisibleUpdate}
          groupData={detailGroup}
          refetch={refetch}
          option={EAction.EDIT}
        />
      </CustomModal>
    </List>
  );
};
