import { FormListFieldData } from "antd";

export interface ICheckboxType {
  label: string;
  name?: string;
  checked: boolean;
  childrens?: {
    [key: string]: ICheckboxType;
  };
}

export interface IFormFieldCustom extends FormListFieldData {
  nameProp: string;
  label: string;
  required?: boolean;
  message?: string;
  rule?: string;
  type?: string;
}
const detailLeftTabPane: Array<IFormFieldCustom> = [
  {
    name: 0,
    key: 0,
    nameProp: "name",
    label: "Customer Name",
    required: true,
    message: "Please enter customer name",
  },

  {
    name: 1,
    key: 1,
    nameProp: "contactName",
    label: "Contact Name",
    required: false,
  },
];
const detailRightTabPane: Array<IFormFieldCustom> = [
  {
    name: 5,
    key: 5,
    nameProp: "email",
    label: "Email Address",
    required: true,
    message: "Please enter valid email",
    type: "email",
  },
  {
    name: 6,
    key: 6,
    nameProp: "website",
    label: "Website",
    required: false,
  },
  {
    name: 7,
    key: 7,
    nameProp: "accessCode",
    label: "Acess Code",
    required: false,
  },
];
const detailCenterTabPane: Array<IFormFieldCustom> = [
  {
    name: 8,
    key: 8,
    nameProp: "otherRef1",
    label: "Other Reference#1",
    required: false,
  },
  {
    name: 9,
    key: 9,
    nameProp: "otherRef2",
    label: "Other Reference#2",
    required: false,
  },
  {
    name: 10,
    key: 10,
    nameProp: "otherRef3",
    label: "Other Reference#3",
    required: false,
  },
];
const addressLeftTabPane: Array<IFormFieldCustom> = [
  {
    name: 0,
    key: 0,
    nameProp: "address1",
    label: "Address Line 1",
    required: true,
    message: "Please enter address",
  },

  {
    name: 1,
    key: 1,
    nameProp: "address2",
    label: "Address Line 2",
    required: false,
  },
  {
    name: 2,
    key: 2,
    nameProp: "address3",
    label: "Address Line 3",
    required: false,
  },
  {
    name: 3,
    key: 3,
    nameProp: "city",
    label: "City",
    required: true,
    message: "Please enter city",
  },
  {
    name: 4,
    key: 4,
    nameProp: "zipCode",
    label: "Zip Code/Postal Code",
    required: true,
    message: "Please enter zip code/ postal code",
  },
];
const addressRightTabPane: Array<IFormFieldCustom> = [
  {
    name: 5,
    key: 5,
    nameProp: "mAddress1",
    label: "M.Address Line 1",
    required: false,
  },

  {
    name: 6,
    key: 6,
    nameProp: "mAddress2",
    label: "M.Address Line 2",
    required: false,
  },
  {
    name: 7,
    key: 7,
    nameProp: "mAddress3",
    label: "M.Address Line 3",
    required: false,
  },
  {
    name: 8,
    key: 8,
    nameProp: "mCity",
    label: "M.City",
    required: false,
    message: "Please enter city",
  },
  {
    name: 9,
    key: 9,
    nameProp: "mZipCode",
    label: "M.Zip Code/Postal Code",
    required: false,
  },
];
const billingLeftTabPane: Array<IFormFieldCustom> = [
  {
    name: 1,
    key: 1,
    nameProp: "minMinuteBillTelePhone",
    label: "Minimum Minute(s) Billed - Audio",
    required: true,
    message: "Please enter valid minimum minute(s) billed - audio",
  },

  {
    name: 2,
    key: 2,
    nameProp: "nextBillIncreaseTelePhone",
    label: "Next Billing Increment In Minutes - Audio",
    required: true,
    message: "Please enter valid next billing increment in minutes - audio",
  },
];
const billingRightTabPane: Array<IFormFieldCustom> = [
  {
    name: 3,
    key: 3,
    nameProp: "minMinuteBillVideo",
    label: "Minimum Minute(s) Billed - Video",
    required: true,
    message: "Please enter valid minimum minute(s) billed - video",
  },

  {
    name: 4,
    key: 4,
    nameProp: "nextBillIncreaseVideo",
    label: "Next Billing Increment In Minute - Video",
    required: true,
    message: "Please enter valid next billing increment in minute - video",
  },
];

const initCustomerData = {
  name: "",
  email: "",
  isActive: true,
  invoiceStyle: "",
  city: "",
  zipCode: "",
  address1: "",
  stateId: 0,
  minMinuteBillTelePhone: 1,
  nextBillIncreaseTelePhone: 1,
  minMinuteBillVideo: 1,
  nextBillIncreaseVideo: 1,
};
const defaultInvoiceStyleData = [
  { value: "Accounting Unit", label: "Accounting Unit" },
  { value: "Detail", label: "Detail" },
  { value: "Summary", label: "Summary" },
];
const defaultBillItemData = {
  label: "Bill Item",
  checked: false,
  childrens: {
    billItem1: {
      label: "Bill Item 1",
      name: "billItem1",
      checked: false,
    },

    billItem1Subtotal: {
      label: "Bill Item 1 Subtotal",
      name: "billItem1Subtotal",
      checked: false,
    },
    billItem2: {
      label: "Bill Item 2",
      name: "billItem2",

      checked: false,
    },
    billItem2Subtotal: {
      label: "Bill Item 2 Subtotal",
      name: "billItem2Subtotal",

      checked: false,
    },
    billItem3: {
      label: "Bill Item 3",
      name: "billItem3",
      checked: false,
    },
    billItem3Subtotal: {
      label: "Bill Item 3 Subtotal",
      name: "billItem3Subtotal",
      checked: false,
    },
    billItem4: {
      label: "Bill Item 4",
      name: "billItem4",
      checked: false,
    },
    billItem4Subtotal: {
      name: "billItem4Subtotal",
      label: "Bill Item 4 Subtotal",
      checked: false,
    },
    preTaxAmount: {
      label: "Pre-Tax Amount",
      name: "preTaxAmount",
      checked: true,
    },
    tax: {
      label: "Tax",
      name: "tax",
      checked: true,
    },
    billTotalAmount: {
      label: "Bill Total Amount",
      name: "billTotalAmount",
      checked: true,
    },
  },
};

const defaultTimeListData = {
  label: "Time",
  checked: false,
  childrens: {
    startDate: {
      label: "Start Date",
      name: "startDate",
      checked: false,
    },
    startTime: {
      label: "Start Time",
      name: "startTime",
      checked: true,
    },
    finishDate: {
      label: "Finish Date",
      name: "finishDate",
      checked: false,
    },
    finishTime: {
      label: "Finish Time",
      name: "finishTime",
      checked: true,
    },
    duration: {
      label: "Duration",
      name: "duration",
      checked: false,
    },
    connectionTime: {
      label: "Connection Time",
      name: "connectionTime",
      checked: false,
    },
  },
};

export {
  initCustomerData,
  detailLeftTabPane,
  detailRightTabPane,
  detailCenterTabPane,
  addressLeftTabPane,
  addressRightTabPane,
  billingLeftTabPane,
  billingRightTabPane,
  defaultBillItemData,
  defaultTimeListData,
  defaultInvoiceStyleData,
};
