/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  DateField,
  Grid,
  Row,
  Table,
  TextField,
  Typography,
} from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { Space } from "antd";
import { EFormatDateTimeForPDF, EMedia } from "constants/constant";
import { COL_INTERPRETER_INVOICE_PDF, COL_INTERPRETER_INVOICE_XLSX, COL_SUMMARY_PDF } from "constants/fileds";
import dayjs from "dayjs";
import { IProcessDetailCall } from "interfaces/callReport";
import {
  IInterpreterDetail,
  IInterpreterInvoice,
} from "interfaces/interpreterInvoice";
import { useCallback, useMemo } from "react";
import { formatNumber, getDataSummary } from "utils";
import { exportInvoiceToCSV, exportInvoiceToExcel, exportToPDF } from "utils/excelExporter";

const { Title } = Typography;

export interface DetailInvoiceInterpreterProps {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  invoiceInterpreter: IInterpreterDetail[] | undefined;
  interpreterDetail: IInterpreterInvoice | undefined;
}

const DetailModal = (props: DetailInvoiceInterpreterProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const { invoiceInterpreter, interpreterDetail, setIsModalVisible } = props;
  const { push } = useNavigation();


  const handleViewCall = useCallback((call: IInterpreterInvoice) => {
    push(`/appointment-detail-interpreter/${call?.callId}`);
  }, [])


  const column = useMemo(
    () => [
      {
        key: "callerId",
        title: "Call Number",
        render: (value: string, record: IInterpreterInvoice) => (
          <>
            <TextField
              onClick={() => handleViewCall(record)}
              value={value ? value : ""}
              style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            />
          </>
        ),
      },

      {
        key: "createdAt",
        title: "Date of Service",
        render: (value: string) =>
          value ? <DateField format="LLL" value={value ? value : ""} /> : "",
      },

      {
        key: "totalPay",
        title: "Total",
        render: (value: number) =>
          value ? <TextField value={`$${formatNumber(value)}` || 0} /> : `$0`,
      },

      {
        key: "createdBy",
        title: "Created By",
        render: (value: IProcessDetailCall) => (
          //<TextField value={value ? value?.createdBy : ""} />
          <></>
        ),
      },

      {
        key: "createdDate",
        title: "Created Date",
        render: (value: string) =>
          value ? <DateField format="LLL" value={value ? value : ""} /> : "",
      },
    ],
    []
  );


  const recordListCall = invoiceInterpreter?.map(
    (process: IInterpreterDetail) => ({
      "Appt ID": process?.callerId,
      "Invoice Create Date": dayjs(process?.createdAt).format(
        EFormatDateTimeForPDF.DATE
      ),
      "Interpreter ID": process?.intId,
      "Interpreter Name": process?.interpreterName,
      "Bus Phone": process?.busPhone,
      "Cell Phone": process?.cellPhone,
      "Fax Number": process?.faxNumber,
      "Customer Name": process?.customerName,
      "Customer Email": process?.emailCustomer,
      "Department Name": process?.departmentName,
      "Sub-Department Name": process?.subdepartmentName,
      "Requester Name": process?.requesterName,
      "Requester Email": process?.requesterEmail,
      Language: process?.language,
      "Start Time": process?.startTime
        ? dayjs(process?.startTime).format(EFormatDateTimeForPDF.TIME)
        : "",
      "Finish Time": process?.endTime
        ? dayjs(process?.endTime).format(EFormatDateTimeForPDF.TIME)
        : "",
      "Start Date": process?.startTime
        ? dayjs(process?.startTime).format(EFormatDateTimeForPDF.DATE)
        : "",
      "Finish Date": process?.endTime
        ? dayjs(process?.endTime).format(EFormatDateTimeForPDF.DATE)
        : "",
      "Duration (s)": process?.duration,
      "Connection Time": process?.connectTime,
      "Pay Total Amount ($)": process?.totalPay
        ? formatNumber(process?.totalPay)
        : "",
      "Type of Call":
        process?.media === EMedia.VIDEO ? EMedia.VIDEO : EMedia.AUDIO,
      Status: process?.status,
      "Other Ref1": process?.otherRef1,
      "Other Ref2": process?.otherRef2,
      "Other Ref3": process?.otherRef3,
      "LEP 1 Full Name": process?.lep1FullName,
      "LEP 2 Full Name": process?.lep2FullName,
      Notepad: process?.note,
    })
  );

  const handleExportExcel = () => {
    exportInvoiceToExcel( {
        fileName :  "Interpreter Invoice",
        dataInvoice :   recordListCall as unknown as IInterpreterInvoice[], 
        headerColInvoice :  COL_INTERPRETER_INVOICE_XLSX,
        headerColSummary : ['Items', 'Subtotal'], 
        dataSummary : getDataSummary(invoiceInterpreter).summaryRecords, 
        totalPay : Number(getDataSummary(invoiceInterpreter).totalAmounts)
      })
  };

  const handleExportCSV = () => {
    exportInvoiceToCSV({
      fileName :  "Interpreter Invoice",
      dataInvoice :   recordListCall as unknown as IInterpreterInvoice[], 
      headerColInvoice :  COL_INTERPRETER_INVOICE_XLSX,
      headerColSummary : ['Items', 'Subtotal'], 
      dataSummary : getDataSummary(invoiceInterpreter).summaryRecords, 
      totalPay : Number(getDataSummary(invoiceInterpreter).totalAmounts)
    })
  };

  const dateRange = `Period: ${dayjs(interpreterDetail?.from_date).format(
    "DD/MM/YYYY"
  )} - ${dayjs(interpreterDetail?.to_date).format("DD/MM/YYYY")}`;


  const handleExportPDF = () => {
    setIsModalVisible(false);
    exportToPDF({
      columns: COL_INTERPRETER_INVOICE_PDF,
      data: recordListCall,
      fileName: "Interpreter Invoice.pdf",
      name: interpreterDetail?.interpreter?.firstname
        ? `Interpreter Name: ${interpreterDetail?.interpreter?.firstname +
        " " +
        interpreterDetail?.interpreter?.lastname
        }`
        : "",
      date: dateRange,
      colSummary: COL_SUMMARY_PDF,
      dataSummary: getDataSummary(invoiceInterpreter).summaryRecords,
      totalSummary: Number(getDataSummary(invoiceInterpreter).totalAmounts)
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="title-process-detail">
        <Row>
          <Title level={5}>
            <span className="title-invoice">Interpreter Name: </span>{" "}
            <b>
              {interpreterDetail?.interpreter?.firstname +
                " " +
                interpreterDetail?.interpreter?.lastname}
            </b>
          </Title>
        </Row>
        <Row>
          <Title level={5}>
            <span className="title-invoice">Call: </span>{" "}
            <b>{invoiceInterpreter?.length} Records(s) Found</b>
          </Title>
        </Row>
      </div>{" "}
      <Table
        dataSource={invoiceInterpreter?.map(
          (item: IInterpreterDetail, index: number) => ({
            ...item,
            key: index,
          })
        )}
        pagination={false}
        scroll={{ x: 800 }}
      >
        {column.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
      <Space style={{ marginTop: 10 }} wrap={isMobile}>
        <Button className="btn-create" onClick={handleExportExcel}>
          Excel
        </Button>
        <Button className="btn-create" onClick={handleExportCSV}>
          CSV
        </Button>
        <Button className="btn-create" onClick={handleExportPDF}>
          PDF
        </Button>
      </Space>
    </div>
  );
};

export default DetailModal;
