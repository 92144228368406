/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useCreate, useCustom } from "@pankod/refine-core";
import {
  Form,
  Input,
  Select,
  useForm,
  Button,
  Typography,
  Col,
  Row,
  Checkbox,
  Grid,
} from "@pankod/refine-antd";

import "../../../../styles/style.less";
import { CREATE_USER_API, PROFILE_API } from "api/baseApi";
import { IUser } from "interfaces/users";
import { ICheckboxChangeActive } from "interfaces";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { openNotification} from "utils";
import { UserProps } from "./interface";

export const UserCreate = (props: UserProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const { setIsModalVisible, page, handleChange } = props;
  const { Option } = Select;

  const [messageErr, setMessageErr] = useState<IUser>();

  const { formProps, form } = useForm<IUser>({
    action: EAction.CREATE,
  });

  const { data: profile } = useCustom({
    url: PROFILE_API,
    method: "get",
  });

  const { mutate, data: createData, isLoading } = useCreate();

  const onFinish = (event: IUser) => {
    mutate(
      {
        resource: CREATE_USER_API,
        values: {
          username: event.username,
          firstname: event.firstname,
          lastname: event.lastname,
          phone: event.phone,
          email: event.email,
          faxNumber: Number(event.faxNumber),
          roleId: event.roleId,
          isActive: event.isActive,
          createdBy: profile?.data?.username,
        },
      },
      {
        onError: (error) => {
          
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  const onCheckIsActive = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ isActive: true });
    } else {
      form.setFieldsValue({ isActive: false });
    }
  };

  useEffect(() => {
    if (createData?.data.status === 201) {
      form.resetFields();
      handleChange(page);
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
      setIsModalVisible(false);
      setMessageErr(messageErr);
    }
  }, [createData, form, setIsModalVisible]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as IUser)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="User Name"
            name="username"
            rules={[
              {
                required: true,
                message:
                  "User Name must start with a letter and can not contain spaces or special characters",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input size="large" maxLength={50} />
          </Form.Item>
          {messageErr?.username && (
            <Typography.Text type="danger">
              {messageErr.username[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input size="large" maxLength={50} />
          </Form.Item>
          {messageErr?.email && (
            <Typography.Text type="danger">
              {messageErr.email[0]}
            </Typography.Text>
          )}
          <Row gutter={16}>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  { required: true, message: "Please enter phone number" },
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Must contain 10 numbers only",
                  },
                ]}
                normalize={(value, prevVal, prevVals) => value.trim()}
              >
                <Input size="large" maxLength={10} />
              </Form.Item>
              {messageErr?.phone && (
                <Typography.Text type="danger">
                  {messageErr.phone[0]}
                </Typography.Text>
              )}
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              <Form.Item
                label="Ext"
                name="faxNumber"
                rules={[
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Must contain maximum 5 numbers",
                  },
                ]}
                normalize={(value, prevVal, prevVals) => value.trim()}
              >
                <Input size="large" maxLength={5} />
              </Form.Item>
              {messageErr?.faxNumber && (
                <Typography.Text type="danger">
                  {messageErr.faxNumber}
                </Typography.Text>
              )}
            </Col>
          </Row>
          <Form.Item
            label="User Role"
            name="roleId"
            rules={[
              {
                required: true,
                message: "Please enter role",
              },
            ]}
          >
            <Select size="large" placeholder="Select role">
              <Option value={1}>Admin</Option>
              <Option value={0}>Agency</Option>
            </Select>
          </Form.Item>
          {messageErr?.roleId && (
            <Typography.Text type="danger">{messageErr.roleId}</Typography.Text>
          )}
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="First Name"
            name="firstname"
            rules={[
              {
                required: true,
                message: "Please enter first name",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input size="large" maxLength={50} />
          </Form.Item>
          {messageErr?.firstname && (
            <Typography.Text type="danger">
              {messageErr.firstname[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="Last Name"
            name="lastname"
            rules={[
              {
                required: true,
                message: "Please enter last name",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input size="large" maxLength={50} />
          </Form.Item>
          {messageErr?.lastname && (
            <Typography.Text type="danger">
              {messageErr.lastname[0]}
            </Typography.Text>
          )}

          <Form.Item
            label="
          "
            name="isActive"
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox
              onChange={(event) => {
                onCheckIsActive(event);
              }}
            >
              Is Active
            </Checkbox>
          </Form.Item>
          {messageErr?.isActive && (
            <Typography.Text type="danger">
              {messageErr.isActive}
            </Typography.Text>
          )}
        </Col>
      </Row>

      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
