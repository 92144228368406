import { createContext } from "react";
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_API_PROXY || "", {
  transports: ["websocket"],
  withCredentials: true,
  query: {
    token: localStorage.getItem("token"),
    platform: "BROWSER",
  },
});

const SocketContext = createContext(socket);

function SocketProvider({ children }: any): JSX.Element {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
}

export { SocketContext, SocketProvider, socket };

