import AudioTrack from "components/AudioTrack";
import VideoTrack from "components/VideoTrack";
import useTrack from "hooks/useTrack";
import {
  AudioTrack as IAudioTrack,
  LocalTrackPublication,
  Participant,
  RemoteTrackPublication,
} from "twilio-video";
import { IVideoTrack } from "types";
import "../styles.css";

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
}

export default function Publication({ publication }: PublicationProps) {
  const track = useTrack(publication);
  if (!track) return null;

  switch (track.kind) {
    case "video":
      return (
        <VideoTrack
          track={track as IVideoTrack}
          // priority={videoPriority}
          // isLocal={track.name.includes('camera') && isLocal}
        />
      );
    case "audio":
      return <AudioTrack track={track as IAudioTrack} />;
    default:
      return null;
  }
}
