import {
  Button,
  Checkbox,
  Form,
  Grid,
  Input,
  Select,
  useForm,
} from "@pankod/refine-antd";
import { BaseKey, useCustom, useUpdate } from "@pankod/refine-core";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { RATE_TYPE_API, UPDATE_BILL_RATE_API } from "api/baseApi";
import { NumericInput } from "components/InputNumber";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ICheckboxChangeActive } from "interfaces";
import { IService } from "interfaces/service";
import { IStandard } from "interfaces/standard";
import { useEffect, useState } from "react";
import { openNotification} from "utils";

type StandardBillRatesUpdateProps = {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  billRate: IStandard | undefined;
  page: number;
  handleChange: (page: number) => void;
};
const { Option } = Select;
export const StandardBillRatesUpdate = (
  props: StandardBillRatesUpdateProps
) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const { setIsModalVisible, billRate, isModalVisible, page, handleChange } =
    props;
  const [valueBillRate, setValueBillRate] = useState("");
  const { form, formProps } = useForm<IStandard>({
    action: EAction.EDIT,
  });

  const { data: servicesData } = useCustom({
    url: RATE_TYPE_API.getAll,
    method: "get",
    config: {
      query: {
        page: "",
        limit: "",
        search: "",
      },
    },
  });

  const { setFields } = form;

  const { mutate, data: updateData, isLoading } = useUpdate();

  const onFinish = (event: IStandard) => {
    mutate(
      {
        resource: UPDATE_BILL_RATE_API,
        id: billRate?.id as BaseKey,
        values: {
          rateTypeId: event.rateTypeId,
          billRate: event.billRate,
          customerId: event.customerId,
          languageType: event.languageType,
          isActive: event.isActive,
        },
      },
      {
        onError: (error) => {
          
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  const onCheck = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ isActive: true });
    } else {
      form.setFieldsValue({ isActive: false });
    }
  };

  useEffect(() => {
    form.resetFields();
    setFields([
      {
        name: "rateTypeId",
        value: billRate?.rateTypeId,
      },
      {
        name: "billRate",
        value: billRate?.billRate,
      },
      {
        name: "customerId",
        value: billRate?.customerId,
      },
      {
        name: "isActive",
        value: billRate?.isActive,
      },
    ]);
  }, [billRate, form, isModalVisible]);

  useEffect(() => {
    if (updateData?.data.status == 200) {
      form.resetFields();
      handleChange(page);
        openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
      setIsModalVisible(false);
    }
  }, [updateData, form, setIsModalVisible]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => {
        onFinish(event as IStandard);
      }}
    >
      <Form.Item
        label="Type of Service"
        name="rateTypeId"
        rules={[
          {
            required: true,
            message: "Please enter Type of Service",
          },
        ]}
        initialValue={billRate?.rateTypeId}
      >
        <Select
          showSearch
          style={{ width: "100%" }}
          optionFilterProp="children"
          filterOption={(
            input,
            option: BaseOptionType | DefaultOptionType | undefined
          ) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {servicesData?.data?.results?.map((item: IService, index: number) => (
            <Select.Option key={index} value={item.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Rate (per minute)"
        name="billRate"
        initialValue={billRate?.billRate}
        rules={[
          {
            required: true,
            pattern: /^-?\d*(\.\d*)?$/,
            message: "Please enter a valid number",
          },
        ]}
        normalize={(value, prevVal, prevVals) => value.trim()}
      >
        {/* <NumericInput
          value={valueBillRate}
          onChange={(e) => setValueBillRate(e)}
        /> */}
        <Input maxLength={50} />
      </Form.Item>
      <Form.Item
        label="Language"
        name="languageType"
        rules={[
          {
            required: true,
            message: "Please enter language",
          },
        ]}
        initialValue={billRate?.languageType}
      >
        <Select placeholder="language">
          <Option value={0}>All other languages</Option>
          <Option value={1}>American Sign Language</Option>
          <Option value={2}>Spanish</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="isActive"
        valuePropName="checked"
        initialValue={billRate?.isActive}
      >
        <Checkbox
          onChange={(event) => {
            onCheck(event);
          }}
        >
          Is Active
        </Checkbox>
      </Form.Item>

      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
