import {
  Button,
  Col,
  Icons,
  Input,
  Row,
  Typography,
} from "@pankod/refine-antd";
import "../styles.css";

interface INotePad {
  message: string;
  setMessage: (e: string) => void;
  onCreateNotePad: () => void;
}

export const Notepad = ({ message, setMessage, onCreateNotePad }: INotePad) => (
  <>
    <Typography.Title level={3}>Notepad</Typography.Title>
    <Row style={{ marginTop: "15px" }}>
      <Col span={24}>
        <Typography.Paragraph>
          <Input.TextArea
            size="large"
            required
            value={message}
            onChange={event => setMessage(event.target.value)}
            placeholder="Enter note"
            rows={5}
          />
        </Typography.Paragraph>
        <Typography.Paragraph
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button onClick={onCreateNotePad} className="btn-create">
            <Icons.SendOutlined /> Send
          </Button>
        </Typography.Paragraph>
      </Col>
    </Row>
  </>
)
