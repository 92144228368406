import { useEffect, useState } from "react";
import { useCreate } from "@pankod/refine-core";
import {
  Form,
  Input,
  useForm,
  Button,
  Row,
  Col,
  Checkbox,
  Grid,
} from "@pankod/refine-antd";
import { ILanguage } from "interfaces/languages";
import "../../../../styles/style.less";
import { CREATE_LANGUAGES_API } from "api/baseApi";
import { ICheckboxChangeActive } from "interfaces";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { openNotification} from "utils";
import { LanguageProps } from "./interface";

export const LanguagesCreate = (props: LanguageProps) => {
  const { setIsModalVisible, page, handleChange } = props;
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const { formProps, form } = useForm<ILanguage>({
    action: EAction.CREATE,
  });

  const { mutate, data: createData, isLoading } = useCreate();

  const onFinish = (event: ILanguage) => {
    mutate(
      {
        resource: CREATE_LANGUAGES_API,
        values: {
          name: event.name.trim(),
          isActive: event.isActive,
          telephone: event.telephone,
          video: event.video,
        },
      },
      {
        onError: (error) => {
          
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  const onCheck = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ isActive: true });
    } else {
      form.setFieldsValue({ isActive: false });
    }
  };
  const onCheckTelephone = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ telephone: true });
    } else {
      form.setFieldsValue({ telephone: false });
    }
  };
  const onCheckVideo = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ video: true });
    } else {
      form.setFieldsValue({ video: false });
    }
  };

  useEffect(() => {
    if (createData?.data.status === 200) {
      form.resetFields();
      handleChange(page);
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
      setIsModalVisible(false);
    }
  }, [createData, form, setIsModalVisible]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as ILanguage)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
            normalize={(value, _prevVal, _prevVals) => value.trimStart()}
          >
            <Input size="large" maxLength={50} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={12}>
          <Form.Item
            label="
          "
            name="isActive"
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox
              onChange={(event) => {
                onCheck(event);
              }}
            >
              Is Active
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 12 : 4}>
          <Form.Item
            name="telephone"
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox
              onChange={(event) => {
                onCheckTelephone(event);
              }}
            >
              Audio
            </Checkbox>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={isMobile ? 12 : 4}>
          <Form.Item name="video" valuePropName="checked" initialValue={true}>
            <Checkbox
              onChange={(event) => {
                onCheckVideo(event);
              }}
            >
              Video
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
