import { Button, DateField, List, Table, TextField } from "@pankod/refine-antd";
import React, { useMemo, useState } from "react";
import "../../../../styles/style.less";
import { useCustom, useNavigation, useTranslate } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { INVOICE_PROCESS_CUSTOMER_API } from "api/baseApi";
import { ICustomerInvoice } from "interfaces/customerInvoice";

export const ViewCustomerInvoiceList: React.FC = () => {
  const translate = useTranslate();
  const { push } = useNavigation();
  const [activeCustomerInvoice, setActiveCustomerInvoice] = useState<
    ICustomerInvoice[]
  >([]);

  const [page, setPage] = useState<number>(1);
  const { data: customersData, refetch } = useCustom({
    url: INVOICE_PROCESS_CUSTOMER_API.getAll,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
      },
    },
    queryOptions: {
      onSuccess: (customersData) => {
        setActiveCustomerInvoice(customersData?.data?.results);
      },
    },
  });

  const handleViewCusInvoice = (customer: ICustomerInvoice) => {
    push(`/view-invoice-customer/${customer?.id}`);
  };
  const collumns = useMemo(
    () => [
      {
        key: "key",
        title: "Id",
        render: (value: string) => (
          <TextField
            value={
              value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
            }
          />
        ),
      },
      {
        key: "id",
        title: "Process Nbr",
        render: (value: string, record: ICustomerInvoice) => (
          <TextField
            value={value ? value : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleViewCusInvoice(record)}
          />
        ),
      },
      {
        key: "customerName",
        title: "Customer's Name",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },

      {
        key: "fromDate",
        title: "From Date",
        render: (value: string) => (
          <DateField format="LL" value={value ? value : ""} />
        ),
      },

      {
        key: "toDate",
        title: "To Date",
        render: (value: string) => (
          <DateField format="LL" value={value ? value : ""} />
        ),
      },

      {
        key: "createBy",
        title: `Created By`,
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "createDate",
        title: `Date`,
        render: (value: string) => (
          <DateField format="LL" value={value ? value : ""} />
        ),
      },
    ],
    [page]
  );

  const pageTotal = customersData?.data.total;
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleBackPage = () => {
    push("/invoice/process-customer-invoice");
  };

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Invoice</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.viewPrintInvoiceCustomer")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      <Table
        dataSource={activeCustomerInvoice?.map(
          (item: ICustomerInvoice, index: number) => ({
            ...item,
            key: index,
          })
        )}
        pagination={
          (pageTotal as number) > 10
            ? {
                current: page,
                total: pageTotal ? pageTotal : 1,
                onChange: handlePageChange,
                showTotal: () => `${pageTotal} total`,
              }
            : false
        }
        scroll={{ x: 900 }}
      >
        {collumns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
      <Button className="process back" onClick={handleBackPage}>
        Back
      </Button>
    </List>
  );
};
