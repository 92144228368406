import {
  Button,
  DatePicker,
  Form,
  Grid,
  List,
  Space,
  Spin,
  Table,
  TextField,
} from "@pankod/refine-antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ICallReportRequester } from "interfaces/callReport";
import { useCustom } from "@pankod/refine-core";
import { CALL_API } from "api/baseApi";
import dayjs, { Dayjs } from "dayjs";
import { EFormatDateTime, EMedia } from "constants/constant";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
  TableColumn,
} from "utils/excelExporter";
import { axiosInstance } from "providers/axios";

const COL_REPORT_XLSX = [
  "Appt ID",
  "Customer Name",
  "Customer Email",
  "Department",
  "Sub-department",
  "Requester Name",
  "Requester Email",
  "Type of Call",
  "Language",
  "Interpreter ID",
  "Date of Call",
  "Time Start",
  "Time Finish",
  "Duration",
  "Connection Time",
  "Bill Rate",
  "Total billed ($)",
  "Other Reference 1",
  "Other Reference 2",
  "Other Reference 3",
  "NotePad",
];

const columns: TableColumn[] = [
  { key: "Appt ID" },
  { key: "Type of Call" },
  { key: "Language" },
  { key: "Interpreter ID" },
  { key: "Date of Call" },
  { key: "Time Start" },
  { key: "Time Finish" },
  { key: "Duration" },
  { key: "Connection Time" },
  { key: "Bill Rate" },
  { key: "Total billed ($)" },
  { key: "Other Reference 1" },
  { key: "Other Reference 2" },
  { key: "Other Reference 3" },
];

export const DetailCallReportRequester: React.FC = () => {
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.sm;
  const user = JSON.parse(localStorage.getItem("user") || "");

  const requesterName = user?.firstname + " " + user?.lastname;

  const [activeHistoryCallRequester, setActiveHistoryCallRequester] = useState<
    ICallReportRequester[]
  >([]);

  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const { data: reportCallsData } = useCustom({
    url: CALL_API.getCallUser,
    method: "get",
    config: {
      query: {
        page: page,
        limit: isSearch ? null : 10,
        start: isSearch
          ? startDate
            ? dayjs(startDate).format(EFormatDateTime.DATE)
            : ""
          : "",
        end: isSearch
          ? endDate
            ? dayjs(endDate).format(EFormatDateTime.DATE)
            : ""
          : "",
      },
    },
    queryOptions: {
      onSuccess: (historyCall) => {
        setActiveHistoryCallRequester(historyCall?.data?.results);
      },
    },
  });

  const handleStartDate = (startDate: Dayjs | null) => {
    if (startDate) {
      setStartDate(startDate);
      setIsSearch(true);
    } else {
      setStartDate("");
    }
  };

  const handleEndDate = (endDate: Dayjs | null) => {
    if (endDate) {
      setEndDate(endDate);
      setIsSearch(true);
    } else {
      setEndDate("");
    }
  };

  const pageTotal = reportCallsData?.data?.total;
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const collumns = useMemo(
    () => [
      {
        title: "Appt ID",
        key: "callerId",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        title: "Type of Call",
        key: "media",
        render: (value: string) => (
          <TextField
            value={value === EMedia.VIDEO ? EMedia.VIDEO : EMedia.AUDIO}
          />
        ),
      },
      {
        title: "Language",
        key: "language",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        title: "Interpreter ID",
        key: "intId",
        render: (value: string) =>
          value ? <TextField value={value ? value : ""} /> : "",
      },
      {
        title: "Date of Call",
        key: "createdAt",
        render: (value: string) =>
          value ? (
            <TextField
              value={value ? dayjs(value).format(EFormatDateTime.DATETIME) : ""}
            />
          ) : (
            ""
          ),
        width: 150,
      },
      {
        title: "Call Start",
        key: "startTime",
        render: (value: string) =>
          value ? (
            <TextField
              value={value ? dayjs(value).format(EFormatDateTime.DATETIME) : ""}
            />
          ) : (
            ""
          ),
        width: 150,
      },
      {
        title: "Call End",
        key: "endTime",
        render: (value: string) =>
          value ? (
            <TextField
              value={value ? dayjs(value).format(EFormatDateTime.DATETIME) : ""}
            />
          ) : (
            ""
          ),
        width: 150,
      },

      {
        title: "Duration",
        key: "duration",
        render: (value: number) => <TextField value={value ? value : ""} />,
      },
      {
        title: "Connection Time",
        key: "connectTime",
        render: (value: number) => <TextField value={value ? value : ""} />,
      },
      {
        title: "Total billed ($)",
        key: "totalBill",
        render: (value: number) => (
          <TextField value={value ? `$${value?.toFixed(2)}` : "$0"} />
        ),
      },
    ],
    [page]
  );

  const customFiledHistory = (callReport: ICallReportRequester[]) => {
    return callReport?.map((report: ICallReportRequester) => ({
      "Appt ID": report?.callerId,
      "Customer Name": report?.customerName,
      "Customer Email": report?.emailCustomer,
      Department: report?.departmentName,
      "Sub-department": report?.subdepartmentName,
      "Requester Name": report?.requesterName,
      "Requester Email": report?.requesterEmail,
      "Type of Call": report?.media === EMedia.VIDEO ? EMedia.VIDEO : EMedia.AUDIO,
      Language: report?.language,
      "Interpreter ID": report?.intId,
      "Date of Call": report.createdAt
        ? dayjs(report?.createdAt).format(EFormatDateTime.DATETIME)
        : "",
      "Time Start": report.startTime
        ? dayjs(report.startTime).format(EFormatDateTime.DATETIME)
        : "",
      "Time Finish": report.endTime
        ? dayjs(report.endTime).format(EFormatDateTime.DATETIME)
        : "",
      Duration: report?.duration,
      "Connection Time": report?.connectTime,
      "Bill Rate": report?.rateBill,
      "Total billed ($)": report?.totalBill?.toFixed(2),
      "Other Reference 1": report?.note?.otherRef1,
      "Other Reference 2": report?.note?.otherRef2,
      "Other Reference 3": report?.note?.otherRef3,
      NotePad: report?.notePad?.replace(/\n/g, ", "),
    }));
  };



  const fetchData = async () => {
    setIsLoading(true);
    setIsSearch(true);
    const response = await axiosInstance.get(
      CALL_API.getCallUser +
      `?page=${page}&limit=&start=${startDate ? dayjs(startDate).format(EFormatDateTime.DATE) : ""
      }&end=${endDate ? dayjs(endDate).format(EFormatDateTime.DATE) : ""}`
    );
    setIsLoading(false);
    return response?.data?.results;
  };
  const dateRange =
    startDate || endDate
      ? `Date Range: ${dayjs(startDate)?.format("DD/MM/YYYY")} - ${dayjs(
        endDate
      )?.format("DD/MM/YYYY")}`
      : "";

  const handleExportPDF = async () => {
    setPage(1);
    const callReportData = await fetchData();
    exportToPDF({
      columns,
      data: customFiledHistory(callReportData),
      fileName: "Call Report Requester.pdf",
      name: "Summary Report",
      date: dateRange,
      nameInvoice: `Requester Name: ${requesterName}`,
      totalRow: `Total Number of Appointments: ${callReportData.length}`,
      totalAmounts: Number(getTotalAmounts(callReportData)),
    });
  };

  const getTotalAmounts = useCallback(
    (callReportsData: any) => {
      return Number((callReportsData.reduce((total: number, currentValue: any) => {
        return total + currentValue.totalPay
      }, 0))).toFixed(2);
    },
    [],
  )

  const handleExportExcel = async () => {
    setPage(1);
    const callReportData = await fetchData();
    exportToExcel(
      "Call Report Requester",
      customFiledHistory(callReportData),
      COL_REPORT_XLSX,
      `Total Number of Appointments: ${callReportData.length}`, `Total Amounts: $${getTotalAmounts(callReportData)}`
    );
  };

  const handleExportCSV = async () => {
    setPage(1);
    const callReportData = await fetchData();
    exportToCSV(
      customFiledHistory(callReportData),
      "Call Report Requester",
      COL_REPORT_XLSX,
      `Total Number of Appointments: ${callReportData.length}`,
      `Total Amounts: $${getTotalAmounts(callReportData)}`
    );
  };

  useEffect(() => {
    setPage(1);
  }, [startDate, endDate]);

  return (
    <Spin spinning={isLoading} size="large">
      <List title="Call Report">
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Start Date"
            name="start"
            className={!isMobile ? "search-item" : ""}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={startDate}
              onChange={(e) => handleStartDate(e)}
              disabledDate={(current) => {
                return endDate ? current && current > endDate : false;
              }}
            />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="end"
            className={!isMobile ? "search-item" : ""}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={endDate}
              disabledDate={(current) => {
                return current && current < startDate;
              }}
              onChange={(e) => handleEndDate(e)}
            />
          </Form.Item>
        </Form>
        <Space wrap={isMobile}>
          <Button className="search-report-call" onClick={handleExportExcel}>
            Excel
          </Button>
          <Button className="search-report-call" onClick={handleExportCSV}>
            CSV
          </Button>

          <Button className="search-report-call" onClick={handleExportPDF}>
            PDF
          </Button>
        </Space>

        <Table
          dataSource={activeHistoryCallRequester?.map(
            (item: ICallReportRequester, index: number) => ({
              ...item,
              key: index,
            })
          )}
          pagination={
            pageTotal > 10
              ? {
                current: page,
                total: pageTotal ? pageTotal : 1,
                onChange: handlePageChange,
                showTotal: () => `${pageTotal} total`,
              }
              : false
          }
          scroll={{ x: 1100 }}
        >
          {collumns.map((col) => (
            <Table.Column dataIndex={col.key} {...col} />
          ))}
        </Table>
      </List>
    </Spin>
  );
};
