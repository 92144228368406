import { ChatStateContext } from 'hooks/useContext/useChatStateContext'
import { useContext } from 'react'
import { isCustomer, isInterpreter } from 'utils'
import { InterpreterCallModal } from './InterpreterCallModal'
import { RequesterCallModal } from './RequesterCallModal'
import { Modal } from '@pankod/refine-antd'

export default function PendingCallModal() {
  const { chatState } = useContext(ChatStateContext);

  return (
    <>
      {chatState.isPending && isInterpreter() && (
        <Modal
          visible={chatState.isPending && isInterpreter()}
          footer={null}
          width={400}
          closable={false}
          maskClosable={false}
          centered
          destroyOnClose
        >
          <InterpreterCallModal />
        </Modal>
      )}
      {chatState.isPending && isCustomer() && (
        <Modal
          visible={chatState.isPending && isCustomer()}
          footer={null}
          width={550}
          closable={false}
          maskClosable={false}
          centered
          destroyOnClose
        >
          <RequesterCallModal />
        </Modal>
      )}
    </>
  )
}
