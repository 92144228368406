/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useCreate } from "@pankod/refine-core";
import {
  Form,
  Input,
  useForm,
  Button,
  Checkbox,
  Row,
  Col,
  Grid,
} from "@pankod/refine-antd";

import "../../../../styles/style.less";
import { CREATE_RATE_TYPE_API } from "api/baseApi";
import { ICheckboxChangeActive } from "interfaces";
import { IService } from "interfaces/service";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { openNotification} from "utils";
import { ServiceProps } from "./interface";

export const ServiceCreate = (props: ServiceProps) => {
  const { setIsModalVisible, page, handleChange } = props;

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const { formProps, form } = useForm<IService>({
    action: EAction.CREATE,
  });

  const onCheck = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ isActive: true });
    } else {
      form.setFieldsValue({ isActive: false });
    }
  };

  const { mutate, data: createData, isLoading } = useCreate();

  const onFinish = (event: IService) => {
    mutate(
      {
        resource: CREATE_RATE_TYPE_API,
        values: {
          name: event.name,
          isActive: event.isActive,
        },
      },
      {
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  useEffect(() => {
    if (createData?.data.status === 200) {
      form.resetFields();
      handleChange(page);
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
      setIsModalVisible(false);
    }
  }, [createData, form, setIsModalVisible]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as IService)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message:
                  "Name must start with a letter and can not contain spaces or special characters",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input maxLength={50} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="
          "
            name="isActive"
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox
              onChange={(event) => {
                onCheck(event);
              }}
            >
              Is Active
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
