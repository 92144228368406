import { CanParams } from "@pankod/refine-core";
import { authProvider } from "./authProvider";
import { useTranslation } from "react-i18next";


export default function AccessControlerProvider(){
    const { t } = useTranslation();

    return async ({ resource } : CanParams) => {
        const role = await authProvider.getPermissions();
        const isAdmin = role?.roleId === 1;
        const isInterpreter = role?.roleId === 2;
        const isCustomer = role?.roleId === 3;
    
        let canAccess = false;
        
        switch (resource) {
          case t("resource.dashboard"):
            canAccess = true;
            break;

          case t("resource.history"):
          case t("resource.service"):
          case t("resource.detailsCustomer"):
          case t("resource.billRate"):
          case t("resource.payRate"):
          case t("resource.customer"):
          case t("resource.department"):
          case t("resource.subDepartment"):
          case t("resource.requesters"):
          case t("resource.interpreter"):
          case t("resource.interpreterEdit"):
          case t("resource.interpreterAdd"):
          case t("resource.processInvoiceInterpreter"):
          case t("resource.viewPrintInvoiceInterpreter"):
          case t("resource.processInvoiceCustomer"):
          case t("resource.viewPrintInvoiceCustomer"):
          case t("resource.interpreterProcessDetail"):
          case t("resource.apptDetailInterpreter"):
          case t("resource.customerProcessDetail"):
          case t("resource.payment"):
          case t("resource.billingReport"):
          case t("resource.appointmentFillLanguage"):
          case t("resource.appointmentFillCustomer"):
          case t("resource.appointmentPayBillReport"):
          case t("resource.interpreterActivityReport"):
          case t("resource.groups"):
          case t("resource.users"):
          case t("resource.languages"):
            canAccess = isAdmin;
            break;

          case t("resource.updateProfile"):
          case t("resource.reportCallInterpreter"):
          case t("resource.detailReportInterpreter"):
            canAccess = isInterpreter;
            break;
              
          case t("resource.listCall"):
          case t("resource.aptDetailCustomer"):
          case t("resource.reportCallRequester"):
          case t("resource.detailReportRequester"):
            canAccess = isCustomer;
            break;
    
          case t("resource.profile"):
            canAccess = isInterpreter || isCustomer;
            break;

          default:
            canAccess = false;
            break;
        }

        return Promise.resolve({ can: canAccess });
      }
}