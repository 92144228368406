import { useEffect, useRef, useState } from "react";
import {
  useCreate,
  useCustom,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import {
  Form,
  Input,
  Select,
  useForm,
  Button,
  Typography,
  Col,
  Row,
  Checkbox,
  Tabs,
  Icons,
  DatePicker,
  List,
  Grid,
  Space,
} from "@pankod/refine-antd";

import "../../../../styles/style.less";
import {
  CREATE_INTERPRETER_API,
  INTERPRETER_TYPE_API,
  LANGUAGES_API,
  PROFILE_API,
  STATE_API,
} from "api/baseApi";
import {
  IInterpreter,
  IInterpreterType,
  ILanguageInterpreter,
} from "interfaces/interpreter";
import { ILanguage } from "interfaces/languages";
import { ICheckboxChangeActive } from "interfaces";
import { IState } from "interfaces/state";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import moment from "moment";
import { openNotification} from "utils";
import { Breadcrumb, notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";

const { TabPane } = Tabs;
const { Title } = Typography;
const { Option } = Select;

const { PlusOutlined, CloseOutlined } = Icons;
export const InterpreterCreate = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const { goBack, push } = useNavigation();
  const translate = useTranslate();

  const [activeTab, setActiveTab] = useState("general");
  const [checkExitsLag, setCheckExitsLag] = useState(false);

  const { formProps, form } = useForm<IInterpreter>({
    action: EAction.CREATE,
  });

  const { data: languagesData } = useCustom({
    url: LANGUAGES_API.getActive,
    method: "get",
    config: {
      query: {
        page: "",
        limit: "",
        search: "",
      },
    },
  });

  const { data: statesData } = useCustom({
    url: STATE_API.getAll,
    method: "get",
  });

  const { data: interpreterTypeData } = useCustom({
    url: INTERPRETER_TYPE_API,
    method: "get",
  });

  const { data: profile } = useCustom({
    url: PROFILE_API,
    method: "get",
  });

  const { mutate, data: createData, isLoading } = useCreate();
  const onFinishFailed = () => {
    notification.error({
      message: "Validation Error",
      description: "Please fill in all the required fields.",
    });
  };

  const onFinish = (event: IInterpreter) => {
    mutate(
      {
        resource: CREATE_INTERPRETER_API,
        values: {
          username: event.username,
          firstname: event.firstname,
          lastname: event.lastname,
          accreditation: event.accreditation,
          intId: event.intId,
          busName: event.busName,
          phone: event.phone,
          phoneType: event.phoneType,
          ext: Number(event.ext),
          email: event.email,
          gender: event.gender,
          dob: event.dob,
          start_date: event.start_date,
          ssn: event.ssn,
          driverLicense: event.driverLicense,
          intType: event.intType,
          notes: event.notes,
          videoPayRate: Number(event.videoPayRate),
          telPayRate: Number(event.telPayRate),
          streetAddress: event.streetAddress,
          aptAddress: event.aptAddress,
          cityAddress: event.cityAddress,
          zipCodeAddress: event.zipCodeAddress,
          stateAddress: event.stateAddress,
          streetMail: event.streetMail,
          aptMail: event.aptMail,
          cityMail: event.cityMail,
          zipCodeMail: event.zipCodeMail,
          stateMail: event.stateMail,
          minMinutePaidTel: Number(event.minMinutePaidTel),
          nextPayIncrTel: Number(event.nextPayIncrTel),
          minMinutePaidVideo: Number(event.minMinutePaidVideo),
          nextPayIncrVideo: Number(event.nextPayIncrVideo),
          paymentType: event.paymentType,
          languages: arrLanguagesInterpreter ?? [],
          createdBy: profile?.data?.username,
        },
      },
      {
        onError: (error) => {
          
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  const [selectedItems, setSelectedItem] = useState<number>();
  const [languageInterpreter, setLanguageInterpreter] =
    useState<ILanguageInterpreter>({
      id: 0,
      name: "",
      telephone: true,
      video: true,
    });

  const [arrLanguagesInterpreter, setArrLanguagesInterpreter] = useState<
    ILanguageInterpreter[]
  >([]);

  const handleChangeItem = (selectedItems: number) => {
    const array = languagesData?.data?.results;
    let arrItem = array.filter(
      (x: { id: number }) => x.id === selectedItems
    )[0];

    setLanguageInterpreter({
      ...languageInterpreter,
      id: arrItem.id,
      name: arrItem.name,
    });

    setSelectedItem(selectedItems);
  };

  useEffect(() => {
    if (createData?.data.status === 200) {
      form.resetFields();
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
      setArrLanguagesInterpreter([]);
      setActiveTab("general");
      push("/listings/interpreter");
    }
  }, [createData, form]);

  const onCheckTelephone = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ telephone: true });
      setLanguageInterpreter({
        ...languageInterpreter,
        telephone: true,
      });
    } else {
      form.setFieldsValue({ telephone: false });
      setLanguageInterpreter({
        ...languageInterpreter,
        telephone: false,
      });
    }
  };

  const onCheckVideo = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ video: true });
      setLanguageInterpreter({
        ...languageInterpreter,
        video: true,
      });
    } else {
      form.setFieldsValue({ video: false });
      setLanguageInterpreter({
        ...languageInterpreter,
        video: false,
      });
    }
  };

  const message =
    "Please select a diffirent language and check Video/Audio box!";

  const checkLanguage = useRef(false);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationMess = (placement: NotificationPlacement) => {
    api.error({
      message: "",
      description: message,
      placement,
      duration: 1,
    });
  };
  const handleChangeLanguage = () => {
    if (languageInterpreter?.id !== 0) {
      setArrLanguagesInterpreter((pre: ILanguageInterpreter[]) => {
        let newState = [...pre];
        checkLanguage.current = newState.some(
          (item: ILanguageInterpreter) => item.id === languageInterpreter.id
        );

        if (!checkLanguage.current) {
          newState = [...newState, languageInterpreter];
        }

        return newState;
      });

      if (checkLanguage.current) {
        openNotificationMess("bottomRight");
        setCheckExitsLag(true);
      }
      form.setFieldsValue({ languages: "Select a language" });
    }
  };

  const handleRemoveLanguage = (id: number) => {
    const newSelectRow = arrLanguagesInterpreter.filter(
      (item: ILanguageInterpreter) => item.id !== id
    );

    setArrLanguagesInterpreter(
      newSelectRow.map((item: ILanguageInterpreter) => item)
    );
  };

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Listing</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("interpreter.label.title.info")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(event) => onFinish(event as IInterpreter)}
        onFinishFailed={() => onFinishFailed()}
      >
        {contextHolder}
        <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
          <TabPane tab="General Information" key="general" forceRender={true}>
            <Title level={5} className="title-address">
              LP1 User Account Login
            </Title>
            <Form.Item
              label="User Name"
              name="username"
              rules={[
                {
                  required: true,
                  message:
                    "User Name must start with a letter and can not contain spaces",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trim()}
            >
              <Input maxLength={50} />
            </Form.Item>

            <Row gutter={16}>
              <Col className="gutter-row" span={isMobile ? 24 : 8}>
                <Form.Item
                  label="Interpreter Type "
                  name="intType"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Interpreter Type ",
                    },
                  ]}
                >
                  <Select>
                    {interpreterTypeData?.data.map((item: IInterpreterType) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="First Name"
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trim()}
                >
                  <Input maxLength={50} />
                </Form.Item>

                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trim()}
                >
                  <Input maxLength={50} />
                </Form.Item>

                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Please enter gender",
                    },
                  ]}
                >
                  <Select placeholder="Gender">
                    <Option value="MALE">MALE</Option>
                    <Option value="FEMALE">FEMALE</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={isMobile ? 24 : 8}>
                <Form.Item
                  label="Interpreter ID"
                  name="intId"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Interpreter ID",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trim()}
                >
                  <Input maxLength={50} />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Please enter last name",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trimStart()}
                >
                  <Input maxLength={50} />
                </Form.Item>

                <Form.Item
                  label="SSN"
                  name="ssn"
                  rules={[
                    {
                      required: false,
                      message: "Please enter SSN",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trim()}
                >
                  <Input className="input" maxLength={50} />
                </Form.Item>

                <Form.Item
                  label="DOB"
                  name="dob"
                  rules={[
                    {
                      required: false,
                      message: "Please enter DOB",
                    },
                  ]}
                >
                  <DatePicker
                    style={{
                      width: "100%",
                      border: "1px solid #d9d9d9",
                      borderRadius: "10px",
                    }}
                    disabledDate={(current) => {
                      return current > moment().startOf("day");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={isMobile ? 24 : 8}>
                <Form.Item
                  label="Accreditation (Certification)"
                  name="accreditation"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Accreditation (Certification)",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trimStart()}
                >
                  <Input maxLength={200} />
                </Form.Item>

                <Form.Item
                  label="Business Name"
                  name="busName"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Business Name",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trimStart()}
                >
                  <Input type="text" className="input" maxLength={200} />
                </Form.Item>

                <Form.Item
                  label="Driver License"
                  name="driverLicense"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Driver License",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trimStart()}
                >
                  <Input type="text" className="input" maxLength={200} />
                </Form.Item>

                <Form.Item
                  label="Start Date"
                  name="start_date"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Start Date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{
                      width: "100%",
                      border: "1px solid #d9d9d9",
                      borderRadius: "10px",
                    }}
                    disabledDate={(current) => {
                      return current < moment().startOf("day");
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Form.Item
                  label="Video Interpretation pay rate (per minute)"
                  name="videoPayRate"
                  rules={[
                    { required: true, message: "Please enter a number" },
                    {
                      pattern: /^(?!0+(?:\.0+)?$)\d+(?:\.\d+)?$/,
                      message: "Please enter a valid number",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trim()}
                >
                  <Input maxLength={10} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Form.Item
                  label="Audio Interpretation pay rate (per minute)"
                  name="telPayRate"
                  rules={[
                    { required: true, message: "Please enter a number" },
                    {
                      pattern: /^(?!0+(?:\.0+)?$)\d+(?:\.\d+)?$/,
                      message: "Please enter a valid number",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trim()}
                >
                  <Input maxLength={50} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Notes"
              name="notes"
              rules={[
                {
                  required: false,
                  message: "Please enter Notes",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trimStart()}
            >
              <Input maxLength={200} />
            </Form.Item>

            <Title level={5} className="title-address">
              Languages
            </Title>
            <Row gutter={16} className="list-language" wrap={isMobile}>
              <Col className="gutter-row" span={isMobile ? 10 : 17}>
                <b>Language</b>
              </Col>
              <Col className="gutter-row" span={3}>
                <b>Video</b>
              </Col>
              <Col className="gutter-row" span={isMobile ? 10 : 3}>
                <b>Audio</b>
              </Col>
              <Col className="gutter-row" span={1}></Col>
            </Row>
            {arrLanguagesInterpreter?.map((item: ILanguageInterpreter) => (
              <Row gutter={16} className="result-list-language" key={item.id}>
                <Col className="gutter-row" span={17}>
                  {item?.name}
                </Col>
                <Col className="gutter-row" span={3}>
                  <Checkbox
                    onChange={(event) => {
                      onCheckVideo(event);
                    }}
                    checked={item?.video}
                    disabled
                  />
                </Col>
                <Col className="gutter-row" span={3}>
                  <Checkbox
                    onChange={(event) => {
                      onCheckTelephone(event);
                    }}
                    checked={item?.telephone}
                    disabled
                  />
                </Col>
                <Col className="gutter-row" span={1}>
                  <CloseOutlined
                    onClick={() => handleRemoveLanguage(item.id)}
                  />
                </Col>
              </Row>
            ))}
            <Row gutter={16}>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Form.Item
                  label="
              "
                  name="languages"
                  rules={[
                    {
                      required: false,
                      message: "Please select language",
                    },
                  ]}
                >
                  <Select
                    value={selectedItems}
                    onChange={handleChangeItem}
                    showSearch
                    placeholder="Select a language"
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    filterOption={(
                      input,
                      option: BaseOptionType | DefaultOptionType | undefined
                    ) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {languagesData?.data?.results.map(
                      (item: ILanguage, index: number) => (
                        <Select.Option key={index} value={item.id}>
                          {item.name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={isMobile ? 15 : 10}>
                <Row gutter={16}>
                  <Col className="gutter-row" span={isMobile ? 24 : 12}>
                    <Form.Item
                      label="
            "
                      name="video"
                      valuePropName="checked"
                      initialValue={true}
                    >
                      <Checkbox
                        onChange={(event) => {
                          onCheckVideo(event);
                        }}
                      >
                        Video
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={isMobile ? 24 : 12}>
                    <Form.Item
                      label="
            "
                      name="telephone"
                      valuePropName="checked"
                      initialValue={true}
                    >
                      <Checkbox
                        onChange={(event) => {
                          onCheckTelephone(event);
                        }}
                      >
                        Audio
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col className="gutter-row" span={2}>
                <Form.Item
                  name="languages"
                  label="
            "
                >
                  <PlusOutlined
                    className="add-language-interpreter"
                    onClick={handleChangeLanguage}
                  />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Address & Phone" key="address" forceRender={true}>
            <Row gutter={16}>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Title level={5} className="title-address">
                  Home Address
                </Title>
                <Form.Item
                  label="Street Address"
                  name="streetAddress"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Street Address",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trimStart()}
                >
                  <Input maxLength={200} />
                </Form.Item>

                <Form.Item
                  label="Apt. #"
                  name="aptAddress"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Apt. #",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trimStart()}
                >
                  <Input maxLength={200} />
                </Form.Item>

                <Form.Item
                  label="City"
                  name="cityAddress"
                  rules={[
                    {
                      required: true,
                      message: "Please enter city",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trimStart()}
                >
                  <Input maxLength={200} />
                </Form.Item>

                <Form.Item
                  label="State / Province"
                  name="stateAddress"
                  rules={[
                    {
                      required: true,
                      message: "Please enter State / Province",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(
                      input,
                      option: BaseOptionType | DefaultOptionType | undefined
                    ) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {statesData?.data?.map((item: IState, index: number) => (
                      <Select.Option key={index} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Zip Code / Postal Code"
                  name="zipCodeAddress"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Zip Code / Postal Code",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trim()}
                >
                  <Input maxLength={50} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Title level={5} className="title-address">
                  Mailing Address
                </Title>
                <Form.Item
                  label="Mailing Street Address"
                  name="streetMail"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Mailing Street Address",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trimStart()}
                >
                  <Input maxLength={200} />
                </Form.Item>

                <Form.Item
                  label="Mailing Apt. #"
                  name="aptMail"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Mailing Apt",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trimStart()}
                >
                  <Input maxLength={200} />
                </Form.Item>

                <Form.Item
                  label="Mailing City"
                  name="cityMail"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Mailing City",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trimStart()}
                >
                  <Input maxLength={200} />
                </Form.Item>

                <Form.Item
                  label="Mailing State / Province"
                  name="stateMail"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Mailing State / Province",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(
                      input,
                      option: BaseOptionType | DefaultOptionType | undefined
                    ) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {statesData?.data?.map((item: IState, index: number) => (
                      <Select.Option key={index} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Mailing Zip Code / Postal Code"
                  name="zipCodeMail"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Mailing Zip Code / Postal Code",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trim()}
                >
                  <Input maxLength={50} />
                </Form.Item>
              </Col>
            </Row>
            <Title level={5} className="title-address">
              Phones
            </Title>
            <Row gutter={16}>
              <Col className="gutter-row" span={isMobile ? 24 : 8}>
                {" "}
                <Form.Item
                  label="Phone Type "
                  name="phoneType"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Phone Type",
                    },
                  ]}
                >
                  <Select>
                    <Option value={1}>Home</Option>
                    <Option value={2}>Cell</Option>
                    <Option value={3}>Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={isMobile ? 24 : 8}>
                {" "}
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      pattern: /^[0-9]*$/,
                      message: "Must contain 10 numbers only",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trim()}
                >
                  <Input maxLength={10} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={isMobile ? 24 : 8}>
                <Form.Item
                  label="Ext"
                  name="ext"
                  rules={[
                    {
                      pattern: /^(?:\d*)$/,
                      message: "Must contain maximum 5 numbers",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trim()}
                >
                  <Input maxLength={5} />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Configurations" key="config" forceRender={true}>
            <Title level={5} className="title-address">
              Interpreter Payment Configuration
            </Title>

            <Title level={5} className="title-config">
              Audio Interpretation Services
            </Title>
            <Row gutter={16}>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Form.Item
                  label="Minimum Minute(s) Paid - Audio"
                  name="minMinutePaidTel"
                  rules={[
                    {
                      required: true,
                      pattern: /^-?\d+$/,
                      message: "Please enter a valid number",
                    },
                  ]}
                  normalize={(value, prevVal, prevVals) => value.trim()}
                  initialValue={0}
                >
                  <Input maxLength={10} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Form.Item
                  label="Next Paying Increment In Minutes - Audio"
                  name="nextPayIncrTel"
                  normalize={(value, prevVal, prevVals) => value.trim()}
                  rules={[
                    {
                      required: true,
                      pattern: /^-?\d+$/,
                      message: "Please enter a valid number",
                    },
                  ]}
                  initialValue={0}
                >
                  <Input maxLength={10} />
                </Form.Item>
              </Col>
            </Row>

            <Title level={5} className="title-config">
              Video Interpretation Services
            </Title>
            <Row gutter={16}>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Form.Item
                  label="Minimum Minute(s) Paid - Video"
                  name="minMinutePaidVideo"
                  normalize={(value, prevVal, prevVals) => value.trim()}
                  rules={[
                    {
                      required: true,
                      pattern: /^-?\d+$/,
                      message: "Please enter a valid number",
                    },
                  ]}
                  initialValue={0}
                >
                  <Input maxLength={10} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Form.Item
                  label="Next Paying Increment In Minutes - Video"
                  name="nextPayIncrVideo"
                  normalize={(value, prevVal, prevVals) => value.trim()}
                  rules={[
                    {
                      required: true,
                      pattern: /^-?\d+$/,
                      message: "Please enter a valid number",
                    },
                  ]}
                  initialValue={0}
                >
                  <Input maxLength={10} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Form.Item
                  label="Payment Type "
                  name="paymentType"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Payment Type ",
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value={1}>Pay</Select.Option>
                    <Select.Option value={2}>Check</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
        <Space>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
          <Button type="default" onClick={() => goBack()}>
            Cancel
          </Button>
        </Space>
      </Form>
    </List>
  );
};
