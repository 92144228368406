export const COL_SUMMARY_PDF = [
    { key: 'Items' },
    { key: 'Subtotal' }
]
export const COL_INTERPRETER_INVOICE_PDF = [
    { key: "Appt ID" },
    { key: "Invoice Create Date" },
    { key: "Customer Name" },
    { key: "Interpreter Name" },
    { key: "Department Name" },
    { key: "Sub-Department Name" },
    { key: "Requester Name" },
    { key: "Language" },
    { key: "Start Date" },
    { key: "Start Time" },
    { key: "Finish Time" },
    { key: "Duration (s)" },
    { key: "Connection Time" },
    { key: "Pay Total Amount ($)" },
    { key: "Type of Call" },
];
export const COL_INTERPRETER_INVOICE_XLSX = [
    "Appt ID",
    "Invoice Create Date",
    "Interpreter ID",
    "Interpreter Name",
    "Bus Phone",
    "Cell Phone",
    "Fax Number",
    "Customer Name",
    "Customer Email",
    "Department Name",
    "Sub-Department Name",
    "Requester Name",
    "Requester Email",
    "Language",
    "Start Date",
    "Start Time",
    "Finish Time",
    "Duration (s)",
    "Connection Time",
    "Pay Total Amount ($)",
    "Type of Call",
    "Status",
    "Other Ref1",
    "Other Ref2",
    "Other Ref3",
    "Notepad",
];