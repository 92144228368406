/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";

import { useRouterContext } from "@pankod/refine-core";
import { AntdLayout, Grid } from "@pankod/refine-antd";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import "../../../styles/style.less";
import { CollageContext } from "hooks/useContext/useCollagedContext";
import "./style.css";
import { Drawer } from "antd";
import { SideMenu } from "./SideMenu";

export const Sider: React.FC = () => {
  const { collapsed, setCollapsed, isShowMenu, setIsShowMenu } =
    useContext(CollageContext);
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const { Link } = useRouterContext();

  const onClose = () => {
    setIsShowMenu(false);
  };

  return (
    <>
      <AntdLayout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
        collapsedWidth={isMobile ? 0 : 80}
        breakpoint="lg"
        style={isMobile ? antLayoutSiderMobile : antLayoutSider}
        width={330}
      >
        <Link to="/" className="contain-logo">
          <div className="logo">
            <img
              src="/images/global/logo_v1.png"
              alt="911 interpreters"
              // width={`${collapsed ? "75%" : "100%"}`}
              className="logo-img"
            />
          </div>
        </Link>

        <SideMenu />
      </AntdLayout.Sider>

      <Drawer
        placement="left"
        closable={false}
        onClose={onClose}
        open={isMobile ? isShowMenu : false}
      >
        <Link to="/" onClick={() => setIsShowMenu(false)}>
          <div className="logo">
            <img
              src="/images/global/logo_v1.png"
              alt="911 interpreters"
              // width="93%"
              className="logo-img"
            />
          </div>
        </Link>

        <SideMenu />
      </Drawer>
    </>
  );
};
