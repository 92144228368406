import {
  Button,
  Card,
  Spin,
  Row,
  Space,
  Typography,
  Grid,
} from "@pankod/refine-antd";
import { CSSProperties } from "react";

const contain: CSSProperties = {
  width: "100%",
  height: "100%",
  alignItems: "center",
};
const containMb: CSSProperties = {
  width: "100%",
  height: "100%",
  alignItems: "center",
  padding: "0 15px",
};
const imgLogo: CSSProperties = {
  width: "120px",
  marginBottom: "20px",
};
const title: CSSProperties = {
  marginBottom: "15px",
  textAlign: "center",
};
const bgLight: CSSProperties = {
  backgroundColor: "#fff",
  width: "100%",
  height: "100vh",
};
const space: CSSProperties = {
  display: "flex",
};
const wrapperLogo: CSSProperties = {
  display: "flex",
  justifyContent: 'center'
}
const cardSM: CSSProperties = {
  width: '300px'
}
const cardXL: CSSProperties = {
  width: '450px'
}
export default function Connecting() {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.sm;
  return (
    <div style={bgLight}>
      <Row
        justify="center"
        style={isMobile ? containMb : contain}
        align="middle"
      >
        <Space direction="vertical">
          <div style={wrapperLogo}>
            <img
              style={imgLogo}
              src="/images/global/logo_v2.png"
              alt="911-logo"
            />
          </div>

          <Space direction="vertical" align="center" style={space}>
            <Spin size="large" />
            <Typography.Title style={title} level={4}>
              Connecting...
            </Typography.Title>
          </Space>
        </Space>
      </Row>
    </div>
  );
}
