import { Grid, Icons, Menu } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { CollageContext } from "hooks/useContext/useCollagedContext";
import { IResource } from "interfaces";
import { useContext } from "react";

const { RightOutlined } = Icons;
export const MenuWithItems = ({
  selectedKey,
  items,
  iconMappings,
  onMenuClick,
  collapsed,
}: any) => {
  const breakpoint = Grid.useBreakpoint();
  const { setCollapsed, setIsShowMenu } = useContext(CollageContext);
  const translate = useTranslate();

  return (
    <Menu
      selectedKeys={[selectedKey]}
      mode="inline"
      onClick={({ key }) => {
        if (!breakpoint.lg) {
          setCollapsed(true);
          setIsShowMenu(false);
        }
        onMenuClick(key as string);
      }}
    >
      {items.map((item: IResource) => {
        const isSelected = item.path === selectedKey;
        return (
          <Menu.Item
            style={{ fontWeight: isSelected ? "bold" : "normal" }}
            key={item.path}
            icon={iconMappings[item.name] || null}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {item.name === translate("resource.reportCallRequester") ||
              item.name === translate("resource.reportCallInterpreter")
                ? "Call Report"
                : item.name}
              {!collapsed && isSelected && <RightOutlined />}
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};
