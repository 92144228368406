import { Button, Space } from "@pankod/refine-antd";
import { useUpdate } from "@pankod/refine-core";
import { ACTIVE_CUSTOMERS_API, DEACTIVE_CUSTOMERS_API } from "api/baseApi";
import { EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ICustomer } from "interfaces/customer";
import { openNotification } from "utils";

type CustomerActiveProps = {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  customer: ICustomer | undefined;
  refetch: () => void;
};

const CustomerActive = (props: CustomerActiveProps) => {
  const { setIsModalVisible, customer, refetch } = props;
  const { mutate: mutateUpdate, isLoading } = useUpdate();



  const handleIsActive = (urlParam: string) => {
    mutateUpdate(
      {
        resource: urlParam,
        id: customer?.id as string,
        values: {
          name: customer,
        },
      },
      {
        onSuccess: (updateData) => {
          openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
          refetch();
        },
      }
    );
  };
  const handleChangeActive = () => {
    if (customer?.active === true) {
      handleIsActive(DEACTIVE_CUSTOMERS_API);
    } else handleIsActive(ACTIVE_CUSTOMERS_API);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Space
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
      }}
    >
      <div>
        You are about to change the Active Status of this{" "}
        <strong>Customer</strong>. Please select <strong>Change</strong> to
        continue or <strong>Cancel</strong> to go back
      </div>
      <Space style={{ marginTop: 10 }}>
        <Button
          onClick={handleChangeActive}
          className="btn-create"
          style={{ marginRight: "10px", backgroundColor: "#" }}
          disabled={isLoading}
        >
          Change
        </Button>
        <Button onClick={handleCancel}>Cancel </Button>
      </Space>
    </Space>
  );
};

export default CustomerActive;
