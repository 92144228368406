import { Button, Space } from "@pankod/refine-antd";
import { useEffect } from "react";
import { BaseKey, useUpdate } from "@pankod/refine-core";
import { ACTIVE_LANGUAGES_API, DEACTIVE_LANGUAGES_API } from "api/baseApi";
import { openNotification } from "utils";
import { LanguageProps } from "./interface";
import { EStatusNotification, MSG_NOTIFICATION } from "constants/constant";

const LanguageActive = (props: LanguageProps) => {
  const { setIsModalVisible, language, page, handleChange } = props;
  const { mutate, data: activeData, isLoading: isLoadingActive } = useUpdate();

  const handleChangeActive = () => {
    if (language?.isActive === true) {
      mutate({
        resource: DEACTIVE_LANGUAGES_API,
        id: language?.id,
        values: {
          name: language,
        },
      });
    } else {
      mutate({
        resource: ACTIVE_LANGUAGES_API,
        id: language?.id as BaseKey,
        values: {
          name: language,
        },
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (activeData?.data.status == 200) {
      setIsModalVisible(false);
      handleChange(page);
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, activeData?.data?.message, EStatusNotification.SUCCESS);
    }
  }, [activeData, setIsModalVisible]);

  return (
    <Space
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
      }}
    >
      <div>
        You are about to change the Active Status of this{" "}
        <strong>Language</strong>. Please select <strong>Change</strong> to
        continue or <strong>Cancel</strong> to go back
      </div>
      <Space style={{ marginTop: 10 }}>
        <Button
          loading={isLoadingActive}
          onClick={handleChangeActive}
          className="btn-create"
          style={{ marginRight: "10px", backgroundColor: "#" }}
        >
          Change
        </Button>
        <Button onClick={handleCancel}>Cancel </Button>
      </Space>
    </Space>
  );
};

export default LanguageActive;
