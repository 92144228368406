import { Button, Space } from "@pankod/refine-antd";
import { useEffect } from "react";
import { BaseKey, useUpdate } from "@pankod/refine-core";
import {
  ACTIVE_SUB_DEPARTMENT_API,
  DEACTIVE_SUB_DEPARTMENT_API,
} from "api/baseApi";
import { openNotification } from "utils";
import { SubDepartmentsProps } from "./interface";
import { EStatusNotification, MSG_NOTIFICATION } from "constants/constant";

const SubDepartmentActive = (props: SubDepartmentsProps) => {
  const { setIsModalVisible, subdepartment, isLoading, updateSuccess } = props;

  const { mutate, data: activeData, isLoading: isLoadingActive } = useUpdate();

  const handleChangeActive = () => {
    if (subdepartment?.isActive === true) {
      mutate({
        resource: DEACTIVE_SUB_DEPARTMENT_API,
        id: subdepartment?.id,
        values: {
          name: subdepartment,
        },
      });
    } else {
      mutate({
        resource: ACTIVE_SUB_DEPARTMENT_API,
        id: subdepartment?.id as BaseKey,
        values: {
          name: subdepartment,
        },
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (activeData?.data.status == 200) {
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, activeData?.data?.message, EStatusNotification.SUCCESS);
      updateSuccess();
      setIsModalVisible(false);
    }
  }, [activeData, setIsModalVisible]);

  return (
    <Space
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
      }}
    >
      <div>
        You are about to change the Active Status of this{" "}
        <strong>Sub-department</strong>. Please select <strong>Change</strong>{" "}
        to continue or <strong>Cancel</strong> to go back
      </div>
      <Space style={{ marginTop: 10 }}>
        <Button
          loading={isLoadingActive}
          onClick={handleChangeActive}
          className="btn-create"
          style={{ marginRight: "10px", backgroundColor: "#" }}
        >
          Change
        </Button>
        <Button onClick={handleCancel}>Cancel </Button>
      </Space>
    </Space>
  );
};

export default SubDepartmentActive;
