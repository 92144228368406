import {
  Button,
  DateField,
  Descriptions,
  List,
  Row,
  Space,
  Typography,
} from "@pankod/refine-antd";
import { useCustom, useNavigation } from "@pankod/refine-core";
import RouterProvider from "@pankod/refine-react-router-v6";
import { CALL_API } from "api/baseApi";
import { ICallDetail } from "interfaces/callReport";
import React, { useCallback } from "react";
import { convertSecondsToTime } from "utils";
import "../style.less";
import BillItemsTable from "./Table/BillItemsTable";
import PayItemsTable from "./Table/PayItemsTable";

const { Title } = Typography;

export const AppoinmentDetailInterpreter: React.FC = () => {
  const params = RouterProvider.useParams();
  const { goBack } = useNavigation();
  const { data: appointmentDetail } = useCustom({
    url: CALL_API.getOne + `/${params.slug}`,
    method: "get",
  });

  const recordApptDetail = appointmentDetail?.data as ICallDetail;

  const handleBackPage = useCallback(
    () => {
      goBack();
    },
    [goBack],
  )


  return (
    <List title="Call Details">
      <Row>
        <Title level={5}>
          <span className="title-invoice">Call Number: </span>{" "}
          {recordApptDetail?.callerId}
        </Title>
      </Row>
      <Space>

        <Button className="process back" onClick={handleBackPage}>
          Back
        </Button>
      </Space>
      <Title level={5} className="title-invoice">
        Requester Information
      </Title>
      <Descriptions bordered>
        <Descriptions.Item
          span={3}
          labelStyle={{ fontWeight: "bold", width: "40%" }}
          label="Customer"
        >
          {recordApptDetail?.user?.requester?.customer?.name}
        </Descriptions.Item>
        <Descriptions.Item
          span={3}
          labelStyle={{ fontWeight: "bold" }}
          label="Department"
        >
          {recordApptDetail?.user?.requester?.department?.name
            ? recordApptDetail?.user?.requester?.department?.name
            : ""}
        </Descriptions.Item>
        <Descriptions.Item
          span={3}
          labelStyle={{ fontWeight: "bold" }}
          label="Requester Name"
        >
          {recordApptDetail?.user?.username}
        </Descriptions.Item>
        <Descriptions.Item
          span={3}
          labelStyle={{ fontWeight: "bold" }}
          label="Requester Phone"
        >
          {recordApptDetail?.user?.phone}
        </Descriptions.Item>
        <Descriptions.Item
          span={3}
          labelStyle={{ fontWeight: "bold" }}
          label="Requester Email"
        >
          {recordApptDetail?.user?.email}
        </Descriptions.Item>
      </Descriptions>
      <Title level={5} className="title-invoice">
        Call Information
      </Title>
      <Descriptions bordered>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "40%" }}
          span={3}
          label="Start Date and Time"
        >
          {recordApptDetail?.startTime ? (
            <DateField
              format="LLL"
              value={
                recordApptDetail?.startTime ? recordApptDetail?.startTime : ""
              }
            />
          ) : (
            ""
          )}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold" }}
          span={3}
          label="Estimated Duration"
        >
          {convertSecondsToTime(recordApptDetail?.duration)}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold" }}
          span={3}
          label="Time Finish"
        >
          {recordApptDetail?.endTime ? (
            <DateField
              format="LLL"
              value={recordApptDetail?.endTime ? recordApptDetail?.endTime : ""}
            />
          ) : (
            ""
          )}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold" }}
          span={3}
          label="Language"
        >
          {recordApptDetail?.language?.name}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold" }}
          span={3}
          label="Covered By"
        >
          {recordApptDetail?.interpreter?.firstname +
            " " +
            recordApptDetail?.interpreter?.lastname}
        </Descriptions.Item>
      </Descriptions>
      <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
        <span className="title-invoice">Invoice Information</span>
      </Row>
      <PayItemsTable recordApptDetail={recordApptDetail} />

      <BillItemsTable recordApptDetail={recordApptDetail} />
    </List>
  );
};
