import {
  AutoComplete,
  Button,
  Checkbox,
  DateField,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Space,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { useEffect, useMemo, useState } from "react";
import "../../../../../styles/style.less";
import { StandardPayRatesCreate } from "./create";
import { IStandard } from "interfaces/standard";
import { CustomModal } from "components/Modal/CustomModal";
import { useCustom, useNavigation, useTranslate } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import PayRateActive from "./active";
import { StandardPayRatesUpdate } from "./update";
import { INTERPRETER_API, PAY_RATE_API } from "api/baseApi";
import RouterProvider from "@pankod/refine-react-router-v6";
import { ELanguageType } from "constants/constant";

const { PlusSquareOutlined, SearchOutlined, CloseOutlined } = Icons;

export const StandardPayRatesList = () => {
  const translate = useTranslate();
  const { goBack } = useNavigation();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const { useParams } = RouterProvider;
  const params = useParams();

  const [isModalVisibleCreate, setIsModalVisibleCreate] = useState(false);
  const [isModalVisibleUpdate, setIsModalVisibleUpdate] = useState(false);
  const [isModalVisibleActive, setIsModalVisibleActive] = useState(false);
  const [detailPayRate, setDetailPayRate] = useState<IStandard>();

  const [valuePayRate, setValuePayRate] = useState("");
  const [activePayRate, setActivePayRate] = useState([]);

  const [page, setPage] = useState(1);

  const { data: payRatesData, refetch } = useCustom({
    url: PAY_RATE_API.getInterpreter,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
        id: params?.slug,
        search: valuePayRate,
      },
    },
    queryOptions: {
      onSuccess: (payRatesData) => {
        setActivePayRate(payRatesData?.data?.results);
      },
    },
  });

  const { data: interpretersData } = useCustom({
    url: INTERPRETER_API.getOne + `/${params?.slug}`,
    method: "get",
  });

  const pageTotal = payRatesData?.data?.total;

  const collumns = useMemo(
    () => [
      {
        key: "rateType",
        title: "Rate Type",
        render: (value: IStandard, record: IStandard) => (
          <TextField
            value={value ? value.name : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => edit(record)}
          />
        ),
      },

      {
        key: "payRate",
        title: `Pay Rate`,
        render: (value: number) => <TextField value={value ? value : ""} />,
      },
      {
        key: "createAt",
        title: `Effective Date`,
        render: (value: string) =>
          value ? <DateField format="LL" value={value ? value : ""} /> : "",
      },
      {
        key: "languageType",
        title: `Language`,
        render: (value: number) => (
          <TextField
            value={
              value === 0
                ? ELanguageType.ALL_LANGUAGE
                : value === 1
                ? ELanguageType.AMERICAN
                : value === 2
                ? ELanguageType.SPANISH
                : ""
            }
          />
        ),
      },
      {
        title: `Is Active`,
        key: "isActive",
        render: (value: boolean, record: IStandard) => (
          <Checkbox checked={value} onChange={() => active(record)} />
        ),
      },
    ],
    []
  );

  const handleCreate = () => {
    setIsModalVisibleCreate(!isModalVisibleCreate);
  };

  const edit = (payRateData: IStandard) => {
    setDetailPayRate(payRateData);
    setIsModalVisibleUpdate(true);
  };

  const active = (payRateData: IStandard) => {
    setDetailPayRate(payRateData);
    setIsModalVisibleActive(true);
  };

  const handleChange = (page: number) => {
    setPage(page);
  };

  const onSelect = (
    payRateId: string,
    option: { value: number; label: string }
  ) => {
    setValuePayRate(option.label);
  };

  const onChange = (payRate: string, option: {}) => {
    setValuePayRate(payRate);
  };

  const handleRemovePayRate = () => {
    setValuePayRate("");
  };

  useEffect(() => {
    refetch();
  }, [isModalVisibleCreate, isModalVisibleUpdate, isModalVisibleActive]);

  useEffect(() => {
    setPage(1);
  }, [valuePayRate]);

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Listing</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.payRate")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      pageHeaderProps={{
        extra: (
          <Space>
            <Button className="btn-create" onClick={handleCreate}>
              <PlusSquareOutlined /> {translate("standard.title.create")}
            </Button>
          </Space>
        ),
      }}
    >
      <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
        <Form.Item
          label="Interpreter Name"
          className={!isMobile ? "search-item" : ""}
        >
          <AutoComplete
            showSearch
            size="large"
            style={{
              width: "100%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            value={
              interpretersData?.data?.user.firstname +
              " " +
              interpretersData?.data?.user.lastname
            }
            disabled
          >
            <Input bordered={false} />
          </AutoComplete>
        </Form.Item>
        <Form.Item
          label="Search Rates"
          className={!isMobile ? "search-item" : ""}
        >
          <AutoComplete
            showSearch
            size="large"
            style={{
              width: "100%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            value={valuePayRate}
            onSelect={onSelect}
            onChange={onChange}
          >
            <Input
              suffix={
                <>
                  {valuePayRate && (
                    <CloseOutlined onClick={handleRemovePayRate} />
                  )}
                  <SearchOutlined />
                </>
              }
              bordered={false}
            />
          </AutoComplete>
        </Form.Item>
      </Form>

      <Table
        dataSource={activePayRate?.map((item: IStandard, index: number) => ({
          ...item,
          key: index,
        }))}
        rowKey="id"
        pagination={
          pageTotal > 10
            ? {
                current: page,
                total: pageTotal || 1,
                onChange: handleChange,
              }
            : false
        }
        scroll={{ x: 800 }}
      >
        {collumns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
      <Button style={{ marginTop: "10px" }} onClick={() => goBack()}>
        Back
      </Button>
      <CustomModal
        title={translate("standard.label.title.info")}
        setIsModalVisible={setIsModalVisibleCreate}
        isModalVisible={isModalVisibleCreate}
      >
        <StandardPayRatesCreate
          setIsModalVisible={setIsModalVisibleCreate}
          isModalVisible={isModalVisibleCreate}
          interpreterId={params?.slug}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>

      <CustomModal
        title={translate("standard.label.title.info")}
        setIsModalVisible={setIsModalVisibleUpdate}
        isModalVisible={isModalVisibleUpdate}
      >
        <StandardPayRatesUpdate
          setIsModalVisible={setIsModalVisibleUpdate}
          isModalVisible={isModalVisibleUpdate}
          payRate={detailPayRate}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>

      <CustomModal
        title="Confirmation"
        setIsModalVisible={setIsModalVisibleActive}
        isModalVisible={isModalVisibleActive}
        width={isMobile ? "100%" : "50%"}
      >
        <PayRateActive
          setIsModalVisible={setIsModalVisibleActive}
          isModalVisible={isModalVisibleActive}
          payRate={detailPayRate}
          page={page}
          handleChange={handleChange}
          refetch={refetch}
        />
      </CustomModal>
    </List>
  );
};
