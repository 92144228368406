import { AuthProvider } from "@pankod/refine-core";
import { socket } from "hooks/useContext/useSocketContext";
import dataProvider from "./dataProvider";
import { ERoomStatus } from "constants/constant";

export const TOKEN_KEY = "token";
export const STORE_PERMISSION = "role";
export const authProvider: AuthProvider = {
  login: async ({ username, password, resourceName }) => {
    const { post } = dataProvider;
    const url = resourceName;

    const payload = {
      username: username,
      password: password,
    };

    const data = await post({
      url: url,
      payload: payload,
    });
    if (socket.io.opts && socket.io.opts.query) {
      socket.io.opts.query.token = data.data.accessToken;
    }
    socket.connect();
    socket.emit("login-user", {
      token: data.data.accessToken,
    });
    localStorage.setItem("user", JSON.stringify(data.data.data));
    localStorage.setItem(TOKEN_KEY, data.data.accessToken);
    localStorage.setItem(STORE_PERMISSION, data.data.data.roleId);
    localStorage.setItem("permission", JSON.stringify(data.data?.permission));
    localStorage.setItem("room-state", ERoomStatus.COMPLETE);
    return Promise.resolve();
  },
  logout: () => {
    socket.emit("logout", {
      clientId: socket.id,
    });
    socket.disconnect();
    localStorage.clear();
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => {
    const roleId = localStorage.getItem(STORE_PERMISSION);
    const permission = localStorage.getItem("permission");

    return Promise.resolve({
      roleId: JSON.parse(roleId as string),
      permission: JSON.parse(permission as string),
    });
  },
};
