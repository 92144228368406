import {
  Button,
  DatePicker,
  Form,
  Grid,
  List,
  Space,
  Spin,
  Table,
  TextField,
} from "@pankod/refine-antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ICallReportInterpreter } from "interfaces/callReport";
import { useCustom } from "@pankod/refine-core";
import { CALL_API } from "api/baseApi";
import dayjs, { Dayjs } from "dayjs";
import { EFormatDateTime } from "constants/constant";
import "../style.less";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
  TableColumn,
} from "utils/excelExporter";
import { axiosInstance } from "providers/axios";
import { formatNumber } from "utils";

const COL_REPORT_INTERPRETER_XLXS = [
  "Call ID",
  "Language",
  "Call Time",
  "Call Start",
  "Call End",
  "Duration",
  "Pay Rate",
  "Income ($)",
  "Status",
  "NotePad",
];

export const DetailCallReportInterpreter: React.FC = () => {
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.sm;

  const user = JSON.parse(localStorage.getItem("user") || "");

  const interpreterName = user?.firstname + " " + user?.lastname;

  const [activeHistoryCallInterpreter, setActiveHistoryCallInterpreter] =
    useState<ICallReportInterpreter[]>([]);

  const [page, setPage] = useState<number>(1);

  const { data: reportCallsData } = useCustom({
    url: CALL_API.getCallUser,
    method: "get",
    config: {
      query: {
        page: page,
        limit: isSearch ? null : 10,
        start: isSearch
          ? startDate
            ? dayjs(startDate).format(EFormatDateTime.DATE)
            : ""
          : "",
        end: isSearch
          ? endDate
            ? dayjs(endDate).format(EFormatDateTime.DATE)
            : ""
          : "",
      },
    },
    queryOptions: {
      onSuccess: (historyCall) => {
        setActiveHistoryCallInterpreter(historyCall?.data?.results);
      },
    },
  });

  const handleStartDate = (startDate: Dayjs | null) => {
    if (startDate) {
      setStartDate(startDate);
      setIsSearch(true);
    } else {
      setStartDate("");
    }
  };

  const handleEndDate = (endDate: Dayjs | null) => {
    if (endDate) {
      setEndDate(endDate);
      setIsSearch(true);
    } else {
      setEndDate("");
    }
  };

  const pageTotal = reportCallsData?.data.total;
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const collumns = useMemo(
    () => [
      {
        title: "Call ID",
        key: "callerId",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        title: "Call Time",
        key: "createdAt",
        render: (value: string) =>
          value ? (
            <TextField
              value={value ? dayjs(value).format(EFormatDateTime.DATETIME) : ""}
            />
          ) : (
            ""
          ),
      },
      {
        title: "Call Start",
        key: "startTime",
        render: (value: string) =>
          value ? (
            <TextField
              value={value ? dayjs(value).format(EFormatDateTime.DATETIME) : ""}
            />
          ) : (
            ""
          ),
      },
      {
        title: "Call End",
        key: "endTime",
        render: (value: string) =>
          value ? (
            <TextField
              value={value ? dayjs(value).format(EFormatDateTime.DATETIME) : ""}
            />
          ) : (
            ""
          ),
      },
      {
        title: "Language",
        key: "language",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        title: "Duration",
        key: "duration",
        render: (value: number) => <TextField value={value ? value : ""} />,
      },

      {
        title: "Pay Rate",
        key: "ratePay",
        render: (value: number) => <TextField value={value ? value : ""} />,
      },
      {
        title: "Income ($)",
        key: "totalPay",
        render: (value: number) => (
          <TextField value={value ? `$${value?.toFixed(2)}` : "$0"} />
        ),
      },
    ],
    [page]
  );
  

  const customFiledHistory = (callReprt: ICallReportInterpreter[]) => {
    return callReprt?.map((report: ICallReportInterpreter) => ({
      "Call ID": report.callerId,
      Language: report.language,
      "Call Time": dayjs(report.createdAt).format(EFormatDateTime.DATETIME),
      "Call Start": report.startTime
        ? dayjs(report.startTime).format(EFormatDateTime.DATETIME)
        : "",
      "Call End": report.endTime
        ? dayjs(report.endTime).format(EFormatDateTime.DATETIME)
        : "",
      Duration: report.duration,
      "Pay Rate": report.ratePay,
      "Income ($)": formatNumber(report.totalPay) || 0,
      Status: report?.status,
      NotePad: report?.notePad?.replace(/\n/g, ", "),
    }));
  };

  const columns: TableColumn[] = [
    { key: "Call ID" },
    { key: "Call Time" },
    { key: "Call Start" },
    { key: "Call End" },
    { key: "Language" },
    { key: "Duration" },
    { key: "Pay Rate" },
    { key: "Income ($)" },
  ];

  const fetchData = async () => {
    setIsLoading(true);
    setIsSearch(true);
    const response = await axiosInstance.get(
      CALL_API.getCallUser +
      `?page=${page}&limit=&start=${startDate ? dayjs(startDate).format(EFormatDateTime.DATE) : ""
      }&end=${endDate ? dayjs(endDate).format(EFormatDateTime.DATE) : ""}`
    );
    setIsLoading(false);
    return response?.data?.results;
  };

  const dateRange =
    startDate || endDate
      ? `Date Range: ${dayjs(startDate)?.format("DD/MM/YYYY")} - ${dayjs(
        endDate
      )?.format("DD/MM/YYYY")}`
      : "";

  const handleExportPDF = async () => {
    setPage(1);
    const callReportsData = await fetchData();
    exportToPDF({
      columns,
      data: customFiledHistory(callReportsData),
      fileName: "Call Report Interpreter.pdf",
      name: "Summary Report",
      date: dateRange,
      nameInvoice: `Interpreter Name: ${interpreterName}`,
      totalRow: `Total Number of Call : ${callReportsData.length}`,
      totalAmounts: getTotalAmounts(callReportsData),
    });
  };

  const getTotalAmounts = useCallback(
    (callReportsData: any) => {
      return (callReportsData.reduce((total: number, currentValue: any) => {
        return total + currentValue.totalPay
      }, 0)).toFixed(2);
    },
    [],
  )

  const handleExportExcel = async () => {
    setPage(1);
    const callReportsData = await fetchData();
    exportToExcel(
      "Call Report Interpreter",
      customFiledHistory(callReportsData),
      COL_REPORT_INTERPRETER_XLXS, `Total Number of Call: ${callReportsData.length}`, `Total Amounts: $${getTotalAmounts(callReportsData)}`
    );
  };

  const handleExportCSV = async () => {
    setPage(1);
    const callReportsData = await fetchData();
    exportToCSV(
      customFiledHistory(callReportsData),
      "Call Report Interpreter",
      COL_REPORT_INTERPRETER_XLXS, `Total Number of Call: ${callReportsData.length}`, `Total Amounts: $${getTotalAmounts(callReportsData)}`
    );
  };

  useEffect(() => {
    setPage(1);
  }, [startDate, endDate]);

  return (
    <Spin spinning={isLoading} size="large">
      <List title="Call Report">
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Start Date"
            name="start"
            className={!isMobile ? "search-item" : ""}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={startDate}
              onChange={(e) => handleStartDate(e)}
              disabledDate={(current) => {
                return endDate ? current && current > endDate : false;
              }}
            />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="end"
            className={!isMobile ? "search-item" : ""}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={endDate}
              disabledDate={(current) => {
                return current && current < startDate;
              }}
              onChange={(e) => handleEndDate(e)}
            />
          </Form.Item>
        </Form>
        <Space wrap={isMobile}>
          <Button className="search-report-call" onClick={handleExportExcel}>
            Excel
          </Button>
          <Button className="search-report-call" onClick={handleExportCSV}>
            CSV
          </Button>
          <Button className="search-report-call" onClick={handleExportPDF}>
            PDF
          </Button>
        </Space>

        <Table
          dataSource={activeHistoryCallInterpreter?.map(
            (item: ICallReportInterpreter, index: number) => ({
              ...item,
              key: index,
            })
          )}
          pagination={
            pageTotal > 10
              ? {
                current: page,
                total: pageTotal ? pageTotal : 1,
                onChange: handlePageChange,
                showTotal: () => `${pageTotal} total`,
              }
              : false
          }
          scroll={{ x: 900 }}
        >
          {collumns.map((col) => (
            <Table.Column dataIndex={col.key} {...col} />
          ))}
        </Table>
      </List>
    </Spin>
  );
};
