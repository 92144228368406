import { Result } from "@pankod/refine-antd";
import {useEffect} from 'react';
import { socket } from "hooks/useContext/useSocketContext";
import { openNotification } from "utils";
import { EStatusNotification, MSG_NOTIFICATION } from "constants/constant";

export const Page404 = () => {
  useEffect(() => {
    socket.on("error-call", () => {
      openNotification(MSG_NOTIFICATION.NOTIFICATION, 'The call ended', EStatusNotification.INFO);
      const id =  setTimeout(()=>{
        window.close();
        return ()=>{
          clearTimeout(id);
        }
      }, 1000);
    });
    return () => {
      socket.off("error-call");
    };
  }, []);

  return (
    <section
      className="ant-layout ant-layout-has-sider"
      style={{ minHeight: "100vh", flexDirection: "row" }}
    >
      <section className="ant-layout">
        <header
          className="ant-layout-header"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0px 24px",
            height: "64px",
            backgroundColor: "#f0f2f5",
          }}
        ></header>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      </section>
    </section>
  );
};
