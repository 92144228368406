import { Button, Icons } from "@pankod/refine-antd";
import { CloseIcon } from "components/icons";
import { EMediaDeviceInfo, EStatusNotification, MSG_NOTIFICATION, WAITING_TIME } from "constants/constant";
import { ChatNotificationContext } from "hooks/useContext/useChatNotificationContext";
import { ChatStateContext } from "hooks/useContext/useChatStateContext";
import { SocketContext } from "hooks/useContext/useSocketContext";
import { useSoundCallContext } from "hooks/useContext/useSoundCallContext";
import { UserInfoContext } from "hooks/useContext/useUserInfoContext";
import { IRoomInfo } from "interfaces/chat";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { handleOpenPopup, isInterpreter, openNotification } from "utils";
import "../../../styles/style.less";
import './style.css';

export const InterpreterCallModal = () => {
  const { chatNotification } = useContext(ChatNotificationContext);
  const { chatState, setChatState } = useContext(ChatStateContext);
  const { recieveSound, missedSound } = useSoundCallContext();
  const { userInfo } = useContext(UserInfoContext);
  const socket = useContext(SocketContext);
  const roomInfo = useRef<IRoomInfo>(null!);
  const [countDown, setCountDown] = useState(28);

  const handleAvailableMic = useCallback(() => {
      setChatState({ ...chatState, isAccept: true });
      socket.emit("create-room", {
        userId: userInfo.id,
        callId: chatNotification.callId,
        clientCallId: chatNotification.clientCallId,
        languageId: chatNotification.languageId,
        type: chatNotification.mediaType,
        userCallId: chatNotification.userCallId
      });

      socket.on("token-twilio", (data: IRoomInfo) => {
        if (isInterpreter()) {
          roomInfo.current = data;
        }
      });
      const id = setInterval(() => {
        if (roomInfo.current) {
          clearInterval(id);
          handleOpenPopup(roomInfo.current);
          setChatState({ ...chatState, isAccept: true, isPending: false });
          roomInfo.current = null!;
        }
      }, 500);
  }, [userInfo, chatState, chatNotification])

  const handleAcceptCall = useCallback( async () => {
    !recieveSound.muted && recieveSound.pause();
    const devices = await navigator.mediaDevices.enumerateDevices();
    const isAvailableMic = devices.find((device) => device.kind === EMediaDeviceInfo.AUDIO_INPUT && device.deviceId);
    if (isAvailableMic) {
      handleAvailableMic();
    }else {
      navigator.mediaDevices.getUserMedia({ audio: true }).then( _ => {
        handleAvailableMic();
      }).catch( _ => {
        openNotification(MSG_NOTIFICATION.ERROR, 'Microphone has been blocked, please allow microphone.', EStatusNotification.ERROR, 3);
      })
    }
  }, [recieveSound, handleAvailableMic]);

  const handleRejectCall = useCallback(async () => {
    recieveSound.pause();
    missedSound.play();
    socket.emit("rejected-call", {
      clientId: chatNotification.clientCallId,
      callId: chatNotification.callId,
      userId: userInfo.id,
    });
    setChatState({ ...chatState, isPending: false });
  }, [userInfo, socket, chatNotification]);

  useEffect(() => {
    if (countDown > 0) {
      const idInternal = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);
      return () => {
        clearInterval(idInternal);
      };
    } else {
      handleRejectCall();
    }
    return () => {
      socket.off('token-twilio');
    }
  }, [countDown, handleRejectCall]);

  return (
    <div className="info-inter">
      <p className="info-name">{chatNotification?.companyName} is calling</p>
      <p className="info-name">Language: {chatNotification?.languageName}</p>
      <h3 className="info-des">The call will start when you accept it</h3>
      <h5 className="info-des">You have {countDown} for the call.</h5>
      <div className="accept-cancel-call">
        <Button
          shape="circle"
          icon={<CloseIcon />}
          onClick={handleRejectCall}
          className="btn btn-cancel"
        />
        <Button
          shape="circle"
          icon={<Icons.VideoCameraOutlined className="icon-logo" />}
          onClick={handleAcceptCall}
          className="btn btn-accept"
          disabled={chatState.isAccept}
        />
      </div>
    </div>
  );
};


