import { Typography } from '@pankod/refine-antd';
import { ETitleStatus } from 'constants/constant';
import { ILanguageOnline } from 'interfaces/users';
import { ProfileDashboard } from './Profile';

type InterpretersActsListProps = {
    listProfile: ILanguageOnline;
    handleOpenModalProfile: (interpreterId: number) => void;
}
export default function InterpretersActsList({ listProfile, handleOpenModalProfile }: InterpretersActsListProps) {
    const languageOnCall: ILanguageOnline = {
        languageId: listProfile?.languageId || 0,
        language: listProfile?.language || '',
        users: listProfile?.users.filter(item => item.status === ETitleStatus.ON_CALL) || []
    }
    const languageOnline: ILanguageOnline = {
        languageId: listProfile?.languageId || 0,
        language: listProfile?.language || '',
        users: listProfile?.users.filter(item => item.status === ETitleStatus.ONLINE) || []
    }
    return (
        <>
            {!!languageOnline.users.length && <Typography.Title level={5}>
                Online
            </Typography.Title>}
            <ProfileDashboard listProfile={languageOnline} handleOpenModalProfile={handleOpenModalProfile} />
            <br hidden={!languageOnline.users.length} />
            {!!languageOnCall.users.length && <Typography.Title level={5}>
                On-Call
            </Typography.Title>}
            <ProfileDashboard listProfile={languageOnCall} handleOpenModalProfile={handleOpenModalProfile} />
        </>
    )
}
