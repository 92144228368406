import { Column, ColumnConfig } from "@ant-design/plots";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Grid,
  List,
  Row,
  Select,
  Skeleton,
  Spin,
} from "@pankod/refine-antd";
import { useCustom, usePermissions, useTranslate } from "@pankod/refine-core";
import {
  GET_CALL_PROGRESS,
  GET_CALL_STATISTICS,
  GET_LANGUAGE_DISTRIBUTION,
  LANGUAGES_API,
  LANGUAGE_ONLINE,
} from "api/baseApi";
import { EPermissions } from "constants/permission";
import { SocketContext } from "hooks/useContext/useSocketContext";
import { IDashboard } from "interfaces";
import { ILanguage } from "interfaces/languages";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { formatDate, formatDateTime } from "utils";
import { exportToCSV } from "utils/excelExporter";
import { CallActivityTable } from "./Table/CallActivity";
import { CallInProgressTable } from "./Table/CallInProgress";
import { InterpretersTable } from "./Table/Interpreters";
import { LanguageDistributionTable } from "./Table/LanguageDistribution";
import { axiosInstance } from "providers/axios";
import dayjs from "dayjs";
import { EFormatDateTime, TIME_AUTO_REFRESH } from "constants/constant";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";

const arrStatusByDashboard = [
  { value: 0, label: "All" },
  { value: 1, label: "Completed" },
  { value: 2, label: "Not Connected" },
  { value: 3, label: "In Progress" },
];

const columnDashboard = [
  "Date",
  "Start time",
  "Language",
  "Customer",
  "Department",
  "Interpreter name",
  "Interpreter ID",
  "End time",
  "Duration",
  "Connection Time",
  "Interpreter Pay",
  "Call total",
  "Status",
];


const { RangePicker } = DatePicker;

type AdminDashboardProps = {
  config: ColumnConfig;
};

export const AdminDashboard = ({ config }: AdminDashboardProps) => {
  const translate = useTranslate();
  const socket = useContext(SocketContext);
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const isTablet = !breakpoint.lg;
  const [startDate, setStartDate] = useState<any>();
  const [isLoadingDownloadExcel, setIsLoadingDownloadExcel] = useState(false);
  const [endDate, setEndDate] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [listOfLanguage, setListOfLanguage] = useState<ILanguage[]>([]);
  const [interpreterOnlinesData, setInterpreterOnlinesData] = useState<any>([]);

  const { data: callActivityData, isLoading: isLoadingCallActivity } =
    useCustom({
      url: GET_CALL_STATISTICS,
      method: "get",
      queryOptions : {
        refetchInterval : TIME_AUTO_REFRESH
      }, 
      config: {
        query: {
          status: selectedStatus,
          id: selectedLanguage || null,
          start: formatDate(startDate),
          end: formatDate(endDate),
        },
      },
    });
  const { data: callProgressData, isLoading: isLoadingCallProgress } =
    useCustom({
      url: GET_CALL_PROGRESS,
      method: "get",
      queryOptions : {
        refetchInterval : TIME_AUTO_REFRESH
      }, 
    });

  const { data: languagesData } = useCustom({
    url: LANGUAGES_API.getAll,
    method: "get",
    config: {
      query: {
        page: "",
        limit: "",
        search: "",
      },
    }
  });

  const { data: interpreterOnline, isLoading: isLoadingInterpreterOnline } =
    useCustom({
      url: LANGUAGE_ONLINE.getAll,
      method: "get",
      queryOptions: {
        refetchInterval : TIME_AUTO_REFRESH,
        onSuccess: (interpreter) => {
          setInterpreterOnlinesData(interpreter?.data);
        },
      },
    });

  const {
    data: languageDistributionData,
    isLoading: isLoadingLanguageDistribution,
  } = useCustom({
    url: GET_LANGUAGE_DISTRIBUTION,
    method: "get",
     queryOptions : {
      refetchInterval : TIME_AUTO_REFRESH
    }, 
    config: {
      query: {
        status: selectedStatus,
        id: selectedLanguage || "",
        start: formatDate(startDate),
        end: formatDate(endDate),
      },
    },
  });

  const newLanguage = {
    id: 0,
    name: "All",
    isActive: true,
    telephone: false,
    audio: true,
  };

  const handleChangeStatusDashboard = useCallback((selectedStatus: number) => {
    setSelectedStatus(selectedStatus);
  }, []);

  const handleSetDuration = useCallback((duration: any) => {
    setStartDate(duration[0]);
    setEndDate(duration[1]);
  }, []);

  const handleSelectedLanguage = useCallback((selectedLanguage: number) => {
    setSelectedLanguage(selectedLanguage);
  }, []);

  const customDashboardsData = (dashboardsData: IDashboard[]) => {
    return dashboardsData?.map((dashboard: IDashboard) => ({
      Date: formatDate(dashboard?.createdAt),
      "Start time": formatDateTime(dashboard?.startTime),
      Language: dashboard?.language,
      Customer: dashboard?.customerName,
      Department: dashboard?.departmentName,
      "Interpreter name": dashboard?.interpreterName,
      "Interpreter ID": dashboard?.intId,
      "End time": formatDateTime(dashboard?.endTime),
      Duration: dashboard?.duration,
      "Connection Time": dashboard?.connectTime,
      "Interpreter Pay": dashboard?.totalPay,
      "Call total": dashboard?.totalBill,
      Status: dashboard?.status,
    }));
  };



  const handleDowloadCSV = async () => {
    setIsLoadingDownloadExcel(true);
    const generateData = await axiosInstance.get(
      `api/call/get-call/download-csv?start=${formatDate(startDate) || ""
      }&end=${formatDate(endDate) || ""}&id=${selectedLanguage || ""
      }&status=${selectedStatus}`
    );
    exportToCSV(
      customDashboardsData(generateData?.data),
      "Dashboard",
      columnDashboard
    );
    setIsLoadingDownloadExcel(false);
  };

  useEffect(() => {
    socket.on("language-ready", (data) => {
      setInterpreterOnlinesData(data?.languages);
    });
    return () => {
      socket.off("language-ready");
    };
  }, [socket]);

  useEffect(() => {
    if (Array.isArray(languagesData?.data?.results)) {
      const newArray = [...(languagesData?.data?.results as any)];
      newArray.unshift(newLanguage);
      setListOfLanguage(newArray);
    } else {
    }
  }, [languagesData?.data?.results]);

  const callActsTableRef = useRef<HTMLDivElement>(null!);

  return (
    <Spin spinning={isLoadingDownloadExcel} size="large">
        <Row>
      <Col span={24}>
        <List title={translate("resource.dashboard")}>
          <Form
            layout="vertical"
            className={!isTablet ? "search-form-dashboard" : ""}
          >
            <Form.Item
              label="Date"
              name="date"
              className={!isTablet ? "search-item" : ""}
            >
              <RangePicker
                onChange={(values) => {
                  handleSetDuration(values);
                }}
                placeholder={[`From`, `To`]}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label="Language"
              name="languageId"
              className={!isTablet ? "search-item" : ""}
            >
              <Select
                defaultValue={0}
                showSearch
                value={selectedLanguage}
                onChange={handleSelectedLanguage}
                filterOption={(
                  input,
                  option: BaseOptionType | DefaultOptionType | undefined
                ) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {listOfLanguage?.map((language: ILanguage, index: number) => (
                  <Select.Option key={index} value={language.id}>
                    {language.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"
              className={!isTablet ? "search-item" : ""}
            >
              <Select
                defaultValue={0}
                value={selectedStatus}
                onChange={handleChangeStatusDashboard}
                showSearch
                filterOption={(
                  input,
                  option: BaseOptionType | DefaultOptionType | undefined
                ) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {arrStatusByDashboard.map((item, index: number) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="
              "
              name="end"
              className={!isTablet ? "search-item" : ""}
            >
              <Button className="btn-create" onClick={handleDowloadCSV}>
                Download as CSV
              </Button>
            </Form.Item>
          </Form>
          <Row className="my-2" gutter={16}>
            <Col md={12} xs={24}>
              {isLoadingCallActivity ? (
                <Skeleton active />
              ) : (
                <CallActivityTable
                  callActsTableRef={callActsTableRef}
                  listOfCallActivity={callActivityData?.data}
                />
              )}
            </Col>
            <Col md={12} xs={24}>
              {isLoadingInterpreterOnline ? (
                <Skeleton active />
              ) : (
                <InterpretersTable
                  listOfInterpretersOnline={interpreterOnline?.data}
                />
              )}
            </Col>
          </Row>
          {isLoadingCallProgress ? (
            <Skeleton active />
          ) : (
            <CallInProgressTable 
              listOfCallInProgress={callProgressData?.data}
            />
          )}
          <Row className="mt-3" gutter={16}>
            <Col md={12} xs={24} style={{ height: "100%" }}>
              {isLoadingLanguageDistribution ? (
                <Skeleton active />
              ) : (
                <LanguageDistributionTable
                  listOfLanguageDistribution={languageDistributionData?.data}
                />
              )}
            </Col>
            <Col
              md={12}
              xs={24}
              style={isMobile ? { marginTop: "10px" } : { maxHeight: "100%" }}
            >
              <List title="Call statistics charts">
                <Row gutter={16}>
                  <Col
                  style={{height : '400px'}}
                    className="gutter-row assets-summary-pie-chart"
                    span={isMobile ? 24 : 24}
                  >
                    <Column {...config} />
                  </Col>
                </Row>
              </List>
            </Col>
          </Row>
        </List>
      </Col>
    </Row>
    </Spin>
  );
};
