import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import {
  useCreate,
  useCustom,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { CREATE_INVOICE_PROCESS_CUSTOMER_API, CUSTOMER_API } from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { Dayjs } from "dayjs";
import { ICustomer } from "interfaces/customer";
import { ICustomerInvoice } from "interfaces/customerInvoice";
import React, { useEffect, useRef, useState } from "react";
import { openNotification} from "utils";
import "../style.less";

const { SearchOutlined, CloseOutlined } = Icons;

const { Title } = Typography;
export const ProcessCustomerInvoiceList: React.FC = () => {
  const t = useTranslate();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const [searchValue, setSearchValue] = useState("");
  const [activeCustomers, setActiveCustomers] = useState<ICustomerInvoice[]>(
    []
  );
  const [customer, setCustomer] = useState<BaseOptionType>();
  const checkedCustomer = useRef<ICustomerInvoice[]>([]);
  const { push } = useNavigation();
  const { formProps, form } = useForm<ICustomerInvoice>({
    action: EAction.CREATE,
  });
  const [dateStart, setDateStart] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const { data: customersData } = useCustom({
    url: CUSTOMER_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
        search: null,
      },
    },
    queryOptions: {
      onSuccess(customersData) {
        setActiveCustomers(
          customersData?.data?.results?.map((item: ICustomerInvoice) => ({
            ...item,
            checked: true,
          }))
        );
      },
    },
  });

  const { data, mutate, isLoading } = useCreate();

  const onFinish = (event: ICustomerInvoice) => {
    const userId = JSON.parse(localStorage.getItem("user") || "");
    if (customer) {
      mutate(
        {
          resource: CREATE_INVOICE_PROCESS_CUSTOMER_API,
          values: {
            fromDate: event.fromDate.toISOString().slice(0, 10),
            toDate: event.toDate.toISOString().slice(0, 10),
            userId: userId?.id,
            customerId: customer?.value,
          },
        },
        {
          onSuccess: (createData) => {
            form.resetFields();
            openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
            push("/invoice/view-print-customer-invoice");
          },
          onError: (error) => {
            openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);
          },
        }
      );
    } else {
      openNotification(MSG_NOTIFICATION.ERROR, "No customer to process!", EStatusNotification.ERROR, 2);
    }
  };

  useEffect(() => {
    setActiveCustomers(
      searchValue
        ? activeCustomers.filter((item: ICustomerInvoice) =>
            item.customerName?.toLowerCase().includes(searchValue)
          )
        : checkedCustomer.current
    );
  }, [searchValue, activeCustomers, checkedCustomer]);

  const handleRemoveCustomer = () => {
    setSearchValue("");
    setCustomer(undefined);
  };

  const onChangeCustomer = (customerId: string, option: BaseOptionType) => {
    setSearchValue(customerId);
    setCustomer(option);
  };

  const handleStartDate = (startDate: Dayjs | null) => {
    setDateStart(startDate ?? "");
  };

  const handleEndDate = (endDate: Dayjs | null) => {
    setEndDate(endDate ?? "");
  };

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Invoice</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {t("resource.processInvoiceCustomer")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(event) => onFinish(event as ICustomerInvoice)}
      >
        <div className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Invoice Period From Date"
            name="fromDate"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter start date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={dateStart}
              onChange={(e) => handleStartDate(e)}
              disabledDate={(current) => {
                return endDate ? current && current > endDate : false;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Invoice Period To Date"
            name="toDate"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter end date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={endDate}
              onChange={(e) => handleEndDate(e)}
              disabledDate={(current) => {
                return current && current < dateStart;
              }}
            />
          </Form.Item>
        </div>

        <Title level={5} className="title-invoice">
          Customer's List
        </Title>

        <Form.Item label="Search Customer by Name">
          <AutoComplete
            showSearch
            size="large"
            style={{
              width: isMobile ? "100%" : "50%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            options={customersData?.data?.results?.map(
              (item: ICustomer, index: number) => ({
                value: item.id,
                label: item.name,
              })
            )}
            value={customer?.label}
            filterOption={(
              searchValue,
              option: BaseOptionType | DefaultOptionType | undefined
            ) =>
              option?.label
                ?.toUpperCase()
                .indexOf(searchValue.toUpperCase()) !== -1
            }
            onChange={onChangeCustomer}
          >
            <Input
              suffix={
                <>
                  {customer?.label && (
                    <CloseOutlined onClick={handleRemoveCustomer} />
                  )}
                  <SearchOutlined />
                </>
              }
              bordered={false}
            />
          </AutoComplete>
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Process
        </Button>
      </Form>
    </List>
  );
};
