import { useEffect, useRef } from 'react';
import { RemoteVideoTrack, Track } from 'twilio-video';
import { IVideoTrack } from '../../types';



interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
}

export default function VideoTrack({ track, isLocal, priority }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if ((track as RemoteVideoTrack).setPriority && priority) {
        (track as RemoteVideoTrack).setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if ((track as RemoteVideoTrack).setPriority && priority) {
        (track as RemoteVideoTrack).setPriority(null);
      }
    };
  }, [track, priority]);

  return <video  ref={ref} />;
}
