import {
  AutoComplete,
  Button,
  Checkbox,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Space,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { useCustom, useNavigation, useTranslate } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { CUSTOMER_API } from "api/baseApi";
import { CustomModal } from "components/Modal/CustomModal";
import { EAction, ELanguage, EMediaType } from "constants/constant";
import {  ICustomer } from "interfaces/customer";
import React, { useEffect, useState } from "react";
import "../../../../styles/style.less";
import { CustomerAction } from "./action";
import CustomerActive from "./active";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
  TableColumn,
} from "utils/excelExporter";
import { axiosInstance } from "providers/axios";
import { IBillRateReport } from "interfaces/billingReport";

const { PlusSquareOutlined, SearchOutlined } = Icons;

interface IBillRate {
  spanishVideo?: number;
  spanishAudio?: number;
  allAudio?: number;
  allVideo?: number;
}

const COL_EXCEL = [
  "Customer",
  "Business Phone",
  "Cell Phone",
  "Email",
  "Home Address",
  "Mailing Address",
  "Access Code",
  "Reference #1",
  "Reference #2",
  "Reference #3",
  "Bill rate for Spanish Video",
  "Bill rate for Spanish Audio", 
  "Bill rate for All Audio", 
  "Bill rate for All Video"
];

const columns: TableColumn[] = [
  { key: "Customer" },
  { key: "Business Phone" },
  { key: "Cell Phone" },
  { key: "Home Address" },
  { key: "Mailing Address" },
  { key: "Access Code" },
  { key: "Reference #1" },
  { key: "Reference #2" },
  { key: "Reference #3" },
  { key: "Bill rate for Spanish Video" },
  { key: "Bill rate for Spanish Audio" },
  { key: "Bill rate for All Audio" },
  { key: "Bill rate for All Video" },
];

export const ManagerCustomerList: React.FC = () => {
  const translate = useTranslate();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const [isModalVisibleCreate, setIsModalVisibleCreate] = useState(false);
  const [isModalVisibleActive, setIsModalVisibleActive] = useState(false);

  const [inputValueCustomer, setInputValueCustomer] = useState<string>("");

  const [detail, setDetail] = useState<ICustomer>();

  const [activeCustomer, setActiveCustomer] = useState<ICustomer[]>([]);
  const [page, setPage] = useState<number>(1);
  const { data: customersData, refetch } = useCustom({
    url: CUSTOMER_API.getAll,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
        search: inputValueCustomer,
      },
    },
    queryOptions: {
      onSuccess: (customersData) => {
        setActiveCustomer(customersData?.data?.results);
      },
    },
  });

  const pageTotal = customersData?.data.total;
  const handleCreate = () => {
    setIsModalVisibleCreate(!isModalVisibleCreate);
  };
  const { push } = useNavigation();
  const edit = (customer: ICustomer) => {
    push(`/customer-details/${customer.id}`);
  };

  const onSelect = (
    customerId: string,
    option: { value: number; label: string }
  ) => {
    setInputValueCustomer(option.label);
  };

  const onChange = (customerId: string) => {
    setInputValueCustomer(customerId);
  };

  const active = (customer: ICustomer) => {
    setDetail(customer);
    setIsModalVisibleActive(true);
  };
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    setPage(1);
  }, [inputValueCustomer]);

  const fetchCustomerData = async () => {
    const response = await axiosInstance.get(
      CUSTOMER_API.getAll + `?page=${page}&limit=&search=${inputValueCustomer}`
    );
    return response?.data?.results;
  };



  const customCustomerData = (customerData: ICustomer[]) => {
    return customerData?.map((customer: ICustomer) => {
      const billRate: IBillRate | undefined = customer?.billRate?.reduce((obj: IBillRate, currentValue: IBillRateReport) => {
        return {
          ...obj,
          [`${ELanguage[currentValue.languageType]}${EMediaType[currentValue.rateTypeId]}`]: currentValue.billRate
        }
      }, {});

      return ({
        Customer: customer?.name,
        "Business Phone": customer?.businessPhone,
        "Cell Phone": customer?.cellPhone,
        Email: customer?.email,
        "Home Address": `${customer?.homeAddress?.address1 || ''} ${customer?.homeAddress?.city || ''} ${customer?.homeAddress?.zipCode || ''}`,
        "Mailing Address": `${customer?.mailingAddress?.address1 || ''} ${customer?.mailingAddress?.city || ''} ${customer?.mailingAddress?.zipCode || ''}`,
        "Access Code": customer?.accessCode,
        "Reference #1": customer?.reference1,
        "Reference #2": customer?.reference2,
        "Reference #3": customer?.reference3,
        "Bill rate for Spanish Audio": billRate?.spanishAudio, 
        "Bill rate for Spanish Video": billRate?.spanishVideo, 
        "Bill rate for All Audio": billRate?.allAudio,
        "Bill rate for All Video": billRate?.allVideo, 
      })
    });
  };

  const handleExportExcel = async () => {
    const customerData = await fetchCustomerData();
    exportToExcel(
      "Customer-Listing",
      customCustomerData(customerData),
      COL_EXCEL
    );
  };

  const handleExportCSV = async () => {
    const customerData = await fetchCustomerData();
    exportToCSV(
      customCustomerData(customerData),
      "Customer-Listing",
      COL_EXCEL
    );
  };



  const handleExportPDF = async () => {
    const customerData = await fetchCustomerData();
    exportToPDF({
      columns,
      data: customCustomerData(customerData),
      fileName: "Customer-Listing.pdf",
      name: "Listing Customer",
      nameInvoice: inputValueCustomer
        ? `Customer Name: ${inputValueCustomer ? inputValueCustomer : ""}`
        : "",
    });
  };

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Listing</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.customer")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      pageHeaderProps={{
        extra: (
          <Space wrap={isMobile}>
            <Button className="btn-create" onClick={handleCreate}>
              <PlusSquareOutlined /> Add Customer
            </Button>
            <Button className="btn-create" onClick={handleExportExcel}>
              Excel
            </Button>
            <Button className="btn-create" onClick={handleExportCSV}>
              CSV
            </Button>
            <Button className="btn-create" onClick={() => handleExportPDF()}>
              PDF
            </Button>
          </Space>
        ),
      }}
    >
      <Form layout="vertical">
        <Form.Item label="Search Customer by Name">
          <AutoComplete
            showSearch
            style={{
              width: isMobile ? "100%" : "50%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            value={inputValueCustomer}
            onSelect={onSelect}
            onChange={onChange}
          >
            <Input suffix={<SearchOutlined />} bordered={false} />
          </AutoComplete>
        </Form.Item>
      </Form>
      <Table
        dataSource={activeCustomer?.map((item: ICustomer, index: number) => ({
          ...item,
          key: index,
        }))}
        pagination={
          (pageTotal as number) > 10
            ? {
              current: page,
              total: pageTotal ? pageTotal : 1,
              onChange: handlePageChange,
              showTotal: () => `${pageTotal} total`,
            }
            : false
        }
        scroll={{ x: 800 }}
      >
        <Table.Column
          dataIndex="key"
          title="Nbr."
          render={(value) => (
            <Typography.Text>
              {value
                ? (page - 1) * 10 + value + 1
                : (page - 1) * 10 + value + 1}
            </Typography.Text>
          )}
        />
        <Table.Column
          dataIndex="name"
          title="Customer"
          render={(value, record: ICustomer) => (
            <Typography.Text
              style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
              onClick={() => edit(record)}
            >
              {value ? value : ""}{" "}
            </Typography.Text>
          )}
        />

        <Table.Column
          dataIndex="businessPhone"
          title="Phone"
          render={(value) => (
            <Typography.Text>{value ? value : ""} </Typography.Text>
          )}
        />
        <Table.Column<ICustomer>
          title="Is Active"
          dataIndex="active"
          render={(_, record) => {
            return (
              <Space>
                <Checkbox
                  className="checkbox"
                  onChange={() => active(record)}
                  checked={record.active}
                ></Checkbox>
              </Space>
            );
          }}
        />
      </Table>

      <CustomModal
        title="Customer Information"
        setIsModalVisible={setIsModalVisibleCreate}
        isModalVisible={isModalVisibleCreate}
        destroyOnClose
      >
        <CustomerAction
          option={EAction.CREATE}
          setIsModalVisible={setIsModalVisibleCreate}
          isModalVisible={isModalVisibleCreate}
          refetch={refetch}
        />
      </CustomModal>

      <CustomModal
        title="Confirmation"
        setIsModalVisible={setIsModalVisibleActive}
        isModalVisible={isModalVisibleActive}
        width={isMobile ? "100%" : "50%"}
      >
        <CustomerActive
          setIsModalVisible={setIsModalVisibleActive}
          isModalVisible={isModalVisibleActive}
          customer={detail}
          refetch={refetch}
        />
      </CustomModal>
    </List>
  );
};
