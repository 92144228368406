import { Button, Grid, List, Space } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";

import { useTranslate } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { CustomModal } from "components/Modal/CustomModal";
import { EAction } from "constants/constant";
import { UserContext } from "hooks/useContext/useUserContext";
import React, { useState } from "react";
import "../../../../styles/style.less";
import { CustomerAction } from "./action";

export const DetailsCustomer: React.FC = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const translate = useTranslate();
  const { goBack } = useNavigation();
  const { customerId } = React.useContext(UserContext);
  const [isModalVisibleClone, setIsModalVisibleClone] = useState(false);
  const [isModalVisibleUpdate, setIsModalVisibleUpdate] = useState(false);
  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Listing</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.detailsCustomer")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      <CustomerAction
        refetch={() => {}}
        option={EAction.DETAIL}
        idCustomer={customerId.toString()}
        setIsModalVisible={() => {}}
      />
      <CustomModal
        title="Customer Information(Cloning)"
        setIsModalVisible={setIsModalVisibleClone}
        isModalVisible={isModalVisibleClone}
      >
        <CustomerAction
          option={EAction.CLONE}
          setIsModalVisible={setIsModalVisibleClone}
          isModalVisible={isModalVisibleClone}
          idCustomer={customerId.toString()}
          refetch={() => {}}
        />
      </CustomModal>

      <CustomModal
        title="Update Customer"
        setIsModalVisible={setIsModalVisibleUpdate}
        isModalVisible={isModalVisibleUpdate}
      >
        <CustomerAction
          option={EAction.EDIT}
          setIsModalVisible={setIsModalVisibleUpdate}
          isModalVisible={isModalVisibleUpdate}
          idCustomer={customerId.toString()}
          refetch={() => {}}
        />
      </CustomModal>
      <Space wrap={isMobile}>
        <Button type="primary" onClick={() => setIsModalVisibleUpdate(true)}>
          Edit
        </Button>
        <Button type="primary" onClick={() => setIsModalVisibleClone(true)}>
          Clone
        </Button>
        <Button onClick={() => goBack()}>Back</Button>
      </Space>
    </List>
  );
};
