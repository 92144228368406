import { EMedia } from "constants/constant";
import { EPermissions } from "constants/permission";
import { useCallback, useEffect, useState } from "react";
import Video, {
  LocalVideoTrack,
  LocalAudioTrack,
  CreateLocalTrackOptions,
} from "twilio-video";

export default function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);

  const getLocalAudioTrack = useCallback((deviceId?: string) => {
    const options: CreateLocalTrackOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return Video.createLocalAudioTrack(options).then((newTrack) => {
      setAudioTrack(newTrack);
      return newTrack;
    });
  }, []);

  const getLocalVideoTrack = useCallback(
    (newOptions?: CreateLocalTrackOptions) => {
      const options: CreateLocalTrackOptions = {
        frameRate: 24,
        height: 720,
        width: 1280,
        name: `camera-${Date.now()}`,
      };
      return Video.createLocalVideoTrack(options).then((newTrack) => {
        setVideoTrack(newTrack);
        return newTrack;
      });
    },
    []
  );

  const getLocalMediaTrack = useCallback(() => {
    if (localStorage.getItem('role') === EPermissions.INTERPRETER.toString() && localStorage.getItem('type-media') === EMedia.AUDIO) {
      Video.createLocalTracks({
        video: false,
        audio: true,
      })
        .then((tracks) => {
          const audioTrack = tracks.find((track) => track.kind === "audio");
          if (audioTrack)
            setAudioTrack(audioTrack as LocalAudioTrack);
        })
        .finally(() => {
          setIsAcquiringLocalTracks(false);
        });
      return;
    }
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        return devices.some((device) => device.kind === "videoinput" && device.deviceId);
      })
      .then((isVideo) => {
        Video.createLocalTracks({
          video: isVideo
            ? {
              frameRate: 24,
              height: 720,
              width: 1280,
              name: `camera-${Date.now()}`,
            }
            : false,
          audio: true,
        })
          .then((tracks) => {
            const videoTrack = tracks.find((track) => track.kind === "video");
            const audioTrack = tracks.find((track) => track.kind === "audio");
            if (videoTrack)
              setVideoTrack(videoTrack as LocalVideoTrack);
            if (audioTrack)
              setAudioTrack(audioTrack as LocalAudioTrack);
          })
          .finally(() => {
            setIsAcquiringLocalTracks(false);
          });
      });
  }, []);

  useEffect(() => {
    setIsAcquiringLocalTracks(true);
    getLocalMediaTrack();
  }, [getLocalMediaTrack]);

  const localTracks = [audioTrack, videoTrack].filter(
    (track) => track !== undefined
  ) as (LocalAudioTrack | LocalVideoTrack)[];

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
  };
}
