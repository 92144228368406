import useLocalTracks from "hooks/useLocalTracks";
import useRoom from "hooks/useRoom";
import { createContext } from "react";
import {
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
} from "twilio-video";

export interface IVideoContext {
  room: Room;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  isAcquiringLocalTracks: boolean;
  connect: (token: string, nameRoom: string) => Promise<void>;
  disconnect: () => void;
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions
  ) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
}

export const VideoContext = createContext<IVideoContext>(null!);

export function VideoProvider({ children }: any): JSX.Element {
  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
  } = useLocalTracks();
  const { room, disconnect, connect, isConnecting } = useRoom(localTracks);
  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        isAcquiringLocalTracks,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        disconnect,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
}