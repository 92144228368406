import { Col, Row, Spin } from "@pankod/refine-antd";
import { AssetsSummaryTable } from "./languages/asset-summary-table";
import { AssetsSummaryPieChart } from "./languages/asset-summary-pie-chart";
import { useState } from "react";
import { useCustom } from "@pankod/refine-core";
import { CALL_PERCENT_TOTAL_API } from "api/baseApi";
import dayjs, { Dayjs } from "dayjs";
import { EFormatDateTime } from "constants/constant";

export interface IReportLanguage {
  calls: string;
  minutes: number;
  languageId: number;
  name: string;
  percentCall: string;
  percentMinute: string;
}

type DashboardCustomerProps = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  isSearching: boolean;
};
export const DashboardCustomer = (props: DashboardCustomerProps) => {
  const { startDate, endDate, isSearching } = props;
  const [reportLanguagesData, setReportLanguagesData] = useState<
    IReportLanguage[]
  >([]);

  const { data: reportLanguage, isLoading: isLoadingData } = useCustom({
    url: CALL_PERCENT_TOTAL_API,
    method: "get",
    config: {
      query: {
        start: isSearching
          ? startDate
            ? dayjs(startDate).format(EFormatDateTime.DATE)
            : ""
          : "",
        end: isSearching
          ? endDate
            ? dayjs(endDate).format(EFormatDateTime.DATE)
            : ""
          : "",
      },
    },

    queryOptions: {
      onSuccess: (callTotalByLanguage) => {
        setReportLanguagesData(
          callTotalByLanguage?.data?.calls?.map((item: IReportLanguage) => ({
            ...item,
            percentCall: Number(item.percentCall),
          }))
        );
      },
    },
  });

  return (
    <Row gutter={16} style={{ paddingTop: "40px" }}>
      <Col className="gutter-row assets-summary-pie-chart" span={24}>
        <Spin spinning={isLoadingData}>
          <AssetsSummaryPieChart
            isSearching={isSearching}
            reportLanguagesData={reportLanguagesData}
            startDate={startDate}
            endDate={endDate}
          ></AssetsSummaryPieChart>
        </Spin>
      </Col>
      <Col
        className="gutter-row assets-summary-table"
        span={24}
        style={{ paddingTop: "30px" }}
      >
        <Spin spinning={isLoadingData}>
          <AssetsSummaryTable
            reportLanguagesData={reportLanguagesData}
            totalCalls={reportLanguage?.data?.totalCalls?.totalCount}
            isSearching={isSearching}
            startDate={startDate}
            endDate={endDate}
          ></AssetsSummaryTable>
        </Spin>
      </Col>
    </Row>
  );
};
