import { ColumnConfig } from "@ant-design/plots";
import { useCustom, usePermissions } from "@pankod/refine-core";
import { GET_TOTAL_BY_MONTH, GET_TOTAL_USER } from "api/baseApi";
import { TIME_AUTO_REFRESH } from "constants/constant";
import { EPermissions } from "constants/permission";
import React, { useCallback, useRef, useState } from "react";
import { AdminDashboard } from "./AdminDashboard";
import { CustomersDashboard } from "./CustomerDashboard";
import { InterpretersDashboard } from "./InterpreterDashboard";
import "./style.css";

interface ICallTotalMonth{
  Calls : number;
  id : number;
  month : string;
}

export const DashboardPage: React.FC = () => {
  
  const { data: permissionsData } = usePermissions();
  
  const [dataDashboard, setDataDashboard] = useState<any>([]);
  const refDetail = useRef<HTMLDivElement>(null);
  const [isProfileDetailVisible, setIsProfileDetailVisible] = useState(false);
  // useOnClickOutside(refDetail, () => setIsProfileDetailVisible(false));

  const { data: totalByMonthsData } = useCustom<ICallTotalMonth[]>({
    url: GET_TOTAL_BY_MONTH,
    method: "get",
    queryOptions: {
      refetchInterval: TIME_AUTO_REFRESH,
      enabled:
        permissionsData?.roleId === EPermissions.ADMIN ||
        permissionsData?.roleId === EPermissions.INTERPRETER,
    },
  });


  const { data: totalCall } = useCustom<any>({
    url: GET_TOTAL_USER,
    method: "get",
    queryOptions: {
      enabled: permissionsData?.roleId !== EPermissions.ADMIN,
    },
  });

  // useEffect(() => {
  //   if (totalByMonthsData?.data) {
  //     setDataDashboard(totalByMonthsData?.data);
  //   } else {
  //     setDataDashboard([]);
  //   }
  // }, [totalByMonthsData?.data]);

  const configColDashboard = useCallback(() =>{
    return ({
      data: totalByMonthsData?.data || [],
      xField: "month",
      yField: "Calls",
      columnWidthRatio: 0.6,
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
          style: {
            fill: "#222",
            opacity: 0.6,
          },
        },
        tickCount: 5,
      },
    }) 
  }, [totalByMonthsData?.data])

  const configColumnAdmin  = () : ColumnConfig =>  {
    return ({
      ...configColDashboard(),
      label: {
        position: "top",
        style: {
          fill: "#222",
          fontWeight: 'bold'
        },
      },
      meta: {
        Calls: {
          formatter: (value: number) => value === 0 ? null : value,
        },
      },
    })
  }

  const RenderDashboard = (roleId : number) => {
    switch (roleId) {
      case EPermissions.ADMIN:
        return <AdminDashboard config={configColumnAdmin()} />;
      case EPermissions.CUSTOMER:
        return <CustomersDashboard totalCall={totalCall} />;
      case EPermissions.INTERPRETER:
        return <InterpretersDashboard totalCall={totalCall} config={configColDashboard()} />
      //no default
    }
  }

  return (
    <>
      {RenderDashboard(permissionsData?.roleId)}
    </>
  );
};
