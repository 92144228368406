import { ReactNode, createContext, useState } from "react";

interface ICollagedContext {
  collapsed: boolean;
  isShowPerson: boolean;
  isShowMenu: boolean;
  setIsShowPerson: (params: boolean) => void;
  setCollapsed: (params: boolean) => void;
  setIsShowMenu: (params: boolean) => void;
}

const CollagedContextDefault = {
  collapsed: false,
  isShowPerson: false,
  isShowMenu: false,
  setIsShowPerson: () => {},
  setIsShowMenu: () => {},
  setCollapsed: () => {},
};

const CollageContext = createContext<ICollagedContext>(CollagedContextDefault);

type CollageProviderProps = {
  children : ReactNode;
  value : React.Context<ICollagedContext>;
}

function CollageProvider({ children }: CollageProviderProps): JSX.Element {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isShowPerson, setIsShowPerson] = useState<boolean>(false);
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  return (
    <CollageContext.Provider
      value={{
        collapsed,
        isShowPerson,
        isShowMenu,
        setIsShowPerson,
        setIsShowMenu,
        setCollapsed,
      }}
    >
      {children}
    </CollageContext.Provider>
  );
}

export { CollageContext, CollageProvider };
