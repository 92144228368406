import { Button } from "@pankod/refine-antd";
import { useEffect } from "react";
import { BaseKey, useUpdate } from "@pankod/refine-core";
import { Space } from "antd";
import { IStandard } from "interfaces/standard";
import { ACTIVE_BILL_RATE_API, DEACTIVE_BILL_RATE_API } from "api/baseApi";
import { openNotification } from "utils";
import { EStatusNotification, MSG_NOTIFICATION } from "constants/constant";

type BillRateActiveProps = {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  billRate: IStandard | undefined;
  page: number;
  handleChange: (page: number) => void;
};

const BillRateActive = (props: BillRateActiveProps) => {
  const { setIsModalVisible, billRate, page, handleChange } = props;
  const { mutate, data: activeData, isLoading: isLoadingActive } = useUpdate();

  const handleChangeActive = () => {
    if (billRate?.isActive === true) {
      mutate({
        resource: DEACTIVE_BILL_RATE_API,
        id: billRate?.id,
        values: {
          name: billRate,
        },
      });
    } else {
      mutate({
        resource: ACTIVE_BILL_RATE_API,
        id: billRate?.id as BaseKey,
        values: {
          name: billRate,
        },
      });
    }
  };

  useEffect(() => {
    if (activeData?.data.status === 200) {
      setIsModalVisible(false);
      handleChange(page);
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, activeData?.data?.message, EStatusNotification.SUCCESS);
    }
  }, [activeData, setIsModalVisible]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Space
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
      }}
    >
      <div>
        You are about to change the Active Status of this{" "}
        <strong>Pay/Bill Rates</strong>. Please select <strong>Change</strong>{" "}
        to continue or <strong>Cancel</strong> to go back
      </div>
      <Space style={{ marginTop: 10 }}>
        <Button
          onClick={handleChangeActive}
          className="btn-create"
          style={{ marginRight: "10px", backgroundColor: "#" }}
        >
          Change
        </Button>
        <Button onClick={handleCancel}>Cancel </Button>
      </Space>
    </Space>
  );
};

export default BillRateActive;
