import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Switch,
  Select,
  Grid,
} from "@pankod/refine-antd";
import { EAction } from "constants/constant";
import "../../../../../styles/style.less";
import {
  defaultInvoiceStyleData,
  detailCenterTabPane,
  detailLeftTabPane,
  detailRightTabPane,
} from "../DataFieldCustomer";
type DetailsTabPaneProps = {
  option?: string;
};
const px = "20px";
export const DetailsTabPane = ({ option }: DetailsTabPaneProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  return (
    <Form.List name="detailsTabPane">
      {(field, { add, remove }) => {
        return (
          <>
            <Row gutter={16}>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                {detailLeftTabPane.map((item) => (
                  <Form.Item
                    label={item.label}
                    name={item.nameProp}
                    key={item.key}
                    style={{ marginBottom: px }}
                    rules={[
                      {
                        required: item.required,
                        message: item.message,
                      },
                    ]}
                    normalize={(value, prevVal, prevVals) => value.trimStart()}
                  >
                    <Input
                      readOnly={option === EAction.DETAIL}
                      size="large"
                      maxLength={50}
                    />
                  </Form.Item>
                ))}
                <Row className="row-number-first" justify="space-between">
                  <Col span={isMobile ? 24 : 18}>
                    <Form.Item label="Business Phone Number" name="busPhone">
                      <Input
                        readOnly={option === EAction.DETAIL}
                        type="number"
                        size="large"
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={isMobile ? 24 : 5}>
                    <Form.Item label="Ext" name="extBusPhone">
                      <Input
                        readOnly={option === EAction.DETAIL}
                        type="number"
                        size="large"
                        maxLength={5}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="row-number" justify="space-between">
                  <Col span={isMobile ? 24 : 18}>
                    <Form.Item label="Cell Phone Number" name="cellPhone">
                      <Input
                        readOnly={option === EAction.DETAIL}
                        type="number"
                        size="large"
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={isMobile ? 24 : 5}>
                    <Form.Item label="Ext" name="extCellPhone">
                      <Input
                        readOnly={option === EAction.DETAIL}
                        type="number"
                        size="large"
                        maxLength={5}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span={isMobile ? 24 : 18}>
                    <Form.Item label="Fax Number" name="faxNumber">
                      <Input
                        readOnly={option === EAction.DETAIL}
                        type="number"
                        size="large"
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={isMobile ? 24 : 5}>
                    <Form.Item label="Ext" name="extFaxNumber">
                      <Input
                        readOnly={option === EAction.DETAIL}
                        type="number"
                        size="large"
                        maxLength={5}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col className="gutter-row" span={isMobile ? 24 : 12}>
                <Row className="row-options">
                  <Col span={12}>
                    <Form.Item
                      label="Is Active"
                      name="isActive"
                      valuePropName="checked"
                      initialValue={true}
                    >
                      <Checkbox disabled={option === EAction.DETAIL} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Broadcast OFF"
                      name="broadCast"
                      valuePropName="checked"
                    >
                      <Switch
                        disabled={option === EAction.DETAIL}
                        style={{ width: "10%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {detailRightTabPane.map((item) => (
                  <Form.Item
                    label={item.label}
                    name={item.nameProp}
                    key={item.key}
                    style={{ marginBottom: px }}
                    rules={[
                      {
                        required: item.required,
                        type: item.type === "email" ? "email" : "string",
                        message: item.message,
                      },
                    ]}
                  >
                    <Input
                      readOnly={option === EAction.DETAIL}
                      size="large"
                      maxLength={50}
                    />
                  </Form.Item>
                ))}
                <Form.Item
                  label="Invoice Style"
                  name="invoiceStyle"
                  rules={[
                    {
                      required: true,
                      message: "Please select invoice style",
                    },
                  ]}
                >
                  <Select
                    disabled={option === EAction.DETAIL}
                    className="ant-select-selector"
                    size="large"
                    options={defaultInvoiceStyleData}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Notes"
                  name="notes"
                  style={{ flexDirection: "column" }}
                >
                  <Input.TextArea
                    readOnly={option === EAction.DETAIL}
                    maxLength={200}
                    rows={5}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {" "}
              {detailCenterTabPane.map((item) => (
                <Col span={isMobile ? 24 : 8} key={item.key}>
                  <Form.Item
                    label={item.label}
                    name={item.nameProp}
                    rules={[
                      {
                        required: item.required,
                        message: item.message,
                      },
                    ]}
                  >
                    <Input
                      readOnly={option === EAction.DETAIL}
                      size="large"
                      maxLength={50}
                    />
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </>
        );
      }}
    </Form.List>
  );
};
