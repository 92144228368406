import {
  Button,
  DatePicker,
  Form,
  Grid,
  List,
  Space,
} from "@pankod/refine-antd";
import { Breadcrumb } from "antd";
import { Dayjs } from "dayjs";
import React, { useState } from "react";

export const IncomeReportsList: React.FC = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.sm;

  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const handleStartDate = (startDate: Dayjs | null) => {
    setStartDate(startDate ?? "");
  };

  const handleEndDate = (endDate: Dayjs | null) => {
    setEndDate(endDate ?? "");
  };

  const isSearchValid = startDate && endDate;

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Report</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            Income Report
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
        <Form.Item
          label="Date of Service From"
          rules={[
            {
              required: true,
              message: "Please enter start date",
            },
          ]}
          className={!isMobile ? "search-item" : ""}
        >
          <DatePicker
            style={{ width: "100%" }}
            value={startDate}
            onChange={(e) => handleStartDate(e)}
            disabledDate={(current) => {
              return endDate ? current && current > endDate : false;
            }}
          />
        </Form.Item>
        <Form.Item
          label="Date of Service To"
          rules={[
            {
              required: true,
              message: "Please enter end date",
            },
          ]}
          className={!isMobile ? "search-item" : ""}
        >
          <DatePicker
            style={{ width: "100%" }}
            value={endDate}
            disabledDate={(current) => {
              return current && current < startDate;
            }}
            onChange={(e) => handleEndDate(e)}
          />
        </Form.Item>
      </Form>
      <Space wrap={isMobile}>
        <Button className="search-report-call" disabled={!isSearchValid}>
          Excel report
        </Button>
        <Button className="search-report-call" disabled={!isSearchValid}>
          CSV report
        </Button>
        <Button className="search-report-call" disabled={!isSearchValid}>
          PDF report
        </Button>
      </Space>
    </List>
  );
};
