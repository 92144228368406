import {
  AutoComplete,
  Button,
  Checkbox,
  Empty,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Result,
  Space,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { useCustom, useTranslate } from "@pankod/refine-core";
import { CUSTOMER_API, DEPARTMENT_API } from "api/baseApi";
import { CustomModal } from "components/Modal/CustomModal";
import { IDepartment } from "interfaces/department";
import React, { useEffect, useState } from "react";
import { DepartmentCreate } from "./create";
import { DepartmentsUpdate } from "./update";
import { Breadcrumb } from "antd";
import DepartmentActive from "./active";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import useNotification from "hooks/useNotification";

const {
  PlusSquareOutlined,
  SearchOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} = Icons;
export const DepartmentsList: React.FC = () => {
  const translate = useTranslate();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleUpdate, setIsModalVisibleUpdate] = useState(false);
  const [isModalVisibleActive, setIsModalVisibleActive] = useState(false);
  const [isShowNotification, setIsShowNotification] = useState(false);

  const [detailDepartment, setDetailDepartment] = useState<IDepartment>();
  const [activeDepartment, setActiveDepartment] = useState<IDepartment[]>([]);

  const [valueCustomer, setValueCustomer] = useState("");
  const [customerId, setCustomerId] = useState<string>();
  const [page, setPage] = useState<number>(1);

  const [isSearch, setIsSearch] = useState(false);
  const { openNotificationInfo, contextHolder } = useNotification();

  const { data: customerData } = useCustom({
    url: CUSTOMER_API.getAll,
    method: "get",
    config: {
      query: {
        limit: null,
        page: null,
        search: valueCustomer,
      },
    },
  });

  const { data: departmentsData, refetch } = useCustom({
    url: DEPARTMENT_API.getByCustomer,
    method: "get",
    config: {
      query: {
        limit: "",
        page: page,
        id: customerId,
      },
    },
    queryOptions: {
      enabled: isSearch,
      onSuccess: (department) => {
        setActiveDepartment(department?.data?.results);
      },
    },
  });

  const columns = [
    {
      title: "Nbr.",
      key: "key",
      render: (value: number) => (
        <TextField
          value={
            value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
          }
        />
      ),
    },
    {
      title: `Department Name`,
      key: "name",
      render: (value: string, record: IDepartment) => (
        <TextField
          value={value ? value : ""}
          style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
          onClick={() => edit(record)}
        />
      ),
    },
    {
      title: `Contact Name`,
      key: "contactName",
      render: (value: string) => <TextField value={value ? value : ""} />,
    },
    {
      title: `Phone`,
      key: "phoneNumber",
      render: (value: string) => <TextField value={value ? value : ""} />,
    },
    {
      title: `Is Active`,
      key: "isActive",
      render: (value: boolean, record: IDepartment) => (
        <Checkbox checked={value} onChange={() => active(record)} />
      ),
    },
  ];

  const handleCreate = () => {
    handleOpenModel();
  };

  const handleOpenModel = () => {
    setIsModalVisible(!isModalVisible);
  };

  const edit = (department: IDepartment) => {
    setDetailDepartment(department);
    setIsModalVisibleUpdate(true);
  };

  const active = (department: IDepartment) => {
    setDetailDepartment(department);
    setIsModalVisibleActive(true);
  };

  const pageTotal = activeDepartment?.length;

  const onSelect = async (customerId: string, option: BaseOptionType) => {
    setPage(1);
    setIsSearch(true);
    setCustomerId(customerId);
    setValueCustomer(option.label);
    setIsShowNotification(true);
  };

  const onChange = (customerId: string, option: BaseOptionType) => {
    setValueCustomer(customerId);
  };

  const handleRemoveItem = () => {
    setValueCustomer("");
    setActiveDepartment([]);
    setIsSearch(false);
    setIsShowNotification(false);
  };

  const handleChange = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    (async () => {
      if (!valueCustomer) {
        setActiveDepartment([]);
      }
    })();
  }, [valueCustomer]);

  let localeNoData = {
    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
  };
  let locale = {
    emptyText: <Result subTitle="Please select department search" />,
  };

  useEffect(() => {
    refetch();
  }, [isModalVisible, isModalVisibleUpdate, isModalVisibleActive]);

  useEffect(() => {
    if (isShowNotification && activeDepartment.length === 0) {
      openNotificationInfo(
        "No data found",
        "There are no results matching Department",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  }, [activeDepartment]);

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Listing</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.department")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      pageHeaderProps={{
        extra: (
          <Space>
            {valueCustomer && (
              <Button className="btn-create" onClick={handleCreate}>
                <PlusSquareOutlined /> {translate("department.title.create")}
              </Button>
            )}
          </Space>
        ),
      }}
    >
      {contextHolder}
      <CustomModal
        title={translate("department.label.title.info")}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      >
        <DepartmentCreate
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
          customerId={customerId}
        />
      </CustomModal>

      <CustomModal
        title={translate("department.label.title.info")}
        setIsModalVisible={setIsModalVisibleUpdate}
        isModalVisible={isModalVisibleUpdate}
      >
        <DepartmentsUpdate
          setIsModalVisible={setIsModalVisibleUpdate}
          isModalVisible={isModalVisibleUpdate}
          department={detailDepartment}
        />
      </CustomModal>

      <CustomModal
        title="Confirmation"
        setIsModalVisible={setIsModalVisibleActive}
        isModalVisible={isModalVisibleActive}
        width={isMobile ? "100%" : "50%"}
      >
        <DepartmentActive
          setIsModalVisible={setIsModalVisibleActive}
          isModalVisible={isModalVisibleActive}
          department={detailDepartment}
        />
      </CustomModal>

      <Form layout="vertical">
        <Form.Item label="Search Customer by Name">
          <AutoComplete
            showSearch
            style={{
              width: isMobile ? "100%" : "50%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            options={customerData?.data?.results?.map(
              (item: IDepartment, index: number) => ({
                value: item.id,
                label: item.name,
              })
            )}
            value={valueCustomer}
            filterOption={(
              valueCustomer,
              option: BaseOptionType | DefaultOptionType | undefined
            ) =>
              option?.label
                ?.toUpperCase()
                .indexOf(valueCustomer.toUpperCase()) !== -1
            }
            onSelect={onSelect}
            onChange={onChange}
          >
            <Input
              suffix={
                <>
                  {valueCustomer && (
                    <CloseOutlined onClick={handleRemoveItem} />
                  )}
                  <SearchOutlined />
                </>
              }
              bordered={false}
            />
          </AutoComplete>
        </Form.Item>
      </Form>

      <Table
        locale={valueCustomer ? localeNoData : locale}
        dataSource={
          customerId
            ? activeDepartment?.map((item: IDepartment, index: number) => ({
                ...item,
                key: index,
              }))
            : []
        }
        pagination={
          pageTotal > 10
            ? {
                total: pageTotal ? pageTotal : 1,
                current: page,
                onChange: handleChange,
                showTotal: () => `${pageTotal} total`,
              }
            : false
        }
        scroll={{ x: 800 }}
      >
        {columns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
    </List>
  );
};
