import { IChatState } from "interfaces/chat";
import { ReactNode, createContext, useState } from "react";


interface IChatStateContext {
  chatState: IChatState;
  setChatState: React.Dispatch<React.SetStateAction<IChatState>>;
}

export const chatStateContextDefault: IChatStateContext = {
  chatState: {
    isAccept: false,
    isPending: false,
    isCancel: false,
  },
  setChatState: () => {},
};

const ChatStateContext = createContext<IChatStateContext>(
  chatStateContextDefault
);

type ChatStateProviderProps = {
  children : ReactNode;
  value : React.Context<IChatStateContext>;
};

function ChatStateProvider({ children } : ChatStateProviderProps) : JSX.Element {
  const [chatState, setChatState] = useState<IChatState>(
    chatStateContextDefault.chatState
  );
  return (
    <ChatStateContext.Provider value={{chatState, setChatState}}>
      {children}
    </ChatStateContext.Provider>
  );
}

export { ChatStateContext, ChatStateProvider };
