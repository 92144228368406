import { CopyOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Form,
  Icons,
  Input,
  Row,
  Space,
  Tooltip,
  Typography
} from "@pankod/refine-antd";
import { Button } from "antd";
import { CSSProperties, useCallback, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./style.css";
import { isEmpty } from "lodash";

type AddPersonModalProps = {
  params: string;
  handleCopyUrlToClipboard: () => void;
  handleInviteWithEmail: (email: string) => void;
  handleInviteWithPhone: (phone: string) => void;
};

const EMAIL_PATTERN = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
const { InfoCircleOutlined } = Icons;

const container: CSSProperties = {
  marginTop: "30px",
};

const PhoneInputCustom = (props: any) => {
  return (
    <>
      <PhoneInput
        specialLabel={""}
        defaultCountry="vn"
        inputStyle={{
          borderColor: props.touched && props.error && "red",
        }}
        {...props}
        onChange={(value) => {
          if(value){
            props.setIsInvalidPhone(false);
          }
          props.setPhone(value);
        }}
        isValid={!props.isInvalidPhone}
      />
      {props.touched && props.error && (
        <p
          style={{ color: "red" }}
          className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense"
        >
          {props.error} sadfasdf
        </p>
      )}
    </>
  );
};

export default function AddPersonModal({
  params,
  handleInviteWithEmail,
  handleCopyUrlToClipboard,
  handleInviteWithPhone,
}: AddPersonModalProps) {
  const [phone, setPhone] = useState("");
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);

  return (
    <>
      <Row style={container} gutter={24}>
        <Col span={20}>
          <Typography.Paragraph>
            <span style={{ fontSize: '16px' }}>Send this link to join the call</span>
            <Tooltip color="#646464" className="tool-tip" openClassName="tool-tip" placement="topLeft" title="The link can only be used once to join the call. If someone else has used it before, it will no longer be valid.">
              <InfoCircleOutlined style={{ color: '#52c41a', cursor: 'pointer', marginLeft: '8px' }} />
            </Tooltip>

            <strong onCopy={e => e.preventDefault()}>
              {`${process.env.REACT_APP_CALL_INVITE_URL}/?roomSid=${params}`}
            </strong>
          </Typography.Paragraph>
        </Col>
        <Col span={4}>
          <Button onClick={handleCopyUrlToClipboard} icon={<CopyOutlined />} />
        </Col>
      </Row>
      <Divider />
      <Typography.Title level={5}>Add an email to invite</Typography.Title>
      <Form
        name="basic"
        onFinish={values=>handleInviteWithEmail(values.email)}
        autoComplete="off"
      >
        <Form.Item
          label=""
          name="email"
          rules={[{ required: true, message: 'Please input valid email!', pattern: EMAIL_PATTERN }]}
        >
          <Input />
        </Form.Item>
        <Button
          type="primary"
          style={{background: "#ff5252" }}
          htmlType="submit"
        >
          Invite via email
        </Button>
      </Form>
      <Divider />
      <Typography.Title level={5}>
        Or add a phone number to call
      </Typography.Title>
      <PhoneInputCustom
        label={"Mobile Phone"}
        req={true}
        helperText={""}
        error={true}
        isSelect={false}
        setPhone={setPhone}
        phone={phone}
        setIsInvalidPhone={setIsInvalidPhone}
        isInvalidPhone={isInvalidPhone}
      />
      <Space style={{ marginTop: 20 }}>
        <Button
          style={{ background: "#ff5252" }}
          type="primary"
          onClick={() => {
            if(phone){
              handleInviteWithPhone(phone)
              return;
            }
            setIsInvalidPhone(true);
          }}
        >
          Invite via sms
        </Button>
      </Space>
    </>
  );
}
