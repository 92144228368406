import {
  AntdLayout,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from "@pankod/refine-antd";
import { useLogin, useNavigation, useTranslate } from "@pankod/refine-core";
import { LOGIN_API } from "api/baseApi";
import { openNotification  } from "utils";
import "./styles.css";
import { useRef } from "react";
import { EStatusNotification, MSG_NOTIFICATION } from "constants/constant";

const { Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
  resourceName: string;
}
export const LoginPage: React.FC = () => {
  const translate = useTranslate();
  const { push } = useNavigation();
  const [form] = Form.useForm<ILoginForm>();
  const { mutate: login, isLoading } = useLogin<ILoginForm>();
  const CardTitle = (
    <Title level={3} className="title">
      {translate("pages.login.title")}
    </Title>
  );

  const handleRedirectResetPass = () => {
    push("/forgot-password");
  };

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img
                className="logo-login"
                src="/images/global/logo_v2.png"
                alt="Refine Logo"
              />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login(
                    {
                      ...values,
                      resourceName: LOGIN_API,
                    },
                    {
                      onError: (error) => {
                        openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
                      },
                    }
                  );
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="username"
                  label={translate("pages.login.username", "Username")}
                  rules={[{ required: true }]}
                >
                  <Input
                    size="large"
                    placeholder={translate("pages.login.username", "Username")}
                    className="input"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={translate("pages.login.password", "Password")}
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                  hasFeedback
                >
                  <Input.Password size="large" placeholder="●●●●●●●●" autoComplete="on"/>
                </Form.Item>

                <div style={{ marginBottom: "12px" }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{
                        fontSize: "12px",
                      }}
                      className="input"
                    >
                      {translate("pages.login.remember", "Remember me")}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="resetPassword"
                    valuePropName="checked"
                    noStyle
                  >
                    <a
                      className="login-form-forgot"
                      onClick={handleRedirectResetPass}
                    >
                      Forgot password
                    </a>
                  </Form.Item>
                </div>
                <Button
                  type="primary"
                size="large"
                  htmlType="submit"
                  block
                  loading={isLoading}
                  className="btn-login"
                >
                  {translate("pages.login.signin")}
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
