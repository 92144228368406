import { ERoomStatus } from "constants/constant";
import { useContext, useEffect } from "react";
import { ChatNotificationContext } from "./useContext/useChatNotificationContext";
import { ChatStateContext } from "./useContext/useChatStateContext";
import { socket } from "./useContext/useSocketContext";
import { useSoundCallContext } from "./useContext/useSoundCallContext";


export default function useSocketChatEvent() {
  const {recieveSound, missedSound, requestSound} = useSoundCallContext();
  const { chatNotification, setChatNotification } = useContext(ChatNotificationContext);
  const { chatState, setChatState } = useContext(ChatStateContext);
 
  useEffect(() => {
    socket.on("receive-call-id", (data) => {
      setChatNotification({ ...chatNotification, callId: data.callId });
      requestSound.play();
    });
    return () => {
      socket.off("receive-call-id");
    };
  }, []);

  useEffect(() => {
    socket.on("interpreter-logout", async () => {
      socket.emit("logout", {
        clientId: socket.id,
      });
      socket.disconnect();
      localStorage.clear();
      window.location.pathname = "/login";
    });
    return () => {
      socket.off("interpreter-logout");
    };
  }, []);

  useEffect(() => {
    socket.on("interpreter-notify", (chatNotificationData) => {
      recieveSound.play();
      setChatNotification({
        ...chatNotificationData,
        mediaType: chatNotificationData.type,
      });
      setChatState({ ...chatState, isAccept: false, isPending: true });
    });

    socket.on("cancel-call", () => {
      if (localStorage.getItem('room-state') === ERoomStatus.CONNECTED) {
        missedSound.play();
      }
      recieveSound.pause();
      setChatState({ ...chatState, isPending: false})
    });

    return () => {
      socket.off("interpreter-notify");
      socket.off("cancel-call");
    };
  }, []);
}
