import { ReactNode, createContext, useState } from "react";

export interface IProfile {
  firstname : string;
  lastname : string;
  avatarpath? : string;
  customerName? : string;
  departmentName? : string;
  email : string;
  faxNumber : number;
  gender : string;
  id : string;
  interpreterId? : number;
  requesterId? : number;
  language? : Array<{
    id : number;
    language : string;
  }>
  phone : string;
  roleId? : number;
  status : string;
  notice : string;
  subDepartmentName? : string;
  username : string;
}

interface IUserInfoContext{
  userInfo : IProfile;
  setUserInfo : React.Dispatch<React.SetStateAction<IProfile>>;
}

const UserInfoContext = createContext<IUserInfoContext>(null!);

type UserInfoProviderProps = {
  children : ReactNode;
  value : React.Context<IUserInfoContext>;
}

function UserInfoProvider({ children }: UserInfoProviderProps): JSX.Element {
  const [userInfo, setUserInfo] = useState<IProfile>(null!);
  return (
    <UserInfoContext.Provider value={{userInfo, setUserInfo}}>{children}</UserInfoContext.Provider>
  );
}

export { UserInfoContext, UserInfoProvider };
