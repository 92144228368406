import { Column } from "@ant-design/plots";
import {
  Card,
  Col,
  Grid,
  List,
  Row,
  Space,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

type totalCall = {
  data?: {
    totalCalls?: number;
    totalMinutes?: number;
  };
};

type InterpretersDashboardProps = {
  totalCall: totalCall | undefined;
  config: any;
};

export const InterpretersDashboard = (props: InterpretersDashboardProps) => {
  const { totalCall, config } = props;
  const translate = useTranslate();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  return (
    <>
      <Row>
        <Col span={24}>
          <List title={translate("resource.dashboard")}>
            <Row gutter={16}>
              <Col span={isMobile ? 24 : 12}>
                <Card
                  title="Total Calls"
                  style={{ height: "150px", borderRadius: "15px" }}
                  headStyle={{
                    textAlign: "center",
                    background: "rgb(229 247 254)",
                  }}
                >
                  <Space
                    align="center"
                    direction="horizontal"
                    style={{ textAlign: "center" }}
                  >
                    <Typography.Title level={2}>
                      {totalCall?.data?.totalCalls}
                    </Typography.Title>
                  </Space>
                </Card>
              </Col>
              <Col span={isMobile ? 24 : 12}>
                <Card
                  title="Total Minutes"
                  style={{
                    height: "150px",
                    borderRadius: "15px",
                    marginTop: isMobile ? 30 : 0,
                  }}
                  headStyle={{
                    background: "rgb(229 247 254)",
                  }}
                >
                  <Space
                    align="center"
                    direction="horizontal"
                    style={{ textAlign: "center" }}
                  >
                    <Typography.Title level={2}>
                      {totalCall?.data?.totalMinutes}
                    </Typography.Title>
                  </Space>
                </Card>
              </Col>
            </Row>
            <Row style={{ paddingTop: "10px" }}>
              <Col span={24}>
                <List title="Call statistics charts">
                  <Row gutter={16}>
                    <Col
                      className="gutter-row assets-summary-pie-chart"
                      span={isMobile ? 24 : 16}
                    >
                      <Column {...config} />
                    </Col>
                  </Row>
                </List>
              </Col>
            </Row>
          </List>
        </Col>
      </Row>
    </>
  );
};
