import { Button, Form, Grid, Icons, Tabs } from "@pankod/refine-antd";
import {
  useCreate,
  useCustom,
  useNavigation,
  useUpdate,
} from "@pankod/refine-core";
import RouterProvider from "@pankod/refine-react-router-v6";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  CREATE_CUSTOMERS_API,
  CUSTOMER_API,
  UPDATE_CUSTOMERS_API,
} from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ICustomerReq, IEventCustomer } from "interfaces/customer";
import { useState } from "react";
import { openNotification } from "utils";
import "../../../../styles/style.less";
import { AddressTabPane } from "./tabs/AddressTabPane";
import { BillingTabPane } from "./tabs/BillingTabPane";
import { DetailsTabPane } from "./tabs/DetailsTabPane";
import { InvoiceCustomizingTabPane } from "./tabs/InvoiceCustomizingTabPane";
const { CheckCircleOutlined } = Icons;

type CustomerActionProps = {
  isModalVisible?: boolean;
  customerDataDetail?: ICustomerReq;
  idCustomer?: string;
  option: string;
  refetch: () => void;
  setIsModalVisible: (data: boolean) => void;
};
const { TabPane } = Tabs;

const invoiceTabPane = {
  appointmentNumber: true,
  apptCreateUser: false,

  processNumber: false,

  invoicePeriodFromDate: true,
  invoicePeriodToDate: true,

  invoiceCreateDate: true,

  customerName: false,
  customerEmail: false,

  bcellPhone: false,
  bfaxNumber: false,

  departmentId: false,
  departmentName: true,
  subDepartmentName: false,

  interpreterId: true,
  interpreterName: true,
  location: false,

  invoiceNumber: false,

  sourceLanguage: false,
  languageName: true,

  requesterName: true,
  requesterPhone: false,
  requesterEmail: false,

  startDate: false,
  startTime: true,
  finishDate: false,
  finishTime: true,
  duration: false,
  connectionTime: false,

  billItem1: false,
  billItem1SubTotal: false,
  billItem2: false,
  billItem2SubTotal: false,
  billItem3: false,
  billItem3SubTotal: false,
  billItem4: false,
  billItem4SubTotal: false,
  preTaxAmount: true,
  tax: true,
  billTotalAmount: true,

  serviceRecipient1FullName: false,
  serviceRecipient2FullName: false,
  bOtherRef1: true,
  bOtherRef2: true,
  bOtherRef3: true,

  additionalInformation: false,

  aptSuite: false,
  streetAddress: false,
  bcity: false,
  zipCodePostalCode: false,
  stateProvince: false,
  typeOfCall: false,
};
export const CustomerAction = (props: CustomerActionProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const { option, refetch, idCustomer, setIsModalVisible, isModalVisible } =
    props;
  const { push } = useNavigation();
  const { useParams } = RouterProvider;
  const params: { slug: string; resource: string } = useParams();
  const [activeTab, setActiveTab] = useState("detail");
  const { data: customerData, refetch: refetchGetOne } =
    useCustom<ICustomerReq>({
      url: CUSTOMER_API.getOne + "/" + params?.slug || "",
      method: "get",
      queryOptions: {
        enabled: !!params?.slug,
        retry: 0,
        onSuccess(customerData) {
          const customerDataDetails = customerData.data;

          form.setFieldsValue({
            detailsTabPane: {
              isActive: customerDataDetails?.isActive,
              name: customerDataDetails?.name,
              email: customerDataDetails?.email,
              contactName: customerDataDetails?.contactName,

              busPhone:
                customerDataDetails.busPhone?.slice(
                  0,
                  customerDataDetails.busPhone.indexOf(" ")
                ) || "",
              extBusPhone:
                customerDataDetails.busPhone?.slice(
                  customerDataDetails.busPhone?.lastIndexOf(" ") + 1,
                  customerDataDetails.busPhone.length
                ) || "",

              cellPhone:
                customerDataDetails?.cellPhone?.slice(
                  0,
                  customerDataDetails?.cellPhone?.indexOf(" ")
                ) || "",
              extCellPhone:
                customerDataDetails?.cellPhone?.slice(
                  customerDataDetails?.cellPhone?.lastIndexOf(" ") + 1,
                  customerDataDetails.cellPhone.length
                ) || "",

              faxNumber:
                customerDataDetails?.faxNumber?.slice(
                  0,
                  customerDataDetails?.faxNumber?.indexOf(" ")
                ) || "",
              extFaxNumber:
                customerDataDetails?.faxNumber?.slice(
                  customerDataDetails?.faxNumber?.lastIndexOf(" ") + 1,
                  customerDataDetails.faxNumber.length
                ) || "",

              invoiceStyle: customerDataDetails?.invoiceStyle,
              accessCode: customerDataDetails?.accessCode,
              website: customerDataDetails?.website,
              broadCast: customerDataDetails?.broadCast,
              notes: customerDataDetails?.notes,
              otherRef1: customerDataDetails?.otherRef1,
              otherRef2: customerDataDetails?.otherRef2,
              otherRef3: customerDataDetails?.otherRef3,
            },
            addressTabPane: {
              city: customerDataDetails?.city,
              zipCode: customerDataDetails?.zipCode,
              address1: customerDataDetails?.address1,
              stateId: customerDataDetails?.stateId,
              mAddress1: customerDataDetails?.mAddress1,
              mAddress2: customerDataDetails?.mAddress2,
              mAddress3: customerDataDetails?.mAddress3,
              mCity: customerDataDetails?.mCity,
              mZipCode: customerDataDetails?.mZipCode,
              address2: customerDataDetails?.address2,
              address3: customerDataDetails?.address3,
              mStateId: customerDataDetails?.mStateId,
            },
            billingTabPane: {
              taxRate: customerDataDetails?.taxRate,
              minMinuteBillTelePhone:
                customerDataDetails?.minMinuteBillTelePhone,
              nextBillIncreaseTelePhone:
                customerDataDetails?.nextBillIncreaseTelePhone,
              minMinuteBillVideo: customerDataDetails?.minMinuteBillVideo,
              nextBillIncreaseVideo: customerDataDetails?.nextBillIncreaseVideo,
            },
          });
          setCustomerRequest(customerDataDetails.field);
        },
        onError() {
          push("/404");
        },
      },
    });

  const [form] = Form.useForm();
  const [customerRequest, setCustomerRequest] =
    useState<ICustomerReq>(invoiceTabPane);
  const [isSameAddress, setIsSameAddress] = useState(false);

  const handleBillRate = () => {
    push(`/bill-rate/${params?.slug || ""}`);
  };

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useCreate();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate();

  const onFinishCreate = (event: IEventCustomer) => {
    const { detailsTabPane, addressTabPane, billingTabPane } = event;

    const extBusPhone = detailsTabPane.extBusPhone || "";
    const extCellPhone = detailsTabPane.extFaxNumber || "";
    const extFaxNumber = detailsTabPane.extFaxNumber || "";

    mutateCreate(
      {
        resource: CREATE_CUSTOMERS_API,
        values: {
          name: detailsTabPane.name,
          email: detailsTabPane.email,
          isActive: detailsTabPane.isActive,
          invoiceStyle: detailsTabPane.invoiceStyle,
          contactName: detailsTabPane.contactName,
          website: detailsTabPane.website,
          notes: detailsTabPane.notes,
          accessCode: detailsTabPane.accessCode,
          otherRef1: detailsTabPane.otherRef1,
          otherRef2: detailsTabPane.otherRef2,
          otherRef3: detailsTabPane.otherRef3,
          broadCast: detailsTabPane.broadCast,
          busPhone: `${
            detailsTabPane.busPhone?.trim() || ""
          } Ext: ${extBusPhone}`,
          cellPhone: `${
            detailsTabPane.cellPhone?.trim() || ""
          } Ext: ${extCellPhone}`,
          faxNumber: `${
            detailsTabPane.faxNumber?.trim() || ""
          } Ext: ${extFaxNumber}`,
          mAddress1: isSameAddress
            ? addressTabPane.address1
            : addressTabPane?.mAddress1,
          mAddress2: isSameAddress
            ? addressTabPane.address2
            : addressTabPane?.mAddress2,
          mAddress3: isSameAddress
            ? addressTabPane.address3
            : addressTabPane?.mAddress3,
          mCity: isSameAddress ? addressTabPane.city : addressTabPane?.mCity,
          mZipCode: isSameAddress
            ? addressTabPane.zipCode
            : addressTabPane?.mZipCode,
          mStateId: isSameAddress
            ? addressTabPane.stateId
            : addressTabPane?.mStateId,
          ...addressTabPane,
          ...billingTabPane,
          ...customerRequest,
        },
      },
      {
        onSuccess: (createData) => {
          openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
          refetch();
          if (option === EAction.CLONE) {
            refetchGetOne();
          }
          form.resetFields();
          setIsSameAddress(false);
          setCustomerRequest(invoiceTabPane);
          setIsModalVisible(false);
          setActiveTab("detail");
        },
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);
        },
      }
    );
  };

  const onFinishUpdate = (event: IEventCustomer) => {
    const { detailsTabPane, addressTabPane, billingTabPane } = event;
    const extBusPhone = detailsTabPane.extBusPhone ?? "";
    const extCellPhone = detailsTabPane.extFaxNumber ?? "";
    const extFaxNumber = detailsTabPane.extFaxNumber ?? "";
    mutateUpdate(
      {
        resource: UPDATE_CUSTOMERS_API,
        id: params.slug,
        values: {
          name: detailsTabPane.name,
          email: detailsTabPane.email,
          isActive: detailsTabPane.isActive,
          invoiceStyle: detailsTabPane.invoiceStyle,
          contactName: detailsTabPane.contactName,
          website: detailsTabPane.website,
          notes: detailsTabPane.notes,
          accessCode: detailsTabPane.accessCode,
          otherRef1: detailsTabPane.otherRef1,
          otherRef2: detailsTabPane.otherRef2,
          otherRef3: detailsTabPane.otherRef3,
          broadCast: detailsTabPane.broadCast,

          busPhone: `${
            detailsTabPane.busPhone?.trim() || ""
          } Ext: ${extBusPhone}`,
          cellPhone: `${
            detailsTabPane.cellPhone?.trim() || ""
          } Ext: ${extCellPhone}`,
          faxNumber: `${
            detailsTabPane.faxNumber?.trim() || ""
          } Ext: ${extFaxNumber}`,
          ...addressTabPane,
          mAddress1: isSameAddress
            ? addressTabPane.address1
            : addressTabPane?.mAddress1,
          mAddress2: isSameAddress
            ? addressTabPane.address2
            : addressTabPane?.mAddress2,
          mAddress3: isSameAddress
            ? addressTabPane.address3
            : addressTabPane?.mAddress3,
          mCity: isSameAddress ? addressTabPane.city : addressTabPane?.mCity,
          mZipCode: isSameAddress
            ? addressTabPane.zipCode
            : addressTabPane?.mZipCode,
          mStateId: isSameAddress
            ? addressTabPane.stateId
            : addressTabPane?.mStateId,
          ...billingTabPane,
          ...customerRequest,
        },
      },
      {
        onSuccess: (updateData) => {
          openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
          refetch();
          refetchGetOne();
          setIsSameAddress(false);
          form.resetFields();
          setCustomerRequest(invoiceTabPane);
          setIsModalVisible(false);
          setActiveTab("detail");
        },
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);
        },
      }
    );
  };

  const handleBooleanInputChange = (e: CheckboxChangeEvent) => {
    setCustomerRequest({
      ...customerRequest,
      [e.target.name as string]: e.target.checked,
    });
  };
  const handleIsSameAddress = (e: CheckboxChangeEvent) => {
    setIsSameAddress(e.target.checked);
  };

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab);
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(event) => {
        option === EAction.CREATE || option === EAction.CLONE
          ? onFinishCreate(event)
          : onFinishUpdate(event);
      }}
    >
      <Tabs activeKey={activeTab} onChange={handleChangeTab} size="large">
        <TabPane tab="Details" key="detail">
          <DetailsTabPane option={option} />
        </TabPane>
        <TabPane
          forceRender={option === EAction.CLONE}
          tab="Address"
          key="address"
        >
          <AddressTabPane
            isSameAddress={isSameAddress}
            handleIsSameAddress={handleIsSameAddress}
            option={option}
          />
        </TabPane>
        <TabPane
          forceRender={option === EAction.CLONE}
          tab="Billing"
          key="billing"
        >
          <BillingTabPane handleBillRate={handleBillRate} option={option} />
        </TabPane>
        <TabPane tab="Invoice Customizing" key="invoice_customizinsg">
          <InvoiceCustomizingTabPane
            customerRequest={customerRequest}
            setCustomerRequest={setCustomerRequest}
            onBooleanInputChange={handleBooleanInputChange}
            option={option}
          />
        </TabPane>
      </Tabs>
      <div className={!isMobile ? "submit" : ""}>
        <Button
          type="primary"
          htmlType="submit"
          hidden={option === EAction.DETAIL}
          loading={
            option === EAction.CREATE || option === EAction.CLONE
              ? isLoadingCreate
              : isLoadingUpdate
          }
        >
          Save
        </Button>
      </div>
    </Form>
  );
};
