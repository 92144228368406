import {
  AntdLayout,
  Button,
  Dropdown,
  Grid,
  Icons,
  Menu,
  Space,
  Switch,
  Typography,
} from "@pankod/refine-antd";
import {
  useCustom,
  useLogout,
  useNavigation,
  usePermissions
} from "@pankod/refine-core";
import { PROFILE_API } from "api/baseApi";
import { CustomModal } from "components/Modal/CustomModal";
import { AvatarIcon } from "components/icons";
import { ESTATUS } from "constants/constant";
import { EPermissions } from "constants/permission";
import { CollageContext } from "hooks/useContext/useCollagedContext";
import { socket } from "hooks/useContext/useSocketContext";
import { IProfile, UserInfoContext } from "hooks/useContext/useUserInfoContext";
import ChangePassword from "pages/interpreter/profile/ChangePassword";
import { useContext, useEffect, useState } from "react";
import "../../../styles/style.less";
import BlockPopupModal from "components/Modal/BlockPopupModal";
import { isCustomer, isInterpreter } from "utils";

const { LogoutOutlined, EditOutlined, DownOutlined, UnlockOutlined, MenuOutlined } = Icons;

export const Header = () => {
  const {  isShowMenu, setIsShowMenu } = useContext(CollageContext);
  const {setUserInfo} = useContext(UserInfoContext);
  const { mutate: logout } = useLogout();
  const { push } = useNavigation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const user = JSON.parse(localStorage.getItem("user") || "");
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.sm;
  const isMobileLg = !breakpoint.lg;
  const { collapsed  } = useContext(CollageContext);
  const { data: permissionsData } = usePermissions();
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [notice, setNotice] = useState("");
  const [isBlockPopUp, setIsBlockPopUp] = useState(false);

  const logoutAccount = () => {
    logout();
    push("/login");
  };

  const { data: profile, refetch } = useCustom({
    url: PROFILE_API,
    method: "get",
    queryOptions : {
      onSuccess : (res)=>{
        const userInfo = {...res.data} as IProfile;
        setUserInfo(userInfo);
      }
    }
  });
  
  const handleChangeStatus = () => {
    socket.emit("update-notice", { user: profile?.data });
    setIsLoadingStatus(!isLoadingStatus);
  };

  useEffect(() => {
    setNotice(profile?.data.notice);
  }, [profile]);

  useEffect(() => {
    socket.on("receive-notice", (data) => {
      setNotice(data?.notice);
    });

    return () => {
      socket.off("receive-notice", () => {});
    };
  }, [profile]);

  const handleUpdateProfile = () => {
    push(`/update-profile/${profile?.data?.interpreterid}`);
  };


  useEffect(() => {
    if(isInterpreter() || isCustomer()){
      setIsBlockPopUp(true)
    }
  }, []);
  
  const menu = (
    <Menu>
      <Menu.Item>
        <UnlockOutlined />
        <Button
          type="link"
          onClick={() => {
            setIsModalVisible(true);
          }}
          style={{ color: "gray" }}
        >
          Change Password
        </Button>
      </Menu.Item>

      {permissionsData?.roleId === EPermissions.INTERPRETER && (
        <>
          <Menu.Item>
            <EditOutlined />
            <Button
              type="link"
              onClick={handleUpdateProfile}
              style={{ color: "gray" }}
            >
              Update Profile
            </Button>
          </Menu.Item>
        </>
      )}

      <Menu.Item>
        <LogoutOutlined />
        <Button
          type="link"
          onClick={() => {
            logoutAccount();
          }}
          style={{ color: "gray" }}
        >
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    refetch();
  }, [isLoadingStatus]);

  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        backgroundColor: "#FFF",
        position: "sticky",
        top: 0,
        zIndex: 10,
        width: "100%",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
      }}
    >
      {isMobileLg && (
        <MenuOutlined
          style={{ fontSize: 20 }}
          onClick={() => setIsShowMenu(!isShowMenu)}
        />
      )}
      <div
        className={
          !collapsed
            ? "container-change-status"
            : isMobile && collapsed
            ? "container-notice-sm"
            : "container-change-status-mobile"
        }
      >
        <Typography.Title level={5} className="change-status">
          {permissionsData?.roleId === EPermissions.INTERPRETER &&
            !isMobile &&
            "Online"}
        </Typography.Title>
        {permissionsData?.roleId === EPermissions.INTERPRETER && (
          <Switch
            checked={
              permissionsData?.roleId === EPermissions.INTERPRETER &&
              (notice === ESTATUS.ON ? true : false)
            }
            // size={isMobile ? "small" : "default"}
            onClick={handleChangeStatus}
            className={isMobile ? "switch-mobile" : ""}
          />
        )}
      </div>
      <div style={{ display: "flex" }}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Space>
            <Button className="dropdown-user">
              <div className="icon-user-dot">
                <AvatarIcon />
              </div>
              {user?.firstname + " " + user?.lastname}
              <DownOutlined className="icon-down-outline" />
            </Button>
          </Space>
        </Dropdown>
        {/* {permissionsData?.roleId === EPermissions.ADMIN && (
          <UsergroupAddOutlined
            style={{ fontSize: "20px", padding: "0 10px", paddingTop: 5 }}
            className="show-interpreter-online"
            onClick={() => setIsShowPerson(!isShowPerson)}
          />
        )} */}
      </div>

      <CustomModal
        title="Change Password"
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        destroyOnClose
      >
        <ChangePassword
          userId={user?.id}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
      </CustomModal>
      <BlockPopupModal isBlockPopup={isBlockPopUp} setIsBlockPopup={setIsBlockPopUp}/>
    </AntdLayout.Header>
  );
};
