import { DashboardPage } from "pages/admin/dashboard";
import { ProcessCustomerInvoiceList } from "pages/admin/invoices/process-customer-invoice/list";
import { ProcessInterpreterInvoiceList } from "pages/admin/invoices/process-interpreter-invoice/list";
import { CustomerProcessDetail } from "pages/admin/invoices/view-customer-invoice/detail";
import { ViewCustomerInvoiceList } from "pages/admin/invoices/view-customer-invoice/list";
import { AppoinmentDetailInterpreter } from "pages/admin/invoices/view-interpreter-invoice/appointment-detail-interpreter";
import { InterpreterProcessDetail } from "pages/admin/invoices/view-interpreter-invoice/detail";
import { ViewInterpreterInvoiceList } from "pages/admin/invoices/view-interpreter-invoice/list";
import { DepartmentsList } from "pages/admin/listings/department/list";
import { StandardBillRatesList } from "pages/admin/listings/manager-customer/bill-rate/list";
import { DetailsCustomer } from "pages/admin/listings/manager-customer/details";
import { ManagerCustomerList } from "pages/admin/listings/manager-customer/list";
import { InterpreterCreate } from "pages/admin/listings/manager-interpreter/create";
import { ManagerInterpreterList } from "pages/admin/listings/manager-interpreter/list";
import { StandardPayRatesList } from "pages/admin/listings/manager-interpreter/pay-rate/list";
import { InterpreterEdit } from "pages/admin/listings/manager-interpreter/update";
import { RequestersList } from "pages/admin/listings/requesters/list";
import { ServiceList } from "pages/admin/listings/service/list";
import { Sub_DepartmentsList } from "pages/admin/listings/sub-departments/list";
import { ApptFillRateByCus } from "pages/admin/reports/appointment-fill-customer/list";
import { ApptFillRateByLang } from "pages/admin/reports/appointment-fill-language/list";
import { BillingReportsList } from "pages/admin/reports/billing-report/list";
import { IncomeReportsList } from "pages/admin/reports/income-report/list";
import { InterpreterActivityReport } from "pages/admin/reports/interpreter-activity-report/list";
import { PaymentReportsList } from "pages/admin/reports/payment/list";
import { GroupList } from "pages/admin/system-admin/groups/list";
import { LanguagesList } from "pages/admin/system-admin/languages/list";
import { UsersList } from "pages/admin/system-admin/users/list";
import { ListCall } from "pages/customer/list_call";
import { UpdateProfile } from "pages/interpreter/profile/UpdateProfile";

import { HistoryList } from "pages/admin/history/list";
import AppointmentDetailCustomer from "pages/admin/invoices/view-customer-invoice/appointment-detail-customer";
import { ApptPayOrBillList } from "pages/admin/reports/appointment-pay-bill/list";
import { DetailCallReportRequester } from "pages/customer/call-report/detail";
import { DetailCallReportInterpreter } from "pages/interpreter/call-report/detail";
import { Profile } from "pages/interpreter/profile/list";
import { useTranslation } from "react-i18next";
import { ResourceProps } from "@pankod/refine-core";

export default function Resources(): Array<ResourceProps> {
  const { t } = useTranslation();
  return [
    {
      name: t("resource.dashboard"),
      list: DashboardPage,
      options: {
        route: "dashboard",
      },
    },
    {
      name: t("resource.history"),
      list: HistoryList,
      options: {
        route: "history",
      },
    },

    //   LISTING
    {
      name: t("resource.service"),
      list: ServiceList,
      options: {
        route: "listings/service",
      },
    },
    {
      name: t("resource.detailsCustomer"),
      list: DetailsCustomer,
      options: {
        route: "customer-details/:slug",
      },
    },
    {
      name: t("resource.billRate"),
      list: StandardBillRatesList,
      options: {
        route: "bill-rate/:slug",
      },
    },
    {
      name: t("resource.payRate"),
      list: StandardPayRatesList,
      options: {
        route: "pay-rate/:slug",
      },
    },
    {
      name: t("resource.customer"),
      list: ManagerCustomerList,
      canDelete: true,
      options: {
        route: "listings/customer",
      },
    },

    {
      name: t("resource.department"),
      list: DepartmentsList,
      options: {
        route: "listings/department",
      },
    },
    {
      name: t("resource.subDepartment"),
      list: Sub_DepartmentsList,
      options: {
        route: "listings/sub-department",
      },
    },

    {
      name: t("resource.requesters"),
      list: RequestersList,
      options: {
        route: "listings/requester",
      },
    },
    {
      name: t("resource.interpreter"),
      list: ManagerInterpreterList,
      options: {
        route: "listings/interpreter",
      },
    },
    {
      name: t("resource.interpreterEdit"),
      list: InterpreterEdit,
      options: {
        route: "interpreter-edit/:slug",
      },
    },
    {
      name: t("resource.interpreterAdd"),
      list: InterpreterCreate,
      options: {
        route: "interpreter-add",
      },
    },
    {
      name: t("resource.profile"),
      list: Profile,
      options: {
        route: "profile",
      },
    },
    {
      name: t("resource.listCall"),
      list: ListCall,
      options: {
        route: "request-an-interpreter",
      },
    },

    {
      name: t("resource.updateProfile"),
      list: UpdateProfile,
      options: {
        route: "update-profile/:slug",
      },
    },

    // INVOICE
    {
      name: t("resource.processInvoiceInterpreter"),
      list: ProcessInterpreterInvoiceList,
      options: {
        route: "invoice/process-interpreter-invoice",
      },
    },
    {
      name: t("resource.viewPrintInvoiceInterpreter"),
      list: ViewInterpreterInvoiceList,
      options: {
        route: "invoice/view-print-interpreter-invoice",
      },
    },
    {
      name: t("resource.processInvoiceCustomer"),
      list: ProcessCustomerInvoiceList,
      options: {
        route: "invoice/process-customer-invoice",
      },
    },
    {
      name: t("resource.viewPrintInvoiceCustomer"),
      list: ViewCustomerInvoiceList,
      options: {
        route: "invoice/view-print-customer-invoice",
      },
    },

    {
      name: t("resource.interpreterProcessDetail"),
      list: InterpreterProcessDetail,
      options: {
        route: "interpreter-process-detail/:slug",
      },
    },
    {
      name: t("resource.apptDetailInterpreter"),
      list: AppoinmentDetailInterpreter,
      options: {
        route: "appointment-detail-interpreter/:slug",
      },
    },
    {
      name: t("resource.customerProcessDetail"),
      list: CustomerProcessDetail,
      options: {
        route: "view-invoice-customer/:slug",
      },
    },
    // CALL DETAILS
    {
      name: t("resource.aptDetailCustomer"),
      list: AppointmentDetailCustomer,
      options: {
        route: "appointment-detail/:slug",
      },
    },

    //   REPORTS
    {
      name: t("resource.payment"),
      list: PaymentReportsList,
      options: {
        route: "report/payment-report",
      },
    },
    {
      name: t("resource.incomeReport"),
      list: IncomeReportsList,
      options: {
        route: "report/income-report",
      },
    },

    {
      name: t("resource.billingReport"),
      list: BillingReportsList,
      options: {
        route: "report/billing-report",
      },
    },
    {
      name: t("resource.appointmentFillLanguage"),
      list: ApptFillRateByLang,
      options: {
        route: "report/appointment-fill-rate-by-language",
      },
    },
    {
      name: t("resource.appointmentFillCustomer"),
      list: ApptFillRateByCus,
      options: {
        route: "report/appointment-fill-rate-by-customer",
      },
    },

    {
      name: t("resource.appointmentPayBillReport"),
      list: ApptPayOrBillList,
      options: {
        route: "report/apt-pay-bill-report",
      },
    },

    {
      name: t("resource.interpreterActivityReport"),
      list: InterpreterActivityReport,
      options: {
        route: "report/interpreter-activity-report",
      },
    },

    //   SYSTEMS
    {
      name: t("resource.groups"),
      list: GroupList,
      options: {
        route: "system-administration/group",
      },
    },
    {
      name: t("resource.users"),
      list: UsersList,
      options: {
        route: "system-administration/user",
      },
    },
    {
      name: t("resource.languages"),
      list: LanguagesList,
      options: {
        route: "system-administration/language",
      },
    },

    //INTERPRETER
    {
      name: t("resource.reportCallInterpreter"),
      list: DetailCallReportInterpreter,
      options: {
        route: "call-report-interpreter",
      },
    },
    {
      name: t("resource.detailReportInterpreter"),
      list: DetailCallReportInterpreter,
      options: {
        route: "call-report-detail-interpreter",
      },
    },

    //REQUESTER
    {
      name: t("resource.reportCallRequester"),
      list: DetailCallReportRequester,
      options: {
        route: "call-report-requester",
      },
    },
    {
      name: t("resource.detailReportRequester"),
      list: DetailCallReportRequester,
      options: {
        route: "call-report-detail-requester",
      },
    },

    // //Login
    // {
    //   name: t("resource.reportCallRequester"),
    //   list: DetailCallReportRequester,
    //   options: {
    //     route: "call-report-requester",
    //   },
    // },
  ];
}
