import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  Row,
  Select,
  useForm,
} from "@pankod/refine-antd";
import { useCreate, useCustom } from "@pankod/refine-core";
import { useEffect, useState } from "react";

import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { CREATE_DEPARTMENT_API, CUSTOMER_API, STATE_API } from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ICheckboxChangeActive } from "interfaces";
import { IDepartment } from "interfaces/department";
import { openNotification } from "utils";
import "../../../../styles/style.less";
import { DepartmentProps } from "./interface";

const { TextArea } = Input;
export const DepartmentCreate = (props: DepartmentProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const { setIsModalVisible, isModalVisible, customerId } = props;
  const [messageErr, setMessageErr] = useState<IDepartment>();

  const { formProps, form } = useForm<IDepartment>({
    action: EAction.CREATE,
  });

  const { data: statesData } = useCustom({
    url: STATE_API.getAll,
    method: "get",
  });

  const { data: customerData } = useCustom({
    url: CUSTOMER_API.getOne + `/${customerId}`,
    method: "get",
  });

  const { mutate, data: createData, isLoading } = useCreate();

  const onFinish = (event: IDepartment) => {
    mutate(
      {
        resource: CREATE_DEPARTMENT_API,
        values: {
          name: event.name,
          contactName: event.contactName,
          street: event.street,
          email: event.email,
          phoneNumber: event.phoneNumber,
          suit: event.suit,
          city: event.city,
          zipCode: event.zipCode,
          mileage: event.mileage,
          travelUnits: event.travelUnits,
          accessCode: event.accessCode,
          stateId: event.stateId,
          isActive: event.isActive,
          customerId: customerId,
        },
      },
      {
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);
        },
      }
    );
  };

  const { setFields } = form;

  useEffect(() => {
    form.resetFields();
    setFields([
      {
        name: "customerId",
        value: customerId,
      },
      {
        name: "accessCodeCustomer",
        value: customerData?.data?.accessCode,
      },
    ]);
  }, [customerData, form, isModalVisible, setFields]);



  useEffect(() => {
    if (createData?.data) {
      form.resetFields();
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
      setIsModalVisible(false);
      setMessageErr(messageErr);
    }
  }, [createData, form, messageErr, setIsModalVisible]);

  const onCheck = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ isActive: true });
    } else {
      form.setFieldsValue({ isActive: false });
    }
  };

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as IDepartment)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="Department Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter Department Name",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            label="Contact Name"
            name="contactName"
            rules={[
              {
                required: true,
                message: "Please enter Contact Name",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input maxLength={200} />
          </Form.Item>

          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Row gutter={16}>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              {" "}
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    pattern: /^(?:\d*)$/,
                    message: "Must contain 10 numbers only",
                  },
                ]}
                normalize={(value, prevVal, prevVals) => value.trimStart()}
              >
                <Input maxLength={10} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              {" "}
              <Form.Item
                label="Ext"
                name="mileage"
                rules={[
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Please enter a valid Ext number!",
                  },
                ]}
                normalize={(value, prevVal, prevVals) => value.trim()}
              >
                <Input maxLength={5} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Notes"
            name="note"
            rules={[
              {
                required: false,
                message: "Please enter Notes",
              },
            ]}
            initialValue={customerData?.data?.notes}
          >
            <TextArea rows={5} disabled />
          </Form.Item>

          <Form.Item
            label="Access Code"
            name="accessCodeCustomer"
            rules={[
              {
                required: false,
                message: "Please enter Access Code",
              },
            ]}
            initialValue={customerData?.data?.accessCode}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          {" "}
          <Form.Item
            label="Street Address"
            name="street"
            rules={[
              {
                required: true,
                message: "Please enter Street Address",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input maxLength={200} />
          </Form.Item>
          <Form.Item
            label="Suite Number"
            name="suit"
            rules={[
              {
                required: false,
                message: "Please enter Suite Number",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input maxLength={50} />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
                message: "Please enter City",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input maxLength={200} />
          </Form.Item>
          <Form.Item
            label="State/Province"
            name="stateId"
            rules={[
              {
                required: true,
                message: "Please enter State/Province",
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Select state"
              optionFilterProp="children"
              filterOption={(
                input,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {statesData?.data?.map((item: IDepartment, index: number) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Zip Code/Postal Code"
            name="zipCode"
            rules={[
              {
                required: true,
                message: "Please enter Zip Code",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input maxLength={50} />
          </Form.Item>
          <Form.Item
            label="Department Code"
            name="accessCode"
            rules={[
              {
                required: false,
                message: "Please enter Department Code",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input maxLength={50} />
          </Form.Item>
          <Form.Item
            label="
          "
            name="isActive"
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox
              onChange={(event) => {
                onCheck(event);
              }}
            >
              Is Active
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
