/* eslint-disable react/jsx-pascal-case */
import {
  AutoComplete,
  Button,
  Checkbox,
  Empty,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Result,
  Space,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { useCustom, useTranslate } from "@pankod/refine-core";
import { CUSTOMER_API, DEPARTMENT_API, SUB_DEPARTMENT_API } from "api/baseApi";
import { CustomModal } from "components/Modal/CustomModal";
import { ISub_Department } from "interfaces/sub_department";
import React, { useEffect, useState } from "react";
import SubDepartmentActive from "./active";
import { SubDepartmentCreate } from "./create";
import { SubDepartmentsUpdate } from "./update";
import { Breadcrumb } from "antd";
import { axiosInstance } from "providers/axios";
import { UserContext } from "hooks/useContext/useUserContext";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import useNotification from "hooks/useNotification";

const {
  PlusSquareOutlined,
  SearchOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} = Icons;
export const Sub_DepartmentsList: React.FC = () => {
  const translate = useTranslate();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleUpdate, setIsModalVisibleUpdate] = useState(false);
  const [isModalVisibleActive, setIsModalVisibleActive] = useState(false);
  const [isShowNotification, setIsShowNotification] = useState(false);
  const [isShowNotificationByDepartment, setIsShowNotificationByDepartment] =
    useState(false);

  const [detailSubDepartment, setDetailSubDepartment] = useState<
    ISub_Department | undefined
  >();
  const [valueCustomer, setValueCustomer] = useState("");
  const [activeSubDepartmentByCus, setActiveSubDepartmentByCus] = useState([]);
  const [activeDepartment, setActiveDepartment] = useState([]);
  const [valueDepartment, setValueDepartment] = useState<string>("");
  const [activeSubdepartment, setActiveSubdepartment] = useState<
    ISub_Department[]
  >([]);

  const [department, setDepartment] = useState<string>("");
  const { customerId, setCustomerId } = React.useContext(UserContext);

  const { openNotificationInfo, contextHolder } = useNotification();

  const [page, setPage] = useState<number>(1);

  const { data: customerData } = useCustom({
    url: CUSTOMER_API.getAll,
    method: "get",
    config: {
      query: {
        limit: "",
        page: "",
        search: "",
      },
    },
  });

  const handleCreate = () => {
    handleOpenModel();
  };

  const handleOpenModel = () => {
    setIsModalVisible(!isModalVisible);
  };

  const columns = [
    {
      title: "Nbr.",
      key: "key",
      render: (value: number) => (
        <TextField
          value={
            value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
          }
        />
      ),
    },
    {
      title: `Department`,
      key: "departmentName",
      render: (value: string) => <TextField value={value ? value : ""} />,
    },
    {
      title: `Sub-department Name`,
      key: "name",
      render: (value: string, record: ISub_Department) => (
        <TextField
          value={value ? value : ""}
          style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
          onClick={() => edit(record)}
        />
      ),
    },
    {
      title: `Sub-department Code`,
      key: "accessCode",
      render: (value: string) => <TextField value={value ? value : ""} />,
    },
    {
      title: `Is Active`,
      key: "isActive",
      render: (value: boolean, record: ISub_Department) => (
        <Checkbox checked={value} onChange={() => active(record)} />
      ),
    },
  ];

  const edit = (subdepartment: ISub_Department) => {
    setDetailSubDepartment(subdepartment);
    setIsModalVisibleUpdate(true);
  };

  const active = (subdepartment: ISub_Department) => {
    setDetailSubDepartment(subdepartment);
    setIsModalVisibleActive(true);
  };

  const pageTotalDepartmentByCus = activeSubDepartmentByCus?.length;
  const pageTotalSubDepartment = activeSubdepartment?.length;

  const onSelect = async (customerId: string, option: BaseOptionType) => {
    setPage(1);

    const [customerBySub, departmentByCusId] = await Promise.all([
      axiosInstance.get(
        SUB_DEPARTMENT_API.getByCustomer + `?id=${customerId}&page=&limit=`
      ),
      axiosInstance.get(
        DEPARTMENT_API.getByCustomer + `?id=${customerId}&page=&limit=`
      ),
    ]);

    setActiveSubDepartmentByCus(customerBySub?.data?.results);
    setActiveDepartment(departmentByCusId?.data?.results);

    setCustomerId(customerId);
    setValueCustomer(option.label);
    setIsShowNotification(true);
  };

  const onSelectDepartment = async (
    departmentId: string,
    option: BaseOptionType
  ) => {
    setPage(1);

    const subDepartments = await axiosInstance.get(
      SUB_DEPARTMENT_API.getByDepartment + `?id=${departmentId}&page=&limit=`
    );
    setActiveSubdepartment(subDepartments.data.results);

    setDepartment(departmentId);
    setValueDepartment(option.label);
    setIsShowNotificationByDepartment(true);
  };

  const onChange = (customerId: string, option: BaseOptionType) => {
    setValueCustomer(customerId);
  };

  const onChangeDepartment = (departmentId: string, option: BaseOptionType) => {
    setValueDepartment(departmentId);
  };

  const updateSuccess = async () => {
    try {
      if (customerId && department) {
        const subDepartmentByDep = await axiosInstance.get(
          SUB_DEPARTMENT_API.getByDepartment + `?id=${department}&page=&limit=`
        );
        setActiveSubdepartment(subDepartmentByDep.data.results);
      }
      if (customerId) {
        const subDepartmentByCus = await axiosInstance.get(
          SUB_DEPARTMENT_API.getByCustomer + `?id=${customerId}&page=&limit=`
        );
        setActiveSubDepartmentByCus(subDepartmentByCus.data.results);
      }
    } catch (error) {}
  };

  const handleRemoveItem = () => {
    setValueCustomer("");
    setValueDepartment("");
    setActiveSubDepartmentByCus([]);
    setActiveDepartment([]);
    setIsShowNotification(false);
  };

  const handleRemoveDepartment = () => {
    setValueDepartment("");
    setDepartment("");
    setActiveSubdepartment([]);
    setIsShowNotificationByDepartment(false);
  };

  const handleChangeCusByDept = (page: number) => {
    setPage(page);
  };

  const handleChangeDeptBySub = (page: number) => {
    setPage(page);
  };

  let locale = {
    emptyText: <Result subTitle="Please select sub-department search" />,
  };

  let localeNoData = {
    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
  };

  useEffect(() => {
    // refetch();
  }, [isModalVisible, isModalVisibleUpdate, isModalVisibleActive]);

  useEffect(() => {
    (async () => {
      if (!valueCustomer) {
        setActiveSubDepartmentByCus([]);
      }
      if (!valueDepartment) {
        setActiveSubdepartment([]);
      }
    })();
  }, [valueCustomer, valueDepartment]);

  useEffect(() => {
    if (isShowNotification && activeSubDepartmentByCus.length === 0) {
      openNotificationInfo(
        "No data found",
        "There are no results matching Sub-Department By Customer",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  }, [activeSubDepartmentByCus, isShowNotification]);

  useEffect(() => {
    if (isShowNotificationByDepartment && activeSubdepartment.length === 0) {
      openNotificationInfo(
        "No data found",
        "There are no results matching Sub-Department By Department",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  }, [activeSubdepartment, isShowNotificationByDepartment]);

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Listing</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.subDepartment")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      pageHeaderProps={{
        extra: (
          <Space>
            {valueCustomer && (
              <Button
                className="btn-create"
                style={{ marginBottom: 10 }}
                onClick={handleCreate}
              >
                <PlusSquareOutlined /> Add Sub-deparment
              </Button>
            )}
          </Space>
        ),
      }}
    >
      {contextHolder}
      <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
        <Form.Item
          label="Search Customer by Name"
          className={!isMobile ? "search-item" : ""}
        >
          <AutoComplete
            showSearch
            size="large"
            style={{
              width: "100%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            options={customerData?.data?.results?.map(
              (item: ISub_Department, index: number) => ({
                value: item.id,
                label: item.name,
              })
            )}
            value={valueCustomer}
            filterOption={(
              valueCustomer,
              option: BaseOptionType | DefaultOptionType | undefined
            ) =>
              option?.label
                ?.toUpperCase()
                .indexOf(valueCustomer.toUpperCase()) !== -1
            }
            onSelect={onSelect}
            onChange={onChange}
          >
            <Input
              suffix={
                <>
                  {valueCustomer && (
                    <CloseOutlined onClick={handleRemoveItem} />
                  )}
                  <SearchOutlined />
                </>
              }
              bordered={false}
            />
          </AutoComplete>
        </Form.Item>
        <Form.Item
          label="Select Department"
          className={!isMobile ? "search-item" : ""}
        >
          <AutoComplete
            showSearch
            size="large"
            style={{
              width: "100%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            options={activeDepartment?.map(
              (item: ISub_Department, index: number) => ({
                value: item.id,
                label: item.name,
              })
            )}
            value={valueDepartment}
            filterOption={(
              valueDepartment,
              option: BaseOptionType | DefaultOptionType | undefined
            ) =>
              option?.label
                ?.toUpperCase()
                .indexOf(valueDepartment.toUpperCase()) !== -1
            }
            onSelect={onSelectDepartment}
            onChange={onChangeDepartment}
          >
            <Input
              suffix={
                <>
                  {valueDepartment && (
                    <CloseOutlined onClick={handleRemoveDepartment} />
                  )}
                  <SearchOutlined />
                </>
              }
              bordered={false}
            />
          </AutoComplete>
        </Form.Item>
      </Form>

      <CustomModal
        title={translate("sub-department.label.title.info")}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      >
        <SubDepartmentCreate
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
          department={department}
          activeDepartment={activeDepartment}
          onSelectDepartment={onSelectDepartment}
          updateSuccess={updateSuccess}
        />
      </CustomModal>

      <CustomModal
        title={translate("sub-department.label.title.info")}
        setIsModalVisible={setIsModalVisibleUpdate}
        isModalVisible={isModalVisibleUpdate}
      >
        <SubDepartmentsUpdate
          setIsModalVisible={setIsModalVisibleUpdate}
          isModalVisible={isModalVisibleUpdate}
          subDepartment={detailSubDepartment}
          updateSuccess={updateSuccess}
        />
      </CustomModal>

      <CustomModal
        title="Confirmation"
        setIsModalVisible={setIsModalVisibleActive}
        isModalVisible={isModalVisibleActive}
        width={isMobile ? "100%" : "50%"}
      >
        <SubDepartmentActive
          setIsModalVisible={setIsModalVisibleActive}
          isModalVisible={isModalVisibleActive}
          subdepartment={detailSubDepartment}
          updateSuccess={updateSuccess}
        />
      </CustomModal>

      <Table
        dataSource={
          valueDepartment && valueCustomer !== undefined
            ? activeSubdepartment?.map(
                (item: ISub_Department, index: number) => ({
                  ...item,
                  key: index,
                })
              )
            : activeSubDepartmentByCus?.map(
                (item: ISub_Department, index: number) => ({
                  ...item,
                  key: index,
                })
              )
        }
        pagination={
          valueDepartment && valueCustomer !== undefined
            ? pageTotalSubDepartment > 10
              ? {
                  current: page,
                  total: pageTotalSubDepartment ? pageTotalSubDepartment : 1,
                  onChange: handleChangeCusByDept,
                  showTotal: () => `${pageTotalSubDepartment} total`,
                }
              : false
            : pageTotalDepartmentByCus > 10
            ? {
                current: page,
                total: pageTotalDepartmentByCus ? pageTotalDepartmentByCus : 1,
                onChange: handleChangeDeptBySub,
                showTotal: () => `${pageTotalDepartmentByCus} total`,
              }
            : false
        }
        locale={valueCustomer ? localeNoData : locale}
        scroll={{ x: 800 }}
      >
        {columns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
    </List>
  );
};
