import {
  Button,
  DateField,
  Grid,
  List,
  Row,
  Space,
  Table,
  TextField,
  Typography,
} from "@pankod/refine-antd";
import { useCustom, useNavigation, useTranslate } from "@pankod/refine-core";
import RouterProvider from "@pankod/refine-react-router-v6";
import { Breadcrumb } from "antd";
import { INVOICE_PROCESS_INTERPRETER_API } from "api/baseApi";
import { CustomModal } from "components/Modal/CustomModal";
import { EFormatDateTimeForPDF, EMedia } from "constants/constant";
import { COL_INTERPRETER_INVOICE_PDF, COL_INTERPRETER_INVOICE_XLSX, COL_SUMMARY_PDF } from "constants/fileds";
import dayjs from "dayjs";
import { IInterpreter } from "interfaces/interpreter";
import {
  IInterpreterDetail,
  IInterpreterInvoice,
} from "interfaces/interpreterInvoice";
import { axiosInstance } from "providers/axios";
import React, { SetStateAction, useCallback, useMemo, useState } from "react";
import { formatNumber, getDataSummary } from "utils";
import { exportInvoiceToCSV, exportInvoiceToExcel, exportToPDF } from "utils/excelExporter";
import "../../../../styles/style.less";
import "../style.less";
import DetailModal from "./Modal/DetailModal";

const { Title } = Typography;

export const InterpreterProcessDetail: React.FC = () => {
  const translate = useTranslate();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [interpreterProcessDetail, setInterpreterProcessDetail] = useState<
    IInterpreterInvoice[]
  >([]);
  const [invoiceInterpreter, setInvoiceInterpreter] =
    useState<IInterpreterDetail[]>();
  const [interpreterDetail, setInterpreterDetail] =
    useState<IInterpreterInvoice>();
  const { push } = useNavigation();
  const { useParams } = RouterProvider;
  const params = useParams();
  const [page, setPage] = useState<number>(1);

  const { data: interpreterProcess } = useCustom({
    url: INVOICE_PROCESS_INTERPRETER_API.getOne + `/${params.slug}`,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
      },
    },
    queryOptions: {
      onSuccess: (interpreterProcess) => {
        setInterpreterProcessDetail(
          interpreterProcess?.data as SetStateAction<IInterpreterInvoice[]>
        );
      },
    },
  });

  const fromDate = interpreterProcess?.data[0]?.from_date;
  const toDate = interpreterProcess?.data[0]?.to_date;

  const dateRange = `Date Range: ${dayjs(fromDate)?.format(
    "DD/MM/YYYY"
  )} - ${dayjs(toDate)?.format("DD/MM/YYYY")}`;

  const { data: interpreterByProcess } = useCustom({
    url: INVOICE_PROCESS_INTERPRETER_API.findManyByProcess + `/${params.slug}`,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
      },
    },
  });

  const recordListCall = interpreterByProcess?.data?.map(
    (process: IInterpreterDetail) => ({
      "Appt ID": process?.callerId,
      "Invoice Create Date": dayjs(process?.createdAt).format(
        EFormatDateTimeForPDF.DATE
      ),
      "Interpreter ID": process?.intId,
      "Interpreter Name": process?.interpreterName,
      "Bus Phone": process?.busPhone,
      "Cell Phone": process?.cellPhone,
      "Fax Number": process?.faxNumber,
      "Customer Name": process?.customerName,
      "Customer Email": process?.emailCustomer,
      "Department Name": process?.departmentName,
      "Sub-Department Name": process?.subdepartmentName,
      "Requester Name": process?.requesterName,
      "Requester Email": process?.requesterEmail,
      Language: process?.language,
      "Start Date": process?.startTime
      ? dayjs(process?.startTime).format(EFormatDateTimeForPDF.DATE)
      : "",
      "Start Time": process?.startTime
        ? dayjs(process?.startTime).format(EFormatDateTimeForPDF.TIME)
        : "",
      "Finish Time": process?.endTime
        ? dayjs(process?.endTime).format(EFormatDateTimeForPDF.TIME)
        : "",
      "Duration (s)": process?.duration,
      "Connection Time": process?.connectTime,
      "Pay Total Amount ($)": process?.totalPay
        ? formatNumber(process?.totalPay)
        : "",
      "Type of Call":
        process?.media === EMedia.VIDEO ? EMedia.VIDEO : EMedia.AUDIO,
      Status: process?.status,
      "Other Ref1": process?.otherRef1,
      "Other Ref2": process?.otherRef2,
      "Other Ref3": process?.otherRef3,
      Notepad: process?.note,
    })
  );

  const handleOpenModal = async (interpreter: IInterpreterInvoice) => {
    try {
      const invoiceInterpreter = await axiosInstance.get(
        `${INVOICE_PROCESS_INTERPRETER_API.getDetail}/${interpreter?.invoice_process_interpreter_id}`
      );
      setInvoiceInterpreter(invoiceInterpreter?.data);
      setInterpreterDetail(interpreter);
      setIsModalVisible(true);
    } catch (error) {}
  };

  const columns = useMemo(
    () => [
      {
        key: "interpreter",
        title: "Interpreter Name",
        render: (value: IInterpreter, record: IInterpreterInvoice) => (
          <>
            <TextField
              onClick={() => handleOpenModal(record)}
              value={value ? value?.firstname + " " + value?.lastname : ""}
              style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            />
          </>
        ),
      },

      {
        key: "total",
        title: "Total",
        render: (value: number) => (
          <TextField value={value ? `$${formatNumber(value)}` : `$0`} />
        ),
      },

      {
        key: "check",
        title: "Check Nbr",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },

      {
        key: "checkDate",
        title: "Check Date",
        render: (value: string) => (
          // value ? <DateField format="LL" value={value ? value : ""} /> : "",
          <></>
        ),
      },

      {
        key: "nameCreate",
        title: `Update User`,
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "create_date",
        title: `Update Date`,
        render: (value: string) => (
          <DateField format="LLL" value={value ? value : ""} />
        ),
      },
    ],
    []
  );

  const pageTotal = interpreterProcess?.data.total;

  const handlePageChange = useCallback((page: number) => {
    setPage(page);
  }, []);

  const recordCallDetail = interpreterByProcess?.data
    ?.filter((item: IInterpreterDetail) => item?.invoiceProcessInterpreter)
    ?.map((process: IInterpreterDetail) => ({
      "Appt ID": process.callerId,
      "Invoice Create Date": dayjs(process?.createdAt).format(EFormatDateTimeForPDF.DATE),
        "Interpreter ID": process?.intId,
        "Interpreter Name": process?.interpreterName,
      "Bus Phone": process?.busPhone,
      "Cell Phone": process?.cellPhone,
      "Fax Number": process?.faxNumber,
      "Customer Name": process?.customerName,
      "Customer Email": process?.emailCustomer,
      "Department Name": process?.departmentName,
      "Sub-Department Name": process?.subdepartmentName,
      "Requester Name": process?.requesterName,
      "Requester Email": process?.requesterEmail,
      Language: process?.language,
      "Start Date":dayjs(process?.startTime).format(EFormatDateTimeForPDF.DATE),
      "Start Time": dayjs(process?.startTime).format(EFormatDateTimeForPDF.TIME),
      "Finish Time":dayjs(process?.endTime).format(EFormatDateTimeForPDF.TIME),
      Duration: process?.duration,
      "Connection Time": process?.connectTime,
      "Pay Total Amount ($)": process?.totalPay
        ? formatNumber(process?.totalPay)
        : "",
      "Type of Call":
        process?.media === EMedia.VIDEO ? EMedia.VIDEO : EMedia.AUDIO,
      Status: process?.status,
      "Other Ref1": process?.otherRef1,
      "Other Ref2": process?.otherRef2,
      "Other Ref3": process?.otherRef3,
      Notepad: process?.note,
    }));

  const handleExportExcel = () => {
    exportInvoiceToExcel( {
      fileName :  "Interpreter Invoice",
      dataInvoice :   recordCallDetail, 
      headerColInvoice :  COL_INTERPRETER_INVOICE_XLSX,
      headerColSummary : ['Items', 'Subtotal'], 
      dataSummary : getDataSummary(interpreterByProcess?.data).summaryRecords, 
      totalPay : Number(getDataSummary(interpreterByProcess?.data).totalAmounts)
    });
  };

  const handleExportCSV = () => {
    exportInvoiceToCSV({
      fileName :  "Interpreter Invoice",
      dataInvoice :  recordCallDetail, 
      headerColInvoice :  COL_INTERPRETER_INVOICE_XLSX,
      headerColSummary : ['Items', 'Subtotal'], 
      dataSummary : getDataSummary(interpreterByProcess?.data).summaryRecords, 
      totalPay : Number(getDataSummary(interpreterByProcess?.data).totalAmounts)
    })
  };

  const handleBackPage = useCallback(() => {
    push("/invoice/view-print-interpreter-invoice");
  }, [])

  const handleExportPDF = () => {
    exportToPDF({
      columns: COL_INTERPRETER_INVOICE_PDF,
      data: recordListCall,
      fileName: "Interpreter Invoice.pdf",
      name: interpreterProcess?.data[0]?.interpreter?.firstname
        ? `Interpreter Name: ${
          interpreterProcess?.data[0]?.interpreter?.firstname +
            " " +
            interpreterProcess?.data[0]?.interpreter?.lastname
          }`
        : "",
      date: dateRange,
      colSummary : COL_SUMMARY_PDF,
      dataSummary : getDataSummary(interpreterByProcess?.data).summaryRecords, 
      totalSummary : Number(getDataSummary(interpreterByProcess?.data).totalAmounts)
    });
  };

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Invoice</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.interpreterProcessDetail")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      <div className="title-process-detail">
        <Row>
          <Title level={5}>
            <span className="title-invoice">Process Number: </span>{" "}
            {interpreterProcess?.data[0]?.id}
          </Title>
        </Row>
        <Row>
          <Title level={5}>
            <span className="title-invoice">Interpreter:</span>{" "}
            {interpreterProcessDetail?.length} Record(s) Found
          </Title>
        </Row>
      </div>
      <Table
        dataSource={interpreterProcessDetail?.map(
          (item: IInterpreterInvoice, index: number) => ({
            ...item,
            key: index,
          })
        )}
        pagination={
          (pageTotal as number) > 10
            ? {
                current: page,
                total: pageTotal ? pageTotal : 1,
                onChange: handlePageChange,
                showTotal: () => `${pageTotal} total`,
              }
            : false
        }
        scroll={{ x: 900 }}
      >
        {columns.map((col) => (
          <Table.Column dataIndex={col.key} {...(col as any)} />
        ))}
      </Table>

      <Space style={{ marginTop: 10 }} wrap={isMobile}>
        <Button className="btn-create" onClick={handleExportExcel}>
          Excel All
        </Button>
        <Button className="btn-create" onClick={handleExportCSV}>
          CSV All
        </Button>
        <Button className="btn-create" onClick={handleExportPDF}>
          PDF All
        </Button>
        <Button className="process back" onClick={handleBackPage}>
          Back
        </Button>
      </Space>

      <CustomModal
        title="Interpreter's Invoice Details"
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      >
        <DetailModal
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
          invoiceInterpreter={invoiceInterpreter}
          interpreterDetail={interpreterDetail}
        />
      </CustomModal>
    </List>
  );
};
