import {
  Button,
  Grid,
  List,
  Row,
  Space,
  Table,
  TextField,
  Typography,
} from "@pankod/refine-antd";
import React, { useEffect, useMemo, useState } from "react";
import "../../../../styles/style.less";
import { Breadcrumb } from "antd";
import { useCustom, useNavigation, useTranslate } from "@pankod/refine-core";
import "../style.less";
import { CUSTOMER_API, INVOICE_PROCESS_CUSTOMER_API } from "api/baseApi";
import { CustomModal } from "components/Modal/CustomModal";
import CustomerInvoiceDetail from "./customer-invoice-detail/list";
import RouterProvider from "@pankod/refine-react-router-v6";
import { ICustomerInvoice } from "interfaces/customerInvoice";
import { axiosInstance } from "providers/axios";
import { exportToCSV, exportToExcel, exportToPDF } from "utils/excelExporter";
import dayjs from "dayjs";
import { convertToTableColumns, customColumn, formatNumber, getTotalAmounts } from "utils";
import { EFormatDateTimeForPDF, EMedia } from "constants/constant";
import "dayjs/locale/en"; // import the English locale

interface IDynamicType {
  [key : string] :  string | number | boolean | undefined | null;
}

const columnMappings = {
  serviceRecipient1FullName: "LEP 1 Full Name",
  serviceRecipient2FullName: "LEP 2 Full Name",
  bbusPhone: "Bus Phone",
  bcellPhone: "Cell Phone",
  bfaxNumber: "Fax Number",
  bcity: "City",
  bOtherRef1: "Other Ref1",
  bOtherRef2: "Other Ref2",
  bOtherRef3: "Other Ref3",
  billTotalAmount: "Bill Total Amount",
  billItem1: "Bill Item1",
  billItem2: "Bill Item2",
  billItem3: "Bill Item3",
  billItem4: "Bill Item4",
  billItem1SubTotal: "Bill Item1 Sub Total",
  billItem2SubTotal: "Bill Item2 Sub Total",
  billItem3SubTotal: "Bill Item3 Sub Total",
  billItem4SubTotal: "Bill Item4 Sub Total",
};

const formatDateTimePDF = (time: string, teamplate: EFormatDateTimeForPDF) => {
  return time ? dayjs(time).format(
    teamplate
  ) : '';
}

const BILL_ITEMS = [
  "billItem1",
  "billItem1Subtotal",
  "billItem2",
  "billItem2Subtotal",
  "billItem3",
  "billItem3Subtotal",
  "billItem4",
  "billItem4Subtotal",
];

const { Title } = Typography;

export const CustomerProcessDetail: React.FC = () => {
  dayjs.locale("en");
  const translate = useTranslate();
  const { push } = useNavigation();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const { useParams } = RouterProvider;
  const params = useParams();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceCustomer, setInvoiceCustomer] = useState<ICustomerInvoice[]>(
    []
  );
  const [invoiceCustomerModal, setInvoiceCustomerModal] = useState<
    ICustomerInvoice[]
  >([]);
  const [customerDetail, setCustomerDetail] = useState<ICustomerInvoice>();

  const [page, setPage] = useState<number>(1);
  const [customerFiled, setCustomerField] = useState<any>();

  const { data: customerProcess } = useCustom({
    url: INVOICE_PROCESS_CUSTOMER_API.getOne + `/${params.slug}`,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
      },
    },
  });

  const arrCusProcess: ICustomerInvoice[] = [];

  if (customerProcess?.data) {
    arrCusProcess.push(customerProcess.data as ICustomerInvoice);
  }

  const viewProcessDetail = async (customer: ICustomerInvoice) => {
    const invoiceCustomer = await axiosInstance.get(
      INVOICE_PROCESS_CUSTOMER_API.getDetail + `/${customer?.invoiceProcessId}`
    );

    const invoiceCustomerShowOne = await axiosInstance.get(
      INVOICE_PROCESS_CUSTOMER_API.getDetailShowOne +
      `/${customer?.invoiceProcessId}`
    );

    setInvoiceCustomer(invoiceCustomer?.data);
    setInvoiceCustomerModal(invoiceCustomerShowOne?.data);
    setCustomerDetail(customer);
    setIsModalVisible(true);
  };

  const { data: customerProcessDetail } = useCustom({
    url: INVOICE_PROCESS_CUSTOMER_API.getDetail + `/${params.slug}`,
    method: "get",
  });

  useEffect(() => {
    (async () => {
      if (customerProcess?.data?.customerId) {
        const customer = await axiosInstance.get(
          CUSTOMER_API.getOne + `/${customerProcess?.data?.customerId}`
        );
        setCustomerField(customer?.data?.field);
      }
    })();
  }, [customerProcess?.data?.customerId]);

  const collumns = useMemo(
    () => [
      {
        key: "id",
        title: "Nbr.",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },

      {
        key: "invoiceProcessId",
        title: "Invoice#",
        render: (value: string, record: ICustomerInvoice) => (
          <TextField
            value={value ? value : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => viewProcessDetail(record)}
          />
        ),
      },

      {
        key: "totalBill",
        title: "Total",
        render: (value: number) => (
          <TextField value={value ? `$${formatNumber(value)}` : `$0`} />
        ),
      },
    ],
    []
  );

  const pageTotal = customerProcess?.data.total;
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleBackPage = () => {
    push("/invoice/view-print-customer-invoice");
  };

  const trueFields = Object.keys(customerFiled || {}).filter(
    (key) => customerFiled[key] === true
  );

  const recordCustomerDetail = customerProcessDetail?.data
    ?.map((process: ICustomerInvoice) => {
      const newItem: Partial<ICustomerInvoice> = { ...process };
      if (trueFields.includes("invoicePeriodFromDate")) {
        newItem.invoicePeriodFromDate = formatDateTimePDF(process?.invoicePeriodFromDate, EFormatDateTimeForPDF.DATE);
      }
      if (trueFields.includes("invoicePeriodToDate")) {
        newItem.invoicePeriodToDate = formatDateTimePDF(process?.invoicePeriodToDate, EFormatDateTimeForPDF.DATE);
      }
      if (trueFields.includes("invoiceCreateDate")) {
        newItem.invoiceCreateDate = formatDateTimePDF(process?.invoiceCreateDate, EFormatDateTimeForPDF.DATE);
      }
      if (trueFields.includes("startTime")) {
        newItem.startTime = formatDateTimePDF(process?.startTime, EFormatDateTimeForPDF.TIME);
        newItem.startDate = formatDateTimePDF(process?.startTime, EFormatDateTimeForPDF.DATE);
      }
      if (trueFields.includes("finishTime")) {
        newItem.finishTime = formatDateTimePDF(process?.finishTime, EFormatDateTimeForPDF.TIME);
        newItem.finishDate = formatDateTimePDF(process?.finishTime, EFormatDateTimeForPDF.DATE);
      }
      if (
        trueFields.some((field) =>
          BILL_ITEMS.includes(field)
        )
      ) {
        newItem.billItem = formatNumber(process?.billItem) || ("" as any);
      }
      if (trueFields.includes("billTotalAmount")) {
        newItem.billTotalAmount =
          formatNumber(process?.billTotalAmount) || ("" as any);
      }
      if (trueFields.includes("tax")) {
        newItem.tax = formatNumber(process?.tax) || ("" as any);
      }
      if (trueFields.includes("preTaxAmount")) {
        newItem.preTaxAmount =
          formatNumber(process?.preTaxAmount) || ("" as any);
      }
      if (trueFields.includes("typeOfCall")) {
        newItem.typeOfCall =
          process?.typeOfCall === EMedia.VIDEO ? EMedia.VIDEO : EMedia.AUDIO;
      }
      if (trueFields.includes("bOtherRef1")) {
        newItem.otherRef1 = process?.bOtherRef1 || "";
      }
      if (trueFields.includes("bOtherRef2")) {
        newItem.otherRef2 = process?.bOtherRef2 || "";
      }
      if (trueFields.includes("bOtherRef3")) {
        newItem.otherRef3 = process?.bOtherRef3 || "";
      }
      return newItem;
    })
    ?.map(
      ({
        callId,
        id,
        createId,
        callTime,
        dateService,
        bOtherRef,
        bOtherRef1,
        bOtherRef2,
        bOtherRef3,
        interpreterIds,
        interpreterId, 
        customerId,
        busPhone, 
        invoiceCreateUser,
        ...rest
      }: any) => {
        return rest;
      }
    )
    ?.map((item: IDynamicType) => {
      const formattedItem: IDynamicType = {};
      const {apptNbr, startDate, finishDate, startTime, finishTime, ...agrs} = item;
      for (const key in {apptNbr, startDate, finishDate, startTime, finishTime, ...agrs}) {
        const formattedKey = key.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
          return str?.toUpperCase();
        });
        if (key === 'intId') {
          formattedItem['Interpreter Id'] = item[key];
          continue;
        }
        formattedItem[formattedKey] = item[key];
      }
      return formattedItem;
    });

  const columnHeader = trueFields.map((column) => {
    let newColumn = column.replace(/([A-Z])/g, " $1"); // separate example 2 letters
    newColumn = newColumn.charAt(0).toUpperCase() + newColumn.slice(1); // capitalize first letter

    if (columnMappings.hasOwnProperty(column)) {
      return columnMappings[column as keyof typeof columnMappings];
    }

    return newColumn;
  });

  const handleExportExcel = () => {
    exportToExcel(
      `Customer Invoice - Process#${customerProcess?.data?.invoiceProcessId}`,
      recordCustomerDetail,
      recordCustomerDetail?.length
        ? customColumn(recordCustomerDetail)
        : columnHeader, `Total Number of Appointments: ${recordCustomerDetail.length}`, `Total Amounts: $${getTotalAmounts(customerProcessDetail?.data).toFixed(2)}`
    );
  };

  const handleExportCSV = () => {
    exportToCSV(
      recordCustomerDetail,
      `Customer Invoice - Process#${customerProcess?.data?.invoiceProcessId}`,
      recordCustomerDetail?.length
        ? customColumn(recordCustomerDetail)
        : columnHeader, `Total Number of Appointments: ${recordCustomerDetail.length}`, `Total Amounts: $${getTotalAmounts(customerProcessDetail?.data).toFixed(2)}`
    );
  };

  const general = columnHeader.map((key) => ({ key }));
  const titleInvoiceCus = `Invoice Report for: ${customerProcess?.data?.customer?.name}`;

  const fromDate = customerProcess?.data?.invoiceProcess.fromDate;
  const toDate = customerProcess?.data?.invoiceProcess.toDate;
  const formattedFromDate = dayjs(fromDate).format("DD/MM/YYYY");
  const formattedToDate = dayjs(toDate).format("DD/MM/YYYY");
  const period = `Period: ${formattedFromDate} - ${formattedToDate}`;

  const handleExportPDF = () => {
    if (recordCustomerDetail.length) {
      exportToPDF({
        columns: convertToTableColumns(recordCustomerDetail),
        data: recordCustomerDetail,
        fileName: `Customer Invoice - Process#${customerProcess?.data?.invoiceProcessId}.pdf`,
        name: titleInvoiceCus,
        date: period,
        nameInvoice: `Invoice#: ${customerProcess?.data?.invoiceProcessId}`,
        isMultiCol: true,
        totalRow: `Total Number of Appointments : ${recordCustomerDetail.length}`,
        totalAmounts: Number(getTotalAmounts(customerProcessDetail?.data).toFixed(2))
      });
    } else {
      exportToPDF({
        columns: general,
        data: recordCustomerDetail,
        fileName: `Customer Invoice - Process#${customerProcess?.data?.invoiceProcessId}.pdf`,
        name: titleInvoiceCus,
        date: period,
        nameInvoice: `Invoice#: ${customerProcess?.data?.invoiceProcessId}`,
        isMultiCol: true,
        totalAmounts: Number(getTotalAmounts(recordCustomerDetail).toFixed(2))
      });
    }
  };

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Invoice</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.customerProcessDetail")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      pageHeaderProps={{
        extra: (
          <Space wrap={isMobile}>
            <Button className="btn-create" onClick={handleExportExcel}>
              Excel
            </Button>
            <Button className="btn-create" onClick={handleExportCSV}>
              CSV
            </Button>
            <Button className="btn-create" onClick={handleExportPDF}>
              PDF
            </Button>
          </Space>
        ),
      }}
    >
      <div className="title-process-detail">
        <Row>
          <Title level={5}>
            <span className="title-invoice">
              Invoices for {customerProcess?.data?.customer?.name}:
            </span>{" "}
            {arrCusProcess?.length} Record(s) Found
          </Title>
        </Row>
      </div>

      <Table
        dataSource={arrCusProcess?.map(
          (item: ICustomerInvoice, index: number) => ({
            ...item,
            key: index,
          })
        )}
        pagination={
          (pageTotal as number) > 10
            ? {
              current: 1,
              total: pageTotal ? pageTotal : 1,
              showTotal: () => `${pageTotal} total`,
              onChange: handlePageChange,
            }
            : false
        }
        scroll={{ x: 800 }}
      >
        {collumns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
      <Button className="process back" onClick={handleBackPage}>
        Back
      </Button>

      <CustomModal
        title={translate("invoice.title.info")}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      >
        <CustomerInvoiceDetail
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
          invoiceCustomer={invoiceCustomer}
          invoiceCustomerModal={invoiceCustomerModal}
          customerDetail={customerDetail}
          columnHeader={columnHeader}
          titleInvoiceCus={titleInvoiceCus}
          period={period}
          nameInvoice={customerProcess?.data?.invoiceProcessId}
          trueFields={trueFields}
        />
      </CustomModal>
    </List>
  );
};
