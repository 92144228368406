import {
  AutoComplete,
  Button,
  DatePicker,
  Empty,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Result,
  Space,
  Spin,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { useCustom } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { CUSTOMER_API, REPORT_CALL_LANGUAGE_API } from "api/baseApi";
import { EFormatDateTime } from "constants/constant";
import dayjs, { Dayjs } from "dayjs";
import useNotification from "hooks/useNotification";
import { ICustomerReq } from "interfaces/customer";
import { IFillLanguage } from "interfaces/fillLanguageReport";
import { axiosInstance } from "providers/axios";
import React, { useMemo, useState } from "react";
import { exportToCSV, exportToExcel, exportToPDF } from "utils/excelExporter";

const { SearchOutlined, CloseOutlined, ExclamationCircleOutlined } = Icons;

const COL_EXCEL = [
  "Language",
  "Total Requests",
  "Requests Filled",
  "Requests not Filled",
  "Requests not connected",
  "Percentage Filled",
];

const COL_PDF = [
  { key: "Language" },
  { key: "Total Requests" },
  { key: "Requests Filled" },
  { key: "Requests not Filled" },
  { key: "Requests not connected" },
  { key: "Percentage Filled" },
];

export const ApptFillRateByLang: React.FC = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [customerId, setCustomerId] = useState<string>();
  const [searchCustomer, setSearchCustomer] = useState<string>("");
  const { openNotificationErrors, openNotificationInfo, contextHolder } =
    useNotification();

  const { data: customersData } = useCustom({
    url: CUSTOMER_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
        search: null,
      },
    },
  });

  const { data: aptFillByLagsData } = useCustom({
    url: REPORT_CALL_LANGUAGE_API,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
        start: startDate ? dayjs(startDate).format(EFormatDateTime.DATE) : "",
        end: endDate ? dayjs(endDate).format(EFormatDateTime.DATE) : "",
        id: customerId,
      },
    },
    queryOptions: {
      enabled: isSearch,
      onSuccess: (aptFillByLag) => {
        if (!aptFillByLag?.data?.results?.length) {
          openNotificationInfo(
            "No data found",
            "There are no results matching Appointment Fill Rate By Language Reports",
            <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
          );
        }
      },
    },
  });

  const pageTotal = aptFillByLagsData?.data?.total;

  const dateRange = `Date Range: ${dayjs(startDate)?.format(
    "DD/MM/YYYY"
  )} - ${dayjs(endDate)?.format("DD/MM/YYYY")}`;

  localStorage.setItem("date", dateRange);

  const handleChange = (page: number) => {
    setPage(page);
  };

  const handleShowAllPDF = (record: IFillLanguage) => {
    const data = [record];
    exportToPDF({
      columns: COL_PDF,
      data: viewData(data),
      fileName: "Appoinment Fill Rate by Language Reports.pdf",
      name: "Fill Rate by Language Report",
      date: localStorage.getItem("date"),
      nameInvoice: searchCustomer ? `Customer: ${searchCustomer}` : "",
    });
    localStorage.removeItem("date");
  };

  const handleExportAllExcel = (record: IFillLanguage) => {
    const data = [record];
    exportToExcel(
      "Appoinment Fill Rate by Language Reports",
      viewData(data),
      COL_EXCEL
    );
  };
  const handleExportAllCSV = (record: IFillLanguage) => {
    const data = [record];
    exportToCSV(
      viewData(data),
      "Appoinment Fill Rate by Language Reports",
      COL_EXCEL
    );
  };
  const collumns = useMemo(
    () => [
      {
        key: "key",
        title: "Nbr.",
        render: (value: string) => (
          <TextField
            value={
              value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
            }
          />
        ),
      },
      {
        key: "language",
        title: "Print",
        render: (value: string, record: IFillLanguage) => (
          <TextField
            value={value ? "Print" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleShowAllPDF(record)}
          />
        ),
      },

      {
        key: "language",
        title: "Excel",
        render: (value: string, record: IFillLanguage) => (
          <TextField
            value={value ? "Excel" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleExportAllExcel(record)}
          />
        ),
      },

      {
        key: "language",
        title: "CSV",
        render: (value: string, record: IFillLanguage) => (
          <TextField
            value={value ? "CSV" : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleExportAllCSV(record)}
          />
        ),
      },

      {
        key: "language",
        title: "Language",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "complete",
        title: "Complete",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "missed",
        title: "Missed",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "rejected",
        title: "Not Connected",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "percent",
        title: "Percent",
        render: (value: string) => (
          <TextField value={value ? `${value}%` : ""} />
        ),
      },

      {
        key: "total",
        title: "Total",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
    ],
    [page]
  );
  const onChange = (customerId: string) => {
    setSearchCustomer(customerId);
  };

  const onSelect = (customerId: string, option: BaseOptionType) => {
    setCustomerId(customerId);
    setSearchCustomer(option.label);
    setIsSearch(true);
  };

  const handleRemoveCustomer = () => {
    setCustomerId("");
    setSearchCustomer("");
    // setIsSearch(false);
  };

  const handleStartDate = (startDate: Dayjs | null) => {
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate("");
    }
  };

  const handleEndDate = (endDate: Dayjs | null) => {
    if (endDate) {
      setIsSearch(true);
      setEndDate(endDate);
    } else {
      setIsSearch(false);
      setEndDate("");
    }
  };


  const viewData = (arrAptFillLag: IFillLanguage[]) => {
    return arrAptFillLag?.map((language: IFillLanguage) => {
      const {
        language: languageName,
        total,
        complete,
        missed,
        rejected,
        percent,
      } = language;
      return {
        Language: languageName,
        "Total Requests": total,
        "Requests Filled": complete,
        "Requests not Filled": missed,
        "Requests not connected": rejected,
        "Percentage Filled": `${percent}%`,
      };
    });
  };



  const handleViewAptFillLag = () => {
    const formattedStartDate = dayjs(startDate).format(EFormatDateTime.DATE);
    const formattedEndDate = dayjs(endDate).format(EFormatDateTime.DATE);
    if (!startDate || !endDate) {
      return Promise.reject(new Error("Missing start or end date"));
    }

    if (customerId) {
      return axiosInstance.get(
        REPORT_CALL_LANGUAGE_API +
        `?id=${customerId}&start=${formattedStartDate}&end=${formattedEndDate}`
      );
    } else {
      return axiosInstance.get(
        REPORT_CALL_LANGUAGE_API +
        `?id=&start=${formattedStartDate}&end=${formattedEndDate}`
      );
    }
  };

  const handleExportPDF = async () => {
    try {
      setIsLoading(true);
      const { data } = await handleViewAptFillLag();
      exportToPDF({
        columns: COL_PDF,
        data: viewData(data?.results),
        fileName: "Appoinment Fill Rate by Language Reports.pdf",
        name: "Fill Rate by Language Report",
        date: dateRange,
        nameInvoice: searchCustomer ? `Customer: ${searchCustomer}` : "",
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotificationErrors(
        "Error",
        "Please fill in all the required fields",
        "topRight",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  };

  const handleExportExcel = async () => {
    try {
      setIsLoading(true);
      const { data } = await handleViewAptFillLag();
      exportToExcel(
        "Appoinment Fill Rate by Language Reports",
        viewData(data?.results),
        COL_EXCEL
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotificationErrors(
        "Error",
        "Please fill in all the required fields",
        "topRight",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  };

  const handleExportCSV = async () => {
    try {
      setIsLoading(true);
      const { data } = await handleViewAptFillLag();
      exportToCSV(
        viewData(data?.results),
        "Appoinment Fill Rate by Language Reports",
        COL_EXCEL
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotificationErrors(
        "Error",
        "Please fill in all the required fields",
        "topRight",
        <ExclamationCircleOutlined style={{ color: "#ff5252" }} />
      );
    }
  };

  let localeNoData = {
    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
  };
  let locale = {
    emptyText: (
      <Result subTitle="Please select appoiment fill by language report" />
    ),
  };

  return (
    <Spin spinning={isLoading} size="large">
      <List
        title={
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item>Report</Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item">
              Appointment Fill Rate By Language Reports
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {contextHolder}
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Invoice Period From Date"
            name="fromDate"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter start date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={startDate}
              onChange={(e) => handleStartDate(e)}
              disabledDate={(current) => {
                return endDate ? current && current > endDate : false;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Invoice Period To Date"
            name="toDate"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter end date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={endDate}
              disabledDate={(current) => {
                return current && current < startDate;
              }}
              onChange={(e) => handleEndDate(e)}
            />
          </Form.Item>
        </Form>
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label={"Search Customer by Name"}
            className={!isMobile ? "search-item" : ""}
          >
            <AutoComplete
              showSearch
              size="large"
              style={{
                width: "100%",
                borderBottom: "1px solid #d9d9d9",
                borderRadius: "0px",
              }}
              options={customersData?.data?.results?.map(
                (item: ICustomerReq, index: number) => ({
                  value: item.id,
                  label: item.name,
                })
              )}
              filterOption={(
                searchCustomer,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.label
                  ?.toUpperCase()
                  .indexOf(searchCustomer.toUpperCase()) !== -1
              }
              bordered={false}
              value={searchCustomer}
              onChange={onChange}
              onSelect={onSelect}
            >
              <Input
                suffix={
                  <>
                    {searchCustomer && (
                      <CloseOutlined onClick={handleRemoveCustomer} />
                    )}
                    <SearchOutlined />
                  </>
                }
                bordered={false}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item
            label="
        "
            className={!isMobile ? "search-item" : ""}
          >
            <Space wrap={isMobile}>
              <Button
                className={
                  isMobile ? "search-report-call-mobile" : "search-report-call"
                }
                onClick={handleExportPDF}
              >
                PDF report
              </Button>
              <Button
                className={
                  isMobile ? "search-report-call-mobile" : "search-report-call"
                }
                onClick={handleExportExcel}
              >
                Excel report
              </Button>
              <Button
                className={
                  isMobile ? "search-report-call-mobile" : "search-report-call"
                }
                onClick={handleExportCSV}
              >
                CSV report
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <Table
          locale={
            (startDate && endDate) || searchCustomer ? localeNoData : locale
          }
          dataSource={aptFillByLagsData?.data?.results?.map(
            (item: IFillLanguage, index: number) => ({
              ...item,
              key: index,
            })
          )}
          rowKey="id"
          pagination={
            pageTotal > 10
              ? {
                total: pageTotal ? pageTotal : 1,
                current: page,
                onChange: handleChange,
                showTotal: () => `${pageTotal} total`,
              }
              : false
          }
          scroll={{ x: 800 }}
        >
          {collumns.map((col) => (
            <Table.Column dataIndex={col.key} {...col} />
          ))}
        </Table>
      </List>
    </Spin>
  );
};
