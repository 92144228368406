import { Table, TextField, Typography } from "@pankod/refine-antd";
import { BaseRecord } from "@pankod/refine-core";
import { CustomModal } from "components/Modal/CustomModal";
import { CollageContext } from "hooks/useContext/useCollagedContext";
import { socket } from "hooks/useContext/useSocketContext";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { ILanguageOnline } from "interfaces/users";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ModalProfileDetail from "../Modal/ModalDetailProfile";
import { ProfileDashboard } from "../Modal/Profile";
import { ProfileDetail } from "../Modal/ProfileDetail";
import "../style.css";
import { ETitleStatus } from "constants/constant";
import InterpretersActsList from "../Modal/InterpretersActsList";

interface IInterpreterOnline {
  language: string;
  languageId: number;
  ready: [];
  inCall: [];
}

type InterpretersTableProps = {
  listOfInterpretersOnline: Array<IInterpreterOnline> | BaseRecord | undefined;
};

export const InterpretersTable = ({
  listOfInterpretersOnline,
}: InterpretersTableProps) => {
  const [interpreterData, setInterpreterData] = useState<any>([]);
  const [listProfile, setListProfile] = useState<ILanguageOnline>(null!);
  const [isModalDetail, setIsModalDetail] = useState(false);
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const [titleStatus, setTitleStatus] = useState("");
  const [isProfile, setIsProfile] = useState(false);
  const [interpreterId, setInterpreterId] = useState<number>();
  const interpretersActs = useRef<Array<IInterpreterOnline>>([]);
  const languageId = useRef<number>();

  const titleProfile = `${titleStatus} : ${listProfile?.users?.length} - ${listProfile?.language}`;

  const handleOpenModalProfile = (interpreterId: number) => {
    setIsProfile(true);
    setInterpreterId(interpreterId);
    setIsModalDetail(true);
    setIsProfileVisible(false);
  };

  const handleOpenModalDetail = (language: any) => {
    const interpreterActs: IInterpreterOnline = interpretersActs.current.find(
      (interpreter) => interpreter.languageId === language.languageId
    ) as IInterpreterOnline;
    setListProfile({
      languageId: interpreterActs.languageId,
      language: interpreterActs.language,
      users: [...interpreterActs.inCall, ...interpreterActs.ready],
    });
    setTitleStatus("Online");
    languageId.current = language.languageId;
    setIsProfileVisible(true);
  };

  const handleOpenOnlineInterpreter = (language: any) => {
    language.users = language.ready;
    setListProfile(language);
    setTitleStatus("Available");
    languageId.current = language.languageId;
    setIsProfileVisible(true);
  };

  const handleOpenOfflineInterpreter = (language: any) => {
    language.users = language.inCall;
    setListProfile(language);
    setTitleStatus("On Call");
    languageId.current = language.languageId;
    setIsProfileVisible(true);
  };
  useEffect(() => {
    if (isProfileVisible) {
      const interpreterActs: IInterpreterOnline = interpretersActs.current.find(
        (interpreter) => interpreter.languageId === languageId.current
      ) as IInterpreterOnline;
      switch (titleStatus) {
        case "Online":
          if(!interpreterActs?.ready?.length && !interpreterActs?.inCall?.length){
            setIsProfileVisible(false);
            return;
          }
          setListProfile({
            ...listProfile,
            users: [...interpreterActs?.inCall, ...interpreterActs?.ready],
          });
          break;
        case "Available":
          if(!interpreterActs?.ready?.length){
            setIsProfileVisible(false);
            return;
          }
          setListProfile({
            ...listProfile,
            users: interpreterActs?.ready || [],
          });
          break;
        case "On Call":
          if (!interpreterActs?.inCall?.length) {
            setIsProfileVisible(false);
            return;
          }
          setListProfile({
            ...listProfile,
            users: interpreterActs?.inCall,
          });
          break;
        //no default
      }
    }
  }, [interpreterData]);

  const collumnInterpreters = useMemo(
    () => [
      {
        title: "Language",
        dataIndex: "language",
        width: "55%",
        render: (value: string, record: ILanguageOnline) => (
          <TextField
            value={value || ""}
            className="interpreter-enable"
            onClick={() => handleOpenModalDetail(record)}
          />
        ),
      },
      {
        title: "Available",
        dataIndex: "numberOfAvailable",
        width: "15%",
        render: (value: number, record: any) => (
          <TextField
            value={value}
            className={value ? "interpreter-enable" : "interpreter-disable"}
            onClick={() => handleOpenOnlineInterpreter(record)}
          />
        ),
      },
      {
        title: "Online",
        dataIndex: "numberOfOnline",
        width: "15%",
        render: (value: number, record: any) => (
          <TextField
            value={value}
            className={value ? "interpreter-enable" : "interpreter-disable"}
            onClick={() => handleOpenModalDetail(record)}
          />
        ),
      },
      {
        title: "On-Call",
        dataIndex: "numberOfOnCall",
        width: "15%",
        render: (value: number, record: any) => (
          <TextField
            value={value}
            className={value ? "interpreter-enable" : "interpreter-disable"}
            onClick={() => handleOpenOfflineInterpreter(record)}
          />
        ),
      },
    ],
    []
  );

  const getInterpreterOnline = useCallback(
    (listInterpreter: Array<IInterpreterOnline>) => {
      interpretersActs.current = listInterpreter;
      return listInterpreter?.map((item: any, index: number) => ({
        key: index,
        language: item.language,
        numberOfAvailable : item.ready.length,
        numberOfOnline: item.ready.length + item.inCall.length,
        numberOfOnCall: item.inCall.length,
        users: [...item.ready, ...item.inCall],
        languageId: item.languageId,
        ready: item.ready,
        inCall: item.inCall,
      }));
    },
    []
  );

  useEffect(() => {
    setInterpreterData(
      getInterpreterOnline(
        listOfInterpretersOnline as Array<IInterpreterOnline>
      )
    );
  }, [listOfInterpretersOnline, getInterpreterOnline]);

  useEffect(() => {
    socket.on("language-ready", (data) => {
      setInterpreterData(getInterpreterOnline(data?.languages));
    });

    return () => {
      socket.off("language-ready");
    };
  }, []);

  return (
    <>
      <Table
        dataSource={interpreterData}
        bordered
        title={() => (
          <Typography.Title level={5}>Interpreters</Typography.Title>
        )}
        pagination={false}
        scroll={{
          y: 330,
          x: 700
        }}
        columns={collumnInterpreters}
      />
      <ModalProfileDetail
        setIsModalVisible={setIsModalDetail}
        isModalVisible={isModalDetail}
      >
        <ProfileDetail
          isModalVisible={isModalDetail}
          interpreterId={interpreterId}
          isProfile={isProfile}
          setIsModalVisible={setIsModalDetail}
        />
      </ModalProfileDetail>
      <CustomModal
        title={titleProfile}
        setIsModalVisible={setIsProfileVisible}
        isModalVisible={isProfileVisible}
        width="35%"
      >
        {'Online' === titleStatus ? (
          <InterpretersActsList
            listProfile={listProfile}
            handleOpenModalProfile={handleOpenModalProfile}
          />
        ) : (
          <ProfileDashboard
            listProfile={listProfile}
            handleOpenModalProfile={handleOpenModalProfile}
          />
        )}
      </CustomModal>
    </>
  );
};
