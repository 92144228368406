import { Button, Grid, Modal } from "@pankod/refine-antd";
import { ReactNode, useCallback } from "react";

type ModalProps = {
  setIsModalVisible: (data: boolean) => void;
  isModalVisible: boolean;
  children?: ReactNode;
  width?: string | number;
}
const ModalProfileDetail = (props: ModalProps) => {
  const { setIsModalVisible, isModalVisible, width, children } = props;

  const handleHiddenModal = useCallback(()=>{
    setIsModalVisible(false);
  }, [])

  return (
    <Modal
      centered
      visible={isModalVisible}
      onOk={handleHiddenModal}
      onCancel={handleHiddenModal}
      footer={null}
      width={width}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
      closable={false}
    >
      {children}
    </Modal>
  );
};

export default ModalProfileDetail;
