import { Avatar, Space } from "antd";
import "../../styles/style.less";

type DefaultAvatarProps = {
  lastname: string;
  sizeAvatar: number;
  fontSizeProps: number;
};

const BGAVATAR = [
  "#ebd834",
  "#ebd834",
  "#eb7434",
  "#eb4f34",
  "#3443eb",
  "#34abeb",
  "#34ebbd",
  "#34eb34",
  "#eb3459",
  "#80eb34",
  "#34eb7d",
  "#34cdeb",
  "#3459eb",
  "#6234eb",
  "#8f34eb",
  "#e6e36c",
  "#4a4906",
  "#194d2e",
  "#084037",
  "#913f16",
  "#360e9c",
  "#a8e3ed",
  "#8ea0a3",
  "#bbedd1",
  "#aad9a0",
  "#cfd9a0",
];

export default function DefaultAvatar({
  lastname,
  sizeAvatar,
  fontSizeProps,
}: DefaultAvatarProps) {
  return (
    <Space className="contain-avartar">
      <Avatar
        size={sizeAvatar}
        style={{
          backgroundColor: BGAVATAR[lastname.toUpperCase().charCodeAt(0) - 65],
          fontSize: "123px",
        }}
      >
        <span
          style={{
            fontSize: fontSizeProps,
          }}
        >
          {lastname[0].toUpperCase()}
        </span>
      </Avatar>
    </Space>
  );
}
