import { Button, Space } from "@pankod/refine-antd";
import { BaseKey, useUpdate } from "@pankod/refine-core";
import { ACTIVE_USER_API, DEACTIVE_USER_API } from "api/baseApi";
import { openNotification } from "utils";
import { UserProps } from "./interface";
import { EStatusNotification, MSG_NOTIFICATION } from "constants/constant";

const UserActive = (props: UserProps) => {
  const { setIsModalVisible, user, page, handleChange } = props;
  const { mutate } = useUpdate();

  const handleChangeActive = () => {
    mutate({
      resource: user?.isActive ? DEACTIVE_USER_API : ACTIVE_USER_API,
      id: user?.id as BaseKey,
      values: {
        name: user,
      },
    }, {
      onSuccess: (res) => {
        setIsModalVisible(false);
        handleChange(page);
        const status = res?.data.message.includes('DeActive') ? 'Deactivated' : 'Activated';
        const description = `User has been ${status}`;
        openNotification(MSG_NOTIFICATION.SUCCESSFUL, description, EStatusNotification.SUCCESS);
      }
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Space
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
      }}
    >
      <div>
        You are about to change the Active Status of these  <strong>Users</strong>
        . Please select <strong>Change</strong> to continue or{" "}
        <strong>Cancel</strong> to go back
      </div>
      <Space style={{ marginTop: 10 }}>
        <Button
          onClick={handleChangeActive}
          className="btn-create"
          style={{ marginRight: "10px", backgroundColor: "#" }}
        >
          Change
        </Button>
        <Button onClick={handleCancel}>Cancel </Button>
      </Space>
    </Space>
  );
};

export default UserActive;
