import {
  Button,
  Icons,
  Input,
  List,
  Space,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { BaseRecord, useCustom, useTranslate } from "@pankod/refine-core";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  LANGUAGES_API,
  LANGUAGE_ORDER_API,
  PROFILE_API
} from "api/baseApi";
import { EMedia, EMediaDeviceInfo, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ChatStateContext } from "hooks/useContext/useChatStateContext";
import { SocketContext } from "hooks/useContext/useSocketContext";
import { useDebounce } from "hooks/useDebounce";
import { ILanguage } from "interfaces/languages";
import { IUser } from "interfaces/users";
import { IRoomInfo } from "interfaces/chat";
import { handleOpenPopup, isCustomer, openNotification } from "utils";
import { useSoundCallContext } from "hooks/useContext/useSoundCallContext";
import "./style.css";

export const ListCall: React.FC = () => {
  const t = useTranslate();
  const socket = useContext(SocketContext);
  const { chatState, setChatState } = useContext(ChatStateContext);
  const {requestSound} = useSoundCallContext();
  const [activeLanguages, setActiveLanguages] = useState<ILanguage[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedValue = useDebounce(searchValue, 500);
  const [page, setPage] = useState<number>(1);
  const [isShowLanguage, setIsShowLanguage] = useState(false);
  const [activeTopLanguage, setActiveTopLanguage] = useState<
    ILanguage[] | BaseRecord
  >([]);
  const idInternal = useRef<number>();
  const roomInfo = useRef<IRoomInfo>(null!);

  const { data: languagesData } = useCustom({
    url: LANGUAGES_API.getAllClientSide,
    method: "get",
    config: {
      query: {
        page: page,
        limit: null,
        search: debouncedValue,
      },
    },
    queryOptions: {
      onSuccess: (languagesData) => {
        setActiveLanguages(languagesData?.data?.results);
      },
    },
  });

  useCustom({
    url: LANGUAGE_ORDER_API,
    method: "get",
    queryOptions: {
      onSuccess: (languagesDataTop) => {
        setActiveTopLanguage(languagesDataTop?.data);
      },
    },
  });

  const pageTotal = languagesData?.data?.total;

  useEffect(() => {
    setPage(1);
  }, [debouncedValue]);

  const handleChange = (page: number) => {
    setPage(page);
  };

  const { data: usersData } = useCustom<IUser>({
    url: PROFILE_API,
    method: "get",
  });
  
  const handleAvailableMic = useCallback((idLanguage: number,_languageName: string,typeMedia: EMedia) => {
    socket.emit("notify-call", {
      userId: usersData?.data?.id,
      type: typeMedia,
      languageId: idLanguage,
      clientId: socket.id,
    });
    localStorage.setItem('type-media', typeMedia);
    setChatState({ ...chatState,isCancel : false, isPending: true });
    socket.on("token-twilio", (data : IRoomInfo) => {
      if (isCustomer()) {
        roomInfo.current = data;
        setChatState({ ...chatState, isCancel : false, isPending: false });
      }
    });
    idInternal.current = window.setInterval(()=>{
      if(roomInfo.current){
        clearInterval(idInternal.current);
        handleOpenPopup(roomInfo.current);
        requestSound.pause();
        roomInfo.current = null!;
      }
    }, 200);
  }, [chatState, requestSound, setChatState, socket, usersData?.data?.id]);

  const handleRequestCall = useCallback(
    async (idLanguage: number, _languageName: string, typeMedia: EMedia) => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const isAvailableMic = devices.find((device) => device.kind === EMediaDeviceInfo.AUDIO_INPUT && device.deviceId);
      if (isAvailableMic) {
        handleAvailableMic(idLanguage, _languageName, typeMedia);
      }else {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then( _ => {
          handleAvailableMic(idLanguage, _languageName, typeMedia);
        }).catch( _ => {
          openNotification(MSG_NOTIFICATION.ERROR, 'Microphone has been blocked, please allow microphone.', EStatusNotification.ERROR, 3);
        })
      }
    },
    [handleAvailableMic],
  )
  
  useEffect(()=>{
    if(chatState.isCancel) clearInterval(idInternal.current);
  }, [chatState])

  useEffect(() => {
    socket.on("language-change", (data) => {
      setActiveLanguages((prevLanguages) =>
        prevLanguages.map((language) => {
          const updatedLanguage = data.languages.find(
            (l: ILanguage) => l.id === language.id
          );
          if (updatedLanguage) {
            return { ...language, ...updatedLanguage };
          } else {
            return language;
          }
        })
      );

      setActiveTopLanguage((prevLanguages) =>
        prevLanguages.map((language: ILanguage) => {
          const updatedLanguage = data.languages.find(
            (l: ILanguage) => l.id === language.id
          );
          if (updatedLanguage) {
            return { ...language, ...updatedLanguage };
          } else {
            return language;
          }
        })
      );
    });
    return () => {
      socket.off("language-change");
      socket.off('token-twilio');
    };
  }, [socket]);

  const handleSearchLanguage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
      setIsShowLanguage(true);
    },
    [],
  )
  

  useEffect(() => {
    if (debouncedValue) {
      setIsShowLanguage(true);
    } else {
      setIsShowLanguage(false);
    }
  }, [debouncedValue, setIsShowLanguage, activeLanguages]);

  return (
    <List title={t("resource.listCall")}>
      <Input
        size="large"
        placeholder="What language do you need?"
        onChange={handleSearchLanguage}
        suffix={<Icons.SearchOutlined />}
      />
      {!isShowLanguage && (
        <>
          <Typography.Title level={5} className="title-language">
            Most Requested Languages
          </Typography.Title>

          <div className="list-language-customer">
            <Table
              className="test"
              dataSource={activeTopLanguage?.map(
                (item: ILanguage, index: number) => ({
                  ...item,
                  key: index,
                })
              )}
              rowKey="id"
              pagination={false}
              scroll={{ x: 800 }}
            >
              <Table.Column
                className="table_column"
                dataIndex="name"
                render={(value) => (
                  <Typography.Text
                    style={{ cursor: "pointer", fontWeight: 700 }}
                  >
                    {value}{" "}
                  </Typography.Text>
                )}
              />
              <Table.Column
                dataIndex="actions"
                className="table_column column-call"
                render={(_, record: ILanguage) => (
                  <Space className="item-call-video">
                    <Button
                      type="text"
                      onClick={() => handleRequestCall(record.id, record.name, EMedia.VIDEO)}
                      disabled={!record.availableVideo}
                      className="btn-media"
                    >
                      <img
                        className="img-media"
                        src={
                          record.availableVideo
                            ? "/images/global/video_enable.png"
                            : "/images/global/video_disable.png"
                        }
                        alt=""
                      />
                    </Button>
                    <Button
                      type="text"
                      className="btn-media"
                      onClick={() => handleRequestCall(record.id, record.name, EMedia.AUDIO)}
                      disabled={!record.availableTelephone}
                    >
                      <img
                        className="img-media"
                        src={
                          record.availableTelephone
                            ? "/images/global/audio_enable.png"
                            : "/images/global/audio_disable.png"
                        }
                        alt=""
                      />
                    </Button>
                  </Space>
                )}
              />
            </Table>
          </div>
        </>
      )}

      <Typography.Title level={5} className="title-language">
        Other Languages
      </Typography.Title>
      <div className="list-language-customer">
        <Table
          className="test"
          dataSource={activeLanguages?.map(
            (item: ILanguage, index: number) => ({
              ...item,
              key: index,
            })
          )}
          rowKey="id"
          pagination={
            pageTotal > 10
              ? {
                current: page,
                total: pageTotal || 1,
                onChange: handleChange,
              }
              : false
          }
          scroll={{ x: 800 }}
        >
          <Table.Column
            className="table_column"
            dataIndex="name"
            render={(value) => (
              <Typography.Text style={{ cursor: "pointer", fontWeight: 700 }}>
                {value}{" "}
              </Typography.Text>
            )}
          />
          <Table.Column
            dataIndex="actions"
            className="table_column column-call"
            render={(_, record: ILanguage) => (
              <Space className="item-call-video">
                <Button
                  onClick={() => handleRequestCall(record.id, record.name, EMedia.VIDEO)}
                  disabled={!record.availableVideo}
                  className="btn-media"
                >
                  <img
                    className="img-media"
                    src={
                      record.availableVideo
                        ? "/images/global/video_enable.png"
                        : "/images/global/video_disable.png"
                    }
                    alt=""
                  />
                </Button>
                <Button
                  className="btn-media"
                  onClick={() => handleRequestCall(record.id, record.name, EMedia.AUDIO)}
                  disabled={!record.availableTelephone}
                >
                  <img
                    className="img-media"
                    src={
                      record.availableTelephone
                        ? "/images/global/audio_enable.png"
                        : "/images/global/audio_disable.png"
                    }
                    alt=""
                  />
                </Button>
              </Space>
            )}
          />
        </Table>
      </div>
    </List>
  );
};
