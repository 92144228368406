import { Button, Space } from "@pankod/refine-antd";
import { useEffect } from "react";
import { BaseKey, useUpdate } from "@pankod/refine-core";
import { ACTIVE_RATE_TYPE_API, DEACTIVE_RATE_TYPE_API } from "api/baseApi";
import { openNotification } from "utils";
import { ServiceProps } from "./interface";
import { EStatusNotification, MSG_NOTIFICATION } from "constants/constant";

const ServiceActive = (props: ServiceProps) => {
  const { setIsModalVisible, service, refetch, page, handleChange } = props;
  const {
    mutate,
    data: activeService,
    isLoading: isLoadingActive,
  } = useUpdate();

  const handleChangeActive = () => {
    if (service?.isActive === true) {
      mutate({
        resource: DEACTIVE_RATE_TYPE_API,
        id: service?.id as BaseKey,
        values: {
          name: service,
        },
      });
    } else {
      mutate({
        resource: ACTIVE_RATE_TYPE_API,
        id: service?.id as BaseKey,
        values: {
          name: service,
        },
      });
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (activeService?.data.status == 200) {
      handleChange(page);
      openNotification(MSG_NOTIFICATION.SUCCESSFUL, activeService?.data?.message, EStatusNotification.SUCCESS);
      setIsModalVisible(false);
    }
  }, [activeService, setIsModalVisible]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [isLoadingActive]);

  return (
    <Space
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
      }}
    >
      <div>
        You are about to change the Active Status of this{" "}
        <strong>Service</strong>. Please select <strong>Change</strong> to
        continue or <strong>Cancel</strong> to go back
      </div>
      <Space style={{ marginTop: 10 }}>
        <Button
          loading={isLoadingActive}
          onClick={handleChangeActive}
          className="btn-create"
          style={{ marginRight: "10px", backgroundColor: "#" }}
        >
          Change
        </Button>
        <Button onClick={handleCancel}>Cancel </Button>
      </Space>
    </Space>
  );
};

export default ServiceActive;
