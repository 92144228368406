import { notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";
import { ReactNode, useState } from "react";

const useNotification = () => {
  const [api, contextHolder] = notification.useNotification({ maxCount: 1 });
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  const openNotificationErrors = (
    message: string,
    description: string,
    placement: NotificationPlacement,
    icon: ReactNode
  ) => {
    if (!isNotificationVisible) {
      setIsNotificationVisible(true);
      api.error({
        message,
        description,
        placement,
        duration: 1,
        icon,
        onClose: () => {
          setIsNotificationVisible(false);
        },
      });
    }
  };

  const openNotificationInfo = (
    message: string,
    description: string,
    icon: ReactNode
  ) => {
    if (!isNotificationVisible) {
      setIsNotificationVisible(true);

      api.info({
        message,
        description,
        placement: "topRight",
        duration: 2,
        icon,
        onClose: () => {
          setIsNotificationVisible(false);
        },
      });
    }
  };

  return { openNotificationErrors, openNotificationInfo, contextHolder };
};

export default useNotification;
