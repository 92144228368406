import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  TagField,
  TextField
} from "@pankod/refine-antd";
import React, { useEffect, useMemo, useState } from "react";

import { useCustom } from "@pankod/refine-core";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import {
  CALL_API,
  CUSTOMER_API,
  FORCE_KILL_ROOM,
  INTERPRETER_API,
} from "api/baseApi";
import {
  EFormatDateTime,
  ERateCall,
  EStatusHistory,
  EStatusReport
} from "constants/constant";
import dayjs, { Dayjs } from "dayjs";
import { ICustomer } from "interfaces/customer";
import { IHistory } from "interfaces/history";
import moment from "moment";
import { axiosInstance } from "providers/axios";
import {
  TableColumn,
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "utils/excelExporter";

const columns: TableColumn[] = [
  { key: "Call ID" },
  { key: "Customer Name" },
  { key: "Customer Email" },
  { key: "Department" },
  { key: "Requester Name" },
  { key: "Type of Call" },
  { key: "Language" },
  { key: "Interpreter Name" },
  { key: "Interpreter ID"},
  { key: "Call Time" },
  { key: "Call Start" },
  { key: "Call End" },
  { key: "Duration" },
  { key: "Connection Time" },
  { key: "Pay Rate"},
  { key: "Bill Rate"},
  { key: "Pay Items ($)" },
  { key: "Bill Items ($)" },
  { key: "Income" },
  { key: "Status" },
  { key: "Customer Rating"}
];

const STATUS_LIST = [
  { value: 0, label: EStatusReport.ALL },
  { value: 1, label: EStatusReport.PENDING },
  { value: 2, label: EStatusReport.CALLING },
  { value: 3, label: EStatusReport.MISSED },
  { value: 4, label: EStatusReport.REJECTED },
  { value: 5, label: EStatusReport.COMPLETE },
];

const COLUMNS_HISTORY_EXCEL = [
  "Call ID",
  "Customer Name",
  "Customer Email",
  "Department",
  "Requester Name",
  "Type of Call",
  "Language",
  "Interpreter Name",
  "Interpreter ID",
  "Call Time",
  "Call Start",
  "Call End",
  "Duration",
  "Connection Time", 
  "Pay Rate",
  "Bill Rate",
  "Pay Items ($)",
  "Bill Items ($)",
  "Income",
  "Status",
  "Other Reference 1",
  "Other Reference 2",
  "Other Reference 3",
  "NotePad",
  "Customer Rating"
];

export const HistoryList: React.FC = () => {
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const [searchCustomer, setSearchCustomer] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [searchInterpreter, setSearchInterpreter] = useState("");
  const [interpreterId, setInterpreterId] = useState("");
  const [activeHistoryCall, setActiveHistoryCall] = useState<IHistory[]>([]);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  const { data: historyCallsData, refetch } = useCustom({
    url: CALL_API.getAll,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
        customerId: customerId,
        interpreterId: interpreterId,
        start: startDate ? dayjs(startDate).format(EFormatDateTime.DATE) : "",
        end: endDate ? dayjs(endDate).format(EFormatDateTime.DATE) : "",
        status: selectedItem,
      },
    },
    queryOptions: {
      onSuccess: (historyCall) => {
        setActiveHistoryCall(historyCall?.data?.results);
      },
    },
  });

  const { data: customersData } = useCustom({
    url: CUSTOMER_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
        search: null,
      },
    },
  });

  const { data: interpretersData } = useCustom({
    url: INTERPRETER_API.getAll,
    method: "get",
    config: {
      query: {
        page: null,
        limit: null,
        search: null,
      },
    },
  });

  const handleStartDate = (startDate: Dayjs | null) => {
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate("");
    }
  };

  const handleEndDate = (endDate: Dayjs | null) => {
    if (endDate) {
      setEndDate(endDate);
    } else {
      setEndDate("");
    }
  };

  const pageTotal = historyCallsData?.data.total;

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleRejectCall = (roomId: string) => {
    setIsLoadingStatus(!isLoadingStatus);
    setIsLoading(true);
    axiosInstance
      .post(`${FORCE_KILL_ROOM}/${roomId}`, {
        roomId: roomId,
      })
      .then((data) => {
        setStatus(data?.data?.status);
        setIsLoading(false);
        return data;
      })
  };

  useEffect(() => {
    refetch();
  }, [isLoadingStatus, status]);

  const collumns = useMemo(
    () => [
      {
        title: "Call ID",
        key: "callerId",
        render: (value: string) => <TextField value={value || ""} />,
      },
      {
        title: "Call Time",
        key: "createdAt",
        render: (value: string) =>
          value ? (
            <TextField
              value={value ? dayjs(value).format(EFormatDateTime.DATETIME) : ""}
            />
          ) : (
            ""
          ),
      },
      {
        title: "Call Start",
        key: "startTime",
        render: (value: string) =>
          value ? (
            <TextField
              value={value ? dayjs(value).format(EFormatDateTime.DATETIME) : ""}
            />
          ) : (
            ""
          ),
      },
      {
        title: "Call End",
        key: "endTime",
        render: (value: string) =>
          value ? (
            <TextField
              value={value ? dayjs(value).format(EFormatDateTime.DATETIME) : ""}
            />
          ) : (
            ""
          ),
      },
      {
        title: "Language",
        key: "language",
        render: (value: string) => <TextField value={value || ""} />,
      },
      {
        title: "Duration(s)",
        key: "duration",
        render: (value: number) => <TextField value={value || ""} />,
      },
      {
        title: "Pay Items",
        key: "totalPay",
        render: (value: number) => (
          <TextField value={value ? `$${value?.toFixed(2)}` : "$0"} />
        ),
      },
      {
        title: "Bill Items",
        key: "totalBill",
        render: (value: number) => (
          <TextField value={value ? `$${value?.toFixed(2)}` : "$0"} />
        ),
      },
      {
        title: "Income",
        key: "income",
        render: (value: number) => (
          <TextField value={value ? `$${value?.toFixed(2)}` : "$0"} />
        ),
      },
      {
        title: "Interpreter",
        key: "interpreterName",
        render: (value: string) => <TextField value={value || ""} />,
      },
      {
        title: "Requester",
        key: "requesterName",
        render: (value: string) => <TextField value={value || ""} />,
      },
      {
        title: "Customer",
        key: "customerName",
        render: (value: string) => <TextField value={value || ""} />,
      },
      {
        title: "Status",
        key: "status",
        render: (value: string, record: IHistory) => (
          <>
            {isLoadingStatus ? (
              <TagField
                value={status}
                style={{
                  background: "#0073b7",
                  color: "white",
                  cursor: "pointer",
                }}
              />
            ) : (
              <Popconfirm
                title="Are you sure you want to end this call?"
                onConfirm={() => handleRejectCall(record?.roomId)}
                disabled={value === EStatusHistory.CALLING ? false : true}
              >
                <TagField
                  value={
                    value === EStatusHistory.PENDING
                      ? EStatusReport.PENDING
                      : value === EStatusHistory.CALLING
                      ? EStatusReport.CALLING
                      : value === EStatusHistory.MISSED
                      ? EStatusReport.MISSED
                      : value === EStatusHistory.COMPLETE
                      ? EStatusReport.COMPLETE
                      : EStatusReport.REJECTED
                  }
                  style={{
                    background:
                      value === EStatusHistory.PENDING
                        ? "#faad14"
                        : value === EStatusHistory.MISSED
                        ? "#ff5252"
                        : value === EStatusHistory.COMPLETE
                        ? "#0073b7"
                        : value === EStatusHistory.CALLING
                        ? "gray"
                        : "red",
                    color: "white",
                    cursor: "pointer",
                  }}
                />
              </Popconfirm>
            )}
          </>
        ),
      },
      // {
      //   title: "Rate Call",
      //   key: "rateCall",
      //   render: (value: number) => <TextField value={ERateCall[value] || ""} />,
      // },
    ],
    [page]
  );

  

  const customFiledHistory = (historysData: IHistory[]) => {
    return historysData.map((report: IHistory) => {
      const formatDate = (date: string) => date ? moment(date).format(EFormatDateTime.DATETIME) : "";
      const formatMoney = (value: number) => value?.toFixed(2) ?? "0";

      return {
        "Call ID": report?.callerId,
        "Customer Name": report?.customerName,
        "Customer Email": report?.emailCustomer,
        Department: report?.departmentName,
        "Requester Name": report?.requesterName,
        "Type of Call": report?.media,
        Language: report?.language,
        "Interpreter Name": report?.interpreterName,
        "Interpreter ID": report?.intId,
        "Call Time": formatDate(report?.createdAt),
        "Call Start": formatDate(report?.startTime),
        "Call End": formatDate(report?.endTime),
        Duration: report?.duration,
        "Connection Time": report?.connectTime,
        "Pay Rate": report?.ratePay,
        "Bill Rate": report?.rateBill,
        "Pay Items ($)": formatMoney(report?.totalPay),
        "Bill Items ($)": formatMoney(report?.totalBill),
        Income: formatMoney(report?.income),
        Status: EStatusReport[report?.status], 
        "Other Reference 1": report?.note?.otherRef1,
        "Other Reference 2": report?.note?.otherRef2,
        "Other Reference 3": report?.note?.otherRef3,
        NotePad: report?.notePad?.replace(/\n/g, ", "),
        "Customer Rating": ERateCall[report?.rating]
      };
    });
  };

  const onChange = (customer: string) => {
    setSearchCustomer(customer);
  };

  const onSelect = (customer: string, option: BaseOptionType) => {
    setSearchCustomer(option?.label);
    setCustomerId(customer);
  };

  const onChangeInterpreter = (interpreter: string) => {
    setSearchInterpreter(interpreter);
  };

  const onSelectInterpreter = (interpreter: string, option: BaseOptionType) => {
    setSearchInterpreter(option?.label);
    setInterpreterId(interpreter);
  };

  const handleRemoveCustomer = () => {
    setSearchCustomer("");
    setCustomerId("");
  };
  const handleRemoveInterpreter = () => {
    setSearchInterpreter("");
    setInterpreterId("");
  };

  

  const fetchData = async () => {
    setIsLoading(true);
    const response = await axiosInstance.get(
      CALL_API.getAll +
        `?page=${page}&limit=&customerId=${customerId}&interpreterId=${interpreterId}&start=${
          startDate ? dayjs(startDate).format(EFormatDateTime.DATE) : ""
        }&end=${
          endDate ? dayjs(endDate).format(EFormatDateTime.DATE) : ""
        }&status=${selectedItem}`
    );
    setIsLoading(false);
    return response?.data?.results;
  };

  const dateRange =
    startDate || endDate
      ? `Date Range: ${dayjs(startDate)?.format("DD/MM/YYYY")} - ${dayjs(
          endDate
        )?.format("DD/MM/YYYY")}`
      : "";

  const handleExportPDF = async () => {
    setPage(1);
    const historysData = await fetchData();
    exportToPDF({
      columns,
      data: customFiledHistory(historysData),
      fileName: "History.pdf",
      name: "Summary Report",
      date: dateRange,
      nameInvoice: searchInterpreter
        ? `Interpreter Name: ${searchInterpreter ? searchInterpreter : ""}`
        : "",
      nameCustomer: searchCustomer
        ? `Customer Name: ${searchCustomer ? searchCustomer : ""}`
        : "",
    });
  };

  const handleExportExcel = async () => {
    setPage(1);
    const historysData = await fetchData();
    exportToExcel("History", customFiledHistory(historysData), COLUMNS_HISTORY_EXCEL);
  };

  const handleExportCSV = async () => {
    setPage(1);
    const historysData = await fetchData();
    exportToCSV(customFiledHistory(historysData), "History", COLUMNS_HISTORY_EXCEL);
  };

  const handleChangeStatus = (selectedStatus: number) => {
    setSelectedItem(selectedStatus);
  };

  useEffect(() => {
    setPage(1);
  }, [startDate, endDate, customerId, interpreterId]);

  return (
    <Spin spinning={isLoading} size="large">
      <List title="History">
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Start Date"
            name="start"
            className={!isMobile ? "search-item" : ""}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={startDate}
              onChange={(e) => handleStartDate(e)}
              disabledDate={(current) => {
                return endDate ? current && current > endDate : false;
              }}
            />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="end"
            className={!isMobile ? "search-item" : ""}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={endDate}
              disabledDate={(current) => {
                return current && current < startDate;
              }}
              onChange={(e) => handleEndDate(e)}
            />
          </Form.Item>
        </Form>
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Search Interpreter by Name"
            className={!isMobile ? "search-item" : ""}
          >
            <AutoComplete
              showSearch
              size="large"
              style={{
                width: "100%",
                borderBottom: "1px solid #d9d9d9",
                borderRadius: "0px",
              }}
              options={interpretersData?.data?.results?.map(
                (item: ICustomer) => ({
                  value: item.id,
                  label: item.name,
                })
              )}
              filterOption={(
                searchInterpreter,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.label
                  ?.toUpperCase()
                  .indexOf(searchInterpreter.toUpperCase()) !== -1
              }
              bordered={false}
              value={searchInterpreter}
              onChange={onChangeInterpreter}
              onSelect={onSelectInterpreter}
            >
              <Input
                suffix={
                  <>
                    {interpreterId && (
                      <Icons.CloseOutlined onClick={handleRemoveInterpreter} />
                    )}
                    <Icons.SearchOutlined />
                  </>
                }
                bordered={false}
              />
            </AutoComplete>
          </Form.Item>

          <Form.Item
            label="Search Customer by Name"
            className={!isMobile ? "search-item" : ""}
          >
            <AutoComplete
              showSearch
              size="large"
              style={{
                width: "100%",
                borderBottom: "1px solid #d9d9d9",
                borderRadius: "0px",
              }}
              options={customersData?.data?.results?.map(
                (item: ICustomer, index: number) => ({
                  value: item.id,
                  label: item.name,
                })
              )}
              filterOption={(
                searchCustomer,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.label
                  ?.toUpperCase()
                  .indexOf(searchCustomer.toUpperCase()) !== -1
              }
              bordered={false}
              value={searchCustomer}
              onChange={onChange}
              onSelect={onSelect}
            >
              <Input
                suffix={
                  <>
                    {customerId && (
                      <Icons.CloseOutlined onClick={handleRemoveCustomer} />
                    )}
                    <Icons.SearchOutlined />
                  </>
                }
                bordered={false}
              />
            </AutoComplete>
          </Form.Item>
        </Form>
        <Form layout="vertical" className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Search By Status"
            className={!isMobile ? "search-item" : ""}
          >
            <Select
              defaultValue={0}
              value={selectedItem}
              onChange={handleChangeStatus}
            >
              {STATUS_LIST.map((item, index: number) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>

        <Space wrap={isMobile}>
          <Button className="search-report-call" onClick={handleExportExcel}>
            Excel
          </Button>
          <Button className="search-report-call" onClick={handleExportCSV}>
            CSV
          </Button>
          <Button className="search-report-call" onClick={handleExportPDF}>
            PDF
          </Button>
        </Space>

        <Table
          dataSource={activeHistoryCall?.map(
            (item: IHistory, index: number) => ({
              ...item,
              key: index,
            })
          )}
          pagination={
            pageTotal > 10
              ? {
                  current: page,
                  total: pageTotal ? pageTotal : 1,
                  onChange: handlePageChange,
                  showTotal: () => `${pageTotal} total`,
                }
              : false
          }
          scroll={{ x: 1600 }}
        >
          {collumns.map((col) => (
            <Table.Column dataIndex={col.key} {...col} />
          ))}
        </Table>
      </List>
    </Spin>
  );
};
