import { LocalAudioTrack } from 'twilio-video';
import { useCallback, useContext } from 'react';
import {VideoContext} from 'hooks/useContext/useVideoContext'
import useIsTrackEnabled from './useIsTrackEnabled';
import useIsLocalTrackStopped from './useIsLocalTrackStopped';


export default function useLocalAudioToggle() {
  const {  room: { localParticipant }, localTracks, getLocalAudioTrack } = useContext(VideoContext);
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);
  const isStopped = useIsLocalTrackStopped(audioTrack);
  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack && !isStopped) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }
    if(!audioTrack && isStopped){
      getLocalAudioTrack()
          .then((track: LocalAudioTrack) => {
            return localParticipant?.publishTrack(track, { priority: "low" });
          })
          .catch((error) => {
            window.alert(error);
          })
    }
  }, [audioTrack, isEnabled, isStopped]);

  return [isEnabled && !isStopped, toggleAudioEnabled] as const;
}
