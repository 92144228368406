import { Pie } from "@ant-design/plots";
import { Empty, List } from "@pankod/refine-antd";
import { IReportLanguage } from "../..";
import dayjs, { Dayjs } from "dayjs";

type AssetsSummaryPieChartProps = {
  reportLanguagesData: IReportLanguage[];
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  isSearching: boolean;
};
export const AssetsSummaryPieChart = (props: AssetsSummaryPieChartProps) => {
  const { reportLanguagesData, startDate, endDate, isSearching } = props;

  const config = {
    appendPadding: 10,
    data: reportLanguagesData?.slice(0, 10),
    angleField: "percentCall",
    colorField: "name",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: "{value} %",
      style: {
        fontSize: 12,
        textAlign: "center",
      },
    },
    legend: {
      position: "bottom",
    },

    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  return (
    <List
      title={`Most Requested Languages ${
        isSearching
          ? startDate &&
            endDate &&
            `(${
              dayjs(startDate).format("DD/MM/YYYY") +
              " - " +
              dayjs(endDate).format("DD/MM/YYYY")
            })`
          : ""
      }`}
    >
      {reportLanguagesData?.length > 0 ? (
        <Pie {...(config as any)} />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </List>
  );
};
