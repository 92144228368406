import { Button, Modal } from '@pankod/refine-antd';
import { useCallback } from 'react';
import '../PendingCallModal/style.css';

type BlockPopupModalProps = {
    isBlockPopup: boolean;
    setIsBlockPopup: (data: boolean) => void;
}

export default function BlockPopupModal({ isBlockPopup, setIsBlockPopup }: BlockPopupModalProps) {
    const isBlockPopupLocalStorage = localStorage.getItem('isBlockPopup') ? true : false;

    const handleAccept = useCallback(
        () => {
            localStorage.setItem('isBlockPopup', JSON.stringify(true));
            setIsBlockPopup(false);
        },
        [setIsBlockPopup],
    )

    return (
        <Modal
            visible={isBlockPopup && !isBlockPopupLocalStorage}
            footer={null}
            width={500}
            closable={false}
            maskClosable={false}
            centered
            destroyOnClose
        >
            <div className="info-inter">
                <p className="info-name">Permission</p>
                <h3 className="info-des"> This app requires pop-ups & redirects settings. Please make sure you turn on this permission on your browser. For further information, you can read here: <a href='https://support.google.com/chrome/answer/95472' target="_blank" rel="noreferrer">https://support.google.com/chrome/answer/95472</a></h3>
                <Button
                    className='btn-ok'
                    onClick={handleAccept}
                >Ok</Button>
            </div>
        </Modal>
    )
}
