import {
  AutoComplete,
  Badge,
  Button,
  Checkbox,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Popconfirm,
  Space,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { RE_SEND_PASSWORD, USER_API } from "api/baseApi";
import { CustomModal } from "components/Modal/CustomModal";
import React, { useEffect, useMemo, useState } from "react";
import "../../../../styles/style.less";
import { UserCreate } from "./create";
import { Breadcrumb } from "antd";
import {
  useCustom,
  useCustomMutation,
  useTranslate,
} from "@pankod/refine-core";
import { IUser } from "interfaces/users";
import { UserUpdate } from "./update";
import UserActive from "./active";
import { ERole } from "constants/permission";
import { EStatusNotification, IStatusResetPassword, MSG_NOTIFICATION } from "constants/constant";
import { convertToTableColumns, customColumn, openNotification } from "utils";
import { exportToCSV, exportToExcel, exportToPDF } from "utils/excelExporter";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { axiosInstance } from "providers/axios";

const { PlusSquareOutlined, SearchOutlined, CloseOutlined } = Icons;
export const UsersList: React.FC = () => {
  const t = useTranslate();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const [isModalVisibleCreate, setIsModalVisibleCreate] = useState(false);
  const [isModalVisibleUpdate, setIsModalVisibleUpdate] = useState(false);
  const [isModalVisibleActive, setIsModalVisibleActive] = useState(false);

  const [detailUser, setDetailUser] = useState<IUser>();
  const [activeUser, setActiveUser] = useState<IUser[]>([]);

  const [searchValueUser, setSearchValueUser] = useState<string>("");

  const [isRequester, setIsRequester] = useState<number | undefined>(0);

  const [page, setPage] = useState<number>(1);

  const { data: usersData, refetch } = useCustom({
    url: USER_API.getAll,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
        search: searchValueUser,
      },
    },
    queryOptions: {
      onSuccess: (usersData) => {
        setActiveUser(usersData?.data?.results);
      },
    },
  });

  const pageTotal = usersData?.data.total;

  const handleCreate = () => {
    handleOpenModel();
  };

  const handleOpenModel = () => {
    setIsModalVisibleCreate(!isModalVisibleCreate);
  };

  const update = (userData: IUser) => {
    setDetailUser(userData);
    setIsModalVisibleUpdate(true);
    setIsRequester(userData.roleId);
  };

  const active = (userData: IUser) => {
    setDetailUser(userData);
    setIsModalVisibleActive(true);
  };

  const { mutate, isLoading: isLoadingSendRequest } = useCustomMutation();
  const onSendRequest = (record: IUser) => {
    mutate(
      {
        url: RE_SEND_PASSWORD,
        method: "put",
        values: {
          id: record.id,
        },
      },
      {
        onSuccess: (createData) => {
          openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
        },
      }
    );
  };

  const collumns = useMemo(
    () => [
      {
        title: "Nbr.",
        key: "key",
        render: (value: number) => (
          <TextField
            value={
              value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
            }
          />
        ),
      },
      {
        title: "User",
        key: "username",
        render: (value: string, record: IUser) => (
          <TextField
            value={value ? value : ""}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => update(record)}
          />
        ),
      },

      {
        title: "Full Name",
        key: "fullname",
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "email",
        title: `Email`,
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "phone",
        title: `Phone`,
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        key: "roleId",
        title: `Authorize`,
        render: (value: number) => (
          <TextField
            value={
              value
                ? value === 1
                  ? ERole.ADMIN
                  : value === 2
                  ? ERole.INTERPRETER
                  : value === 3
                  ? ERole.REQUESTER
                  : ERole.AGENCY
                : ERole.AGENCY
            }
          />
        ),
      },
      {
        title: `Is Active`,
        key: "isActive",
        render: (value: boolean, record: IUser) => (
          <Checkbox checked={value} onChange={() => active(record)} />
        ),
      },
      {
        title: `Status`,
        key: "status",
        render: (value: string, record: IUser) => (
          <>
            {value === IStatusResetPassword.EXPIRED ? (
              <Popconfirm title="Send" onConfirm={() => onSendRequest(record)}>
                <Button type="primary" shape="round" size="small">
                  SEND
                </Button>
              </Popconfirm>
            ) : value === IStatusResetPassword.PENDING ? (
              <Badge
                count={IStatusResetPassword.PENDING}
                style={{
                  backgroundColor: "#faad14",
                  fontSize: 11,
                  cursor: "pointer",
                }}
              />
            ) : (
              <Badge
                count={IStatusResetPassword.APPROVED}
                style={{
                  backgroundColor: "#1890ff",
                  fontSize: 11,
                  cursor: "pointer",
                }}
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const handleChange = (page: number) => {
    setPage(page);
  };

  const onChange = (userName: string) => {
    setSearchValueUser(userName);
  };

  const handleRemoveUser = () => {
    setSearchValueUser("");
  };

  useEffect(() => {
    refetch();
  }, [
    isModalVisibleCreate,
    isModalVisibleUpdate,
    isModalVisibleActive,
    isLoadingSendRequest,
  ]);

  useEffect(() => {
    setPage(1);
  }, [searchValueUser]);

  const fetchUsersData = async () => {
    const response = await axiosInstance.get(
      USER_API.getAll + `?page=${page}&limit=&search=${searchValueUser}`
    );
    return response?.data?.results;
  };

  const customUserData = (userData: IUser[]) => {
    return userData?.map((user: IUser) => ({
      Username: user.username,
      Email: user.email,
      "Full Name": user.fullname,
      Roles: user
        ? user.roleId === 1
          ? ERole.ADMIN
          : user.roleId === 2
          ? ERole.INTERPRETER
          : user.roleId === 3
          ? ERole.REQUESTER
          : ERole.AGENCY
        : ERole.AGENCY,
      Phone: user.phone,
      Customer: user.customerName,
      Department: user.departmentName,
    }));
  };

  const handleExportPDF = async () => {
    const userData = await fetchUsersData();
    exportToPDF({
      columns: convertToTableColumns(customUserData(userData)),
      data: customUserData(userData),
      fileName: "User-Listing.pdf",
      name: "Listing User",
      nameInvoice: searchValueUser
        ? `User Name: ${searchValueUser ? searchValueUser : ""}`
        : "",
    });
  };

  const handleExportExcel = async () => {
    const userData = await fetchUsersData();
    exportToExcel(
      "User-Listing",
      customUserData(userData),
      customColumn(customUserData(userData))
    );
  };

  const handleExportCSV = async () => {
    const userData = await fetchUsersData();
    exportToCSV(
      customUserData(userData),
      "User-Listing",
      customColumn(customUserData(userData))
    );
  };

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>System Administration</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {t("resource.users")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      pageHeaderProps={{
        extra: (
          <Space wrap={isMobile}>
            <Button className="btn-create" onClick={handleCreate}>
              <PlusSquareOutlined /> {t("user.title.create")}
            </Button>
            <Button className="btn-create" onClick={handleExportExcel}>
              Excel
            </Button>
            <Button className="btn-create" onClick={handleExportCSV}>
              CSV
            </Button>
            <Button className="btn-create" onClick={() => handleExportPDF()}>
              PDF
            </Button>
          </Space>
        ),
      }}
    >
      <Form layout="vertical">
        <Form.Item label="Search User">
          <AutoComplete
            showSearch
            size="large"
            style={{
              width: isMobile ? "100%" : "50%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            value={searchValueUser}
            onChange={onChange}
          >
            <Input
              suffix={
                <>
                  {searchValueUser && (
                    <CloseOutlined onClick={handleRemoveUser} />
                  )}
                  <SearchOutlined />
                </>
              }
              bordered={false}
            />
          </AutoComplete>
        </Form.Item>
      </Form>

      <Table
        dataSource={activeUser?.map((item: IUser, index: number) => ({
          ...item,
          key: index,
        }))}
        rowKey="id"
        pagination={
          pageTotal > 10
            ? {
                current: page,
                total: pageTotal ? pageTotal : 1,
                onChange: handleChange,
                showTotal: () => `${pageTotal} total`,
              }
            : false
        }
        scroll={{ x: 800 }}
      >
        {collumns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>

      <CustomModal
        title={t("user.label.title.info")}
        setIsModalVisible={setIsModalVisibleCreate}
        isModalVisible={isModalVisibleCreate}
      >
        <UserCreate
          setIsModalVisible={setIsModalVisibleCreate}
          isModalVisible={isModalVisibleCreate}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>

      <CustomModal
        title={t("user.label.title.info")}
        setIsModalVisible={setIsModalVisibleUpdate}
        isModalVisible={isModalVisibleUpdate}
      >
        <UserUpdate
          setIsModalVisible={setIsModalVisibleUpdate}
          isModalVisible={isModalVisibleUpdate}
          user={detailUser}
          isRequester={isRequester}
          setIsRequester={setIsRequester}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>

      <CustomModal
        title="Confirmation"
        setIsModalVisible={setIsModalVisibleActive}
        isModalVisible={isModalVisibleActive}
        width={isMobile ? "100%" : "50%"}
      >
        <UserActive
          setIsModalVisible={setIsModalVisibleActive}
          isModalVisible={isModalVisibleActive}
          user={detailUser}
          page={page}
          handleChange={handleChange}
        />
      </CustomModal>
    </List>
  );
};
