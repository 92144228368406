import axios from "axios";
import { HttpError } from "@pankod/refine-core";
import { TOKEN_KEY } from "./authProvider";
import { EAuth } from "constants/constant";
import { socket } from "hooks/useContext/useSocketContext";

const baseUrl = process.env.REACT_APP_API_PROXY;

export const getAccessToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "Application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    if (
      error.response.status === 401 &&
      error.response.data?.message === EAuth.AUTHORIZED
    ) {
      socket.emit("logout", {
        clientId: socket.id,
      });
      socket.disconnect();
      localStorage.clear();
      window.location.pathname = "/login";
    }

    return Promise.reject(customError);
  }
);
