import { Button, DateField, List, Table, TextField } from "@pankod/refine-antd";
import React, { useMemo, useState } from "react";
import "../../../../styles/style.less";
import { INVOICE_PROCESS_INTERPRETER_API } from "api/baseApi";
import { useCustom, useNavigation, useTranslate } from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { IInterpreterInvoice } from "interfaces/interpreterInvoice";
import { IInterpreter } from "interfaces/interpreter";

export const ViewInterpreterInvoiceList: React.FC = () => {
  const translate = useTranslate();
  const { push } = useNavigation();
  const [activeInterpreterInvoice, setActiveInterpreterInvoice] = useState<
    IInterpreterInvoice[]
  >([]);

  const [page, setPage] = useState<number>(1);
  const { data: interpreterData } = useCustom({
    url: INVOICE_PROCESS_INTERPRETER_API.getAll,
    method: "get",
    config: {
      query: {
        page: page,
        limit: 10,
      },
    },
    queryOptions: {
      onSuccess: (interpreterData) => {
        setActiveInterpreterInvoice(interpreterData?.data?.results);
      },
    },
  });

  const handleShowProcess = (interpreter: IInterpreterInvoice) => {
    push(`/interpreter-process-detail/${interpreter?.id}`);
  };

  const collumns = useMemo(
    () => [
      {
        key: "key",
        title: "Id",
        render: (value: string) => (
          <TextField
            value={
              value ? (page - 1) * 10 + value + 1 : (page - 1) * 10 + value + 1
            }
          />
        ),
      },
      {
        key: "id",
        title: "Process",
        render: (value: string, record: IInterpreterInvoice) => (
          <TextField
            value={value ? value : 1}
            style={{ cursor: "pointer", color: "#ff5252", fontWeight: 700 }}
            onClick={() => handleShowProcess(record)}
          />
        ),
      },

      {
        key: "fromDate",
        title: "From Date",
        render: (value: string) => (
          <DateField format="LL" value={value ? value : ""} />
        ),
      },

      {
        key: "toDate",
        title: "To Date",
        render: (value: string) => (
          <DateField format="LL" value={value ? value : ""} />
        ),
      },

      {
        key: "user",
        title: `Created By`,
        render: (value: IInterpreter) => (
          <TextField
            value={value ? value?.firstname + " " + value?.lastname : ""}
          />
        ),
      },
      {
        key: "createDate",
        title: `Date`,
        render: (value: string) => (
          <DateField format="LL" value={value ? value : ""} />
        ),
      },
    ],
    [page]
  );

  const pageTotal = interpreterData?.data.total;
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleBackPage = () => {
    push("/invoice/process-interpreter-invoice");
  };

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Invoice</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.viewPrintInvoiceInterpreter")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      <Table
        dataSource={activeInterpreterInvoice?.map(
          (item: IInterpreterInvoice, index: number) => ({
            ...item,
            key: index,
          })
        )}
        pagination={
          (pageTotal as number) > 10
            ? {
                current: page,
                total: pageTotal ? pageTotal : 1,
                onChange: handlePageChange,
                showTotal: () => `${pageTotal} total`,
              }
            : false
        }
        scroll={{ x: 900 }}
      >
        {collumns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
      <Button className="process back" onClick={handleBackPage}>
        Back
      </Button>
    </List>
  );
};
