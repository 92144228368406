import { Table, Typography } from "@pankod/refine-antd";
import { EMedia } from "constants/constant";
import { ICallDetail } from "interfaces/callReport";
import { convertSecondsToTime, formatNumber } from "utils";

type PayItemsTableProps = {
  recordApptDetail: ICallDetail;
};
const { Title } = Typography;
export const PayItemsTable = (props: PayItemsTableProps) => {
  const { recordApptDetail } = props;

  const dataSource = [
    {
      name: `${
        recordApptDetail?.media === EMedia.VIDEO ? EMedia.VIDEO : EMedia.AUDIO
      }`,
      quantity: `${
        recordApptDetail?.duration
          ? `${convertSecondsToTime(recordApptDetail?.duration)}`
          : ""
      }`,
      rate: `${recordApptDetail?.ratePay || ""}`,
      ratio: `${recordApptDetail?.ratioPay}`,
      total: `${
        recordApptDetail?.totalPay
          ? `$${formatNumber(recordApptDetail?.totalPay)}`
          : `$0`
      }`,
    },
  ];

  const columns = [
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },

    {
      title: "Ratio",
      dataIndex: "ratio",
      key: "ratio",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  return (
    <>
      <Title level={5}>Pay Items</Title>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        summary={() => (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4}>
                <b>Total</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <b>
                  {recordApptDetail?.totalPay
                    ? `$${formatNumber(recordApptDetail?.totalPay)}`
                    : `$0`}
                </b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        scroll={{ x: 800 }}
      />
    </>
  );
};

export default PayItemsTable;
