import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  Row,
  Select,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import { BaseKey, useCustom, useUpdate } from "@pankod/refine-core";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { DEPARTMENT_API, STATE_API, UPDATE_DEPARTMENT_API } from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { ICheckboxChangeActive } from "interfaces";
import { IDepartment } from "interfaces/department";
import { axiosInstance } from "providers/axios";
import { useEffect, useState } from "react";
import { openNotification} from "utils";

type DepartmentsUpdateProps = {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  department: IDepartment | undefined;
};
const { TextArea } = Input;
export const DepartmentsUpdate = (props: DepartmentsUpdateProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const { setIsModalVisible, department, isModalVisible } = props;
  const [messageErr, setMessageErr] = useState<IDepartment>();
  const [departmentEdit, setDepartmentEdit] = useState<IDepartment>();

  const { form, formProps } = useForm<IDepartment>({
    action: EAction.EDIT,
  });

  useEffect(() => {
    (async () => {
      const departmentEditData = await axiosInstance.get(
        DEPARTMENT_API.getOne + `/${department?.id}`
      );

      if (departmentEditData) {
        setDepartmentEdit(departmentEditData?.data);
      }
    })();
  }, [department]);

  const { data: statesData } = useCustom({
    url: STATE_API.getAll,
    method: "get",
  });

  const { setFields } = form;

  const { mutate, data: updateData, isLoading } = useUpdate();

  const onFinish = (event: IDepartment) => {
    mutate(
      {
        resource: UPDATE_DEPARTMENT_API,
        id: department?.id as BaseKey,
        values: {
          name: event.name,
          contactName: event.contactName,
          street: event.street,
          email: event.email,
          phoneNumber: event.phoneNumber,
          suit: event.suit,
          city: event.city,
          zipCode: event.zipCode,
          mileage: event.mileage,
          travelUnits: event.travelUnits,
          accessCode: event.accessCode,
          stateId: event.stateId,
          isActive: event.isActive,
          customerId: departmentEdit?.customerId,
        },
      },
      {
        onError: (error) => {
          openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
        },
      }
    );
  };

  const onCheck = (event: ICheckboxChangeActive) => {
    if (event.target.checked) {
      form.setFieldsValue({ isActive: true });
    } else {
      form.setFieldsValue({ isActive: false });
    }
  };

  useEffect(() => {
    form.resetFields();
    setFields([
      {
        name: "name",
        value: departmentEdit?.name,
      },
      {
        name: "contactName",
        value: departmentEdit?.contactName,
      },
      {
        name: "email",
        value: departmentEdit?.email,
      },
      {
        name: "phoneNumber",
        value: departmentEdit?.phoneNumber,
      },
      {
        name: "street",
        value: departmentEdit?.street,
      },
      {
        name: "suit",
        value: departmentEdit?.suit,
      },
      {
        name: "city",
        value: departmentEdit?.city,
      },
      {
        name: "stateId",
        value: departmentEdit?.stateId,
      },
      {
        name: "zipCode",
        value: departmentEdit?.zipCode,
      },
      {
        name: "accessCode",
        value: departmentEdit?.accessCode,
      },
      {
        name: "customerId",
        value: departmentEdit?.customerId,
      },
      {
        name: "customerAccessCode",
        value: departmentEdit?.customer?.accessCode,
      },
    ]);
  }, [department, departmentEdit, form, isModalVisible]);

  useEffect(() => {
    if (updateData?.data.status == 200) {
      form.resetFields();
        openNotification(MSG_NOTIFICATION.SUCCESSFUL, updateData?.data?.message, EStatusNotification.SUCCESS);
      setIsModalVisible(false);
      setMessageErr(messageErr);
    }
  }, [updateData, form, setIsModalVisible]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(event) => onFinish(event as IDepartment)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          <Form.Item
            label="Department Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter Department Name",
              },
            ]}
            initialValue={departmentEdit?.name}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.name && (
            <Typography.Text type="danger">
              {messageErr.name[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="Contact Name"
            name="contactName"
            rules={[
              {
                required: true,
                message: "Please enter Contact Name",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input maxLength={200} />
          </Form.Item>
          {messageErr?.contactName && (
            <Typography.Text type="danger">
              {messageErr.contactName[0]}
            </Typography.Text>
          )}

          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
            initialValue={departmentEdit?.email}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.email && (
            <Typography.Text type="danger">
              {messageErr.email[0]}
            </Typography.Text>
          )}
          <Row gutter={16}>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              {" "}
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    pattern: /^(?:\d*)$/,
                    message: "Must contain 10 numbers only",
                  },
                ]}
                normalize={(value, prevVal, prevVals) => value.trim()}
                initialValue={departmentEdit?.phoneNumber}
              >
                <Input maxLength={10} />
              </Form.Item>
              {messageErr?.phoneNumber && (
                <Typography.Text type="danger">
                  {messageErr.phoneNumber[0]}
                </Typography.Text>
              )}
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 12}>
              {" "}
              <Form.Item
                label="Ext"
                name="mileage"
                rules={[
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Please enter a valid Ext number!",
                  },
                ]}
                normalize={(value, prevVal, prevVals) => value.trim()}
                initialValue={departmentEdit?.mileage}
              >
                <Input maxLength={5} />
              </Form.Item>
              {messageErr?.phoneNumber && (
                <Typography.Text type="danger">
                  {messageErr.phoneNumber[0]}
                </Typography.Text>
              )}
            </Col>
          </Row>

          <Form.Item
            label="Notes"
            name="note"
            rules={[
              {
                required: false,
                message: "Please enter Notes",
              },
            ]}
            initialValue={departmentEdit?.customer?.notes}
          >
            <TextArea rows={5} disabled />
          </Form.Item>
          {messageErr?.note && (
            <Typography.Text type="danger">
              {messageErr.note[0]}
            </Typography.Text>
          )}

          <Form.Item
            label="Access Code"
            name="customerAccessCode"
            rules={[
              {
                required: false,
                message: "Please enter Access Code",
              },
            ]}
            initialValue={departmentEdit?.customer?.accessCode}
          >
            <Input disabled />
          </Form.Item>
          {messageErr?.accessCode && (
            <Typography.Text type="danger">
              {messageErr.accessCode[0]}
            </Typography.Text>
          )}
        </Col>
        <Col className="gutter-row" span={isMobile ? 24 : 12}>
          {" "}
          <Form.Item
            label="Street Address"
            name="street"
            rules={[
              {
                required: true,
                message: "Please enter Street Address",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
            initialValue={departmentEdit?.street}
          >
            <Input maxLength={200} />
          </Form.Item>
          {messageErr?.street && (
            <Typography.Text type="danger">
              {messageErr.street[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="Suite Number"
            name="suit"
            rules={[
              {
                required: false,
                message: "Please enter Suite Number",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
            initialValue={departmentEdit?.suit}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.suit && (
            <Typography.Text type="danger">
              {messageErr.suit[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
                message: "Please enter City",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
          >
            <Input maxLength={200} />
          </Form.Item>
          {messageErr?.city && (
            <Typography.Text type="danger">
              {messageErr.city[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="State/Province"
            name="stateId"
            rules={[
              {
                required: true,
                message: "Please enter State/Province",
              },
            ]}
            initialValue={departmentEdit?.stateId}
          >
            <Select
              allowClear
              showSearch
              placeholder="Select state"
              optionFilterProp="children"
              filterOption={(
                input,
                option: BaseOptionType | DefaultOptionType | undefined
              ) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {statesData?.data?.map((item: IDepartment, index: number) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {messageErr?.stateId && (
            <Typography.Text type="danger">
              {messageErr.stateId}
            </Typography.Text>
          )}
          <Form.Item
            label="Zip Code/Postal Code"
            name="zipCode"
            rules={[
              {
                required: true,
                message: "Please enter Zip Code",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trimStart()}
            initialValue={departmentEdit?.zipCode}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.zipCode && (
            <Typography.Text type="danger">
              {messageErr.zipCode[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="Department Code"
            name="accessCode"
            rules={[
              {
                required: false,
                message: "Please enter Department Code",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
            initialValue={departmentEdit?.accessCode}
          >
            <Input maxLength={50} />
          </Form.Item>
          {messageErr?.departmentCode && (
            <Typography.Text type="danger">
              {messageErr.departmentCode[0]}
            </Typography.Text>
          )}
          <Form.Item
            label="
            "
            name="isActive"
            initialValue={departmentEdit?.isActive}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(event) => {
                onCheck(event);
              }}
              checked={departmentEdit?.isActive}
            >
              Is Active
            </Checkbox>
          </Form.Item>
          {messageErr?.isActive && (
            <Typography.Text type="danger">
              {messageErr.isActive}
            </Typography.Text>
          )}
        </Col>
      </Row>

      <div className={!isMobile ? "submit" : ""}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
