import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Grid,
  Icons,
  Input,
  List,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import {
  useCreate,
  useCustom,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import { Breadcrumb } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  CREATE_INVOICE_PROCESS_INTERPRETER_API,
  INTERPRETER_API,
  INTERPRETER_TYPE_API,
} from "api/baseApi";
import { EAction, EStatusNotification, MSG_NOTIFICATION } from "constants/constant";
import { Dayjs } from "dayjs";
import { useDebounce } from "hooks/useDebounce";
import { IInterpreterInvoice } from "interfaces/interpreterInvoice";
import React, { useEffect, useRef, useState } from "react";
import { openNotification} from "utils";
import "../style.less";

const { SearchOutlined } = Icons;
const { Title } = Typography;

interface IInterpreterType {
  id: number;
  name: string;
  checked: boolean;
}
interface IInvoiceInterpreter {
  id: number;
  name: string;
  email: string;
  isActive: boolean;
  intType: number;
  checked: boolean;
  languages: {
    id: number;
    name: string;
    isActive: boolean;
  };
}
export const ProcessInterpreterInvoiceList: React.FC = () => {
  const translate = useTranslate();
  const { push } = useNavigation();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchTerm = useDebounce(searchValue.toLowerCase(), 1000);

  const [activeInterpreters, setActiveInterpreters] = useState<
    IInvoiceInterpreter[]
  >([]);
  const [checkAll, setCheckAll] = useState(true);
  const checkedInterpreter = useRef<IInvoiceInterpreter[]>([]);
  const [interpreterType, setInterpreterType] = useState<IInterpreterType[]>(
    []
  );
  const [dateStart, setDateStart] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const { mutate, isLoading } = useCreate();
  const { formProps, form } = useForm<IInterpreterInvoice>({
    action: EAction.CREATE,
  });

  const { data: interpretersData, isSuccess } = useCustom({
    url: INTERPRETER_API.getActive,
    method: "get",
    queryOptions: {
      onSuccess(interpretersData) {
        setActiveInterpreters(
          interpretersData?.data?.map((item: IInvoiceInterpreter) => ({
            ...item,
            checked: true,
          }))
        );
      },
    },
  });

  const { data: interpreterTypeData } = useCustom({
    url: INTERPRETER_TYPE_API,
    method: "get",
    queryOptions: {
      onSuccess(interpreterTypeData) {
        setInterpreterType(
          interpreterTypeData?.data?.map((item: IInvoiceInterpreter) => ({
            ...item,
            checked: true,
          }))
        );
      },
    },
  });

  useEffect(() => {
    if (debouncedSearchTerm) {
      setActiveInterpreters(
        checkedInterpreter.current.filter((item: IInvoiceInterpreter) =>
          item.name.toLowerCase().includes(debouncedSearchTerm)
        )
      );
    } else {
      setActiveInterpreters(
        checkedInterpreter.current.filter((item) => {
          for (let index = 0; index < interpreterType.length; ++index) {
            if (
              interpreterType[index].id === item.intType &&
              interpreterType[index].checked
            ) {
              return true;
            }
          }
          return false;
        })
      );
    }
  }, [debouncedSearchTerm]);

  const onCheck = (e: CheckboxChangeEvent, idItem: number) => {
    setInterpreterType((prevData: IInterpreterType[]) => {
      const newState = [...prevData];
      newState.forEach((item) => {
        if (item.id === idItem) {
          item.checked = e.target.checked;
        }
      });
      return newState;
    });
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setActiveInterpreters((prevData: IInvoiceInterpreter[]) => {
      const newState = [...prevData];
      newState.forEach((item) => {
        item.checked = e.target.checked;
      });
      return newState;
    });
    setCheckAll(e.target.checked);
  };

  const onCheckItem = (e: CheckboxChangeEvent, idItem: number) => {
    setActiveInterpreters((prevData: IInvoiceInterpreter[]) => {
      const newState: IInvoiceInterpreter[] = [...prevData];
      newState.forEach((item) => {
        if (item.id === idItem) item.checked = e.target.checked;
      });
      return newState;
    });
  };
  useEffect(() => {
    setCheckAll(activeInterpreters.every((item) => item.checked));
  }, [activeInterpreters]);

  useEffect(() => {
    if (interpretersData && activeInterpreters) {
      if (activeInterpreters.length === interpretersData.data.length)
        checkedInterpreter.current = [...activeInterpreters];
    }
  }, [activeInterpreters, isSuccess, interpretersData]);

  useEffect(() => {
    if (!interpreterType) {
      return;
    }

    const filteredInterpreters = checkedInterpreter.current.filter((item) => {
      for (let index = 0; index < interpreterType.length; ++index) {
        const type = interpreterType[index];
        if (type.id === item.intType && type.checked) {
          return true;
        }
      }
      return false;
    });

    setActiveInterpreters(filteredInterpreters);
  }, [interpreterType, checkedInterpreter]);

  const activeProcessInterpreter = activeInterpreters
    ?.filter((interpreter: IInvoiceInterpreter) => interpreter.checked === true)
    ?.map((interpreter: IInvoiceInterpreter) => interpreter.id);

  const onFinish = (event: IInterpreterInvoice) => {
    const userId = JSON.parse(localStorage.getItem("user") || "");

    if (activeProcessInterpreter.length > 0) {
      mutate(
        {
          resource: CREATE_INVOICE_PROCESS_INTERPRETER_API,
          values: {
            fromDate: event.fromDate.toISOString().slice(0, 10),
            toDate: event.toDate.toISOString().slice(0, 10),
            userId: userId?.id,
            interpreters: activeProcessInterpreter,
          },
        },
        {
          onSuccess: (createData) => {
            form.resetFields();
            openNotification(MSG_NOTIFICATION.SUCCESSFUL, createData?.data?.message, EStatusNotification.SUCCESS);
            push("/invoice/view-print-interpreter-invoice");
          },
          onError: (error) => {
            openNotification(MSG_NOTIFICATION.ERROR, error.message, EStatusNotification.ERROR, 2);;
          },
        }
      );
    } else {
      openNotification(MSG_NOTIFICATION.ERROR, "No interpreter to process!", EStatusNotification.ERROR, 2);;
    }
  };

  const handleStartDate = (startDate: Dayjs | null) => {
    setDateStart(startDate ?? "");
  };

  const handleEndDate = (endDate: Dayjs | null) => {
    setEndDate(endDate ?? "");
  };

  return (
    <List
      title={
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>Invoice</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">
            {translate("resource.processInvoiceInterpreter")}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(event) => onFinish(event as IInterpreterInvoice)}
      >
        <div className={!isMobile ? "search-form" : ""}>
          <Form.Item
            label="Invoice Period From Date"
            name="fromDate"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter start date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={dateStart}
              onChange={(e) => handleStartDate(e)}
              disabledDate={(current) => {
                return endDate ? current && current > endDate : false;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Invoice Period To Date"
            name="toDate"
            className={!isMobile ? "search-item" : ""}
            rules={[
              {
                required: true,
                message: "Please enter end date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              value={endDate}
              disabledDate={(current) => {
                return current && current < dateStart;
              }}
              onChange={(e) => handleEndDate(e)}
            />
          </Form.Item>
        </div>
        <Title level={5} className="title-invoice">
          Filter by Interpreter Type
        </Title>
        <div>
          {interpreterType.map((item: IInterpreterType) => (
            <Checkbox
              checked={item.checked}
              onChange={(e) => onCheck(e, item.id)}
              key={item.id}
              className="item-interpreter"
            >
              {item.name}
            </Checkbox>
          ))}
        </div>
        <Title level={5} className="title-invoice">
          Interpreter's List
        </Title>
        <Form.Item label="Search by Interpreter's Name" className="item-filter">
          <Input
            style={{
              width: isMobile ? "100%" : "50%",
              borderBottom: "1px solid #d9d9d9",
              borderRadius: "0px",
            }}
            bordered={false}
            onChange={(event) => {
              setSearchValue(event.target.value);
            }}
            // onChange={handleSearchChange}
            suffix={<SearchOutlined />}
          />

          <Checkbox
            checked={checkAll}
            className="item-interpreter"
            onChange={onCheckAllChange}
            key="id"
          >
            Select / Deselect all Interpreters
          </Checkbox>
        </Form.Item>

        <Button type="primary" htmlType="submit" loading={isLoading}>
          Process
        </Button>
        <div className="list-interpreters">
          {activeInterpreters?.map((item: IInvoiceInterpreter) => (
            <Checkbox
              className={!isMobile ? "checkbox content-customer-invoice" : ""}
              key={item.id}
              value={item.id}
              onChange={(e) => onCheckItem(e, item.id)}
              checked={item.checked}
            >
              <div className="item-interpreter">{item.name}</div>
            </Checkbox>
          ))}
        </div>
      </Form>
    </List>
  );
};
