import { Table, TextField, Typography } from "@pankod/refine-antd";
import { BaseRecord } from "@pankod/refine-core";
import { EMedia } from "constants/constant";
import { useCallback, useMemo } from "react";

interface ICallActivity {
  averageCall: string;
  averageConnect: string;
  complete: string;
  disconnect: string;
  progress: string;
  total: string;
  media: string;
}

type CallActivityTableProps = {
  callActsTableRef : React.MutableRefObject<HTMLDivElement>;
  listOfCallActivity: ICallActivity[] | BaseRecord | undefined;
}

export const CallActivityTable = ({callActsTableRef,  listOfCallActivity }: CallActivityTableProps) => {

  const collumnsCallAct = useMemo(
    () => [
      {
        title: "Description",
        dataIndex: 'description',
        render: (value: string) => <TextField value={value || ""} />,
      },
      {
        title: `Video`,
        dataIndex: 'video',
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
      {
        title: `Audio`,
        dataIndex: 'audio',
        render: (value: string) => <TextField value={value ? value : ""} />,
      },
    ],
    []
  );

  const getCallActivityData = useCallback(() => {
    const callActs = listOfCallActivity?.reduce((callActOb: any, item: ICallActivity) => {
      if (item.media === EMedia.VIDEO) {
        callActOb = {
          ...callActOb,
          averageCallVideo: item.averageCall,
          averageConnectVideo: item.averageConnect,
          completeVideo: item.complete,
          disconnectVideo: item.disconnect,
          progressVideo: item.progress,
          totalVideo: item.total
        };
        return callActOb;
      }
      else {
        callActOb = {
          ...callActOb,
          averageCallAudio: item.averageCall,
          averageConnectAudio: item.averageConnect,
          completeAudio: item.complete,
          disconnectAudio: item.disconnect,
          progressAudio: item.progress,
          totalAudio: item.total
        };
        return callActOb;
      }
    }, {})
    return callActs ? [
      {
        key: "1",
        description: "Total Calls Received",
        video: callActs.totalVideo,
        audio: callActs.totalAudio,
      },
      {
        key: "2",
        description: "Calls Completed",
        video: callActs.completeVideo,
        audio: callActs.completeAudio,
      },
      {
        key: "3",
        description: "Calls Not Connected",
        video: callActs.disconnectVideo,
        audio: callActs.disconnectAudio,
      },
      {
        key: "4",
        description: "Calls In Progress",
        video: callActs.progressVideo,
        audio: callActs.progressAudio,
      },
      {
        key: "5",
        description: "Average connection time",
        video: callActs.averageConnectVideo,
        audio: callActs.averageConnectAudio,
      },
      {
        key: "6",
        description: "Average Call Length",
        video: callActs.averageCallVideo,
        audio: callActs.averageCallAudio,
      },
    ] : [];
  }, [listOfCallActivity]);

  return (
    <Table   
      bordered
      ref={callActsTableRef}
      dataSource={getCallActivityData()}
      pagination={false}
      title={() => (
        <Typography.Title level={5}>Call Activity</Typography.Title>
      )}
      columns={collumnsCallAct}
    />
  );
};
