import {
  Button,
  Divider,
  Grid,
  Icons,
  Row,
  Typography,
} from "@pankod/refine-antd";
import { useCustom, usePermissions } from "@pankod/refine-core";
import { IMAGE_API, LANGUAGE_ONLINE } from "api/baseApi";
import { EPermissions } from "constants/permission";
import { IInterpreterOnline, ILanguageOnline } from "interfaces/users";
import { useState } from "react";
import "../style.css";
import { Image } from "antd";
import { socket } from "hooks/useContext/useSocketContext";
import { ILanguage } from "interfaces/languages";
import { ETitleStatus } from "constants/constant";

type ProfileDetailProps = {
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
  isProfile: boolean;
  interpreterId: number | undefined;
};

const { UserOutlined, MailOutlined, PhoneFilled } = Icons;

export const ProfileDetail = (props: ProfileDetailProps) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.sm;
  const { isModalVisible, setIsModalVisible, interpreterId } = props;
  const { data: permissionsData } = usePermissions();
  const baseUrl = process.env.REACT_APP_API_PROXY;
  const [interpreterOnDetail, setInterpreterOnDetail] =
    useState<IInterpreterOnline>();

   useCustom<any>({
    url: LANGUAGE_ONLINE.getOne + `/${interpreterId}`,
    method: "get",
    queryOptions: {
      enabled: permissionsData?.roleId === EPermissions.ADMIN,
      onSuccess: (interpreter) => {
        setInterpreterOnDetail(interpreter?.data);
      },
    },
  });

  const handleLogoutForInterpreter = () => {
    socket.emit("logout-interpreter", {
      userId: interpreterOnDetail?.id,
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <div className={"profile-detail"}>
        <Divider orientation="left"style={{ borderTopColor: "#626262" }}>
          {interpreterOnDetail?.avatar ? (
            <Image
              preview={false}
              src={baseUrl + IMAGE_API + `/${interpreterOnDetail?.avatar}`}
              style={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                objectFit: "cover",
                cursor: "pointer",
              }}
            />
          ) : (
            <Image
              preview={false}
              src="/images/global/image.png"
              style={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                objectFit: "cover",
                cursor: "pointer",
              }}
            />
          )}
        </Divider>

        <Row
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "0px 20px 20px 0px",
            marginTop: "-40px",
          }}
        >
          {interpreterOnDetail?.status ===ETitleStatus.ONLINE.toUpperCase()? (  <Button
            style={{
              fontSize: isMobile ? 11 : 14,
            }}
            className="logout-interpreter"
            onClick={handleLogoutForInterpreter}
          >
            Logout For Interpreter
          </Button>):<br></br>
          }
        </Row>

        <Typography.Paragraph className="container-profile">
          <Typography.Paragraph>
            <Typography.Title level={4} style={{ color: "white" }}>
              {interpreterOnDetail ? interpreterOnDetail?.username : ""}
            </Typography.Title>
            <Typography.Text style={{ color: "white" }}>
              {interpreterOnDetail
                ? interpreterOnDetail?.firstname +
                  " " +
                  interpreterOnDetail?.lastname
                : ""}
            </Typography.Text>
          </Typography.Paragraph>

          <Divider style={{ background: "#ffffff87" }} />
          <Typography.Paragraph>
            <Typography.Title
              level={5}
              style={{
                color: "white",
                textTransform: "uppercase",
                display: "block",
              }}
            >
              ABOUT ME
            </Typography.Title>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Text style={{ color: "white" }}>
              <MailOutlined />{" "}
              {interpreterOnDetail ? interpreterOnDetail?.email : ""}
            </Typography.Text>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Text style={{ color: "white" }}>
              <PhoneFilled />{" "}
              {interpreterOnDetail?.fax ? `+${interpreterOnDetail?.fax}.` : ""}
              {interpreterOnDetail?.phone ? interpreterOnDetail?.phone : ""}
            </Typography.Text>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Title
              level={5}
              style={{
                color: "white",
                textTransform: "uppercase",
                display: "block",
              }}
            >
              LANGUAGES
            </Typography.Title>
          </Typography.Paragraph>

          <Typography.Paragraph
            style={{ display: "flex", flexDirection: "column", color: "white" }}
          >
            {interpreterOnDetail?.languages?.map((language: ILanguage) => (
              <div key={language?.id}>{language?.name}</div>
            ))}
          </Typography.Paragraph>
        </Typography.Paragraph>
      </div>
    </>
  );
};
