import {
  Button,
  Col,
  DateField,
  Descriptions,
  Icons,
  List,
  Row,
  Space,
  Typography,
} from "@pankod/refine-antd";
import { useCustom, useNavigation, useTranslate } from "@pankod/refine-core";
import { CALL_API, CUSTOMER_API } from "api/baseApi";
import { ICustomer } from "interfaces/customer";
import { useMemo, useState } from "react";
import "../style.less";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import * as XLSX from "xlsx";
import { CustomModal } from "components/Modal/CustomModal";
import RouterProvider from "@pankod/refine-react-router-v6";
import PayItemsTable from "./Table/PayItemsTable";
import BillItemsTable from "./Table/BillItemsTable";
import { ICallDetail } from "interfaces/callReport";
import { RequesterInformationEdit } from "./Modal/edit-requester-infomation";
import { AppointmentInformationEdit } from "./Modal/edit-appointment-information";
import { InvoiceInformationEdit } from "./Modal/edit-invoice-information";
import { convertSecondsToTime } from "utils";

const { Title } = Typography;
const AppointmentDetailCustomer = () => {
  const { push } = useNavigation();
  const t = useTranslate();

  const { useParams } = RouterProvider;
  const params = useParams();
  const [activeCustomer, setActiveCustomer] = useState<ICustomer[]>([]);
  const [isModalVisibleEditRequester, setIsModalVisibleEditRequester] =
    useState(false);
  const [isModalVisibleEditAppointment, setIsModalVisibleEditAppointment] =
    useState(false);

  const [isModalVisibleInvoice, setIsModalVisibleEditInvoice] = useState(false);

  const { data: appointmentDetail } = useCustom({
    url: CALL_API.getOne + `/${params.slug}`,
    method: "get",
  });

  const recordApptdetail = appointmentDetail?.data as ICallDetail;

  const handleBackPage = () => {
    push("/invoice/view-print-customer-invoice");
  };

  const exportCustomerPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "911 Interpreters";
    const headers = [
      [
        "Access Code",
        "Customer",
        "Business Phone",
        "Cell Phone",
        "Fax Phone",
        "Email",
        "Home Address",
        "Mailing Address",
        "Invoice Style",
        "Reference #1",
        "Reference #2",
        "Reference #3",
      ],
    ];
    const footers = `TOTAL: ${activeCustomer?.length}`;

    const customerData = activeCustomer.map((customer) => [
      customer.accessCode,
      customer.name,
      customer.businessPhone,
      customer.cellPhone,
      customer.faxNumber,
      customer.email,
      customer.homeAddress?.address1 +
        " " +
        customer.homeAddress?.city +
        " " +
        customer.homeAddress?.zipCode,
      customer.mailingAddress?.address1 +
        " " +
        customer.mailingAddress?.city +
        " " +
        customer.mailingAddress?.zipCode,
      customer.invoiceStyle,
      customer.reference1,
      customer.reference2,
      customer.reference3,
    ]);

    var pageSize = doc.internal.pageSize;

    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    doc.text(title, doc.internal.pageSize.getWidth() / 2, 20, {
      align: "center",
    });
    autoTable(doc, {
      startY: 30,
      head: headers,
      body: customerData as RowInput[],
      styles: { fontSize: 8 },
    });
    doc.text(footers, 20, pageHeight - 10);
    doc.save("customer-listing.pdf");
  };

  const exportCustomer = (filename: string) => {
    const customerData = XLSX.utils.book_new();
    const customerSheet = XLSX.utils.json_to_sheet(activeCustomer);
    XLSX.utils.book_append_sheet(customerData, customerSheet, "activeCustomer");
    XLSX.writeFile(customerData, filename);
  };

  const editRequester = (requester: any) => {
    setIsModalVisibleEditRequester(true);
  };

  const editAppointment = (requester: any) => {
    setIsModalVisibleEditAppointment(true);
  };

  const editInvoice = (requester: any) => {
    setIsModalVisibleEditInvoice(true);
  };

  return (
    <List title="Call Details">
      <Row>
        <Title level={5}>
          <span className="title-invoice">Call Number: </span>{" "}
          {recordApptdetail?.callerId}
        </Title>
      </Row>
      <Space>
        {/* <Button
            className="btn-create"
            onClick={() => exportCustomer("customer-listing.xlsx")}
          >
            Excel
          </Button>
          <Button
            className="btn-create"
            onClick={() => exportCustomer("customer-listing.csv")}
          >
            CSV
          </Button>
          <Button className="btn-create" onClick={() => exportCustomerPDF()}>
            PDF
          </Button> */}

        <Button className="process back" onClick={handleBackPage}>
          Back
        </Button>
      </Space>

      <div>
        <Row gutter={16}>
          <Col span={22}>
            {" "}
            <Title level={5} className="title-invoice">
              Requester Information
            </Title>
          </Col>
          <Col span={2}>
            {" "}
            {/* <Button
                className="btn-create"
                onClick={(requester) => editRequester(requester)}
              >
                Edit
              </Button> */}
          </Col>
        </Row>

        <Descriptions bordered>
          <Descriptions.Item
            label="Customer"
            span={3}
            labelStyle={{ fontWeight: "bold", width: "40%" }}
          >
            {recordApptdetail?.user?.requester?.customer?.name}
          </Descriptions.Item>
          <Descriptions.Item
            label="Department"
            labelStyle={{ fontWeight: "bold" }}
            span={3}
          >
            {recordApptdetail?.user?.requester?.department?.name
              ? recordApptdetail?.user?.requester?.department?.name
              : ""}
          </Descriptions.Item>
          <Descriptions.Item
            label="Requester Name"
            labelStyle={{ fontWeight: "bold" }}
            span={3}
          >
            {recordApptdetail?.user?.firstname +
              " " +
              recordApptdetail?.user?.lastname}
          </Descriptions.Item>
          <Descriptions.Item
            label="Requester Phone"
            labelStyle={{ fontWeight: "bold" }}
            span={3}
          >
            {recordApptdetail?.user?.phone}
          </Descriptions.Item>
          <Descriptions.Item
            label="Requester Email"
            labelStyle={{ fontWeight: "bold" }}
            span={3}
          >
            {recordApptdetail?.user?.email}
          </Descriptions.Item>
        </Descriptions>
      </div>

      <div className="requester-information">
        <Row gutter={16}>
          <Col span={22}>
            {" "}
            <Title level={5} className="title-invoice">
              Call Information
            </Title>
          </Col>
          <Col span={2}>
            {" "}
            {/* <Button
                className="btn-create"
                onClick={(requester) => editAppointment(requester)}
              >
                Edit
              </Button> */}
          </Col>
        </Row>
        <Descriptions bordered>
          <Descriptions.Item
            label="Start Date and Time"
            labelStyle={{ fontWeight: "bold", width: "40%" }}
            span={3}
          >
            {recordApptdetail?.startTime ? (
              <DateField
                format="LLL"
                value={
                  recordApptdetail?.startTime ? recordApptdetail?.startTime : ""
                }
              />
            ) : (
              ""
            )}
          </Descriptions.Item>

          <Descriptions.Item
            label="Duration"
            labelStyle={{ fontWeight: "bold" }}
            span={3}
          >
            {convertSecondsToTime(recordApptdetail?.duration)}
          </Descriptions.Item>
          <Descriptions.Item
            label="Time Finish"
            labelStyle={{ fontWeight: "bold" }}
            span={3}
          >
            {recordApptdetail?.endTime ? (
              <DateField
                format="LLL"
                value={
                  recordApptdetail?.endTime ? recordApptdetail?.endTime : ""
                }
              />
            ) : (
              ""
            )}
          </Descriptions.Item>
          <Descriptions.Item
            label="Language"
            labelStyle={{ fontWeight: "bold" }}
            span={3}
          >
            {recordApptdetail?.language.name}
          </Descriptions.Item>
          <Descriptions.Item
            label="Covered By"
            labelStyle={{ fontWeight: "bold" }}
            span={3}
          >
            {recordApptdetail?.interpreter?.firstname +
              " " +
              recordApptdetail?.interpreter?.lastname}
          </Descriptions.Item>
        </Descriptions>
      </div>

      <Row gutter={16}>
        <Col span={22}>
          {" "}
          <Title level={5} className="title-invoice">
            Invoice Information
          </Title>
        </Col>
        {/* <Col span={2}>
          {" "}
          <Button
            className="btn-create"
            onClick={(requester) => editInvoice(requester)}
          >
            Edit
          </Button>
        </Col> */}
      </Row>
      <PayItemsTable recordApptDetail={recordApptdetail} />
      <BillItemsTable recordApptDetail={recordApptdetail} />

      <CustomModal
        title="Edit Requester Information"
        setIsModalVisible={setIsModalVisibleEditRequester}
        isModalVisible={isModalVisibleEditRequester}
      >
        <RequesterInformationEdit
          setIsModalVisible={setIsModalVisibleEditRequester}
          isModalVisible={isModalVisibleEditRequester}
          requester={undefined}
        />
      </CustomModal>
      <CustomModal
        title="Edit Appointment Information"
        setIsModalVisible={setIsModalVisibleEditAppointment}
        isModalVisible={isModalVisibleEditAppointment}
      >
        <AppointmentInformationEdit
          setIsModalVisible={setIsModalVisibleEditAppointment}
          isModalVisible={isModalVisibleEditAppointment}
          requester={undefined}
        />
      </CustomModal>

      <CustomModal
        title="Edit Invoice Information"
        setIsModalVisible={setIsModalVisibleEditInvoice}
        isModalVisible={isModalVisibleInvoice}
      >
        <InvoiceInformationEdit
          setIsModalVisible={setIsModalVisibleEditInvoice}
          isModalVisible={isModalVisibleInvoice}
          requester={undefined}
        />
      </CustomModal>
    </List>
  );
};

export default AppointmentDetailCustomer;
