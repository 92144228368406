import {
  Button,
  Grid,
  Row,
  Space,
  Table,
  TextField,
  Typography,
} from "@pankod/refine-antd";
import { IReportLanguage } from "../..";
import { useEffect, useState } from "react";
import {
  TableColumn,
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "utils/excelExporter";
import dayjs, { Dayjs } from "dayjs";

const { Title } = Typography;

type AssetsSummaryTableProps = {
  reportLanguagesData: IReportLanguage[];
  totalCalls: number;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  isSearching: boolean;
};

type Minutes = {
  total: number;
  percent: number;
};

export const AssetsSummaryTable = (props: AssetsSummaryTableProps) => {
  const { reportLanguagesData, totalCalls, startDate, endDate, isSearching } =
    props;
  const [page, setPage] = useState<number>(1);
  const [minutes, setMinutes] = useState<Minutes>();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const columns = [
    {
      title: "",
      key: "key",
      render: (value: number) => <TextField value={value + 1} />,
    },
    {
      title: `Language`,
      key: "name",
      render: (value: string) => <TextField value={value || ""} />,
    },
    {
      title: `Total Calls`,
      key: "calls",
      render: (value: string) => (
        <TextField value={value ? Number(value) : 0} />
      ),
    },
    {
      title: `Total Minutes`,
      key: "minutes",
      render: (value: number) => <TextField value={value || 0} />,
    },
    {
      title: `% of Total Minutes`,
      key: "percentMinute",
      render: (value: string) => (
        <TextField value={value ? Number(value) : 0} />
      ),
    },
  ];

  const pageTotal = reportLanguagesData?.length;
  const handleChange = (page: number) => {
    setPage(page);
  };
  const languageDataPie = reportLanguagesData?.map(
    (language: IReportLanguage) => ({
      Language: language?.name,
      "Total Calls": language?.calls,
      "Total Minutes": language?.minutes,
      "% of Total Calls": language?.percentCall,
      "% of Total Minutes": language?.percentMinute,
    })
  );

  const columnReportLang: TableColumn[] = [
    { key: "Language" },
    { key: "Total Calls" },
    { key: "Total Minutes" },
    { key: "% of Total Calls" },
    { key: "% of Total Minutes" },
  ];

  const data = languageDataPie;
  const dateRange =
    startDate || endDate
      ? `Date Range: ${dayjs(startDate)?.format("DD/MM/YYYY")} - ${dayjs(
          endDate
        )?.format("DD/MM/YYYY")}`
      : "";
  const handleExportPDF = () => {
    exportToPDF({
      columns: columnReportLang,
      data,
      fileName: "Usage Report Languages.pdf",
      name: "Report-Languages",
      date: isSearching ? dateRange : "",
      nameInvoice: "",
    });
  };

  const columnReportLanguage = languageDataPie?.reduce((acc: any, curr: {}) => {
    Object.keys(curr).forEach((key) => {
      if (!acc.includes(key)) {
        acc.push(key);
      }
    });
    return acc;
  }, []);

  const handleExportExcel = () => {
    exportToExcel(
      "Usage Report Languages",
      languageDataPie,
      columnReportLanguage
    );
  };

  const handleExportCSV = () => {
    exportToCSV(
      languageDataPie,
      "Usage Report Languages",
      columnReportLanguage
    );
  };

  useEffect(() => {
    setMinutes({
      total: reportLanguagesData?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.minutes,
        0
      ),
      percent: reportLanguagesData?.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.percentMinute),
        0
      ),
    });
  }, [reportLanguagesData]);

  return (
    <>
      <Row
        className="report-language"
        style={{ paddingBottom: isMobile ? 20 : 0 }}
      >
        <Title
          level={5}
          style={{
            marginTop: 20,
            color: "rgba(0, 0, 0, 0.85)",
            fontWeight: 600,
            fontSize: 20,
            lineHeight: 2,
          }}
        >
          {`Usage Report-Languages ${
            isSearching
              ? startDate &&
                endDate &&
                `(${
                  dayjs(startDate).format("DD/MM/YYYY") +
                  " - " +
                  dayjs(endDate).format("DD/MM/YYYY")
                })`
              : ""
          }`}
        </Title>
        <Space wrap={isMobile}>
          <Button className="btn-create" onClick={handleExportExcel}>
            Excel
          </Button>
          <Button className="btn-create" onClick={handleExportCSV}>
            CSV
          </Button>
          <Button className="btn-create" onClick={handleExportPDF}>
            PDF
          </Button>
        </Space>
      </Row>

      <Table
        dataSource={reportLanguagesData?.map(
          (item: IReportLanguage, index: number) => ({
            ...item,
            key: index,
          })
        )}
        pagination={
          pageTotal > 10
            ? {
                total: pageTotal ? pageTotal : 1,
                current: page,
                onChange: handleChange,
              }
            : false
        }
        summary={() => (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                <b>{reportLanguagesData?.length > 0 ? "Total" : ""}</b>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={2}>
                <b>{reportLanguagesData?.length > 0 ? totalCalls : ""}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <b>{reportLanguagesData?.length > 0 ? minutes?.total : ""}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <b>
                  {reportLanguagesData?.length > 0
                    ? `${Math.round(minutes?.percent || 0)}%`
                    : ""}
                </b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        scroll={{ x: 800 }}
      >
        {columns.map((col) => (
          <Table.Column dataIndex={col.key} {...col} />
        ))}
      </Table>
    </>
  );
};
