import { notification } from "@pankod/refine-antd";
import { EFormatDateTime, EMedia, ERoomStatus, EStatusNotification, MIN_DURATION, MSG_NOTIFICATION } from "constants/constant";
import { EPermissions } from "constants/permission";
import dayjs, { Dayjs } from "dayjs";
import { TableColumn } from "./excelExporter";
import { IRoomInfo } from "interfaces/chat";
import { socket } from "hooks/useContext/useSocketContext";

type IconType = 'success' | 'info' | 'error' | 'warning';

notification.config({
  maxCount : 1,
})

export const openNotification = (msg : string, des : string, _type : IconType, _duration? : number)=>{
  notification.open({
    message : msg, 
    description : des, 
    type : _type, 
    duration : _duration || MIN_DURATION
  })
};

export const isInterpreter = () => localStorage.getItem('role') === EPermissions.INTERPRETER.toString();
export const isCustomer = () => localStorage.getItem('role') === EPermissions.CUSTOMER.toString();

export const formatNumber = (num: number) => {
  const digitsBeforeDecimal = num?.toString().split(".")[0].length;

  if (digitsBeforeDecimal <= 2) {
    return num?.toFixed(2);
  } else {
    const formattedNum = num?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const index = formattedNum?.lastIndexOf(".");
    return (
      formattedNum?.slice(0, index).replace(".", ",") +
      formattedNum?.slice(index)
    );
  }
};

export const customColumn = (arrCustom: any) => {
 return arrCustom?.reduce((acc: any, curr: {}) => {
    Object.keys(curr).forEach((key) => {
      if (!acc.includes(key)) {
        acc.push(key);
      }
    });
    return acc;
  }, []);
};

export function convertToTableColumns(arr: any[]): TableColumn[] {
  const columns: TableColumn[] = [];

  arr.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (!columns.some((column) => column.key === key)) {
        columns.push({ key });
      }
    });
  });

  return columns;
}

export const convertSecondsToTime = (seconds: any) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
  if (seconds < 3600) {
    return formattedMinutes + ":" + formattedSeconds;
  } else {
    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  }
};

export const formatDate = (time: Dayjs) => {
  return time ? dayjs(time).format(EFormatDateTime.DATE) : null;
}

export const formatDateTime = (time: Dayjs) => {
  return time ? dayjs(time).format(EFormatDateTime.DATETIME) : '';
} 

function toUnit(time : any, a : number, b : number) {
  return String(Math.floor((time % a) / b)).padStart(2, '0');
}

export function formatTimer(time : any) {
  if (Number.isNaN(parseInt(time, 10))) {
    return '';
  }

  const days = Math.floor(time / 86400);
  const hours = toUnit(time, 86400, 3600);
  const minutes = toUnit(time, 3600, 60);
  const seconds = toUnit(time, 60, 1);

  if (days > 0) {
    return `${days} ${days > 1 ? 'days' : 'day'} ${hours}:${minutes}:${seconds}`;
  }

  if (parseInt(hours) > 0) {
    return `${hours}:${minutes}:${seconds}`;
  }

  return `${minutes}:${seconds}`;
}
export interface ITotalPayMedia {
  totalPayVideo: number;
  totalPayAudio: number;
}

export const getDataSummary = (interpreterInvoice : any) => {
  const totalPay: ITotalPayMedia = interpreterInvoice.reduce((totalPay: ITotalPayMedia, currentValue: any) => {
    if (currentValue.media === EMedia.VIDEO) return ({
      ...totalPay,
      totalPayVideo: totalPay.totalPayVideo + currentValue.totalPay,
    })
    return ({
      ...totalPay,
      totalPayAudio: totalPay.totalPayAudio + currentValue.totalPay
    })
  }, {
    totalPayVideo: 0,
    totalPayAudio: 0
  });

  const totalAmounts = (totalPay.totalPayAudio + totalPay.totalPayVideo).toFixed(2);

  const summaryRecords = [{
    'Items': EMedia.VIDEO,
    'Subtotal': totalPay.totalPayVideo.toFixed(2)
  }, {
    'Items': EMedia.AUDIO,
    'Subtotal': totalPay.totalPayAudio.toFixed(2)
  }]
  
  return {summaryRecords, totalAmounts}
};

export const getTotalAmounts = (customerInvoice : any) =>{
  return customerInvoice.reduce((total : number, currentValue : any)=>{
    return total + currentValue.billTotalAmount;
  }, 0) || 0;
}

export  const handleOpenPopup = (data : IRoomInfo) => {
  localStorage.setItem("room-state", ERoomStatus.CONNECTED);
  localStorage.setItem("type-media", data.type);
  localStorage.setItem("token-twilio", data.infoCall.token);
  localStorage.setItem("token-room", data.infoCall.indentity.room);
  localStorage.setItem("callId", data.callId);
  localStorage.setItem("interpreter-id", data.interpreterId.toString());
  localStorage.setItem("language-name", data.language);
  localStorage.setItem("clientCallId", data.clientId.toString());
  const url = `${process.env.REACT_APP_CALL_URL
    }/?roomSid=${localStorage.getItem("token-room")}`;
  const newWindow = window.open();
  newWindow && (newWindow.location = url);
  if (newWindow === null || typeof newWindow === "undefined") {
    const description = "Pop-ups has been blocked, please allow pop-ups.";
    openNotification(MSG_NOTIFICATION.ERROR, description, EStatusNotification.ERROR, 4);
    socket.emit("block-call", {
      callId: localStorage.getItem("callId") as string,
    });
  }
};